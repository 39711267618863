/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import pushNotificationTypes from './pushNotification.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	sendPushNotificationResp: null,
	notificationCustomerListResp: null,
	notificationCustomerListArr: [],
};

const pushNotificationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case pushNotificationTypes.SEND_PUSH_NOTIFICATION_SUCCESS:
			return { ...state, sendPushNotificationResp: action.payload };

		case pushNotificationTypes.SEND_PUSH_NOTIFICATION_FAILURE:
			return { ...state, sendPushNotificationResp: action.payload };

		case pushNotificationTypes.SEND_PUSH_NOTIFICATION_CHANGED:
			return { ...state, sendPushNotificationResp: null };

		case pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_SUCCESS:
			return {
				...state,
				notificationCustomerListResp: action.payload,
				notificationCustomerListArr: docodeResponse(
					action.payload,
					'NotificationCustomerListKey',
				),
			};

		case pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_FAILURE:
			return {
				...state,
				notificationCustomerListResp: action.payload,
				notificationCustomerListArr: [],
			};

		case pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_CHANGED:
			return {
				...state,
				notificationCustomerListResp: null,
				notificationCustomerListArr: [],
			};

		default:
			return state;
	}
};

export default pushNotificationReducer;
