/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import listOfCustomerTypes from './listOfCustomer.types';

/* LIST OF CUSTOMER START */
export const listOfCustomerChanged = () => ({
	type: listOfCustomerTypes.GET_LIST_OF_CUSTOMER_CHANGED,
});

export const listOfCustomerReset = () => ({
	type: listOfCustomerTypes.GET_LIST_OF_CUSTOMER_RESET,
});

export const listOfCustomerStart = (listOfCustomerDetails) => ({
	type: listOfCustomerTypes.GET_LIST_OF_CUSTOMER_START,
	payload: listOfCustomerDetails,
});

export const listOfCustomerSuccess = (listCustomerSuccess) => ({
	type: listOfCustomerTypes.GET_LIST_OF_CUSTOMER_SUCCESS,
	payload: listCustomerSuccess,
});

export const listOfCustomerFailure = (error) => ({
	type: listOfCustomerTypes.GET_LIST_OF_CUSTOMER_FAILURE,
	payload: error,
});
/* LIST OF CUSTOMER END */

/* ON COD START */
export const onCodStart = (listOfCustomerDetails) => ({
	type: listOfCustomerTypes.ON_COD_START,
	payload: listOfCustomerDetails,
});

export const onCodSuccess = (listCustomerSuccess) => ({
	type: listOfCustomerTypes.ON_COD_SUCCESS,
	payload: listCustomerSuccess,
});

export const onCodFailure = (error) => ({
	type: listOfCustomerTypes.ON_COD_FAILURE,
	payload: error,
});
export const onCodChanged = () => ({
	type: listOfCustomerTypes.ON_COD_CHANGED,
});

export const onCodReset = () => ({
	type: listOfCustomerTypes.ON_COD_RESET,
});
/* ON COD END */

/* OFF COD START */
export const offCodStart = (listOfCustomerDetails) => ({
	type: listOfCustomerTypes.OFF_COD_START,
	payload: listOfCustomerDetails,
});

export const offCodSuccess = (listCustomerSuccess) => ({
	type: listOfCustomerTypes.OFF_COD_SUCCESS,
	payload: listCustomerSuccess,
});

export const offCodFailure = (error) => ({
	type: listOfCustomerTypes.OFF_COD_FAILURE,
	payload: error,
});
export const offCodChanged = () => ({
	type: listOfCustomerTypes.OFF_COD_CHANGED,
});

export const offCodReset = () => ({
	type: listOfCustomerTypes.OFF_COD_RESET,
});
/* OFF COD END */

/* VIEW CUSTOMER ORDER HISTORY START */
export const viewCustomerOrderHistoryStart = (listOfCustomerDetails) => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_START,
	payload: listOfCustomerDetails,
});

export const viewCustomerOrderHistorySuccess = (listCustomerSuccess) => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_SUCCESS,
	payload: listCustomerSuccess,
});

export const viewCustomerOrderHistoryFailure = (error) => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_FAILURE,
	payload: error,
});
export const viewCustomerOrderHistoryChanged = () => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_CHANGED,
});

export const viewCustomerOrderHistoryReset = () => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_RESET,
});
/* VIEW CUSTOMER ORDER HISTORY END */

/* REMOVE CUSTOMER START */
export const removeCustomerStart = (listOfCustomerDetails) => ({
	type: listOfCustomerTypes.REMOVE_CUSTOMER_START,
	payload: listOfCustomerDetails,
});

export const removeCustomerSuccess = (listCustomerSuccess) => ({
	type: listOfCustomerTypes.REMOVE_CUSTOMER_SUCCESS,
	payload: listCustomerSuccess,
});

export const removeCustomerFailure = (error) => ({
	type: listOfCustomerTypes.REMOVE_CUSTOMER_FAILURE,
	payload: error,
});
export const removeCustomerChanged = () => ({
	type: listOfCustomerTypes.REMOVE_CUSTOMER_CHANGED,
});

export const removeCustomerReset = () => ({
	type: listOfCustomerTypes.REMOVE_CUSTOMER_RESET,
});
/* REMOVE CUSTOMER END */

/* VIEW CUSTOMER ORDER LIST START */
export const viewCustomerOrderListStart = (listOfCustomerDetails) => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_START,
	payload: listOfCustomerDetails,
});

export const viewCustomerOrderListSuccess = (listCustomerSuccess) => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_SUCCESS,
	payload: listCustomerSuccess,
});

export const viewCustomerOrderListFailure = (error) => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_FAILURE,
	payload: error,
});
export const viewCustomerOrderListChanged = () => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_CHANGED,
});

export const viewCustomerOrderListReset = () => ({
	type: listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_RESET,
});
/* VIEW CUSTOMER ORDER LIST END */

/* ADD CUSTOMER START */
export const addCustomerStart = (listOfCustomerDetails) => ({
	type: listOfCustomerTypes.ADD_CUSTOMER_START,
	payload: listOfCustomerDetails,
});

export const addCustomerSuccess = (listCustomerSuccess) => ({
	type: listOfCustomerTypes.ADD_CUSTOMER_SUCCESS,
	payload: listCustomerSuccess,
});

export const addCustomerFailure = (error) => ({
	type: listOfCustomerTypes.ADD_CUSTOMER_FAILURE,
	payload: error,
});
export const addCustomerChanged = () => ({
	type: listOfCustomerTypes.ADD_CUSTOMER_CHANGED,
});

export const addCustomerReset = () => ({
	type: listOfCustomerTypes.ADD_CUSTOMER_RESET,
});
/* ADD CUSTOMER END */
