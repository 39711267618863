/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getComboList = (postListValue) => {
	const data = {
		GetComboListProductsData: postListValue,
	};
	const url = `${API_URL}GetComboListProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const insertComboProducts = (postListValue) => {
	const data = {
		CreateComboListProductsData: postListValue,
	};
	const url = `${API_URL}CreateComboListProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateComboProducts = (postListValue) => {
	const data = {
		UpdateComboProductsData: postListValue,
	};
	const url = `${API_URL}UpdateComboProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteComboProducts = (postListValue) => {
	const data = {
		DeleteComboProductsData: postListValue,
	};
	const url = `${API_URL}DeleteComboProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAddComboProducts = (postListValue) => {
	const data = {
		GetAddComboProductsData: postListValue,
	};
	const url = `${API_URL}GetAddComboProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createProducts = (postListValue) => {
	const data = {
		CreateProductsData: postListValue,
	};
	const url = `${API_URL}CreateProductsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getProductsList = (postListValue) => {
	const data = {
		GetProductsListData: postListValue,
	};
	const url = `${API_URL}GetProductsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteProducts = (postListValue) => {
	const data = {
		RemoveProductsData: postListValue,
	};
	const url = `${API_URL}DeleteProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const isFestivalSwitchOn = (fetchData) => {
	const data = {
		IsFestivalToken: fetchData,
	};
	const url = `${API_URL}IsFestivalSwitchOn`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
