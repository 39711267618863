/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getUserTransactionStatusList = (postListValue, apiName) => {
	const data = {
		TransactionStatusGetToken: postListValue,
	};
	const url = `${API_URL}${apiName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const transactionStatusUpdate = (transactionData, apiName) => {
	const data = {
		TransactionStatusUpdateToken: transactionData,
	};
	const url = `${API_URL}${apiName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
