/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import productComboTypes from './productCombo.types';

/* PRODUCT COMBO LIST START */
export const productComboStart = (productComboDetails) => ({
	type: productComboTypes.GET_PRODUCT_COMBO_LIST_START,
	payload: productComboDetails,
});

export const productComboSuccess = (productComboListSuccess) => ({
	type: productComboTypes.GET_PRODUCT_COMBO_LIST_SUCCESS,
	payload: productComboListSuccess,
});

export const productComboFailure = (error) => ({
	type: productComboTypes.GET_PRODUCT_COMBO_LIST_FAILURE,
	payload: error,
});

export const productComboChanged = () => ({
	type: productComboTypes.GET_PRODUCT_COMBO_LIST_CHANGED,
});

export const productComboReset = () => ({
	type: productComboTypes.GET_PRODUCT_COMBO_LIST_RESET,
});
/* PRODUCT COMBO LIST END */

/* INSERT PRODUCT COMBO START */
export const insertProductComboStart = (productComboDetails) => ({
	type: productComboTypes.INSERT_PRODUCT_COMBO_START,
	payload: productComboDetails,
});

export const insertProductComboSuccess = (productComboListSuccess) => ({
	type: productComboTypes.INSERT_PRODUCT_COMBO_SUCCESS,
	payload: productComboListSuccess,
});

export const insertProductComboFailure = (error) => ({
	type: productComboTypes.INSERT_PRODUCT_COMBO_FAILURE,
	payload: error,
});

export const insertProductComboChanged = () => ({
	type: productComboTypes.INSERT_PRODUCT_COMBO_CHANGED,
});

export const insertProductComboReset = () => ({
	type: productComboTypes.INSERT_PRODUCT_COMBO_RESET,
});
/* INSERT PRODUCT COMBO END */

/* UPDATE PRODUCT COMBO START */
export const editProductComboStart = (productComboDetails) => ({
	type: productComboTypes.UPDATE_PRODUCT_COMBO_START,
	payload: productComboDetails,
});

export const editProductComboSuccess = (productComboListSuccess) => ({
	type: productComboTypes.UPDATE_PRODUCT_COMBO_SUCCESS,
	payload: productComboListSuccess,
});

export const editProductComboFailure = (error) => ({
	type: productComboTypes.UPDATE_PRODUCT_COMBO_FAILURE,
	payload: error,
});

export const editProductComboChanged = () => ({
	type: productComboTypes.UPDATE_PRODUCT_COMBO_CHANGED,
});

export const editProductComboReset = () => ({
	type: productComboTypes.UPDATE_PRODUCT_COMBO_RESET,
});
/* UPDATE PRODUCT COMBO END */

/* OFF PRODUCT COMBO START */
export const offProductComboStart = (productComboDetails) => ({
	type: productComboTypes.OFF_PRODUCT_COMBO_START,
	payload: productComboDetails,
});

export const offProductComboSuccess = (productComboListSuccess) => ({
	type: productComboTypes.OFF_PRODUCT_COMBO_SUCCESS,
	payload: productComboListSuccess,
});

export const offProductComboFailure = (error) => ({
	type: productComboTypes.OFF_PRODUCT_COMBO_FAILURE,
	payload: error,
});

export const offProductComboChanged = () => ({
	type: productComboTypes.OFF_PRODUCT_COMBO_CHANGED,
});

export const offProductComboReset = () => ({
	type: productComboTypes.OFF_PRODUCT_COMBO_RESET,
});
/* OFF PRODUCT COMBO END */

/* ON PRODUCT COMBO START */
export const onProductComboStart = (productComboDetails) => ({
	type: productComboTypes.ON_PRODUCT_COMBO_START,
	payload: productComboDetails,
});

export const onProductComboSuccess = (productComboListSuccess) => ({
	type: productComboTypes.ON_PRODUCT_COMBO_SUCCESS,
	payload: productComboListSuccess,
});

export const onProductComboFailure = (error) => ({
	type: productComboTypes.ON_PRODUCT_COMBO_FAILURE,
	payload: error,
});

export const onProductComboChanged = () => ({
	type: productComboTypes.ON_PRODUCT_COMBO_CHANGED,
});

export const onProductComboReset = () => ({
	type: productComboTypes.ON_PRODUCT_COMBO_RESET,
});
/* ON PRODUCT COMBO END */

/* ADD LIST PRODUCT START */
export const addListProductStart = (productComboDetails) => ({
	type: productComboTypes.ADD_LIST_PRODUCT_START,
	payload: productComboDetails,
});

export const addListProductSuccess = (productComboListSuccess) => ({
	type: productComboTypes.ADD_LIST_PRODUCT_SUCCESS,
	payload: productComboListSuccess,
});

export const addListProductFailure = (error) => ({
	type: productComboTypes.ADD_LIST_PRODUCT_FAILURE,
	payload: error,
});

export const addListProductChanged = () => ({
	type: productComboTypes.ADD_LIST_PRODUCT_CHANGED,
});

export const addListProductReset = () => ({
	type: productComboTypes.ADD_LIST_PRODUCT_RESET,
});
/* ADD LIST PRODUCT END */

/* CREATE ETHIC PRODUCT START */
export const createEthicProductStart = (productComboDetails) => ({
	type: productComboTypes.CREATE_ETHIC_PRODUCT_START,
	payload: productComboDetails,
});

export const createEthicProductSuccess = (productComboListSuccess) => ({
	type: productComboTypes.CREATE_ETHIC_PRODUCT_SUCCESS,
	payload: productComboListSuccess,
});

export const createEthicProductFailure = (error) => ({
	type: productComboTypes.CREATE_ETHIC_PRODUCT_FAILURE,
	payload: error,
});

export const createEthicProductChanged = () => ({
	type: productComboTypes.CREATE_ETHIC_PRODUCT_CHANGED,
});

export const createEthicProductReset = () => ({
	type: productComboTypes.CREATE_ETHIC_PRODUCT_RESET,
});
/* CREATE ETHIC PRODUCT END */

/* GET ETHIC PRODUCTS LIST START */
export const ethicProductListStart = (productComboDetails) => ({
	type: productComboTypes.GET_ETHIC_PRODUCT_LIST_START,
	payload: productComboDetails,
});

export const ethicProductListSuccess = (productComboListSuccess) => ({
	type: productComboTypes.GET_ETHIC_PRODUCT_LIST_SUCCESS,
	payload: productComboListSuccess,
});

export const ethicProductListFailure = (error) => ({
	type: productComboTypes.GET_ETHIC_PRODUCT_LIST_FAILURE,
	payload: error,
});

export const ethicProductListChanged = () => ({
	type: productComboTypes.GET_ETHIC_PRODUCT_LIST_CHANGED,
});

export const ethicProductListReset = () => ({
	type: productComboTypes.GET_ETHIC_PRODUCT_LIST_RESET,
});
/* GET ETHIC PRODUCTS LIST END */

/* DELETE ETHIC PRODUCTS START */
export const deleteEthicProductStart = (productComboDetails) => ({
	type: productComboTypes.DELETE_ETHIC_PRODUCT_START,
	payload: productComboDetails,
});

export const deleteEthicProductSuccess = (productComboListSuccess) => ({
	type: productComboTypes.DELETE_ETHIC_PRODUCT_SUCCESS,
	payload: productComboListSuccess,
});

export const deleteEthicProductFailure = (error) => ({
	type: productComboTypes.DELETE_ETHIC_PRODUCT_FAILURE,
	payload: error,
});

export const deleteEthicProductChanged = () => ({
	type: productComboTypes.DELETE_ETHIC_PRODUCT_CHANGED,
});

export const deleteEthicProductReset = () => ({
	type: productComboTypes.DELETE_ETHIC_PRODUCT_RESET,
});
/* DELETE ETHIC PRODUCTS END */
