/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import priceUpdateAllProductsTypes from './priceUpdateAllProducts.types';
import { getAllProductsList, updateAllProductsPrice } from './priceUpdateAllProducts.service';
import {
	getAllProductsListSuccess,
	getAllProductsListFailure,
	getAllProductsListChanged,
	updatePriceAllProductsSuccess,
	updatePriceAllProductsFailure,
	updatePriceAllProductsChanged,
} from './priceUpdateAllProducts.action';
import { encodeJson } from '../enode-decode';

/* GET ALL PRODUCTS START */
export function* allProductsListGet({ payload }) {
	try {
		const key = 'GetAllProductsListKey';
		const jsonData = {
			SearchValueSet: payload.searchValueSet,
			BranchID: payload.branchID,
			Limit: payload.limit,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getAllProductsList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getAllProductsListFailure(responseData.data));
		} else {
			yield put(getAllProductsListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getAllProductsListFailure(error));
	}
}

export function* allProductsListGetReset() {
	yield put(getAllProductsListChanged());
}

export function* onAllProductsListResponseReset() {
	yield takeLatest(
		priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_RESPONSE_RESET_START,
		allProductsListGetReset,
	);
}

export function* onAllProductsList() {
	yield takeLatest(
		priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_START,
		allProductsListGet,
	);
}
/* GET ALL PRODUCTS END */

/* UPDATE PRICE ALL PRODUCTS START */
export function* allProductsPriceUpdate({ payload }) {
	try {
		const key = 'UpdateAllProductsPriceKey';
		const jsonData = {
			PriceChartArr: payload.priceChartArr,
			ProductID: payload.productID,
			StaffID: payload.staffID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateAllProductsPrice, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePriceAllProductsFailure(responseData.data));
		} else {
			yield put(updatePriceAllProductsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePriceAllProductsFailure(error));
	}
}

export function* allProductsPriceUpdateReset() {
	yield put(updatePriceAllProductsChanged());
}

export function* onAllProductsPriceUpdateResponseReset() {
	yield takeLatest(
		priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_RESPONSE_RESET_START,
		allProductsPriceUpdateReset,
	);
}

export function* onAllProductsPriceUpdate() {
	yield takeLatest(
		priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_START,
		allProductsPriceUpdate,
	);
}
/* UPDATE PRICE ALL PRODUCTS END */

export function* priceUpdateAllProductsSaga() {
	yield all([
		call(onAllProductsList),
		call(onAllProductsListResponseReset),
		call(onAllProductsPriceUpdate),
		call(onAllProductsPriceUpdateResponseReset),
	]);
}
