/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import CustomerDashboardTypes from './customerDashboard.types';

/** ***************** TOTAL CUSTOMER DETAILS START *********************** */
export const totalCustomerDetailStart = (fetchdata) => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_START,
	payload: fetchdata,
});

export const totalCustomerDetailSuccess = (successData) => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_SUCCESS,
	payload: successData,
});

export const totalCustomerDetailFailure = (failureData) => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_FAILURE,
	payload: failureData,
});

export const totalCustomerDetailResponseResetStart = () => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_RESPONSE_RESET_START,
});

export const totalCustomerDetailResponseChanged = () => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_RESPONSE_CHANGED,
});

/** ***************** TOTAL CUSTOMER DETAILS END *********************** */

/** ***************** TOTAL DATE WISE CUSTOMER DETAILS  START *********************** */
export const totalCustomerDateWiseDetailStart = (fetchdata) => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_START,
	payload: fetchdata,
});

export const totalCustomerDateWiseDetailSuccess = (successData) => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_SUCCESS,
	payload: successData,
});

export const totalCustomerDateWiseDetailFailure = (failureData) => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_FAILURE,
	payload: failureData,
});

export const totalCustomerDateWiseDetailResponseResetStart = () => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_RESPONSE_RESET_START,
});

export const totalCustomerDateWiseDetailResponseChanged = () => ({
	type: CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_RESPONSE_CHANGED,
});

/** ***************** TOTAL DATE WISE CUSTOMER DETAILS  END *********************** */
