/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import staffListTypes from './staffList.types';

/* STAFF LIST START */
export const staffListStart = (staffDetails) => ({
	type: staffListTypes.STAFF_LIST_START,
	payload: staffDetails,
});

export const staffListSuccess = (staffSuccess) => ({
	type: staffListTypes.STAFF_LIST_SUCCESS,
	payload: staffSuccess,
});

export const staffListFailure = (error) => ({
	type: staffListTypes.STAFF_LIST_FAILURE,
	payload: error,
});

export const staffListChanged = () => ({
	type: staffListTypes.STAFF_LIST_CHANGED,
});

export const staffListReset = () => ({
	type: staffListTypes.STAFF_LIST_RESET,
});

export const createStaffStart = (staffDetails) => ({
	type: staffListTypes.STAFF_CREATE_REPSONSE_START,
	payload: staffDetails,
});

export const createStaffSuccess = (staffSuccess) => ({
	type: staffListTypes.STAFF_CREATE_REPSONSE_SUCCESS,
	payload: staffSuccess,
});

export const createStaffFailure = (error) => ({
	type: staffListTypes.STAFF_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createStaffChanged = () => ({
	type: staffListTypes.STAFF_CREATE_REPSONSE_CHANGED,
});

export const createStaffReset = () => ({
	type: staffListTypes.STAFF_CREATE_REPSONSE_RESET,
});

export const updateStaffStart = (staffDetails) => ({
	type: staffListTypes.STAFF_UPDATE_REPSONSE_START,
	payload: staffDetails,
});

export const updateStaffSuccess = (staffSuccess) => ({
	type: staffListTypes.STAFF_UPDATE_REPSONSE_SUCCESS,
	payload: staffSuccess,
});

export const updateStaffFailure = (error) => ({
	type: staffListTypes.STAFF_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateStaffChanged = () => ({
	type: staffListTypes.STAFF_UPDATE_REPSONSE_CHANGED,
});

export const updateStaffReset = () => ({
	type: staffListTypes.STAFF_UPDATE_REPSONSE_RESET,
});

export const deleteStaffStart = (staffDetails) => ({
	type: staffListTypes.STAFF_DELETE_REPSONSE_START,
	payload: staffDetails,
});

export const deleteStaffSuccess = (staffSuccess) => ({
	type: staffListTypes.STAFF_DELETE_REPSONSE_SUCCESS,
	payload: staffSuccess,
});

export const deleteStaffFailure = (error) => ({
	type: staffListTypes.STAFF_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteStaffChanged = () => ({
	type: staffListTypes.STAFF_DELETE_REPSONSE_CHANGED,
});

export const deleteStaffReset = () => ({
	type: staffListTypes.STAFF_DELETE_REPSONSE_RESET,
});
/* STAFF LIST END */
