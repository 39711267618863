/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import stockListTypes from './stockList.types';

/* STOCK LIST START */
export const stockListStart = (stockDetails) => ({
	type: stockListTypes.STOCK_LIST_START,
	payload: stockDetails,
});

export const stockListSuccess = (stockSuccess) => ({
	type: stockListTypes.STOCK_LIST_SUCCESS,
	payload: stockSuccess,
});

export const stockListFailure = (error) => ({
	type: stockListTypes.STOCK_LIST_FAILURE,
	payload: error,
});

export const stockListChanged = () => ({
	type: stockListTypes.STOCK_LIST_CHANGED,
});

export const stockListReset = () => ({
	type: stockListTypes.STOCK_LIST_RESET,
});
/* STOCK LIST END */

/* REDUCE STOCK START */
export const reduceStockStart = (stockDetails) => ({
	type: stockListTypes.REDUCE_STOCK_START,
	payload: stockDetails,
});

export const reduceStockSuccess = (stockSuccess) => ({
	type: stockListTypes.REDUCE_STOCK_SUCCESS,
	payload: stockSuccess,
});

export const reduceStockFailure = (error) => ({
	type: stockListTypes.REDUCE_STOCK_FAILURE,
	payload: error,
});

export const reduceStockChanged = () => ({
	type: stockListTypes.REDUCE_STOCK_CHANGED,
});

export const reduceStockReset = () => ({
	type: stockListTypes.REDUCE_STOCK_RESET,
});
/* REDUCE STOCK END */

/* ADD STOCK START */
export const stockAddStart = (stockDetails) => ({
	type: stockListTypes.ADD_STOCK_START,
	payload: stockDetails,
});

export const stockAddSuccess = (stockSuccess) => ({
	type: stockListTypes.ADD_STOCK_SUCCESS,
	payload: stockSuccess,
});

export const stockAddFailure = (error) => ({
	type: stockListTypes.ADD_STOCK_FAILURE,
	payload: error,
});

export const stockAddChanged = () => ({
	type: stockListTypes.ADD_STOCK_CHANGED,
});

export const stockAddReset = () => ({
	type: stockListTypes.ADD_STOCK_RESET,
});
/* ADD STOCK END */

/* TOP SELLING SWITCH START */
export const topSellingSwitchStart = (stockDetails) => ({
	type: stockListTypes.TOP_SELLING_SWITCH_START,
	payload: stockDetails,
});

export const topSellingSwitchSuccess = (stockSuccess) => ({
	type: stockListTypes.TOP_SELLING_SWITCH_SUCCESS,
	payload: stockSuccess,
});

export const topSellingSwitchFailure = (error) => ({
	type: stockListTypes.TOP_SELLING_SWITCH_FAILURE,
	payload: error,
});

export const topSellingSwitchChanged = () => ({
	type: stockListTypes.TOP_SELLING_SWITCH_CHANGED,
});

export const topSellingSwitchReset = () => ({
	type: stockListTypes.TOP_SELLING_SWITCH_RESET,
});
/* TOP SELLING SWITCH END */

/* VIEW STOCK EXPIRY START */
export const viewStockExpiryStart = (stockDetails) => ({
	type: stockListTypes.VIEW_STOCK_EXPIRY_START,
	payload: stockDetails,
});

export const viewStockExpirySuccess = (stockSuccess) => ({
	type: stockListTypes.VIEW_STOCK_EXPIRY_SUCCESS,
	payload: stockSuccess,
});

export const viewStockExpiryFailure = (error) => ({
	type: stockListTypes.VIEW_STOCK_EXPIRY_FAILURE,
	payload: error,
});

export const viewStockExpiryChanged = () => ({
	type: stockListTypes.VIEW_STOCK_EXPIRY_CHANGED,
});

export const viewStockExpiryReset = () => ({
	type: stockListTypes.VIEW_STOCK_EXPIRY_RESET,
});
/* VIEW STOCK EXPIRY END */
