/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { encodeJson } from '../enode-decode';

import listOfCustomerTypes from './listOfCustomer.types';
import {
	getListOfCustomer,
	listOfOnCod,
	listOfOffCod,
	getListViewCutomerHistory,
	deleteCustomer,
	getListViewCutomerOrder,
	addNewCustomer,
} from './listOfCustomer.service';
import {
	listOfCustomerSuccess,
	listOfCustomerFailure,
	listOfCustomerChanged,
	onCodSuccess,
	onCodFailure,
	onCodChanged,
	offCodSuccess,
	offCodFailure,
	offCodChanged,
	viewCustomerOrderHistorySuccess,
	viewCustomerOrderHistoryFailure,
	viewCustomerOrderHistoryChanged,
	removeCustomerSuccess,
	removeCustomerFailure,
	removeCustomerChanged,
	viewCustomerOrderListSuccess,
	viewCustomerOrderListFailure,
	viewCustomerOrderListChanged,
	addCustomerSuccess,
	addCustomerFailure,
	addCustomerChanged,
} from './listOfCustomer.action';

/* LIST OF CUSTOMER START */
export function* customerOfList({ payload }) {
	try {
		const key = 'Get_Customer_Details';
		const jsonData = {
			SearchValueSet: payload.searchValueSet,
			Limit: payload.limit,
			UserID: payload.UserID,
		};
		const fetchData = encodeJson(jsonData, key);
		const responseData = yield call(getListOfCustomer, fetchData);
		if (responseData.data.statusCode === '100') {
			yield put(listOfCustomerFailure(responseData.data));
		} else {
			yield put(listOfCustomerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(listOfCustomerFailure(error));
	}
}

export function* getListOfCustomerReset() {
	yield put(listOfCustomerChanged());
}

export function* getCustomerListReset() {
	yield takeLatest(listOfCustomerTypes.GET_LIST_OF_CUSTOMER_RESET, getListOfCustomerReset);
}

export function* getCustomerList() {
	yield takeLatest(listOfCustomerTypes.GET_LIST_OF_CUSTOMER_START, customerOfList);
}
/* LIST OF CUSTOMER END */

/* ON COD START */
export function* onCodList({ payload: { onCod } }) {
	try {
		const jsonData = {
			OnUserID: onCod,
		};
		const responseData = yield call(listOfOnCod, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(onCodFailure(responseData.data));
		} else {
			yield put(onCodSuccess(responseData.data));
		}
	} catch (error) {
		yield put(onCodFailure(error));
	}
}

export function* onCodListReset() {
	yield put(onCodChanged());
}

export function* onCodDetails() {
	yield takeLatest(listOfCustomerTypes.ON_COD_START, onCodList);
}

export function* resetOnCodDetails() {
	yield takeLatest(listOfCustomerTypes.ON_COD_RESET, onCodListReset);
}
/* ON COD END */

/* OFF COD START */
export function* offCodList({ payload: { offCod } }) {
	try {
		const jsonData = {
			OffUserID: offCod,
		};
		const responseData = yield call(listOfOffCod, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(offCodFailure(responseData.data));
		} else {
			yield put(offCodSuccess(responseData.data));
		}
	} catch (error) {
		yield put(offCodFailure(error));
	}
}

export function* offCodListReset() {
	yield put(offCodChanged());
}

export function* offCodDetails() {
	yield takeLatest(listOfCustomerTypes.OFF_COD_START, offCodList);
}

export function* resetOffCodDetails() {
	yield takeLatest(listOfCustomerTypes.OFF_COD_RESET, offCodListReset);
}
/* OFF COD END */

/* VIEW CUSTOMER ORDER HISTORY START */
export function* viewCustomerHistoryList({ payload }) {
	try {
		const key = 'View_Cutomer_History';
		const jsonData = {
			UserID: payload.UserID,
			ProductsTab: payload.productsTab,
			Limit: payload.limit,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getListViewCutomerHistory, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(viewCustomerOrderHistoryFailure(responseData.data));
		} else {
			yield put(viewCustomerOrderHistorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewCustomerOrderHistoryFailure(error));
	}
}

export function* viewCustomerHistoryListReset() {
	yield put(viewCustomerOrderHistoryChanged());
}

export function* viewCustomerHistoryDetails() {
	yield takeLatest(
		listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_START,
		viewCustomerHistoryList,
	);
}

export function* resetviewCustomerHistoryDetails() {
	yield takeLatest(
		listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_RESET,
		viewCustomerHistoryListReset,
	);
}
/* VIEW CUSTOMER ORDER HISTORY END */

/* REMOVE CUSTOMER START */
export function* customerRemoveList({ payload }) {
	try {
		const key = 'Cutomer_Remove';
		const jsonData = {
			UserID: payload.userID,
			StaffID: payload.staffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteCustomer, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(removeCustomerFailure(responseData.data));
		} else {
			yield put(removeCustomerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(removeCustomerFailure(error));
	}
}

export function* customerRemoveListReset() {
	yield put(removeCustomerChanged());
}

export function* removeCustomerDetails() {
	yield takeLatest(listOfCustomerTypes.REMOVE_CUSTOMER_START, customerRemoveList);
}

export function* resetViewRemoveCustomer() {
	yield takeLatest(listOfCustomerTypes.REMOVE_CUSTOMER_RESET, customerRemoveListReset);
}
/* REMOVE CUSTOMER END */

/* VIEW CUSTOMER ORDER LIST START */
export function* viewCustomerOrderList({ payload }) {
	try {
		const key = 'View_Customer_GetKey';
		const jsonData = {
			UserID: payload.UserID,
			OrderPlaceID: payload.OrderPlaceID,
			OrderFromCustomerID: payload.OrderFromCustomerID,
			ProductsTab: payload.ProductsTab,
			OrderMode: payload.OrderMode,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getListViewCutomerOrder, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(viewCustomerOrderListFailure(responseData.data));
		} else {
			yield put(viewCustomerOrderListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewCustomerOrderListFailure(error));
	}
}

export function* viewCustomerOrderListReset() {
	yield put(viewCustomerOrderListChanged());
}

export function* viewCustomerListDetails() {
	yield takeLatest(listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_START, viewCustomerOrderList);
}

export function* resetviewCustomerListDetails() {
	yield takeLatest(
		listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_RESET,
		viewCustomerOrderListReset,
	);
}
/* VIEW CUSTOMER ORDER LIST END */

/* ON CUSTOMER ADD START */
export function* onCustomerAdd({ payload: { values, StaffID } }) {
	try {
		const jsonData = {
			UserName: values.UserName,
			PhoneNumber: values.PhoneNumber,
			Address: values.Address,
			PinCode: values.PinCode,
			EmailID: values.EmailID,
			LocationName: values.LocationName,
			LandMark: values.LandMark,
			CityID: values.CityID,
			StateID: values.StateID,
			CreatedBy: StaffID,
		};
		const responseData = yield call(addNewCustomer, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(addCustomerFailure(responseData.data));
		} else {
			yield put(addCustomerSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addCustomerFailure(error));
	}
}

export function* onCustomerAddReset() {
	yield put(addCustomerChanged());
}

export function* onCustomerAddDetails() {
	yield takeLatest(listOfCustomerTypes.ADD_CUSTOMER_START, onCustomerAdd);
}

export function* resetOnCustomerAddDetails() {
	yield takeLatest(listOfCustomerTypes.ADD_CUSTOMER_RESET, onCustomerAddReset);
}
/* ON CUSTOMER ADD END */

export function* listOfCustomersSaga() {
	yield all([
		call(getCustomerList),
		call(getCustomerListReset),
		call(onCodDetails),
		call(resetOnCodDetails),
		call(offCodDetails),
		call(resetOffCodDetails),
		call(viewCustomerHistoryDetails),
		call(resetviewCustomerHistoryDetails),
		call(removeCustomerDetails),
		call(resetViewRemoveCustomer),
		call(viewCustomerListDetails),
		call(resetviewCustomerListDetails),
		call(onCustomerAddDetails),
		call(resetOnCustomerAddDetails),
	]);
}
