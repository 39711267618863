/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import stockListTypes from './stockList.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	stockListResponse: null,
	stockListArrResp: [],
	reduceStockResponse: null,
	stockAddResponse: null,
	topSellingSwitchResponse: null,
	viewStockExpiryResponse: null,
	viewStockExpiryArrResp: [],
};

const stockListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case stockListTypes.STOCK_LIST_SUCCESS:
			return {
				...state,
				stockListResponse: action.payload,
				stockListArrResp: docodeResponse(action.payload, 'StockListKey'),
			};

		case stockListTypes.STOCK_LIST_FAILURE:
			return {
				...state,
				stockListResponse: action.payload,
				stockListArrResp: [],
			};

		case stockListTypes.STOCK_LIST_CHANGED:
			return { ...state, stockListResponse: null };

		case stockListTypes.REDUCE_STOCK_SUCCESS:
			return {
				...state,
				reduceStockResponse: action.payload,
			};

		case stockListTypes.REDUCE_STOCK_FAILURE:
			return {
				...state,
				reduceStockResponse: action.payload,
			};

		case stockListTypes.REDUCE_STOCK_CHANGED:
			return { ...state, reduceStockResponse: null };

		case stockListTypes.ADD_STOCK_SUCCESS:
			return {
				...state,
				stockAddResponse: action.payload,
			};

		case stockListTypes.ADD_STOCK_FAILURE:
			return {
				...state,
				stockAddResponse: action.payload,
			};

		case stockListTypes.ADD_STOCK_CHANGED:
			return { ...state, stockAddResponse: null };

		case stockListTypes.TOP_SELLING_SWITCH_SUCCESS:
			return {
				...state,
				topSellingSwitchResponse: action.payload,
			};

		case stockListTypes.TOP_SELLING_SWITCH_FAILURE:
			return {
				...state,
				topSellingSwitchResponse: action.payload,
			};

		case stockListTypes.TOP_SELLING_SWITCH_CHANGED:
			return { ...state, topSellingSwitchResponse: null };

		case stockListTypes.VIEW_STOCK_EXPIRY_SUCCESS:
			return {
				...state,
				viewStockExpiryResponse: action.payload,
				viewStockExpiryArrResp: docodeResponse(action.payload, 'GetViewStockListKey'),
			};

		case stockListTypes.VIEW_STOCK_EXPIRY_FAILURE:
			return {
				...state,
				viewStockExpiryResponse: action.payload,
				viewStockExpiryArrResp: [],
			};

		case stockListTypes.VIEW_STOCK_EXPIRY_CHANGED:
			return { ...state, viewStockExpiryResponse: null };

		default:
			return state;
	}
};

export default stockListReducer;
