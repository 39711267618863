// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const getListProducts = (fetchData, serviceName) => {
	const data = {
		CategoryProductListToken: fetchData,
	};
	const url = `${API_URL + serviceName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getProductListAll = (fetchData) => {
	const data = {
		DashboardProductToken: fetchData,
	};
	const url = `${API_URL}GetDashboardProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const checkUserLogin = (fetchData) => {
	const data = {
		LoginToken: fetchData,
	};
	const url = `${API_URL}UserLogin`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const verifyOtp = (fetchData) => {
	const data = {
		LoginOtpToken: fetchData,
	};
	const url = `${API_URL}OtpVerify`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addressAdd = (fetchData) => {
	const data = {
		AddAddressToken: fetchData,
	};
	const url = `${API_URL}addAddressDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const orderPlace = (fetchData) => {
	const data = {
		ProductPlaceOrderToken: fetchData,
	};
	const url = `${API_URL}PlaceNewOrder`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllBranchPincode = (flowerData) => {
	const data = {
		AllBranchPincodeToken: flowerData,
	};
	const url = `${API_URL}getAllBranchPincode`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
