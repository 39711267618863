/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import StaffReportTypes from './staff-report.types';

/** ***************** FETCH STAFF REPORT DETAILS START *********************** */
export const fetchStaffReportDetailStart = (fetchdata) => ({
	type: StaffReportTypes.STAFF_REPORT_DETAILS_START,
	payload: fetchdata,
});

export const fetchStaffReportDetailSuccess = (successData) => ({
	type: StaffReportTypes.STAFF_REPORT_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchStaffReportDetailFailure = (failureData) => ({
	type: StaffReportTypes.STAFF_REPORT_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchStaffReportDetailResponseResetStart = () => ({
	type: StaffReportTypes.STAFF_REPORT_DETAILS_RESPONSE_RESET_START,
});

export const fetchStaffReportDetailResponseChanged = () => ({
	type: StaffReportTypes.STAFF_REPORT_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH STAFF REPORT DETAILS END *********************** */
