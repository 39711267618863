/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import priceUpdateTypes from './priceUpdate.types';

/* PRICE UPDATE LIST START */
export const priceUpdateStart = (priceDetails) => ({
	type: priceUpdateTypes.PRICE_UPDATE_LIST_START,
	payload: priceDetails,
});

export const priceUpdateSuccess = (priceSuccess) => ({
	type: priceUpdateTypes.PRICE_UPDATE_LIST_SUCCESS,
	payload: priceSuccess,
});

export const priceUpdateFailure = (error) => ({
	type: priceUpdateTypes.PRICE_UPDATE_LIST_FAILURE,
	payload: error,
});

export const priceUpdateChanged = () => ({
	type: priceUpdateTypes.PRICE_UPDATE_LIST_CHANGED,
});

export const priceUpdateReset = () => ({
	type: priceUpdateTypes.PRICE_UPDATE_LIST_RESET,
});
/* PRICE UPDATE LIST END */

/* PRICE UPDATE LIST START */
export const priceEditStart = (priceDetails) => ({
	type: priceUpdateTypes.PRICE_UPDATE_START,
	payload: priceDetails,
});

export const priceEditSuccess = (priceSuccess) => ({
	type: priceUpdateTypes.PRICE_UPDATE_SUCCESS,
	payload: priceSuccess,
});

export const priceEditFailure = (error) => ({
	type: priceUpdateTypes.PRICE_UPDATE_FAILURE,
	payload: error,
});

export const priceEditChanged = () => ({
	type: priceUpdateTypes.PRICE_UPDATE_CHANGED,
});

export const priceEditReset = () => ({
	type: priceUpdateTypes.PRICE_UPDATE_RESET,
});
/* PRICE UPDATE LIST END */
