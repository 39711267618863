/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import productComboTypes from './productCombo.types';
import {
	getProductComboList,
	addProductCombo,
	updateProductCombo,
	updateOffProductCombo,
	updateOnProductCombo,
	getAddProductList,
	ethicProductCreate,
	getEthicProductList,
	deleteEthicProduct,
} from './productCombo.service';
import {
	productComboSuccess,
	productComboFailure,
	productComboChanged,
	insertProductComboSuccess,
	insertProductComboFailure,
	insertProductComboChanged,
	editProductComboSuccess,
	editProductComboFailure,
	editProductComboChanged,
	offProductComboSuccess,
	offProductComboFailure,
	offProductComboChanged,
	onProductComboSuccess,
	onProductComboFailure,
	onProductComboChanged,
	addListProductSuccess,
	addListProductFailure,
	addListProductChanged,
	createEthicProductSuccess,
	createEthicProductFailure,
	createEthicProductChanged,
	ethicProductListSuccess,
	ethicProductListFailure,
	ethicProductListChanged,
	deleteEthicProductSuccess,
	deleteEthicProductFailure,
	deleteEthicProductChanged,
} from './productCombo.action';

/* LIST PRODUCT COMBO START */
export function* listOfProductCombo({ payload: { listOfComboProduct } }) {
	try {
		const responseData = yield call(getProductComboList, listOfComboProduct);
		if (responseData.data.statusCode === '100') {
			yield put(productComboFailure(responseData.data));
		} else {
			yield put(productComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productComboFailure(error));
	}
}

export function* listOfProductComboReset() {
	yield put(productComboChanged());
}

export function* getListProductComboReset() {
	yield takeLatest(productComboTypes.GET_PRODUCT_COMBO_LIST_RESET, listOfProductComboReset);
}

export function* getListProductCombo() {
	yield takeLatest(productComboTypes.GET_PRODUCT_COMBO_LIST_START, listOfProductCombo);
}
/* LIST PRODUCT COMBO END */

/* INSERT PRODUCT COMBO START */
export function* productComboAddList({ payload: { values, StaffID } }) {
	try {
		const jsonData = {
			ComboName: values.ComboName,
			ProductComboDesc: values.ProductComboDesc,
			ComboPrice: values.ComboPrice,
			ComboMrpPrice: values.ComboMrpPrice,
			// ComboDiscount: values.ComboDiscount,
			CreatedBy: StaffID,
		};
		const responseData = yield call(addProductCombo, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(insertProductComboFailure(responseData.data));
		} else {
			yield put(insertProductComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(insertProductComboFailure(error));
	}
}

export function* productComboAddListReset() {
	yield put(insertProductComboChanged());
}

export function* productComboAddStart() {
	yield takeLatest(productComboTypes.INSERT_PRODUCT_COMBO_START, productComboAddList);
}

export function* productComboAddReset() {
	yield takeLatest(productComboTypes.INSERT_PRODUCT_COMBO_RESET, productComboAddListReset);
}
/* INSERT PRODUCT COMBO END */

/* UPDATE PRODUCT COMBO START */
export function* updateProductComboList({ payload: { values, StaffID } }) {
	try {
		const jsonData = {
			ProductComboID: values.ProductComboID,
			ComboName: values.ComboName,
			ProductComboDesc: values.ProductComboDesc,
			ComboPrice: values.ComboPrice,
			ComboMrpPrice: values.ComboMrpPrice,
			// ComboDiscount: values.ComboDiscount,
			CreatedBy: StaffID,
		};
		const responseData = yield call(updateProductCombo, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(editProductComboFailure(responseData.data));
		} else {
			yield put(editProductComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(editProductComboFailure(error));
	}
}

export function* updateProductComboResponse() {
	yield put(editProductComboChanged());
}

export function* updateProductComboReset() {
	yield takeLatest(productComboTypes.UPDATE_PRODUCT_COMBO_RESET, updateProductComboResponse);
}

export function* updateProductComboStart() {
	yield takeLatest(productComboTypes.UPDATE_PRODUCT_COMBO_START, updateProductComboList);
}
/* UPDATE PRODUCT COMBO END */

/* OFF PRODUCT COMBO START */
export function* productComboOff({ payload: { turnOffProductCombo } }) {
	try {
		const jsonData = {
			ProductComboID: turnOffProductCombo,
		};
		const responseData = yield call(updateOffProductCombo, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(offProductComboFailure(responseData.data));
		} else {
			yield put(offProductComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(offProductComboFailure(error));
	}
}

export function* productComboOffResponse() {
	yield put(offProductComboChanged());
}

export function* productComboOffReset() {
	yield takeLatest(productComboTypes.OFF_PRODUCT_COMBO_RESET, productComboOffResponse);
}

export function* productComboOffStart() {
	yield takeLatest(productComboTypes.OFF_PRODUCT_COMBO_START, productComboOff);
}
/* OFF PRODUCT COMBO END */

/* ON PRODUCT COMBO START */
export function* productComboOn({ payload: { turnOnProductCombo } }) {
	try {
		const jsonData = {
			ProductComboID: turnOnProductCombo,
		};
		const responseData = yield call(updateOnProductCombo, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(onProductComboFailure(responseData.data));
		} else {
			yield put(onProductComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(onProductComboFailure(error));
	}
}

export function* productComboOnResponse() {
	yield put(onProductComboChanged());
}

export function* productComboOnReset() {
	yield takeLatest(productComboTypes.ON_PRODUCT_COMBO_RESET, productComboOnResponse);
}

export function* productComboOnStart() {
	yield takeLatest(productComboTypes.ON_PRODUCT_COMBO_START, productComboOn);
}
/* ON PRODUCT COMBO END */

/* ADD PRODUCT LIST START */
export function* listProductAdd({ payload: { comboAddProductList } }) {
	try {
		const responseData = yield call(getAddProductList, comboAddProductList);
		if (responseData.data.statusCode === '100') {
			yield put(addListProductFailure(responseData.data));
		} else {
			yield put(addListProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addListProductFailure(error));
	}
}

export function* listProductAddReset() {
	yield put(addListProductChanged());
}

export function* listProductAddResponseReset() {
	yield takeLatest(productComboTypes.ADD_LIST_PRODUCT_RESET, listProductAddReset);
}

export function* listProductAddStart() {
	yield takeLatest(productComboTypes.ADD_LIST_PRODUCT_START, listProductAdd);
}
/* ADD PRODUCT LIST END */

/* CREATE ETHIC PRODUCT START */
export function* createEthicProduct({ payload: { productDropDown } }) {
	try {
		const jsonData = JSON.stringify(productDropDown).replace(/"/g, "'");
		const responseData = yield call(ethicProductCreate, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(createEthicProductFailure(responseData.data));
		} else {
			yield put(createEthicProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createEthicProductFailure(error));
	}
}

export function* createEthicProductReset() {
	yield put(createEthicProductChanged());
}

export function* ethicProductCreateResponseReset() {
	yield takeLatest(productComboTypes.CREATE_ETHIC_PRODUCT_RESET, createEthicProductReset);
}

export function* ethicProductCreateStart() {
	yield takeLatest(productComboTypes.CREATE_ETHIC_PRODUCT_START, createEthicProduct);
}
/* CREATE ETHIC PRODUCT END */

/* ETHIC PRODUCTS LIST START */
export function* ethicProductList({ payload: { id } }) {
	try {
		const responseData = yield call(getEthicProductList, id);
		if (responseData.data.statusCode === '100') {
			yield put(ethicProductListFailure(responseData.data));
		} else {
			yield put(ethicProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(ethicProductListFailure(error));
	}
}

export function* ethicProductListReset() {
	yield put(ethicProductListChanged());
}

export function* ethicProductListGetResponseReset() {
	yield takeLatest(productComboTypes.GET_ETHIC_PRODUCT_LIST_RESET, ethicProductListReset);
}

export function* ethicProductListGetStart() {
	yield takeLatest(productComboTypes.GET_ETHIC_PRODUCT_LIST_START, ethicProductList);
}
/* ETHIC PRODUCTS LIST END */

/* DELETE ETHIC PRODUCT START */
export function* ethicProductsRemove({ payload: { ProductsValue } }) {
	try {
		const responseData = yield call(deleteEthicProduct, ProductsValue);
		if (responseData.data.statusCode === '100') {
			yield put(deleteEthicProductFailure(responseData.data));
		} else {
			yield put(deleteEthicProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteEthicProductFailure(error));
	}
}

export function* ethicProductsRemoveResponse() {
	yield put(deleteEthicProductChanged());
}

export function* removeEthicProductResponseReset() {
	yield takeLatest(productComboTypes.DELETE_ETHIC_PRODUCT_RESET, ethicProductsRemoveResponse);
}

export function* removeEthicProductStart() {
	yield takeLatest(productComboTypes.DELETE_ETHIC_PRODUCT_START, ethicProductsRemove);
}
/* DELETE ETHIC PRODUCT END */

export function* productComboSaga() {
	yield all([
		call(getListProductCombo),
		call(getListProductComboReset),
		call(productComboAddStart),
		call(productComboAddReset),
		call(updateProductComboStart),
		call(updateProductComboReset),
		call(productComboOffStart),
		call(productComboOffReset),
		call(productComboOnStart),
		call(productComboOnReset),
		call(listProductAddStart),
		call(listProductAddResponseReset),
		call(ethicProductCreateStart),
		call(ethicProductCreateResponseReset),
		call(ethicProductListGetStart),
		call(ethicProductListGetResponseReset),
		call(removeEthicProductStart),
		call(removeEthicProductResponseReset),
	]);
}
