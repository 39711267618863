/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
const TodayEodsTypes = {
	TODAY_EODS_STAFF_LIST_START: 'today_eods_staff_list_start',
	TODAY_EODS_STAFF_LIST_SUCCESS: 'today_eods_staff_list_success',
	TODAY_EODS_STAFF_LIST_FAILURE: 'today_eods_staff_list_failure',
	TODAY_EODS_STAFF_LIST_RESPONSE_RESET_START: 'today_eods_staff_list_response_reset_start',
	TODAY_EODS_STAFF_LIST_RESPONSE_CHANGED: 'today_eods_staff_list_response_reset_changed',

	TODAY_EODS_ORDER_LIST_START: 'today_eods_order_list_start',
	TODAY_EODS_ORDER_LIST_SUCCESS: 'today_eods_order_list_success',
	TODAY_EODS_ORDER_LIST_FAILURE: 'today_eods_order_list_failure',
	TODAY_EODS_ORDER_LIST_RESPONSE_RESET_START: 'today_eods_order_list_response_reset_start',
	TODAY_EODS_ORDER_LIST_RESPONSE_CHANGED: 'today_eods_order_list_response_reset_changed',

	UPDATE_ORDER_STATUS_START: 'update_order_status_start',
	UPDATE_ORDER_STATUS_SUCCESS: 'update_order_status_success',
	UPDATE_ORDER_STATUS_FAILURE: 'update_order_status_failure',
	UPDATE_ORDER_STATUS_RESPONSE_RESET_START: 'update_order_status_response_reset_start',
	UPDATE_ORDER_STATUS_RESPONSE_CHANGED: 'update_order_status_response_reset_changed',
};
export default TodayEodsTypes;
