/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import TodayEodsTypes from './today-eods.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	fetchTodayEodsStaffListResponse: null,
	fetchTodayEodsStaffListArr: [],
	fetchTodayEodsOrderListResponse: null,
	fetchTodayEodsOrderListArr: [],
	updateOrderStatusResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case TodayEodsTypes.TODAY_EODS_STAFF_LIST_SUCCESS:
			return {
				...state,
				fetchTodayEodsStaffListResponse: action.payload,
				fetchTodayEodsStaffListArr: docodeResponse(
					action.payload,
					'GetTodayEodsStaffListKey',
				),
			};

		case TodayEodsTypes.TODAY_EODS_STAFF_LIST_FAILURE:
			return {
				...state,
				fetchTodayEodsStaffListResponse: action.payload,
				fetchTodayEodsStaffListArr: [],
			};

		case TodayEodsTypes.TODAY_EODS_STAFF_LIST_RESPONSE_CHANGED:
			return { ...state, fetchTodayEodsStaffListResponse: null };

		case TodayEodsTypes.TODAY_EODS_ORDER_LIST_SUCCESS:
			return {
				...state,
				fetchTodayEodsOrderListResponse: action.payload,
				fetchTodayEodsOrderListArr: docodeResponse(
					action.payload,
					'GetTodayEodsOrderListKey',
				),
			};

		case TodayEodsTypes.TODAY_EODS_ORDER_LIST_FAILURE:
			return {
				...state,
				fetchTodayEodsOrderListResponse: action.payload,
				fetchTodayEodsOrderListArr: [],
			};

		case TodayEodsTypes.TODAY_EODS_ORDER_LIST_RESPONSE_CHANGED:
			return { ...state, fetchTodayEodsOrderListResponse: null };

		case TodayEodsTypes.UPDATE_ORDER_STATUS_SUCCESS:
			return { ...state, updateOrderStatusResponse: action.payload };

		case TodayEodsTypes.UPDATE_ORDER_STATUS_FAILURE:
			return { ...state, updateOrderStatusResponse: action.payload };

		case TodayEodsTypes.UPDATE_ORDER_STATUS_RESPONSE_CHANGED:
			return { ...state, updateOrderStatusResponse: null };

		default:
			return state;
	}
};
