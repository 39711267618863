const PackingReportActionType = {
	GET_WHOLE_PACKING_HISTORY_START: 'get_whole_packing_history_start',
	GET_WHOLE_PACKING_HISTORY_SUCCESS: 'get_whole_packing_history_success',
	GET_WHOLE_PACKING_HISTORY_FAILURE: 'get_whole_packing_history_failure',
	GET_WHOLE_PACKING_HISTORY_RESPONSE_REST_START: 'get_whole_packing_history_response_reset_start',
	GET_WHOLE_PACKING_HISTORY_RESPONSE_CHANGED: 'get_whole_packing_history_response_changed',

	GET_DELIVERY_PACKING_HISTORY_START: 'get_delivery_packing_history_start',
	GET_DELIVERY_PACKING_HISTORY_SUCCESS: 'get_delivery_packing_history_success',
	GET_DELIVERY_PACKING_HISTORY_FAILURE: 'get_delivery_packing_history_failure',
	GET_DELIVERY_PACKING_HISTORY_RESPONSE_REST_START:
		'get_delivery_packing_history_response_reset_start',
	GET_DELIVERY_PACKING_HISTORY_RESPONSE_CHANGED: 'get_delivery_packing_history_response_changed',
};

export default PackingReportActionType;
