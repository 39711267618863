/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useRef, useState, useEffect } from 'react';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Brand from '../Brand/Brand';
import Navigation from '../Navigation/Navigation';
import User from '../User/User';
import { dashboardMenu, demoPages, ethicMenu, flowersMenu, level2StaffMenu } from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Card, { CardBody } from '../../components/bootstrap/Card';

import Hand from '../../assets/img/hand.png';
import HandWebp from '../../assets/img/hand.webp';
import Icon from '../../components/icon/Icon';
import Button from '../../components/bootstrap/Button';
import Tooltips from '../../components/bootstrap/Tooltips';
import useDarkMode from '../../hooks/useDarkMode';
import useAsideTouch from '../../hooks/useAsideTouch';
import {
	signInStart,
	signInReset,
	loginAndLogoutStart,
	loginAndLogoutReset,
} from '../../redux/login/login.actions';
import { selectCurrentUser, selectLoginAndLogoutLogResp } from '../../redux/login/login.selector';
import showNotification from '../../components/extras/showNotification';
// import Popovers from '../../components/bootstrap/Popovers';

const Aside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const { asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x } = useAsideTouch();

	const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

	const constraintsRef = useRef(null);

	const [doc, setDoc] = useState(false);

	const { t } = useTranslation(['translation', 'menu']);

	const { darkModeStatus } = useDarkMode();

	// Use useRef for state variables that should persist across renders
	const isLoggedIn = useRef(true);
	const logoutTimer = useRef(null);

	const dispatch = useDispatch();

	const navigate = useNavigate();
	const loginData = useSelector(selectCurrentUser);
	const loginAndLogoutLogResp = useSelector(selectLoginAndLogoutLogResp);

	const sideMenuCrenditals = useRef({
		staffPhoneNumber: '',
		staffID: '',
		deliveryProduct: '',
		logType: '',
	});

	useEffect(() => {
		if (loginData !== null) {
			sideMenuCrenditals.current.staffPhoneNumber = loginData.response[0].StaffPhoneNumber;
			sideMenuCrenditals.current.staffID = loginData.response[0].StaffID;
			sideMenuCrenditals.current.deliveryProduct = loginData.response[0].DeliveryProduct;
			const values = {
				phoneNumber: sideMenuCrenditals.current.staffPhoneNumber,
			};
			dispatch(signInStart({ values }));
		}
	}, []);

	useEffect(() => {
		if (loginData !== null) {
			if (loginData.statusCode === '02') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Cancel' size='lg' className='me-1' />
						<span>Staff Not Exist</span>
					</span>,
					'Staff is decative',
				);
				logOutScreen();
			}
		}

		if (loginAndLogoutLogResp !== null && loginAndLogoutLogResp !== undefined) {
			if (
				loginAndLogoutLogResp.statusCode === '01' &&
				sideMenuCrenditals.current.logType === 'LOGOUT'
			) {
				dispatch(signInReset());
				navigate(`${demoPages.login.path}`);
			}
			dispatch(loginAndLogoutReset());
		}

		const handleUserActivity = () => {
			resetLogoutTimer();
		};

		// Attach event listeners for user activity (e.g., mousemove, keydown)
		window.addEventListener('mousemove', handleUserActivity);
		window.addEventListener('keydown', handleUserActivity);

		// Set the initial logout timer
		resetLogoutTimer();

		// Cleanup event listeners on component unmount
		return () => {
			window.removeEventListener('mousemove', handleUserActivity);
			window.removeEventListener('keydown', handleUserActivity);
			clearTimeout(logoutTimer);
		};
	}, [loginData, loginAndLogoutLogResp, logoutTimer]);

	// Function to reset the logout timer
	const resetLogoutTimer = () => {
		if (logoutTimer) {
			clearTimeout(logoutTimer);
		}

		// Set a new timer for 10 minutes (adjust the time as needed)
		const newLogoutTimer = setTimeout(() => {
			// Perform logout action
			isLoggedIn.current = false;
			if (sideMenuCrenditals.current.staffID === '91') {
				logOutScreen();
			}
			// You might want to redirect to a login page or show a modal here
		}, 1200000); // 10 minutes in milliseconds

		logoutTimer.current = newLogoutTimer;
	};

	const logOutScreen = () => {
		sideMenuCrenditals.current.logType = 'LOGOUT';
		const stData = {
			StaffID: sideMenuCrenditals.current.staffID,
			LogType: sideMenuCrenditals.current.logType,
		};
		dispatch(loginAndLogoutStart(stData));
	};

	// const { fullScreenStatus, setFullScreenStatus } = useContext(ThemeContext);
	// const styledBtn = {
	// 	color: darkModeStatus ? 'dark' : 'light',
	// 	hoverShadow: 'default',
	// 	isLight: !darkModeStatus,
	// 	size: 'lg',
	// };

	return (
		<>
			<motion.aside
				style={asideStyle}
				className={classNames(
					'aside',
					{ open: asideStatus },
					{
						'aside-touch-bar': hasTouchButton && isModernDesign,
						'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
						'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
					},
				)}>
				<div className='aside-head'>
					<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
				</div>
				<div className='aside-body'>
					{sideMenuCrenditals.current.deliveryProduct === 'Ethic' ? (
						<Navigation menu={ethicMenu} id='aside-ethic' />
					) : null}
					{sideMenuCrenditals.current.deliveryProduct === 'Flower' ? (
						<Navigation menu={flowersMenu} id='aside-flower' />
					) : null}
					{sideMenuCrenditals.current.deliveryProduct === 'Admin' ? (
						<Navigation menu={dashboardMenu} id='aside-admin' />
					) : null}
					{sideMenuCrenditals.current.staffID === '91' ? (
						<Navigation menu={level2StaffMenu} id='aside-flower' />
					) : null}
					{/* {!doc && (
						<>
							<NavigationLine />
							<Navigation menu={demoPages} id='aside-demo-pages' />
							<NavigationLine />
							<Navigation menu={layoutMenu} id='aside-menu' />
						</>
					)}

					{doc && (
						<>
							<NavigationLine />
							<Navigation menu={componentsMenu} id='aside-menu-two' />
							<NavigationLine />
						</>
					)} */}

					{asideStatus && doc && (
						<Card className='m-3 '>
							<CardBody className='pt-0'>
								<img
									srcSet={HandWebp}
									src={Hand}
									alt='Hand'
									width={130}
									height={130}
								/>
								<p
									className={classNames('h4', {
										'text-dark': !darkModeStatus,
										'text-light': darkModeStatus,
									})}>
									{t('Everything is ready!')}
								</p>
								<Button
									color='info'
									isLight
									className='w-100'
									onClick={() => setDoc(false)}>
									{t('Demo Pages')}
								</Button>
							</CardBody>
						</Card>
					)}
				</div>
				<div className='aside-foot'>
					{/* <nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => {
									setDoc(!doc);
								}}
								data-tour='documentation'>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											icon={doc ? 'ToggleOn' : 'ToggleOff'}
											color={doc ? 'success' : null}
											className='navigation-icon'
										/>
										<span className='navigation-text'>
											{t('menu:Documentation')}
										</span>
									</span>
								</span>
							</div>
						</div>
					</nav> */}
					<nav aria-label='aside-bottom-menu'>
						<div className='navigation'>
							<div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={logOutScreen}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon icon='Logout' className='navigation-icon' />
										<span className='navigation-text'>Logout</span>
									</span>
								</span>
							</div>
							{/* Dark Mode */}
							{/* <div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => setDarkModeStatus(!darkModeStatus)}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											className='navigation-icon'
											icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
											aria-label='Toggle fullscreen'
											data-tour='dark-mode'
										/>
										<span className='navigation-text'>DarkMode</span>
									</span>
								</span>
							</div> */}

							{/*	Full Screen */}
							{/* <div
								role='presentation'
								className='navigation-item cursor-pointer'
								onClick={() => setFullScreenStatus(!fullScreenStatus)}>
								<span className='navigation-link navigation-link-pill'>
									<span className='navigation-link-info'>
										<Icon
											// eslint-disable-next-line react/jsx-props-no-spreading
											{...styledBtn}
											className='navigation-icon'
											icon={
												fullScreenStatus ? 'FullscreenExit' : 'Fullscreen'
											}
											aria-label='Toggle dark mode'
										/>
										<span className='navigation-text'>Full Screen</span>
									</span>
								</span>
							</div> */}
						</div>
					</nav>
					<User />
				</div>
			</motion.aside>
			{asideStatus && hasTouchButton && isModernDesign && (
				<>
					<motion.div className='aside-drag-area' ref={constraintsRef} />
					<Tooltips title='Toggle Aside' flip={['top', 'right']}>
						<motion.div
							className='aside-touch'
							drag='x'
							whileDrag={{ scale: 1.2 }}
							whileHover={{ scale: 1.1 }}
							dragConstraints={constraintsRef}
							// onDrag={(event, info) => console.log(info.point.x, info.point.y)}
							dragElastic={0.1}
							style={{ x, zIndex: 1039 }}
							onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
						/>
					</Tooltips>
				</>
			)}
		</>
	);
};

export default Aside;
