/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import bannerTypes from './banner.types';

/* BANNER LIST START */
export const bannerListStart = (bannerDetails) => ({
	type: bannerTypes.BANNER_LIST_START,
	payload: bannerDetails,
});

export const bannerListSuccess = (bannerSuccess) => ({
	type: bannerTypes.BANNER_LIST_SUCCESS,
	payload: bannerSuccess,
});

export const bannerListFailure = (error) => ({
	type: bannerTypes.BANNER_LIST_FAILURE,
	payload: error,
});

export const bannerListChanged = () => ({
	type: bannerTypes.BANNER_LIST_CHANGED,
});

export const bannerListReset = () => ({
	type: bannerTypes.BANNER_LIST_RESET,
});
/* BANNER LIST END */

/* BANNER CREATE START */
export const bannerCreateStart = (bannerDetails) => ({
	type: bannerTypes.BANNER_CREATE_START,
	payload: bannerDetails,
});

export const bannerCreateSuccess = (bannerSuccess) => ({
	type: bannerTypes.BANNER_CREATE_SUCCESS,
	payload: bannerSuccess,
});

export const bannerCreateFailure = (error) => ({
	type: bannerTypes.BANNER_CREATE_FAILURE,
	payload: error,
});

export const bannerCreateChanged = () => ({
	type: bannerTypes.BANNER_CREATE_CHANGED,
});

export const bannerCreateReset = () => ({
	type: bannerTypes.BANNER_CREATE_RESET,
});
/* BANNER CREATE END */

/* BANNER UPDATE START */
export const bannerUpdateStart = (bannerDetails) => ({
	type: bannerTypes.BANNER_UPDATE_START,
	payload: bannerDetails,
});

export const bannerUpdateSuccess = (bannerSuccess) => ({
	type: bannerTypes.BANNER_UPDATE_SUCCESS,
	payload: bannerSuccess,
});

export const bannerUpdateFailure = (error) => ({
	type: bannerTypes.BANNER_UPDATE_FAILURE,
	payload: error,
});

export const bannerUpdateChanged = () => ({
	type: bannerTypes.BANNER_UPDATE_CHANGED,
});

export const bannerUpdateReset = () => ({
	type: bannerTypes.BANNER_UPDATE_RESET,
});
/* BANNER UPDATE END */

/* BANNER DELETE START */
export const bannerDeleteStart = (bannerDetails) => ({
	type: bannerTypes.BANNER_DELETE_START,
	payload: bannerDetails,
});

export const bannerDeleteSuccess = (bannerSuccess) => ({
	type: bannerTypes.BANNER_DELETE_SUCCESS,
	payload: bannerSuccess,
});

export const bannerDeleteFailure = (error) => ({
	type: bannerTypes.BANNER_DELETE_FAILURE,
	payload: error,
});

export const bannerDeleteChanged = () => ({
	type: bannerTypes.BANNER_DELETE_CHANGED,
});

export const bannerDeleteReset = () => ({
	type: bannerTypes.BANNER_DELETE_RESET,
});
/* BANNER DELETE END */
