const placeOrderType = {
	LIST_PRODUCT_START: 'LIST_PRODUCT_START',
	LIST_PRODUCT_SUCCESS: 'LIST_PRODUCT_SUCCESS',
	LIST_PRODUCT_FAILURE: 'LIST_PRODUCT_FAILURE',
	LIST_PRODUCT_RESET: 'LIST_PRODUCT_RESET',
	LIST_PRODUCT_CHANGED: 'LIST_PRODUCT_CHANGED',

	ALL_PRODUCT_LIST_START: 'ALL_PRODUCT_LIST_START',
	ALL_PRODUCT_LIST_SUCCESS: 'ALL_PRODUCT_LIST_SUCCESS',
	ALL_PRODUCT_LIST_FAILURE: 'ALL_PRODUCT_LIST_FAILURE',
	ALL_PRODUCT_LIST_RESET: 'ALL_PRODUCT_LIST_RESET',
	ALL_PRODUCT_LIST_CHANGED: 'ALL_PRODUCT_LIST_CHANGED',

	USER_LOGIN_CHECK_START: 'USER_LOGIN_CHECK_START',
	USER_LOGIN_CHECK_SUCCESS: 'USER_LOGIN_CHECK_SUCCESS',
	USER_LOGIN_CHECK_FAILURE: 'USER_LOGIN_CHECK_FAILURE',
	USER_LOGIN_CHECK_RESET: 'USER_LOGIN_CHECK_RESET',
	USER_LOGIN_CHECK_CHANGED: 'USER_LOGIN_CHECK_CHANGED',

	OTP_VERIFIY_START: 'OTP_VERIFIY_START',
	OTP_VERIFIY_SUCCESS: 'OTP_VERIFIY_SUCCESS',
	OTP_VERIFIY_FAILURE: 'OTP_VERIFIY_FAILURE',
	OTP_VERIFIY_RESET: 'OTP_VERIFIY_RESET',
	OTP_VERIFIY_CHANGED: 'OTP_VERIFIY_CHANGED',

	ADD_ADDRESS_START: 'ADD_ADDRESS_START',
	ADD_ADDRESS_SUCCESS: 'ADD_ADDRESS_SUCCESS',
	ADD_ADDRESS_FAILURE: 'ADD_ADDRESS_FAILURE',
	ADD_ADDRESS_RESET: 'ADD_ADDRESS_RESET',
	ADD_ADDRESS_CHANGED: 'ADD_ADDRESS_CHANGED',

	PLACE_ORDER_START: 'PLACE_ORDER_START',
	PLACE_ORDER_SUCCESS: 'PLACE_ORDER_SUCCESS',
	PLACE_ORDER_FAILURE: 'PLACE_ORDER_FAILURE',
	PLACE_ORDER_RESET: 'PLACE_ORDER_RESET',
	PLACE_ORDER_CHANGED: 'PLACE_ORDER_CHANGED',

	GET_ALL_PINCODE_LIST_START: 'get_all_pincode_list_start',
	GET_ALL_PINCODE_LIST_SUCCESS: 'get_all_pincode_list_success',
	GET_ALL_PINCODE_LIST_FAILURE: 'get_all_pincode_list_failure',
	GET_ALL_PINCODE_LIST_RESET_START: 'get_all_pincode_list_reset_start',
	GET_ALL_PINCODE_LIST_CHANGED: 'get_all_pincode_list_changed',

	UPDATE_CATEGORY_PRODUCT_LIST: 'UPDATE_CATEGORY_PRODUCT_LIST',

	ADD_PRODUSCT_CATEGORY_CART_ITEM: 'ADD_PRODUSCT_CATEGORY_CART_ITEM',

	REMOVE_PRODUSCT_CATEGORY_CART_ITEM: 'REMOVE_PRODUSCT_CATEGORY_CART_ITEM',

	UPDATE_PRODUCT_LIST_CATEGORY: 'UPDATE_PRODUCT_LIST_CATEGORY',

	ORDER_LOCATION_ADDRESS_UPDATE: 'ORDER_LOCATION_ADDRESS_UPDATE',
};
export default placeOrderType;
