/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import otpTypes from './otpList.types';
import { otpListGet } from './otpList.service';
import { otpListSuccess, otpListFailure, otpListChanged } from './otpList.action';

/* OTP LIST START */
export function* otpAllList({ payload: { otpData } }) {
	try {
		const responseData = yield call(otpListGet, otpData);
		if (responseData.data.statusCode === '100') {
			yield put(otpListFailure(responseData.data));
		} else {
			yield put(otpListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(otpListFailure(error));
	}
}

export function* otpAllListReset() {
	yield put(otpListChanged());
}

export function* getListOfOtpReset() {
	yield takeLatest(otpTypes.GET_OTP_LIST_RESET, otpAllListReset);
}

export function* getListOfOtp() {
	yield takeLatest(otpTypes.GET_OTP_LIST_START, otpAllList);
}
/* OTP LIST END */

export function* otpSaga() {
	yield all([call(getListOfOtp), call(getListOfOtpReset)]);
}
