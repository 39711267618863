// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const BranchGetDetails = (fetchData) => {
	const data = {
		BranchGetDetailsToken: fetchData,
	};
	const url = `${API_URL}GetBranchDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
