/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import ruleAddListTypes from './ruleAddList.types';

/* RULE LIST START */
export const ruleListStart = (ruleDetails) => ({
	type: ruleAddListTypes.RULE_LIST_START,
	payload: ruleDetails,
});

export const ruleListSuccess = (ruleSuccess) => ({
	type: ruleAddListTypes.RULE_LIST_SUCCESS,
	payload: ruleSuccess,
});

export const ruleListFailure = (error) => ({
	type: ruleAddListTypes.RULE_LIST_FAILURE,
	payload: error,
});

export const ruleListChanged = () => ({
	type: ruleAddListTypes.RULE_LIST_CHANGED,
});

export const ruleListReset = () => ({
	type: ruleAddListTypes.RULE_LIST_RESET,
});
/* RULE LIST END */

/* RULE CREATE START */
export const createRuleStart = (ruleDetails) => ({
	type: ruleAddListTypes.RULE_CREATE_REPSONSE_START,
	payload: ruleDetails,
});

export const createRuleSuccess = (ruleSuccess) => ({
	type: ruleAddListTypes.RULE_CREATE_REPSONSE_SUCCESS,
	payload: ruleSuccess,
});

export const createRuleFailure = (error) => ({
	type: ruleAddListTypes.RULE_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createRuleChanged = () => ({
	type: ruleAddListTypes.RULE_CREATE_REPSONSE_CHANGED,
});

export const createRuleReset = () => ({
	type: ruleAddListTypes.RULE_CREATE_REPSONSE_RESET,
});
/* RULE CREATE END */

/* RULE UPDATE START */
export const updateRuleStart = (ruleDetails) => ({
	type: ruleAddListTypes.RULE_UPDATE_REPSONSE_START,
	payload: ruleDetails,
});

export const updateRuleSuccess = (ruleSuccess) => ({
	type: ruleAddListTypes.RULE_UPDATE_REPSONSE_SUCCESS,
	payload: ruleSuccess,
});

export const updateRuleFailure = (error) => ({
	type: ruleAddListTypes.RULE_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateRuleChanged = () => ({
	type: ruleAddListTypes.RULE_UPDATE_REPSONSE_CHANGED,
});

export const updateRuleReset = () => ({
	type: ruleAddListTypes.RULE_UPDATE_REPSONSE_RESET,
});
/* RULE UPDATE END */
