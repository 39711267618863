/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import inventoryTypes from './createInventory.types';

const INITIAL_STATE = {
	inventoryListResponse: null,
	inventoryListStatus: null,
	inventoryCreateResponse: null,
	inventoryCreateStatus: null,
	inventoryUpdateResponse: null,
	inventoryUpdateStatus: null,
	inventoryDeleteResponse: null,
	inventoryDeleteStatus: null,
	inventoryVendorListResponse: null,
	inventoryVendorListStatus: null,
	inventoryProductListResponse: null,
	inventoryProductListStatus: null,
	addInventoryOptionsResponse: null,
	addInventoryOptionsStatus: null,
	getInventoryOptionsResponse: null,
	getInventoryOptionsStatus: null,
	removeInventoryOptionsResponse: null,
	removeInventoryOptionsStatus: null,
};

const inventoryListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case inventoryTypes.INVENTORY_LIST_SUCCESS:
			return {
				...state,
				inventoryListResponse: action.payload,
				inventoryListStatus: 'Success',
			};

		case inventoryTypes.INVENTORY_LIST_FAILURE:
			return {
				...state,
				inventoryListResponse: action.payload,
				inventoryListStatus: 'Failure',
			};

		case inventoryTypes.INVENTORY_LIST_CHANGED:
			return { ...state, inventoryListResponse: null, inventoryListStatus: null };

		case inventoryTypes.INVENTORY_CREATE_SUCCESS:
			return {
				...state,
				inventoryCreateResponse: action.payload,
				inventoryCreateStatus: 'Success',
			};
		case inventoryTypes.INVENTORY_CREATE_FAILURE:
			return {
				...state,
				inventoryCreateResponse: action.payload,
				inventoryCreateStatus: 'Failure',
			};
		case inventoryTypes.INVENTORY_CREATE_CHANGED:
			return { ...state, inventoryCreateResponse: null, inventoryCreateStatus: null };

		case inventoryTypes.INVENTORY_UPDATE_SUCCESS:
			return {
				...state,
				inventoryUpdateResponse: action.payload,
				inventoryUpdateStatus: 'Success',
			};
		case inventoryTypes.INVENTORY_UPDATE_FAILURE:
			return {
				...state,
				inventoryUpdateResponse: action.payload,
				inventoryUpdateStatus: 'Failure',
			};
		case inventoryTypes.INVENTORY_UPDATE_CHANGED:
			return { ...state, inventoryUpdateResponse: null, inventoryUpdateStatus: null };

		case inventoryTypes.INVENTORY_DELETE_SUCCESS:
			return {
				...state,
				inventoryDeleteResponse: action.payload,
				inventoryDeleteStatus: 'Success',
			};
		case inventoryTypes.INVENTORY_DELETE_FAILURE:
			return {
				...state,
				inventoryDeleteResponse: action.payload,
				inventoryDeleteStatus: 'Failure',
			};
		case inventoryTypes.INVENTORY_DELETE_CHANGED:
			return { ...state, inventoryDeleteResponse: null, inventoryDeleteStatus: null };

		case inventoryTypes.INVENTORY_VENDOR_LIST_SUCCESS:
			return {
				...state,
				inventoryVendorListResponse: action.payload,
				inventoryVendorListStatus: 'Success',
			};
		case inventoryTypes.INVENTORY_VENDOR_LIST_FAILURE:
			return {
				...state,
				inventoryVendorListResponse: action.payload,
				inventoryVendorListStatus: 'Failure',
			};
		case inventoryTypes.INVENTORY_VENDOR_LIST_CHANGED:
			return { ...state, inventoryVendorListResponse: null, inventoryVendorListStatus: null };

		case inventoryTypes.INVENTORY_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				inventoryProductListResponse: action.payload,
				inventoryProductListStatus: 'Success',
			};
		case inventoryTypes.INVENTORY_PRODUCT_LIST_FAILURE:
			return {
				...state,
				inventoryProductListResponse: action.payload,
				inventoryProductListStatus: 'Failure',
			};
		case inventoryTypes.INVENTORY_PRODUCT_LIST_CHANGED:
			return {
				...state,
				inventoryProductListResponse: null,
				inventoryProductListStatus: null,
			};

		case inventoryTypes.ADD_INVENTORY_OPTIONS_SUCCESS:
			return {
				...state,
				addInventoryOptionsResponse: action.payload,
				addInventoryOptionsStatus: 'Success',
			};

		case inventoryTypes.ADD_INVENTORY_OPTIONS_FAILURE:
			return {
				...state,
				addInventoryOptionsResponse: action.payload,
				addInventoryOptionsStatus: 'Failure',
			};

		case inventoryTypes.ADD_INVENTORY_OPTIONS_CHANGED:
			return { ...state, addInventoryOptionsResponse: null, addInventoryOptionsStatus: null };

		case inventoryTypes.GET_INVENTORY_OPTIONS_LIST_SUCCESS:
			return {
				...state,
				getInventoryOptionsResponse: action.payload,
				getInventoryOptionsStatus: 'Success',
			};

		case inventoryTypes.GET_INVENTORY_OPTIONS_LIST_FAILURE:
			return {
				...state,
				getInventoryOptionsResponse: action.payload,
				getInventoryOptionsStatus: 'Failure',
			};

		case inventoryTypes.GET_INVENTORY_OPTIONS_LIST_CHANGED:
			return { ...state, getInventoryOptionsResponse: null, getInventoryOptionsStatus: null };

		case inventoryTypes.REMOVE_INVENTORY_OPTIONS_SUCCESS:
			return {
				...state,
				removeInventoryOptionsResponse: action.payload,
				removeInventoryOptionsStatus: 'Success',
			};

		case inventoryTypes.REMOVE_INVENTORY_OPTIONS_FAILURE:
			return {
				...state,
				removeInventoryOptionsResponse: action.payload,
				removeInventoryOptionsStatus: 'Failure',
			};

		case inventoryTypes.REMOVE_INVENTORY_OPTIONS_CHANGED:
			return {
				...state,
				removeInventoryOptionsResponse: null,
				removeInventoryOptionsStatus: null,
			};

		default:
			return state;
	}
};

export default inventoryListReducer;
