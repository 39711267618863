/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import purchaseOrderTypes from './purchaseOrder.types';

/* PURCHASE ORDER LIST START */
export const purchaseOrderListStart = (purchaseOrderDetails) => ({
	type: purchaseOrderTypes.PURCHASE_ORDER_LIST_START,
	payload: purchaseOrderDetails,
});

export const purchaseOrderListSuccess = (purchaseOrderSuccess) => ({
	type: purchaseOrderTypes.PURCHASE_ORDER_LIST_SUCCESS,
	payload: purchaseOrderSuccess,
});

export const purchaseOrderListFailure = (error) => ({
	type: purchaseOrderTypes.PURCHASE_ORDER_LIST_FAILURE,
	payload: error,
});

export const purchaseOrderListChanged = () => ({
	type: purchaseOrderTypes.PURCHASE_ORDER_LIST_CHANGED,
});

export const purchaseOrderListReset = () => ({
	type: purchaseOrderTypes.PURCHASE_ORDER_LIST_RESET,
});
/* PURCHASE ORDER LIST END */

/* UPDATE PURCHASE ORDER STATUS START */
export const updatePurchaseOrderStatusStart = (purchaseOrderDetails) => ({
	type: purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_START,
	payload: purchaseOrderDetails,
});

export const updatePurchaseOrderStatusSuccess = (purchaseOrderSuccess) => ({
	type: purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_SUCCESS,
	payload: purchaseOrderSuccess,
});

export const updatePurchaseOrderStatusFailure = (error) => ({
	type: purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_FAILURE,
	payload: error,
});

export const updatePurchaseOrderStatusChanged = () => ({
	type: purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_CHANGED,
});

export const updatePurchaseOrderStatusReset = () => ({
	type: purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_RESET,
});
/* UPDATE PURCHASE ORDER STATUS END */

/* VIEW PO PRODUCTS LIST START */
export const viewPoProductsListStart = (purchaseOrderDetails) => ({
	type: purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_START,
	payload: purchaseOrderDetails,
});

export const viewPoProductsListSuccess = (purchaseOrderSuccess) => ({
	type: purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_SUCCESS,
	payload: purchaseOrderSuccess,
});

export const viewPoProductsListFailure = (error) => ({
	type: purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_FAILURE,
	payload: error,
});

export const viewPoProductsListChanged = () => ({
	type: purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_CHANGED,
});

export const viewPoProductsListReset = () => ({
	type: purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_RESET,
});
/* VIEW PO PRODUCTS LIST END */
