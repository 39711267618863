/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getDashboardCategoryList = (fetchData) => {
	const data = {
		GetAllDashboardCategoryListToken: fetchData,
	};
	const url = `${API_URL}GetAllDashboardCategoryList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createDashboardCategory = (fetchData) => {
	const data = {
		CreateDashboardCategoryToken: fetchData,
	};
	const url = `${API_URL}CreateDashboardCategoryDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateDashboardCategory = (fetchData) => {
	const data = {
		UpdateDashboardCategoryToken: fetchData,
	};
	const url = `${API_URL}UpdateDashboardCategoryDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteDashboardCategory = (fetchData) => {
	const data = {
		RemoveDashboardCategoryToken: fetchData,
	};
	const url = `${API_URL}DeleteDashboardCategoryDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
