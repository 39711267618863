const pincodeTypes = {
	PINCODE_LIST_START: 'PINCODE_LIST_START',
	PINCODE_LIST_SUCCESS: 'PINCODE_LIST_SUCCESS',
	PINCODE_LIST_FAILURE: 'PINCODE_LIST_FAILURE',
	PINCODE_LIST_RESET: 'PINCODE_LIST_RESET',
	PINCODE_LIST_CHANGED: 'PINCODE_LIST_CHANGED',

	PINCODE_CREATE_REPSONSE_START: 'PINCODE_CREATE_REPSONSE_START',
	PINCODE_CREATE_REPSONSE_SUCCESS: 'PINCODE_CREATE_REPSONSE_SUCCESS',
	PINCODE_CREATE_REPSONSE_FAILURE: 'PINCODE_CREATE_REPSONSE_FAILURE',
	PINCODE_CREATE_REPSONSE_CHANGED: 'PINCODE_CREATE_REPSONSE_CHANGED',
	PINCODE_CREATE_REPSONSE_RESET: 'PINCODE_CREATE_REPSONSE_RESET',

	PINCODE_UPDATE_REPSONSE_START: 'PINCODE_UPDATE_REPSONSE_START',
	PINCODE_UPDATE_REPSONSE_SUCCESS: 'PINCODE_UPDATE_REPSONSE_SUCCESS',
	PINCODE_UPDATE_REPSONSE_FAILURE: 'PINCODE_UPDATE_REPSONSE_FAILURE',
	PINCODE_UPDATE_REPSONSE_CHANGED: 'PINCODE_UPDATE_REPSONSE_CHANGED',
	PINCODE_UPDATE_REPSONSE_RESET: 'PINCODE_UPDATE_REPSONSE_RESET',

	PINCODE_DELETE_REPSONSE_START: 'PINCODE_DELETE_REPSONSE_START',
	PINCODE_DELETE_REPSONSE_SUCCESS: 'PINCODE_DELETE_REPSONSE_SUCCESS',
	PINCODE_DELETE_REPSONSE_FAILURE: 'PINCODE_DELETE_REPSONSE_FAILURE',
	PINCODE_DELETE_REPSONSE_CHANGED: 'PINCODE_DELETE_REPSONSE_CHANGED',
	PINCODE_DELETE_REPSONSE_RESET: 'PINCODE_DELETE_REPSONSE_RESET',
};
export default pincodeTypes;
