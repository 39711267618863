/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { encodeJson } from '../enode-decode';

import comboListTypes from './comboList.types';
import {
	getComboList,
	insertComboProducts,
	updateComboProducts,
	getAddComboProducts,
	createProducts,
	getProductsList,
	deleteComboProducts,
	deleteProducts,
	isFestivalSwitchOn,
} from './comboList.service';
import {
	comboListSuccess,
	comboListFailure,
	comboListChanged,
	insertComboSuccess,
	insertComboFailure,
	insertComboChanged,
	editComboSuccess,
	editComboFailure,
	editComboChanged,
	removeComboSuccess,
	removeComboFailure,
	removeComboChanged,
	addComboProductSuccess,
	addComboProductFailure,
	addComboProductChanged,
	createProductSuccess,
	createProductFailure,
	createProductChanged,
	productsListSuccess,
	productsListFailure,
	productsListChanged,
	deleteProductSuccess,
	deleteProductFailure,
	deleteProductChanged,
	isFestivalSuccess,
	isFestivalFailure,
	isFestivalChanged,
} from './comboList.action';

/* LIST COMBO START */
export function* listOfCombo({ payload }) {
	try {
		const key = 'GetComboListProductsKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			SearchValueSet: payload.searchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getComboList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(comboListFailure(responseData.data));
		} else {
			yield put(comboListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(comboListFailure(error));
	}
}

export function* listOfComboReset() {
	yield put(comboListChanged());
}

export function* getListComboReset() {
	yield takeLatest(comboListTypes.GET_COMBO_LIST_RESET, listOfComboReset);
}

export function* getListCombo() {
	yield takeLatest(comboListTypes.GET_COMBO_LIST_START, listOfCombo);
}
/* LIST COMBO END */

/* INSERT COMBO START */
export function* insertComboList({ payload }) {
	try {
		const key = 'CreateComboListProductsKey';
		const jsonData = {
			ComboName: payload.ComboName,
			ComboImage: payload.ComboImage,
			ComboPrice: payload.ComboPrice,
			ComboMrpPrice: payload.ComboMrpPrice,
			ComboDiscount: payload.ComboDiscount,
			AvailableTo: payload.comboDate,
			CreatedBy: payload.StaffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(insertComboProducts, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(insertComboFailure(responseData.data));
		} else {
			yield put(insertComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(insertComboFailure(error));
	}
}

export function* insertComboListReset() {
	yield put(insertComboChanged());
}

export function* insertComboStart() {
	yield takeLatest(comboListTypes.INSERT_COMBO_RESPONSE_START, insertComboList);
}

export function* insertComboResponseReset() {
	yield takeLatest(comboListTypes.INSERT_COMBO_RESPONSE_RESET, insertComboListReset);
}
/* INSERT COMBO END */

/* UPDATE COMBO START */
export function* updateComboReset({ payload }) {
	try {
		const key = 'UpdateComboProductsKey';
		const jsonData = {
			ComboID: payload.ComboID,
			ComboName: payload.ComboName,
			ComboImage: payload.ComboImage,
			ComboPrice: payload.ComboPrice,
			ComboMrpPrice: payload.ComboMrpPrice,
			ComboDiscount: payload.ComboDiscount,
			AvailableTo: payload.comboDate,
			CreatedBy: payload.StaffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateComboProducts, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(editComboFailure(responseData.data));
		} else {
			yield put(editComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(editComboFailure(error));
	}
}

export function* updateComboList() {
	yield put(editComboChanged());
}

export function* updateComboResponseReset() {
	yield takeLatest(comboListTypes.UPDATE_COMBO_RESPONSE_RESET, updateComboList);
}

export function* updateComboStart() {
	yield takeLatest(comboListTypes.UPDATE_COMBO_RESPONSE_START, updateComboReset);
}
/* UPDATE COMBO END */

/* DELETE COMBO START */
export function* comboRemove({ payload }) {
	try {
		const key = 'DeleteComboProductsKey';
		const jsonData = {
			AvailableForEver: payload.AvailableForEver,
			ComboID: payload.ComboID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteComboProducts, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(removeComboFailure(responseData.data));
		} else {
			yield put(removeComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(removeComboFailure(error));
	}
}

export function* comboRemoveResponse() {
	yield put(removeComboChanged());
}

export function* comboRemoveResponseReset() {
	yield takeLatest(comboListTypes.DELETE_COMBO_RESPONSE_RESET, comboRemoveResponse);
}

export function* comboRemoveStart() {
	yield takeLatest(comboListTypes.DELETE_COMBO_RESPONSE_START, comboRemove);
}
/* DELETE COMBO END */

/* ADD COMBO PRODUCT START */
export function* comboProductAdd({ payload }) {
	try {
		const key = 'GetAddComboProductsKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			DropdownSearchValueSet: payload.dropdownSearchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getAddComboProducts, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addComboProductFailure(responseData.data));
		} else {
			yield put(addComboProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addComboProductFailure(error));
	}
}

export function* comboProductAddReset() {
	yield put(addComboProductChanged());
}

export function* comboProductAddResponseReset() {
	yield takeLatest(comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_RESET, comboProductAddReset);
}

export function* comboProductAddStart() {
	yield takeLatest(comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_START, comboProductAdd);
}
/* ADD COMBO PRODUCT END */

/* CREATE PRODUCT START */
export function* createProduct({ payload }) {
	try {
		const key = 'CreateProductsKey';
		const jsonData = {
			ComboID: payload.ComboID,
			ProductID: payload.ProductID,
			ProductClassifyID: payload.ProductClassifyID,
			PriceChartID: payload.PriceChartID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createProducts, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createProductFailure(responseData.data));
		} else {
			yield put(createProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createProductFailure(error));
	}
}

export function* createProductReset() {
	yield put(createProductChanged());
}

export function* productCreateResponseReset() {
	yield takeLatest(comboListTypes.CREATE_PRODUCT_RESPONSE_RESET, createProductReset);
}

export function* productCreateStart() {
	yield takeLatest(comboListTypes.CREATE_PRODUCT_RESPONSE_START, createProduct);
}
/* CREATE PRODUCT END */

/* PRODUCTS LIST START */
export function* productsGetList({ payload }) {
	try {
		const key = 'GetProductsListKey';
		const jsonData = {
			ComboID: payload.comboID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getProductsList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(productsListFailure(responseData.data));
		} else {
			yield put(productsListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productsListFailure(error));
	}
}

export function* productsGetListReset() {
	yield put(productsListChanged());
}

export function* productsListGetResponseReset() {
	yield takeLatest(comboListTypes.GET_PRODUCT_LIST_RESPONSE_RESET, productsGetListReset);
}

export function* productsListGetStart() {
	yield takeLatest(comboListTypes.GET_PRODUCT_LIST_RESPONSE_START, productsGetList);
}
/* PRODUCTS LIST END */

/* DELETE PRODUCT START */
export function* productsRemove({ payload }) {
	try {
		const key = 'RemoveProductsKey';
		const jsonData = {
			ComboID: payload.ComboID,
			ProductID: payload.ProductID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteProducts, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteProductFailure(responseData.data));
		} else {
			yield put(deleteProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteProductFailure(error));
	}
}

export function* productsRemoveResponse() {
	yield put(deleteProductChanged());
}

export function* removeProductsResponseReset() {
	yield takeLatest(comboListTypes.DELETE_PRODUCT_RESET, productsRemoveResponse);
}

export function* removeProductsStart() {
	yield takeLatest(comboListTypes.DELETE_PRODUCT_START, productsRemove);
}
/* DELETE PRODUCT END */

/* IS FESTIVAL START */
export function* startIsFestival({ payload }) {
	try {
		const key = 'Is_Festival_SwitchOn';
		const jsonData = {
			ComboID: payload.festivalComboID,
			IsFestival: payload.isFestival,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(isFestivalSwitchOn, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(isFestivalFailure(responseData.data));
		} else {
			yield put(isFestivalSuccess(responseData.data));
		}
	} catch (error) {
		yield put(isFestivalFailure(error));
	}
}

export function* IsFestivalStartResponse() {
	yield put(isFestivalChanged());
}

export function* onIsFestivalStart() {
	yield takeLatest(comboListTypes.IS_FESTIVAL_SWITCH_START, startIsFestival);
}

export function* onIsFestivalResponseReset() {
	yield takeLatest(comboListTypes.IS_FESTIVAL_SWITCH_RESET, IsFestivalStartResponse);
}

/* IS FESTIVAL END */

export function* comboListSaga() {
	yield all([
		call(getListCombo),
		call(getListComboReset),
		call(insertComboStart),
		call(insertComboResponseReset),
		call(updateComboStart),
		call(updateComboResponseReset),
		call(comboRemoveStart),
		call(comboRemoveResponseReset),
		call(comboProductAddStart),
		call(comboProductAddResponseReset),
		call(productCreateStart),
		call(productCreateResponseReset),
		call(productsListGetStart),
		call(productsListGetResponseReset),
		call(removeProductsStart),
		call(removeProductsResponseReset),
		call(onIsFestivalStart),
		call(onIsFestivalResponseReset),
	]);
}
