/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import festivalTypes from './festival.types';

const INITIAL_STATE = {
	festivalListResponse: null,
	festivalListStatus: null,
	insertFestivalComboResponse: null,
	insertFestivalComboStatus: null,
	editFestivalResponse: null,
	editFestivalStatus: null,
	removeFestivalComboResponse: null,
	removeFestivalComboStatus: null,
	productListResponse: null,
	productListStatus: null,
	createComboProductResponse: null,
	createComboProductStatus: null,
	comboProductListResponse: null,
	comboProductListStatus: null,
	deleteComboProductResponse: null,
	deleteComboProductStatus: null,
};

const festivalListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case festivalTypes.FESTIVAL_LIST_SUCCESS:
			return {
				...state,
				festivalListResponse: action.payload,
				festivalListStatus: 'Success',
			};

		case festivalTypes.FESTIVAL_LIST_FAILURE:
			return {
				...state,
				festivalListResponse: action.payload,
				festivalListStatus: 'Failure',
			};

		case festivalTypes.FESTIVAL_LIST_CHANGED:
			return { ...state, festivalListResponse: null, festivalListStatus: null };

		case festivalTypes.INSERT_FESTIVAL_LIST_SUCCESS:
			return {
				...state,
				insertFestivalComboResponse: action.payload,
				insertFestivalComboStatus: 'Success',
			};
		case festivalTypes.INSERT_FESTIVAL_LIST_FAILURE:
			return {
				...state,
				insertFestivalComboResponse: action.payload,
				insertFestivalComboStatus: 'Failure',
			};
		case festivalTypes.INSERT_FESTIVAL_LIST_CHANGED:
			return { ...state, insertFestivalComboResponse: null, insertFestivalComboStatus: null };

		case festivalTypes.UPDATE_FESTIVAL_LIST_SUCCESS:
			return {
				...state,
				editFestivalResponse: action.payload,
				editFestivalStatus: 'Success',
			};
		case festivalTypes.UPDATE_FESTIVAL_LIST_FAILURE:
			return {
				...state,
				editFestivalResponse: action.payload,
				editFestivalStatus: 'Failure',
			};
		case festivalTypes.UPDATE_FESTIVAL_LIST_CHANGED:
			return { ...state, editFestivalResponse: null, editFestivalStatus: null };

		case festivalTypes.DELETE_FESTIVAL_LIST_SUCCESS:
			return {
				...state,
				removeFestivalComboResponse: action.payload,
				removeFestivalComboStatus: 'Success',
			};
		case festivalTypes.DELETE_FESTIVAL_LIST_FAILURE:
			return {
				...state,
				removeFestivalComboResponse: action.payload,
				removeFestivalComboStatus: 'Failure',
			};
		case festivalTypes.DELETE_FESTIVAL_LIST_CHANGED:
			return { ...state, removeFestivalComboResponse: null, removeFestivalComboStatus: null };

		case festivalTypes.PRODUCT_LIST_SUCCESS:
			return {
				...state,
				productListResponse: action.payload,
				productListStatus: 'Success',
			};
		case festivalTypes.PRODUCT_LIST_FAILURE:
			return {
				...state,
				productListResponse: action.payload,
				productListStatus: 'Failure',
			};
		case festivalTypes.PRODUCT_LIST_CHANGED:
			return { ...state, productListResponse: null, productListStatus: null };

		case festivalTypes.CREATE_COMBO_PRODUCT_SUCCESS:
			return {
				...state,
				createComboProductResponse: action.payload,
				createComboProductStatus: 'Success',
			};
		case festivalTypes.CREATE_COMBO_PRODUCT_FAILURE:
			return {
				...state,
				createComboProductResponse: action.payload,
				createComboProductStatus: 'Failure',
			};
		case festivalTypes.CREATE_COMBO_PRODUCT_CHANGED:
			return { ...state, createComboProductResponse: null, createComboProductStatus: null };

		case festivalTypes.COMBO_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				comboProductListResponse: action.payload,
				comboProductListStatus: 'Success',
			};
		case festivalTypes.COMBO_PRODUCT_LIST_FAILURE:
			return {
				...state,
				comboProductListResponse: action.payload,
				comboProductListStatus: 'Failure',
			};
		case festivalTypes.COMBO_PRODUCT_LIST_CHANGED:
			return { ...state, comboProductListResponse: null, comboProductListStatus: null };

		case festivalTypes.DELETE_COMBO_SUCCESS:
			return {
				...state,
				deleteComboProductResponse: action.payload,
				deleteComboProductStatus: 'Success',
			};
		case festivalTypes.DELETE_COMBO_FAILURE:
			return {
				...state,
				deleteComboProductResponse: action.payload,
				deleteComboProductStatus: 'Failure',
			};
		case festivalTypes.DELETE_COMBO_CHANGED:
			return { ...state, deleteComboProductResponse: null, deleteComboProductStatus: null };

		default:
			return state;
	}
};

export default festivalListReducer;
