/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import flowerOrderTypes from './flowerOrder.types';
import generalTypesAction from '../genera.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	flowerOrderResponse: null,
	flowerOrderStatus: null,
	viewFlowerOrderResponse: null,
	viewFlowerOrderStatus: null,
	viewFlowersTranscationResponse: null,
	viewFlowersTranscationStatus: null,
	viewFlowersTranscationResponseDecode: null,
};

const flowerOrderReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case flowerOrderTypes.FLOWER_ORDER_LIST_SUCCESS:
			return {
				...state,
				flowerOrderResponse: action.payload,
				flowerOrderStatus: 'Success',
			};

		case flowerOrderTypes.FLOWER_ORDER_LIST_FAILURE:
			return {
				...state,
				flowerOrderResponse: action.payload,
				flowerOrderStatus: 'Failure',
			};

		case flowerOrderTypes.FLOWER_ORDER_LIST_CHANGED:
			return { ...state, flowerOrderResponse: null, flowerOrderStatus: null };

		case flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_SUCCESS:
			return {
				...state,
				viewFlowerOrderResponse: action.payload,
				viewFlowerOrderStatus: 'Success',
			};

		case flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_FAILURE:
			return {
				...state,
				viewFlowerOrderResponse: action.payload,
				viewFlowerOrderStatus: 'Failure',
			};

		case flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_CHANGED:
			return { ...state, viewFlowerOrderResponse: null, viewFlowerOrderStatus: null };

		case flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_SUCCESS:
			return {
				...state,
				viewFlowersTranscationResponse: action.payload,
				viewFlowersTranscationResponseDecode: decodeApiResponse(
					action.payload,
					generalTypesAction.FLOWERSTRANSACTIONS,
				),
				viewFlowersTranscationStatus: 'Success',
			};

		case flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_FAILURE:
			return {
				...state,
				viewFlowersTranscationResponse: action.payload,
				viewFlowersTranscationResponseDecode: null,
				viewFlowersTranscationStatus: 'Failure',
			};

		case flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_CHANGED:
			return {
				...state,
				viewFlowersTranscationResponse: null,
				viewFlowersTranscationResponseDecode: null,
				viewFlowersTranscationStatus: null,
			};

		default:
			return state;
	}
};

export default flowerOrderReducer;
