/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getListOfProduct = (postListValue) => {
	const data = {
		ProductListData: postListValue,
	};
	const url = `${API_URL}GetListOfProduct`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createProductList = (postListValue) => {
	const data = {
		AddProductListData: postListValue,
	};
	const url = `${API_URL}CreateProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const editProduct = (postListValue) => {
	const data = {
		EditProductListData: postListValue,
	};
	const url = `${API_URL}UpdateProducts`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getProductOptionsList = (postListValue) => {
	const data = {
		GetProductOptionsData: postListValue,
	};
	const url = `${API_URL}GetProductOptionsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const insertProductOptions = (postListValue) => {
	const data = {
		AddProductOptionsData: postListValue,
	};
	const url = `${API_URL}InsertProductOptions`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteProductOptions = (postListValue) => {
	const data = {
		RemoveProductOptionsData: postListValue,
	};
	const url = `${API_URL}DeleteProductOptions`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const productOptionsEdit = (postListValue) => {
	const data = {
		EditProductOptionsData: postListValue,
	};
	const url = `${API_URL}EditProductOptions`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
