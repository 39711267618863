/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import minicherTypes from './addMinicher.types';

const INITIAL_STATE = {
	minicherResponse: null,
	minicherStatus: null,
	insertMinicherResponse: null,
	insertMinicherStatus: null,
	editMinicherResponse: null,
	editMinicherStatus: null,
	offMinicherResponse: null,
	offMinicherStatus: null,
	onMinicherResponse: null,
	onMinicherStatus: null,
	addListMinicherProductResponse: null,
	addListMinicherProductStatus: null,
	createMinicherProductResponse: null,
	createMinicherProductStatus: null,
	getMinicherProductListResponse: null,
	getMinicherProductListStatus: null,
	deleteMinicherProductsResponse: null,
	deleteMinicherProductsStatus: null,
};

const minicherReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case minicherTypes.GET_MINICHER_LIST_SUCCESS:
			return {
				...state,
				minicherResponse: action.payload,
				minicherStatus: 'Success',
			};

		case minicherTypes.GET_MINICHER_LIST_FAILURE:
			return {
				...state,
				minicherResponse: action.payload,
				minicherStatus: 'Failure',
			};

		case minicherTypes.GET_MINICHER_LIST_CHANGED:
			return { ...state, minicherResponse: null, minicherStatus: null };

		case minicherTypes.INSERT_MINICHER_SUCCESS:
			return {
				...state,
				insertMinicherResponse: action.payload,
				insertMinicherStatus: 'Success',
			};
		case minicherTypes.INSERT_MINICHER_FAILURE:
			return {
				...state,
				insertMinicherResponse: action.payload,
				insertMinicherStatus: 'Failure',
			};
		case minicherTypes.INSERT_MINICHER_CHANGED:
			return { ...state, insertMinicherResponse: null, insertMinicherStatus: null };

		case minicherTypes.UPDATE_MINICHER_SUCCESS:
			return {
				...state,
				editMinicherResponse: action.payload,
				editMinicherStatus: 'Success',
			};
		case minicherTypes.UPDATE_MINICHER_FAILURE:
			return {
				...state,
				editMinicherResponse: action.payload,
				editMinicherStatus: 'Failure',
			};
		case minicherTypes.UPDATE_MINICHER_CHANGED:
			return { ...state, editMinicherResponse: null, editMinicherStatus: null };

		case minicherTypes.OFF_MINICHER_SUCCESS:
			return {
				...state,
				offMinicherResponse: action.payload,
				offMinicherStatus: 'Success',
			};
		case minicherTypes.OFF_MINICHER_FAILURE:
			return {
				...state,
				offMinicherResponse: action.payload,
				offMinicherStatus: 'Failure',
			};
		case minicherTypes.OFF_MINICHER_CHANGED:
			return { ...state, offMinicherResponse: null, offMinicherStatus: null };

		case minicherTypes.ON_MINICHER_SUCCESS:
			return {
				...state,
				onMinicherResponse: action.payload,
				onMinicherStatus: 'Success',
			};
		case minicherTypes.ON_MINICHER_FAILURE:
			return {
				...state,
				onMinicherResponse: action.payload,
				onMinicherStatus: 'Failure',
			};
		case minicherTypes.ON_MINICHER_CHANGED:
			return { ...state, onMinicherResponse: null, onMinicherStatus: null };

		case minicherTypes.ADD_LIST_MINICHER_PRODUCT_SUCCESS:
			return {
				...state,
				addListMinicherProductResponse: action.payload,
				addListMinicherProductStatus: 'Success',
			};
		case minicherTypes.ADD_LIST_MINICHER_PRODUCT_FAILURE:
			return {
				...state,
				addListMinicherProductResponse: action.payload,
				addListMinicherProductStatus: 'Failure',
			};
		case minicherTypes.ADD_LIST_MINICHER_PRODUCT_CHANGED:
			return {
				...state,
				addListMinicherProductResponse: null,
				addListMinicherProductStatus: null,
			};
		case minicherTypes.CREATE_MINICHER_PRODUCT_SUCCESS:
			return {
				...state,
				createMinicherProductResponse: action.payload,
				createMinicherProductStatus: 'Success',
			};
		case minicherTypes.CREATE_MINICHER_PRODUCT_FAILURE:
			return {
				...state,
				createMinicherProductResponse: action.payload,
				createMinicherProductStatus: 'Failure',
			};
		case minicherTypes.CREATE_MINICHER_PRODUCT_CHANGED:
			return {
				...state,
				createMinicherProductResponse: null,
				createMinicherProductStatus: null,
			};

		case minicherTypes.GET_MINICHER_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				getMinicherProductListResponse: action.payload,
				getMinicherProductListStatus: 'Success',
			};
		case minicherTypes.GET_MINICHER_PRODUCT_LIST_FAILURE:
			return {
				...state,
				getMinicherProductListResponse: action.payload,
				getMinicherProductListStatus: 'Failure',
			};
		case minicherTypes.GET_MINICHER_PRODUCT_LIST_CHANGED:
			return {
				...state,
				getMinicherProductListResponse: null,
				getMinicherProductListStatus: null,
			};

		case minicherTypes.DELETE_MINICHER_PRODUCT_SUCCESS:
			return {
				...state,
				deleteMinicherProductsResponse: action.payload,
				deleteMinicherProductsStatus: 'Success',
			};
		case minicherTypes.DELETE_MINICHER_PRODUCT_FAILURE:
			return {
				...state,
				deleteMinicherProductsResponse: action.payload,
				deleteMinicherProductsStatus: 'Failure',
			};
		case minicherTypes.DELETE_MINICHER_PRODUCT_CHANGED:
			return {
				...state,
				deleteMinicherProductsResponse: null,
				deleteMinicherProductsStatus: null,
			};

		default:
			return state;
	}
};

export default minicherReducer;
