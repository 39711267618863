/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import repackedTypes from './rePacked.types';

const INITIAL_STATE = {
	repackedListResponse: null,
	repackedListStatus: null,
	repackedCreateResponse: null,
	repackedCreateStatus: null,
	repackedUpdateResponse: null,
	repackedUpdateStatus: null,
};

const repackedListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case repackedTypes.REPACKED_LIST_SUCCESS:
			return {
				...state,
				repackedListResponse: action.payload,
				repackedListStatus: 'Success',
			};

		case repackedTypes.REPACKED_LIST_FAILURE:
			return {
				...state,
				repackedListResponse: action.payload,
				repackedListStatus: 'Failure',
			};

		case repackedTypes.REPACKED_LIST_CHANGED:
			return { ...state, repackedListResponse: null, repackedListStatus: null };

		case repackedTypes.REPACKED_CREATE_SUCCESS:
			return {
				...state,
				repackedCreateResponse: action.payload,
				repackedCreateStatus: 'Success',
			};
		case repackedTypes.REPACKED_CREATE_FAILURE:
			return {
				...state,
				repackedCreateResponse: action.payload,
				repackedCreateStatus: 'Failure',
			};
		case repackedTypes.REPACKED_CREATE_CHANGED:
			return { ...state, repackedCreateResponse: null, repackedCreateStatus: null };

		case repackedTypes.REPACKED_UPDATE_SUCCESS:
			return {
				...state,
				repackedUpdateResponse: action.payload,
				repackedUpdateStatus: 'Success',
			};
		case repackedTypes.REPACKED_UPDATE_FAILURE:
			return {
				...state,
				repackedUpdateResponse: action.payload,
				repackedUpdateStatus: 'Failure',
			};
		case repackedTypes.REPACKED_UPDATE_CHANGED:
			return { ...state, repackedUpdateResponse: null, repackedUpdateStatus: null };

		default:
			return state;
	}
};

export default repackedListReducer;
