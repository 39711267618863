/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import dashboardCategoryTypes from './dashboardCategory.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	dashboardCategoryListResponse: null,
	dashboardCategoryListArr: [],
	dashboardCategoryCreateResponse: null,
	dashboardCategoryUpdateResponse: null,
	dashboardCategoryDeleteResponse: null,
};

const dashboardCategoryReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_SUCCESS:
			return {
				...state,
				dashboardCategoryListResponse: action.payload,
				dashboardCategoryListArr: docodeResponse(
					action.payload,
					'DashboardCategoryListKey',
				),
			};

		case dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_FAILURE:
			return {
				...state,
				dashboardCategoryListResponse: action.payload,
				dashboardCategoryListArr: [],
			};

		case dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_CHANGED:
			return { ...state, dashboardCategoryListResponse: null };

		case dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_SUCCESS:
			return { ...state, dashboardCategoryCreateResponse: action.payload };
		case dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_FAILURE:
			return { ...state, dashboardCategoryCreateResponse: action.payload };
		case dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_CHANGED:
			return { ...state, dashboardCategoryCreateResponse: null };

		case dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_SUCCESS:
			return { ...state, dashboardCategoryUpdateResponse: action.payload };
		case dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_FAILURE:
			return { ...state, dashboardCategoryUpdateResponse: action.payload };
		case dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_CHANGED:
			return { ...state, dashboardCategoryUpdateResponse: null };

		case dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_SUCCESS:
			return { ...state, dashboardCategoryDeleteResponse: action.payload };
		case dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_FAILURE:
			return { ...state, dashboardCategoryDeleteResponse: action.payload };
		case dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_CHANGED:
			return { ...state, dashboardCategoryDeleteResponse: null };

		default:
			return state;
	}
};

export default dashboardCategoryReducer;
