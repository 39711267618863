/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import purchaseReportTypes from './purchaseReport.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	purchaseReportListResponse: null,
	purchaseReportListResponseArr: [],
};

const purchaseReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case purchaseReportTypes.PURCHASE_REPORT_LIST_SUCCESS:
			return {
				...state,
				purchaseReportListResponse: action.payload,
				purchaseReportListResponseArr: docodeResponse(
					action.payload,
					'GetPurchaseHistoryListKey',
				),
			};

		case purchaseReportTypes.PURCHASE_REPORT_LIST_FAILURE:
			return {
				...state,
				purchaseReportListResponse: action.payload,
				purchaseReportListResponseArr: [],
			};

		case purchaseReportTypes.PURCHASE_REPORT_LIST_CHANGED:
			return { ...state, purchaseReportListResponse: null };

		default:
			return state;
	}
};

export default purchaseReportReducer;
