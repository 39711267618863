// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-shadow */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import refundTypes from './refund.types';

/* REFUND LIST START */
export const refundStart = (refundDetails) => ({
	type: refundTypes.REFUND_LIST_START,
	payload: refundDetails,
});

export const refundSuccess = (refundListSuccess) => ({
	type: refundTypes.REFUND_LIST_SUCCESS,
	payload: refundListSuccess,
});

export const refundFailure = (error) => ({
	type: refundTypes.REFUND_LIST_FAILURE,
	payload: error,
});

export const refundChanged = () => ({
	type: refundTypes.REFUND_LIST_CHANGED,
});

export const refundReset = () => ({
	type: refundTypes.REFUND_LIST_RESET,
});
/* REFUND LIST END */

/* REFUND UPDATE START */
export const refundUpdateStart = (refundDetails) => ({
	type: refundTypes.REFUND_UPDATE_START,
	payload: refundDetails,
});

export const refundUpdateSuccess = (refundUpdateSuccess) => ({
	type: refundTypes.REFUND_UPDATE_SUCCESS,
	payload: refundUpdateSuccess,
});

export const refundUpdateFailure = (error) => ({
	type: refundTypes.REFUND_UPDATE_FAILURE,
	payload: error,
});

export const refundUpdateChanged = () => ({
	type: refundTypes.REFUND_UPDATE_CHANGED,
});

export const refundUpdateReset = () => ({
	type: refundTypes.REFUND_UPDATE_RESET,
});
/* REFUND UPDATE END */

/* VIEW REFUNDED LIST START */
export const viewRefundedStart = (start) => ({
	type: refundTypes.VIEW_REFUNDED_LIST_START,
	payload: start,
});

export const viewRefundedSuccess = (success) => ({
	type: refundTypes.VIEW_REFUNDED_LIST_SUCCESS,
	payload: success,
});

export const viewRefundedFailure = (error) => ({
	type: refundTypes.VIEW_REFUNDED_LIST_FAILURE,
	payload: error,
});

export const viewRefundedChanged = () => ({
	type: refundTypes.VIEW_REFUNDED_LIST_CHANGED,
});

export const viewRefundedReset = () => ({
	type: refundTypes.VIEW_REFUNDED_LIST_RESET,
});
/* VIEW REFUNDED LIST END */
