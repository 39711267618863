/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getListEndOfDay = (fetchData) => {
	const data = {
		EndOfDayListToken: fetchData,
	};
	const url = `${API_URL}GetListAllToBeDeliverd`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const endOfDayUpdate = (endOfDay) => {
	const data = {
		UserID: endOfDay.UserID,
		eodDate: endOfDay.eodDate,
	};
	const url = `${API_URL}AddEndOfDay`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const fetchEndOfDayDetails = (fetchEndOfDay) => {
	const data = {
		Limit: fetchEndOfDay.limit,
	};
	const url = `${API_URL}FetchEndOfDayDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const viewOrderList = (fetchData) => {
	const data = {
		OrderDetailsViewToken: fetchData,
	};
	const url = `${API_URL}GetViewOrdersDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const flowerOrderCancel = (cancelOrderDetails) => {
	const data = {
		CancelFlowerOrderToken: cancelOrderDetails,
	};
	const url = `${API_URL}CancelFlowerPlaceOrder`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const assginedToStaffOrders = (assginedToStaff) => {
	const data = {
		AssginedToStaffOrdersData: assginedToStaff,
	};
	const url = `${API_URL}AssginedToStaffOrders`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const assignedRevertOrder = (orderDetails) => {
	const data = {
		AssignedRevertOrderToken: orderDetails,
	};
	const url = `${API_URL}AssignedRevertOrder`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getEodCancelOrder = (orderDetails) => {
	const data = {
		EodCancelOrderGetToken: orderDetails,
	};
	const url = `${API_URL}GetEodCancelOrder`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getOrderPinCodeList = (orderDetails) => {
	const data = {
		GetOrderPinCodeListToken: orderDetails,
	};
	const url = `${API_URL}GetOrderPinCodeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getEodStaffList = (orderDetails) => {
	const data = {
		EodStaffListGetToken: orderDetails,
	};
	const url = `${API_URL}GetEodStaffList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
