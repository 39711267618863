/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import purchaseListTypes from './purchaseList.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	purchaseListResponse: null,
	purchaseListResponseArr: [],
};

const purchaseListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case purchaseListTypes.PURCHASE_LIST_SUCCESS:
			return {
				...state,
				purchaseListResponse: action.payload,
				purchaseListResponseArr: docodeResponse(action.payload, 'PurchaseListKey'),
			};

		case purchaseListTypes.PURCHASE_LIST_FAILURE:
			return {
				...state,
				purchaseListResponse: action.payload,
				purchaseListResponseArr: [],
			};

		case purchaseListTypes.PURCHASE_LIST_CHANGED:
			return { ...state, purchaseListResponse: null };

		default:
			return state;
	}
};

export default purchaseListReducer;
