/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
// LIVE
export const API_URL = 'https://api.pookadai.co.in/';
export const HUB_API_URL = 'https://api.pookadai.co.in/StockManagement/Hub/';
export const INV_API_URL = 'https://api.pookadai.co.in/StockManagement/Inventory/';

// export const API_URL = 'https://qa.api.pookadai.co.in/';
// export const HUB_API_URL = 'https://qa.api.pookadai.co.in/StockManagement/Hub/';
// export const INV_API_URL = 'https://qa.api.pookadai.co.in/StockManagement/Inventory/';

// export const API_URL = 'https://qa.api.pookadai.co.in/';
// export const API_URL = 'http://localhost:8888/pookadai/pookadaiapi/';
// export const HUB_API_URL = 'http://localhost:8888/pookadaiapi/StockManagement/Hub/';
// export const INV_API_URL = 'http://localhost:8888/pookadaiapi/StockManagement/Inventory/';
