/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import JWT from 'expo-jwt';
import generalTypesAction from '../genera.types';
import ruleAddListTypes from './ruleAddList.types';
import { getRuleList, createRule, updateRule } from './ruleAddList.service';
import {
	ruleListSuccess,
	ruleListFailure,
	ruleListChanged,
	createRuleSuccess,
	createRuleFailure,
	createRuleChanged,
	updateRuleSuccess,
	updateRuleFailure,
	updateRuleChanged,
} from './ruleAddList.action';
import { encodeJson } from '../enode-decode';

/* RULE  LIST START */
export function* ruleListDetailsStart({ payload: { limit, searchValueSet } }) {
	try {
		const jsonData = {
			Limit: limit,
			SearchValueSet: searchValueSet,
		};
		const RuleData = encodeJson(jsonData, generalTypesAction.RULE);
		const responseData = yield call(getRuleList, RuleData);
		if (responseData.data.statusCode === '100') {
			yield put(ruleListFailure(responseData.data));
		} else {
			yield put(ruleListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(ruleListFailure(error));
	}
}

export function* ruleListDetailsChanged() {
	yield put(ruleListChanged());
}

export function* ruleListDetails() {
	yield takeLatest(ruleAddListTypes.RULE_LIST_START, ruleListDetailsStart);
}

export function* ruleListResponseReset() {
	yield takeLatest(ruleAddListTypes.RULE_LIST_RESET, ruleListDetailsChanged);
}
/* RULE  LIST END */

/* RULE CREATE START */
export function* createNewRule({ payload: { values, offerType } }) {
	try {
		const jsonData = {
			RuleID: values.ruleID,
			RuleName: values.ruleName,
			AmountGreater: values.amountGreater,
			VaildTill: values.vaildTill,
			OfferType: offerType,
			CreatedBy: values.createdBy,
		};
		const RuleData = encodeJson(jsonData, generalTypesAction.RULE);
		const responseData = yield call(createRule, RuleData);
		if (responseData.data.statusCode === '100') {
			yield put(createRuleFailure(responseData.data));
		} else {
			const DecodeRuleData = JWT.decode(responseData.data.response, generalTypesAction.RULE);
			yield put(createRuleSuccess(DecodeRuleData.data));
		}
	} catch (error) {
		yield put(createRuleFailure(error));
	}
}

export function* createRuleResponseReset() {
	yield put(createRuleChanged());
}

export function* ruleCreateStart() {
	yield takeLatest(ruleAddListTypes.RULE_CREATE_REPSONSE_START, createNewRule);
}

export function* ruleCreateResponseReset() {
	yield takeLatest(ruleAddListTypes.RULE_CREATE_REPSONSE_RESET, createRuleResponseReset);
}
/* RULE CREATE END */

/* RULE UPDATE START */
export function* updateRuleDetails({ payload: { values, offerType } }) {
	try {
		const jsonData = {
			RuleID: values.ruleID,
			RuleName: values.ruleName,
			AmountGreater: values.amountGreater,
			VaildTill: values.vaildTill,
			OfferType: offerType,
			CreatedBy: values.createdBy,
		};
		const RuleData = encodeJson(jsonData, generalTypesAction.RULE);
		const responseData = yield call(updateRule, RuleData);
		if (responseData.data.statusCode === '100') {
			yield put(updateRuleFailure(responseData.data));
		} else {
			const DecodeRuleData = JWT.decode(responseData.data.response, generalTypesAction.RULE);
			yield put(updateRuleSuccess(DecodeRuleData.data));
		}
	} catch (error) {
		yield put(updateRuleFailure(error));
	}
}

export function* updateRuleResponseReset() {
	yield put(updateRuleChanged());
}

export function* ruleUpdateStart() {
	yield takeLatest(ruleAddListTypes.RULE_UPDATE_REPSONSE_START, updateRuleDetails);
}

export function* ruleUpdateResponseReset() {
	yield takeLatest(ruleAddListTypes.RULE_UPDATE_REPSONSE_RESET, updateRuleResponseReset);
}
/* RULE UPDATE END */

export function* ruleListSaga() {
	yield all([
		call(ruleListDetails),
		call(ruleListResponseReset),
		call(ruleCreateStart),
		call(ruleCreateResponseReset),
		call(ruleUpdateStart),
		call(ruleUpdateResponseReset),
	]);
}
