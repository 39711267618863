/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import minicherTypes from './addMinicher.types';
import {
	getMinicherList,
	addMinicher,
	updateMinicher,
	updateOffMinicher,
	updateOnMinicher,
	getAddMinicherProductList,
	minicherProductCreate,
	getMinicherProductList,
	deleteMinicherProduct,
} from './addMinicher.service';
import {
	minicherSuccess,
	minicherFailure,
	minicherChanged,
	insertMinicherSuccess,
	insertMinicherFailure,
	insertMinicherChanged,
	editMinicherSuccess,
	editMinicherFailure,
	editMinicherChanged,
	offMinicherSuccess,
	offMinicherFailure,
	offMinicherChanged,
	onMinicherSuccess,
	onMinicherFailure,
	onMinicherChanged,
	addListMinicherProductSuccess,
	addListMinicherProductFailure,
	addListMinicherProductChanged,
	createMinicherProductSuccess,
	createMinicherProductFailure,
	createMinicherProductChanged,
	minicherProductListSuccess,
	minicherProductListFailure,
	minicherProductListChanged,
	deleteMinicherProductSuccess,
	deleteMinicherProductFailure,
	deleteMinicherProductChanged,
} from './addMinicher.action';

/* LIST MINICHER START */
export function* listOfMinicher({ payload: { listOfComboProduct } }) {
	try {
		const responseData = yield call(getMinicherList, listOfComboProduct);
		if (responseData.data.statusCode === '100') {
			yield put(minicherFailure(responseData.data));
		} else {
			yield put(minicherSuccess(responseData.data));
		}
	} catch (error) {
		yield put(minicherFailure(error));
	}
}

export function* listOfMinicherReset() {
	yield put(minicherChanged());
}

export function* getListMinicherReset() {
	yield takeLatest(minicherTypes.GET_MINICHER_LIST_RESET, listOfMinicherReset);
}

export function* getListMinicher() {
	yield takeLatest(minicherTypes.GET_MINICHER_LIST_START, listOfMinicher);
}
/* LIST MINICHER END */

/* INSERT MINICHER START */
export function* minicherAddList({ payload: { values, StaffID } }) {
	try {
		const jsonData = {
			MinicherName: values.MinicherName,
			MinicherQuantity: values.MinicherQuantity,
			MinicherImage: values.MinicherImage,
			MinicherPrice: values.MinicherPrice,
			MinicherMrpPrice: values.MinicherMrpPrice,
			CreatedBy: StaffID,
		};
		const responseData = yield call(addMinicher, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(insertMinicherFailure(responseData.data));
		} else {
			yield put(insertMinicherSuccess(responseData.data));
		}
	} catch (error) {
		yield put(insertMinicherFailure(error));
	}
}

export function* minicherAddListReset() {
	yield put(insertMinicherChanged());
}

export function* minicherAddStart() {
	yield takeLatest(minicherTypes.INSERT_MINICHER_START, minicherAddList);
}

export function* minicherAddReset() {
	yield takeLatest(minicherTypes.INSERT_MINICHER_RESET, minicherAddListReset);
}
/* INSERT MINICHER END */

/* UPDATE MINICHER START */
export function* updateMinicherList({ payload: { values, StaffID } }) {
	try {
		const jsonData = {
			MinicherID: values.MinicherID,
			MinicherName: values.MinicherName,
			MinicherQuantity: values.MinicherQuantity,
			MinicherImage: values.MinicherImage,
			MinicherPrice: values.MinicherPrice,
			MinicherMrpPrice: values.MinicherMrpPrice,
			CreatedBy: StaffID,
		};
		const responseData = yield call(updateMinicher, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(editMinicherFailure(responseData.data));
		} else {
			yield put(editMinicherSuccess(responseData.data));
		}
	} catch (error) {
		yield put(editMinicherFailure(error));
	}
}

export function* updateMinicherResponse() {
	yield put(editMinicherChanged());
}

export function* updateMinicherReset() {
	yield takeLatest(minicherTypes.UPDATE_MINICHER_RESET, updateMinicherResponse);
}

export function* updateMinicherStart() {
	yield takeLatest(minicherTypes.UPDATE_MINICHER_START, updateMinicherList);
}
/* UPDATE MINICHER END */

/* OFF MINICHER START */
export function* minicherOff({ payload: { turnOffMinicher } }) {
	try {
		const jsonData = {
			MinicherID: turnOffMinicher,
		};
		const responseData = yield call(updateOffMinicher, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(offMinicherFailure(responseData.data));
		} else {
			yield put(offMinicherSuccess(responseData.data));
		}
	} catch (error) {
		yield put(offMinicherFailure(error));
	}
}

export function* minicherOffResponse() {
	yield put(offMinicherChanged());
}

export function* minicherOffReset() {
	yield takeLatest(minicherTypes.OFF_MINICHER_RESET, minicherOffResponse);
}

export function* minicherOffStart() {
	yield takeLatest(minicherTypes.OFF_MINICHER_START, minicherOff);
}
/* OFF MINICHER END */

/* ON MINICHER START */
export function* minicherOn({ payload: { turnOnMinicher } }) {
	try {
		const jsonData = {
			MinicherID: turnOnMinicher,
		};
		const responseData = yield call(updateOnMinicher, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(onMinicherFailure(responseData.data));
		} else {
			yield put(onMinicherSuccess(responseData.data));
		}
	} catch (error) {
		yield put(onMinicherFailure(error));
	}
}

export function* minicherOnResponse() {
	yield put(onMinicherChanged());
}

export function* minicherOnReset() {
	yield takeLatest(minicherTypes.ON_MINICHER_RESET, minicherOnResponse);
}

export function* minicherOnStart() {
	yield takeLatest(minicherTypes.ON_MINICHER_START, minicherOn);
}
/* ON MINICHER END */

/* ADD MINICHER PRODUCT LIST START */
export function* listProductAdd({ payload: { comboAddProductList } }) {
	try {
		const responseData = yield call(getAddMinicherProductList, comboAddProductList);
		if (responseData.data.statusCode === '100') {
			yield put(addListMinicherProductFailure(responseData.data));
		} else {
			yield put(addListMinicherProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addListMinicherProductFailure(error));
	}
}

export function* listProductAddReset() {
	yield put(addListMinicherProductChanged());
}

export function* listProductAddResponseReset() {
	yield takeLatest(minicherTypes.ADD_LIST_MINICHER_PRODUCT_RESET, listProductAddReset);
}

export function* listProductAddStart() {
	yield takeLatest(minicherTypes.ADD_LIST_MINICHER_PRODUCT_START, listProductAdd);
}
/* ADD MINICHER PRODUCT LIST END */

/* CREATE MINICHER PRODUCT START */
export function* createMinicherProduct({ payload: { productDropDown } }) {
	try {
		const jsonData = JSON.stringify(productDropDown).replace(/"/g, "'");
		const responseData = yield call(minicherProductCreate, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(createMinicherProductFailure(responseData.data));
		} else {
			yield put(createMinicherProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createMinicherProductFailure(error));
	}
}

export function* createMinicherProductReset() {
	yield put(createMinicherProductChanged());
}

export function* minicherProductCreateResponseReset() {
	yield takeLatest(minicherTypes.CREATE_MINICHER_PRODUCT_RESET, createMinicherProductReset);
}

export function* minicherProductCreateStart() {
	yield takeLatest(minicherTypes.CREATE_MINICHER_PRODUCT_START, createMinicherProduct);
}
/* CREATE MINICHER PRODUCT END */

/* MINICHER PRODUCTS LIST START */
export function* minicherProductList({ payload: { id } }) {
	try {
		const responseData = yield call(getMinicherProductList, id);
		if (responseData.data.statusCode === '100') {
			yield put(minicherProductListFailure(responseData.data));
		} else {
			yield put(minicherProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(minicherProductListFailure(error));
	}
}

export function* minicherProductListReset() {
	yield put(minicherProductListChanged());
}

export function* minicherProductListGetResponseReset() {
	yield takeLatest(minicherTypes.GET_MINICHER_PRODUCT_LIST_RESET, minicherProductListReset);
}

export function* minicherProductListGetStart() {
	yield takeLatest(minicherTypes.GET_MINICHER_PRODUCT_LIST_START, minicherProductList);
}
/* MINICHER PRODUCTS LIST END */

/* DELETE MINICHER PRODUCT START */
export function* minicherProductsRemove({ payload: { ProductsValue } }) {
	try {
		const responseData = yield call(deleteMinicherProduct, ProductsValue);
		if (responseData.data.statusCode === '100') {
			yield put(deleteMinicherProductFailure(responseData.data));
		} else {
			yield put(deleteMinicherProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteMinicherProductFailure(error));
	}
}

export function* minicherProductsRemoveResponse() {
	yield put(deleteMinicherProductChanged());
}

export function* removeMinicherProductResponseReset() {
	yield takeLatest(minicherTypes.DELETE_MINICHER_PRODUCT_RESET, minicherProductsRemoveResponse);
}

export function* removeMinicherProductStart() {
	yield takeLatest(minicherTypes.DELETE_MINICHER_PRODUCT_START, minicherProductsRemove);
}
/* DELETE MINICHER PRODUCT END */

export function* minicherSaga() {
	yield all([
		call(getListMinicher),
		call(getListMinicherReset),
		call(minicherAddStart),
		call(minicherAddReset),
		call(updateMinicherStart),
		call(updateMinicherReset),
		call(minicherOffStart),
		call(minicherOffReset),
		call(minicherOnStart),
		call(minicherOnReset),
		call(listProductAddStart),
		call(listProductAddResponseReset),
		call(minicherProductCreateStart),
		call(minicherProductCreateResponseReset),
		call(minicherProductListGetStart),
		call(minicherProductListGetResponseReset),
		call(removeMinicherProductStart),
		call(removeMinicherProductResponseReset),
	]);
}
