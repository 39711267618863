const reconciliationTypes = {
	RECONCILIATION_LIST_START: 'RECONCILIATION_LIST_START',
	RECONCILIATION_LIST_SUCCESS: 'RECONCILIATION_LIST_SUCCESS',
	RECONCILIATION_LIST_FAILURE: 'RECONCILIATION_LIST_FAILURE',
	RECONCILIATION_LIST_RESET: 'RECONCILIATION_LIST_RESET',
	RECONCILIATION_LIST_CHANGED: 'RECONCILIATION_LIST_CHANGED',

	UPDATE_TRANSACTION_STATUS_START: 'update_transaction_status_start',
	UPDATE_TRANSACTION_STATUS_SUCCESS: 'update_transaction_status_success',
	UPDATE_TRANSACTION_STATUS_FAILURE: 'update_transaction_status_failure',
	UPDATE_TRANSACTION_STATUS_REST: 'update_transaction_status_rest',
	UPDATE_TRANSACTION_STATUS_CHANGED: 'update_transaction_status_changed',
};
export default reconciliationTypes;
