const orderReportType = {
	ORDER_REPORT_LIST_START: 'ORDER_REPORT_LIST_START',
	ORDER_REPORT_LIST_SUCCESS: 'ORDER_REPORT_LIST_SUCCESS',
	ORDER_REPORT_LIST_FAILURE: 'ORDER_REPORT_LIST_FAILURE',
	ORDER_REPORT_LIST_RESET: 'ORDER_REPORT_LIST_RESET',
	ORDER_REPORT_LIST_CHANGED: 'ORDER_REPORT_LIST_CHANGED',

	ORDER_REPORT_VIEW_START: 'ORDER_REPORT_VIEW_START',
	ORDER_REPORT_VIEW_SUCCESS: 'ORDER_REPORT_VIEW_SUCCESS',
	ORDER_REPORT_VIEW_FAILURE: 'ORDER_REPORT_VIEW_FAILURE',
	ORDER_REPORT_VIEW_RESET: 'ORDER_REPORT_VIEW_RESET',
	ORDER_REPORT_VIEW_CHANGED: 'ORDER_REPORT_VIEW_CHANGED',
};

export default orderReportType;
