/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import bannerTypes from './banner.types';
import { getBannerList, addBanner, bannerDelete, bannerUpdate } from './banner.service';
import {
	bannerListSuccess,
	bannerListFailure,
	bannerListChanged,
	bannerCreateSuccess,
	bannerCreateFailure,
	bannerCreateChanged,
	bannerUpdateSuccess,
	bannerUpdateFailure,
	bannerUpdateChanged,
	bannerDeleteSuccess,
	bannerDeleteFailure,
	bannerDeleteChanged,
} from './banner.action';
import { encodeJson } from '../enode-decode';

/* BANNER  LIST START */
export function* bannerListDetailsStart({ payload }) {
	try {
		const key = 'GetBannerListKey';
		const jsonData = {
			FromDate: payload.limit,
			SearchValueSet: payload.searchValueSet,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getBannerList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(bannerListFailure(responseData.data));
		} else {
			yield put(bannerListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(bannerListFailure(error));
	}
}

export function* bannerListDetailsChanged() {
	yield put(bannerListChanged());
}

export function* bannerListDetails() {
	yield takeLatest(bannerTypes.BANNER_LIST_START, bannerListDetailsStart);
}

export function* bannerListResponseReset() {
	yield takeLatest(bannerTypes.BANNER_LIST_RESET, bannerListDetailsChanged);
}
/* BANNER  LIST END */

/* BANNER CREATE START */
export function* createNewBanner({ payload }) {
	try {
		const key = 'CREATEBANNER';
		const jsonData = {
			BannerImage: payload.BannerImage,
			BannerName: payload.BannerName,
			ProductType: payload.ProductType,
			StaffID: payload.StaffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addBanner, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(bannerCreateFailure(responseData.data));
		} else {
			yield put(bannerCreateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(bannerCreateFailure(error));
	}
}

export function* createBannerResponseReset() {
	yield put(bannerCreateChanged());
}

export function* createBannerStart() {
	yield takeLatest(bannerTypes.BANNER_CREATE_START, createNewBanner);
}

export function* bannerCreateResponseReset() {
	yield takeLatest(bannerTypes.BANNER_CREATE_RESET, createBannerResponseReset);
}
/* BANNER CREATE END */

/* BANNER UPDATE  START */
export function* updateBanner({ payload }) {
	try {
		const key = 'UPDATEBANNER';
		const jsonData = {
			BannerID: payload.BannerID,
			BannerImage: payload.BannerImage,
			BannerName: payload.BannerName,
			ProductType: payload.ProductType,
			StaffID: payload.StaffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(bannerUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(bannerUpdateFailure(responseData.data));
		} else {
			yield put(bannerUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(bannerUpdateFailure(error));
	}
}

export function* updateBannerResponseReset() {
	yield put(bannerUpdateChanged());
}

export function* updateBannerStart() {
	yield takeLatest(bannerTypes.BANNER_UPDATE_START, updateBanner);
}

export function* bannerUpdateResponseReset() {
	yield takeLatest(bannerTypes.BANNER_UPDATE_RESET, updateBannerResponseReset);
}
/* BANNER UPDATE  END */

/* BANNER DELETE START */
export function* deleteBanner({ payload }) {
	try {
		const key = 'DELETEBANNER';
		const jsonData = {
			IsActive: payload.IsActive,
			BannerID: payload.BannerID,
			StaffID: payload.StaffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(bannerDelete, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(bannerDeleteFailure(responseData.data));
		} else {
			yield put(bannerDeleteSuccess(responseData.data));
		}
	} catch (error) {
		yield put(bannerDeleteFailure(error));
	}
}

export function* deleteBannerResponseReset() {
	yield put(bannerDeleteChanged());
}

export function* deleteBannerStart() {
	yield takeLatest(bannerTypes.BANNER_DELETE_START, deleteBanner);
}

export function* bannerDeleteResponseReset() {
	yield takeLatest(bannerTypes.BANNER_DELETE_RESET, deleteBannerResponseReset);
}
/* BANNER DELETE END */

export function* bannerListSaga() {
	yield all([
		call(bannerListDetails),
		call(bannerListResponseReset),
		call(createBannerStart),
		call(bannerCreateResponseReset),
		call(deleteBannerStart),
		call(bannerDeleteResponseReset),
		call(updateBannerStart),
		call(bannerUpdateResponseReset),
	]);
}
