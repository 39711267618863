// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const getOrderReport = (fetchData) => {
	const data = {
		GetOrderReportListToken: fetchData,
	};
	const url = `${API_URL}OrderReportList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getOrderReportView = (fetchData) => {
	const data = {
		ViewOrderDetailsToken: fetchData,
	};
	const url = `${API_URL}OrderDetailsView`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
