// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import refundTypes from './refund.types';
import { getUserAllOrderList, getRefundUpdate, getCustomerRefundedList } from './refund.service';
import {
	refundSuccess,
	refundFailure,
	refundChanged,
	refundUpdateSuccess,
	refundUpdateFailure,
	refundUpdateChanged,
	viewRefundedSuccess,
	viewRefundedFailure,
	viewRefundedChanged,
} from './refund.action';
import { encodeJson } from '../enode-decode';

/* REFUND LIST START */
export function* listRefund({ payload }) {
	try {
		const key = 'GetCustomerOnlineAndWalletListKey';
		const jsonData = {
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			BranchID: payload.branchID,
			PaymnetType: payload.paymnetType,
			SearchValueSet: payload.searchValueSet,
			Secratekey: key,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(getUserAllOrderList, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(refundFailure(responseData.data));
		} else {
			yield put(refundSuccess(responseData.data));
		}
	} catch (error) {
		yield put(refundFailure(error));
	}
}

export function* listRefundReset() {
	yield put(refundChanged());
}

export function* getRefundListReset() {
	yield takeLatest(refundTypes.REFUND_LIST_RESET, listRefundReset);
}

export function* getRefundList() {
	yield takeLatest(refundTypes.REFUND_LIST_START, listRefund);
}
/* REFUND LIST END */

/* REFUND UPDATE START */
export function* updateRefundDetails({ payload }) {
	try {
		const key = 'Refund_Update_Details';
		const jsonData = {
			UserID: payload.userID,
			OrderID: payload.orderID,
			OrderPlaceID: payload.orderPlaceID,
			ProductOrderPlaceID: payload.productOrderPlaceID,
			OrderModeID: payload.orderModeID,
			TransactionID: payload.transactionID,
			PaymnetType: payload.paymnetType,
			RefundReason: payload.refundReason,
			CreatedBy: payload.createdBy,
			PaymentTransactionID: payload.paymentTransactionID,
			TransactionAmount: payload.transactionAmount,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getRefundUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(refundUpdateFailure(responseData.data));
		} else {
			yield put(refundUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(refundUpdateFailure(error));
	}
}

export function* updateRefundResponseReset() {
	yield put(refundUpdateChanged());
}

export function* onRefundUpdateStart() {
	yield takeLatest(refundTypes.REFUND_UPDATE_START, updateRefundDetails);
}

export function* onRefundUpdateResponseReset() {
	yield takeLatest(refundTypes.REFUND_UPDATE_RESET, updateRefundResponseReset);
}
/* REFUND UPDATE END */

/* VIEW REFUNDED LIST START */
export function* listViewRefunded({ payload }) {
	try {
		const key = 'GetCustomerRefundedListKey';
		const jsonData = {
			UserID: payload.userID,
			OrderID: payload.orderID,
			PaymnetType: payload.paymnetType,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(getCustomerRefundedList, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(viewRefundedFailure(responseData.data));
		} else {
			yield put(viewRefundedSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewRefundedFailure(error));
	}
}

export function* listViewRefundedReset() {
	yield put(viewRefundedChanged());
}

export function* onViewRefundedResponseReset() {
	yield takeLatest(refundTypes.VIEW_REFUNDED_LIST_RESET, listViewRefundedReset);
}

export function* onViewRefundedStart() {
	yield takeLatest(refundTypes.VIEW_REFUNDED_LIST_START, listViewRefunded);
}
/* VIEW REFUNDED LIST END */

export function* refundSaga() {
	yield all([
		call(getRefundList),
		call(getRefundListReset),
		call(onRefundUpdateStart),
		call(onRefundUpdateResponseReset),
		call(onViewRefundedStart),
		call(onViewRefundedResponseReset),
	]);
}
