/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import dashboardCategoryTypes from './dashboardCategory.types';

/* DASHBOARD CATEGORY START */
export const dashboardCategoryListStart = (dashboardCategoryDetails) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_START,
	payload: dashboardCategoryDetails,
});

export const dashboardCategoryListSuccess = (dashboardCategorySuccess) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_SUCCESS,
	payload: dashboardCategorySuccess,
});

export const dashboardCategoryListFailure = (error) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_FAILURE,
	payload: error,
});

export const dashboardCategoryListChanged = () => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_CHANGED,
});

export const dashboardCategoryListReset = () => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_RESET,
});

export const createDashboardCategoryStart = (dashboardCategoryDetails) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_START,
	payload: dashboardCategoryDetails,
});

export const createDashboardCategorySuccess = (dashboardCategorySuccess) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_SUCCESS,
	payload: dashboardCategorySuccess,
});

export const createDashboardCategoryFailure = (error) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createDashboardCategoryChanged = () => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_CHANGED,
});

export const createDashboardCategoryReset = () => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_RESET,
});

export const updateDashboardCategoryStart = (dashboardCategoryDetails) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_START,
	payload: dashboardCategoryDetails,
});

export const updateDashboardCategorySuccess = (dashboardCategorySuccess) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_SUCCESS,
	payload: dashboardCategorySuccess,
});

export const updateDashboardCategoryFailure = (error) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateDashboardCategoryChanged = () => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_CHANGED,
});

export const updateDashboardCategoryReset = () => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_RESET,
});

export const deleteDashboardCategoryStart = (dashboardCategoryDetails) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_START,
	payload: dashboardCategoryDetails,
});

export const deleteDashboardCategorySuccess = (dashboardCategorySuccess) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_SUCCESS,
	payload: dashboardCategorySuccess,
});

export const deleteDashboardCategoryFailure = (error) => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deleteDashboardCategoryChanged = () => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_CHANGED,
});

export const deleteDashboardCategoryReset = () => ({
	type: dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_RESET,
});
/* DASHBOARD CATEGORY END */
