/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getProductOrderList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		FromDate: postListValue.FromDate,
		ToDate: postListValue.ToDate,
		OrderStatus: postListValue.activeTab,
		SearchValueSet: postListValue.searchValueSet,
		SelectedEmptyDate: postListValue.selectedEmptyDate,
	};
	const url = `${API_URL}GetProductOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const viewProductOrderList = (postListValue) => {
	const data = {
		UserID: postListValue.UserID,
		ProductOrderPlaceID: postListValue.ProductOrderPlaceID,
		FromDate: postListValue.FromDate,
		ToDate: postListValue.ToDate,
		OrderStatus: postListValue.activeTab,
		SearchValueSet: postListValue.searchValueSet,
		SelectedEmptyDate: postListValue.selectedEmptyDate,
	};
	const url = `${API_URL}GetViewProductOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const orderStatusUpdate = (postListValue) => {
	const data = {
		OrderStatus: postListValue.OrderStatus,
		ProductOrderPlaceID: postListValue.ProductOrderPlaceID,
	};
	const url = `${API_URL}UpdateOrderStatus`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const viewTranscationList = (postListValue) => {
	const data = {
		UserID: postListValue.UserID,
		ProductOrderPlaceID: postListValue.ProductOrderPlaceID,
	};
	const url = `${API_URL}GetViewTranscationList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
