/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import JWT from 'expo-jwt';
import generalTypesAction from '../genera.types';
import purchaseOrderTypes from './purchaseOrder.types';
import {
	getPurchaseOrderList,
	updatePurchaseOrderStatus,
	getViewPoProductsList,
} from './purchaseOrder.service';
import {
	purchaseOrderListSuccess,
	purchaseOrderListFailure,
	purchaseOrderListChanged,
	updatePurchaseOrderStatusSuccess,
	updatePurchaseOrderStatusFailure,
	updatePurchaseOrderStatusChanged,
	viewPoProductsListSuccess,
	viewPoProductsListFailure,
	viewPoProductsListChanged,
} from './purchaseOrder.action';
import { encodeJson } from '../enode-decode';

/* PURCHASE ORDER LIST START */
export function* purchaseOrderListDetailsStart({
	payload: { limit, searchValueSet, id, activeTab },
}) {
	try {
		const jsonData = {
			Limit: limit,
			SearchValueSet: searchValueSet,
			HubID: id,
			PurchaseOrderStatus: activeTab,
		};
		const PurchaseOrderData = encodeJson(jsonData, generalTypesAction.PURCHASEORDER);
		const responseData = yield call(getPurchaseOrderList, PurchaseOrderData);
		if (responseData.data.statusCode === '100') {
			yield put(purchaseOrderListFailure(responseData.data));
		} else {
			yield put(purchaseOrderListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(purchaseOrderListFailure(error));
	}
}

export function* purchaseOrderListDetailsChanged() {
	yield put(purchaseOrderListChanged());
}

export function* purchaseOrderListDetails() {
	yield takeLatest(purchaseOrderTypes.PURCHASE_ORDER_LIST_START, purchaseOrderListDetailsStart);
}

export function* purchaseOrderListResponseReset() {
	yield takeLatest(purchaseOrderTypes.PURCHASE_ORDER_LIST_RESET, purchaseOrderListDetailsChanged);
}
/* PURCHASE ORDER LIST END */

/* PURCHASE ORDER UPDATE STATUS START */
export function* updateOrderStatusDetailsStart({ payload: { orderStatusCredentials } }) {
	try {
		const jsonData = {
			OrderStatus: orderStatusCredentials.orderStatus,
			PurchaseOrderID: orderStatusCredentials.purchaseOrderID,
		};
		const PurchaseOrderData = encodeJson(jsonData, generalTypesAction.PURCHASEORDER);
		const responseData = yield call(updatePurchaseOrderStatus, PurchaseOrderData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePurchaseOrderStatusFailure(responseData.data));
		} else {
			const DecodePurchseOrderData = JWT.decode(
				responseData.data.response,
				generalTypesAction.PURCHASEORDER,
			);
			yield put(updatePurchaseOrderStatusSuccess(DecodePurchseOrderData.data));
		}
	} catch (error) {
		yield put(updatePurchaseOrderStatusFailure(error));
	}
}

export function* updateOrderStatusDetailsChanged() {
	yield put(updatePurchaseOrderStatusChanged());
}

export function* updateOrderStatusDetails() {
	yield takeLatest(
		purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_START,
		updateOrderStatusDetailsStart,
	);
}

export function* updateOrderStatusResponseReset() {
	yield takeLatest(
		purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_RESET,
		updateOrderStatusDetailsChanged,
	);
}
/* PURCHASE ORDER UPDATE STATUS END */

/* VIEW PO PRODUCTS LIST START */
export function* viewPoProductsDetailsStart({ payload: { purchaseOrderID, activeTab } }) {
	try {
		const jsonData = {
			PurchaseOrderID: purchaseOrderID,
			PurchaseOrderStatus: activeTab,
		};
		const PurchaseOrderData = encodeJson(jsonData, generalTypesAction.PURCHASEORDER);
		const responseData = yield call(getViewPoProductsList, PurchaseOrderData);
		if (responseData.data.statusCode === '100') {
			yield put(viewPoProductsListFailure(responseData.data));
		} else {
			yield put(viewPoProductsListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewPoProductsListFailure(error));
	}
}

export function* viewPoProductsDetailsChanged() {
	yield put(viewPoProductsListChanged());
}

export function* viewPoProductsDetails() {
	yield takeLatest(purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_START, viewPoProductsDetailsStart);
}

export function* viewPoProductsDetailsResponseReset() {
	yield takeLatest(purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_RESET, viewPoProductsDetailsChanged);
}
/* VIEW PO PRODUCTS LIST END */

export function* purchaseOrderListSaga() {
	yield all([
		call(purchaseOrderListDetails),
		call(purchaseOrderListResponseReset),
		call(updateOrderStatusDetails),
		call(updateOrderStatusResponseReset),
		call(viewPoProductsDetails),
		call(viewPoProductsDetailsResponseReset),
	]);
}
