/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import TodayEodsTypes from './today-eods.types';

/** ***************** TODAY EODS STAFF LIST START *********************** */
export const fetchTodayEodsStaffListStart = (fetchdata) => ({
	type: TodayEodsTypes.TODAY_EODS_STAFF_LIST_START,
	payload: fetchdata,
});

export const fetchTodayEodsStaffListSuccess = (successData) => ({
	type: TodayEodsTypes.TODAY_EODS_STAFF_LIST_SUCCESS,
	payload: successData,
});

export const fetchTodayEodsStaffListFailure = (failureData) => ({
	type: TodayEodsTypes.TODAY_EODS_STAFF_LIST_FAILURE,
	payload: failureData,
});

export const fetchTodayEodsStaffListResponseResetStart = () => ({
	type: TodayEodsTypes.TODAY_EODS_STAFF_LIST_RESPONSE_RESET_START,
});

export const fetchTodayEodsStaffListResponseChanged = () => ({
	type: TodayEodsTypes.TODAY_EODS_STAFF_LIST_RESPONSE_CHANGED,
});

/** ***************** TODAY EODS STAFF LIST END *********************** */

/** ***************** TODAY EODS ORDER LIST START *********************** */
export const fetchTodayEodsOrderListStart = (fetchdata) => ({
	type: TodayEodsTypes.TODAY_EODS_ORDER_LIST_START,
	payload: fetchdata,
});

export const fetchTodayEodsOrderListSuccess = (successData) => ({
	type: TodayEodsTypes.TODAY_EODS_ORDER_LIST_SUCCESS,
	payload: successData,
});

export const fetchTodayEodsOrderListFailure = (failureData) => ({
	type: TodayEodsTypes.TODAY_EODS_ORDER_LIST_FAILURE,
	payload: failureData,
});

export const fetchTodayEodsOrderListResponseResetStart = () => ({
	type: TodayEodsTypes.TODAY_EODS_ORDER_LIST_RESPONSE_RESET_START,
});

export const fetchTodayEodsOrderListResponseChanged = () => ({
	type: TodayEodsTypes.TODAY_EODS_ORDER_LIST_RESPONSE_CHANGED,
});

/** ***************** TODAY EODS ORDER LIST END *********************** */

/** ***************** UPDATE ORDER STATUS START *********************** */
export const updateOrderStatusStart = (fetchdata) => ({
	type: TodayEodsTypes.UPDATE_ORDER_STATUS_START,
	payload: fetchdata,
});

export const updateOrderStatusSuccess = (successData) => ({
	type: TodayEodsTypes.UPDATE_ORDER_STATUS_SUCCESS,
	payload: successData,
});

export const updateOrderStatusFailure = (failureData) => ({
	type: TodayEodsTypes.UPDATE_ORDER_STATUS_FAILURE,
	payload: failureData,
});

export const updateOrderStatusResponseResetStart = () => ({
	type: TodayEodsTypes.UPDATE_ORDER_STATUS_RESPONSE_RESET_START,
});

export const updateOrderStatusResponseChanged = () => ({
	type: TodayEodsTypes.UPDATE_ORDER_STATUS_RESPONSE_CHANGED,
});

/** ***************** UPDATE ORDER STATUS END *********************** */
