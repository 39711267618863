/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import dashBoardTypes from './DashBoard.types';
import {
	getDashboardOrderList,
	getListViewDashboard,
	getListCustomerDashboard,
	getSalesOrderDetails,
	getTotalOnlineCreatedList,
	dashboardReconciliationUpdate,
} from './DashBoard.service';
import {
	dashBoardListSuccess,
	dashBoardListFailure,
	dashBoardListChanged,
	dashBoardViewListSuccess,
	dashBoardViewListFailure,
	dashBoardViewListChanged,
	dashBoardCustomerListSuccess,
	dashBoardCustomerListFailure,
	dashBoardCustomerListChanged,
	fetchOrderSalesDetailSuccess,
	fetchOrderSalesDetailFailure,
	fetchOrderSalesDetailResponseChanged,
	totalOnlineCreatedListSuccess,
	totalOnlineCreatedListFailure,
	totalOnlineCreatedListResponseChanged,
	updateTransactionStatusDashboardSuccess,
	updateTransactionStatusDashboardFailure,
	updateTransactionStatusDashboardChanged,
} from './DashBoard.action';
import { encodeJson } from '../enode-decode';

/* DASHBOARD LIST START */
export function* dashBoardList({ payload }) {
	try {
		const key = 'DashboardListKey';
		const jsonData = {
			Limit: payload.limit,
			ActiveTab: payload.activeTab,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getDashboardOrderList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(dashBoardListFailure(responseData.data));
		} else {
			yield put(dashBoardListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(dashBoardListFailure(error));
	}
}

export function* getDashBoardList() {
	yield takeLatest(dashBoardTypes.GET_DASHBOARD_LIST_START, dashBoardList);
}

export function* createDashBoardListChanged() {
	yield put(dashBoardListChanged());
}

export function* dashBoardResponseReset() {
	yield takeLatest(dashBoardTypes.GET_DASHBOARD_LIST_RESET, createDashBoardListChanged);
}
/* DASHBOARD LIST END */

/* DASHBOARD VIEW LIST START */
export function* dashBoardViewList({ payload }) {
	try {
		const key = 'DashboardViewListKey';
		const jsonData = {
			OrderPlaceID: payload.oderPlaceID,
			UserID: payload.userID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getListViewDashboard, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(dashBoardViewListFailure(responseData.data));
		} else {
			yield put(dashBoardViewListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(dashBoardViewListFailure(error));
	}
}

export function* viewDashBoardListChanged() {
	yield put(dashBoardViewListChanged());
}

export function* getDashBoardViewList() {
	yield takeLatest(dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_START, dashBoardViewList);
}

export function* dashBoardViewResponseReset() {
	yield takeLatest(dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_RESET, viewDashBoardListChanged);
}
/* DASHBOARD VIEW LIST END */

/* DASHBOARD CUSTOMER LIST START */
export function* dashBoardCustomerList({ payload }) {
	try {
		const key = 'DashboardCustomerListKey';
		const jsonData = {
			Limit: payload.limit,
			ActiveTab: payload.activeTab,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getListCustomerDashboard, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(dashBoardCustomerListFailure(responseData.data));
		} else {
			yield put(dashBoardCustomerListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(dashBoardCustomerListFailure(error));
	}
}

export function* customerDashBoardListChanged() {
	yield put(dashBoardCustomerListChanged());
}

export function* getDashBoardCustomerList() {
	yield takeLatest(dashBoardTypes.DASHBOARD_CUSTOMER_LIST_START, dashBoardCustomerList);
}

export function* dashBoardCustomerResponseReset() {
	yield takeLatest(dashBoardTypes.DASHBOARD_CUSTOMER_LIST_RESET, customerDashBoardListChanged);
}
/* DASHBOARD CUSTOMER LIST END */

/** *********************  START FETCH ORDER SALES DETAILS  ******************** */
export function* fetchOrderSalesDetails({ payload }) {
	try {
		const key = 'FetchOrderSalesDetailsKey';
		const jsonData = {
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			FilterData: payload.filterValue,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getSalesOrderDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchOrderSalesDetailFailure(responseData.data));
		} else {
			yield put(fetchOrderSalesDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchOrderSalesDetailFailure(error));
	}
}

export function* resetFetchOrderSalesResponse() {
	yield put(fetchOrderSalesDetailResponseChanged());
}

export function* onFetchOrderSalesDetails() {
	yield takeLatest(dashBoardTypes.FETCH_ORDER_SALES_DETAILS_START, fetchOrderSalesDetails);
}

export function* onFetchOrderSalesResponseReset() {
	yield takeLatest(
		dashBoardTypes.FETCH_ORDER_SALES_DETAILS_RESPONSE_RESET_START,
		resetFetchOrderSalesResponse,
	);
}
/** *********************  END FETCH ORDER SALES DETAILS ******************** */

/** *********************  START FETCH ONLINE PAYMENTS CREATED DETAILS  ******************** */
export function* fetchTotalOnlineCreated({ payload }) {
	try {
		const key = 'GetTotalOnlineCreatedListKey';
		const jsonData = {
			Limit: payload.limit,
			ActiveTab: payload.activeTab,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getTotalOnlineCreatedList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(totalOnlineCreatedListFailure(responseData.data));
		} else {
			yield put(totalOnlineCreatedListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(totalOnlineCreatedListFailure(error));
	}
}

export function* resetTotalOnlineCreatedResponse() {
	yield put(totalOnlineCreatedListResponseChanged());
}

export function* onTotalOnlineCreated() {
	yield takeLatest(dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_START, fetchTotalOnlineCreated);
}

export function* onTotalOnlineCreatedResponseReset() {
	yield takeLatest(
		dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_RESPONSE_RESET_START,
		resetTotalOnlineCreatedResponse,
	);
}
/** *********************  END FETCH ONLINE PAYMENTS CREATED DETAILS ******************** */

/** *********************  DASHBOARD TRANSACTION CHECK START  ******************** */
export function* updateTransactionCheckDashoard({ payload }) {
	try {
		const key = 'DashboardReconciliationUpdateKey';
		const jsonData = {
			Limit: payload.limit,
			ActiveTab: payload.activeTab,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(dashboardReconciliationUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateTransactionStatusDashboardFailure(responseData.data));
		} else {
			yield put(updateTransactionStatusDashboardSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateTransactionStatusDashboardFailure(error));
	}
}

export function* resetUpdateTransactionCheckDashoardResponse() {
	yield put(updateTransactionStatusDashboardChanged());
}

export function* onUpdateTransactionCheckDashoard() {
	yield takeLatest(
		dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_START,
		updateTransactionCheckDashoard,
	);
}

export function* onUpdateTransactionCheckDashoardResponseReset() {
	yield takeLatest(
		dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_RESPONSE_RESET_START,
		resetUpdateTransactionCheckDashoardResponse,
	);
}
/** *********************  DASHBOARD TRANSACTION CHECK END ******************** */

export function* dashBoardListSaga() {
	yield all([
		call(getDashBoardList),
		call(dashBoardResponseReset),
		call(getDashBoardViewList),
		call(dashBoardViewResponseReset),
		call(getDashBoardCustomerList),
		call(dashBoardCustomerResponseReset),
		call(onFetchOrderSalesDetails),
		call(onFetchOrderSalesResponseReset),
		call(onTotalOnlineCreated),
		call(onTotalOnlineCreatedResponseReset),
		call(onUpdateTransactionCheckDashoard),
		call(onUpdateTransactionCheckDashoardResponseReset),
	]);
}
