/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import SalesReportTypes from './sales-report.types';

/** ***************** FETCH SALES DETAILS START *********************** */
export const fetchSalesDetailStart = (fetchdata) => ({
	type: SalesReportTypes.FETCH_SALES_DETAILS_START,
	payload: fetchdata,
});

export const fetchSalesDetailSuccess = (successData) => ({
	type: SalesReportTypes.FETCH_SALES_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchSalesDetailFailure = (failureData) => ({
	type: SalesReportTypes.FETCH_SALES_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchSalesDetailResponseResetStart = () => ({
	type: SalesReportTypes.FETCH_SALES_DETAILS_RESPONSE_RESET_START,
});

export const fetchSalesDetailResponseChanged = () => ({
	type: SalesReportTypes.FETCH_SALES_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH SALES DETAILS END *********************** */

/** ***************** FETCH PRODUCT SALES DETAILS START *********************** */
export const fetchProductSalesDetailStart = (fetchdata) => ({
	type: SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_START,
	payload: fetchdata,
});

export const fetchProductSalesDetailSuccess = (successData) => ({
	type: SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchProductSalesDetailFailure = (failureData) => ({
	type: SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchProductSalesDetailResponseResetStart = () => ({
	type: SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_RESPONSE_RESET_START,
});

export const fetchProductSalesDetailResponseChanged = () => ({
	type: SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH PRODUCT SALES DETAILS END *********************** */
