/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import pushNotificationTypes from './pushNotification.types';
import { sendPushNotification, getNotificationCustomerList } from './pushNotification.service';
import {
	sendPushNotificationSuccess,
	sendPushNotificationFailure,
	sendPushNotificationChanged,
	notificationCustomerListSuccess,
	notificationCustomerListFailure,
	notificationCustomerListChanged,
} from './pushNotification.action';
import { encodeJson } from '../enode-decode';

/* SEND PUSH NOTIFICATION START */
export function* sendPushNotificationStatus({ payload }) {
	try {
		const key = 'SendPushNotificationKey';
		const jsonData = {
			UserArr: payload.userArr,
			NotificationMessage: payload.notificationMessage,
			Secratekey: key,
		};
		const notificationData = encodeJson(jsonData, key);
		const responseData = yield call(sendPushNotification, notificationData);
		if (responseData.data.statusCode === '100') {
			yield put(sendPushNotificationFailure(responseData.data));
		} else {
			yield put(sendPushNotificationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(sendPushNotificationFailure(error));
	}
}

export function* restSendPushNotificationStatusResponse() {
	yield put(sendPushNotificationChanged());
}

export function* onSendPushNotificationStart() {
	yield takeLatest(
		pushNotificationTypes.SEND_PUSH_NOTIFICATION_START,
		sendPushNotificationStatus,
	);
}

export function* onSendPushNotificationResponse() {
	yield takeLatest(
		pushNotificationTypes.SEND_PUSH_NOTIFICATION_RESET,
		restSendPushNotificationStatusResponse,
	);
}
/* SEND PUSH NOTIFICATION END */

/* NOTIFICATION CUSTOMER LIST START */
export function* notificationCustomerListStatus({ payload }) {
	try {
		const key = 'NotificationCustomerListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			SearchValueSet: payload.searchValueSet,
			Secratekey: key,
		};
		const notificationData = encodeJson(jsonData, key);
		const responseData = yield call(getNotificationCustomerList, notificationData);
		if (responseData.data.statusCode === '100') {
			yield put(notificationCustomerListFailure(responseData.data));
		} else {
			yield put(notificationCustomerListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(notificationCustomerListFailure(error));
	}
}

export function* restNotificationCustomerListStatusResponse() {
	yield put(notificationCustomerListChanged());
}

export function* onNotificationCustomerListStart() {
	yield takeLatest(
		pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_START,
		notificationCustomerListStatus,
	);
}

export function* onNotificationCustomerListResponse() {
	yield takeLatest(
		pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_RESET,
		restNotificationCustomerListStatusResponse,
	);
}
/* NOTIFICATION CUSTOMER LIST END */

export function* pushNotificationSaga() {
	yield all([
		call(onSendPushNotificationStart),
		call(onSendPushNotificationResponse),
		call(onNotificationCustomerListStart),
		call(onNotificationCustomerListResponse),
	]);
}
