/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import productOrderTypes from './productOrder.types';

const INITIAL_STATE = {
	productOrderResponse: null,
	productOrderStatus: null,
	viewProductOrderResponse: null,
	viewProductOrderStatus: null,
	updateOrderStatusResponse: null,
	updateOrderStatus: null,
	viewTranscationListResponse: null,
	viewTranscationListStatus: null,
};

const productOrderReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case productOrderTypes.PRODUCT_ORDER_LIST_SUCCESS:
			return {
				...state,
				productOrderResponse: action.payload,
				productOrderStatus: 'Success',
			};

		case productOrderTypes.PRODUCT_ORDER_LIST_FAILURE:
			return {
				...state,
				productOrderResponse: action.payload,
				productOrderStatus: 'Failure',
			};

		case productOrderTypes.PRODUCT_ORDER_LIST_CHANGED:
			return { ...state, productOrderResponse: null, productOrderStatus: null };

		case productOrderTypes.VIEW_PRODUCT_ORDER_LIST_SUCCESS:
			return {
				...state,
				viewProductOrderResponse: action.payload,
				viewProductOrderStatus: 'Success',
			};

		case productOrderTypes.VIEW_PRODUCT_ORDER_LIST_FAILURE:
			return {
				...state,
				viewProductOrderResponse: action.payload,
				viewProductOrderStatus: 'Failure',
			};

		case productOrderTypes.VIEW_PRODUCT_ORDER_LIST_CHANGED:
			return { ...state, viewProductOrderResponse: null, viewProductOrderStatus: null };

		case productOrderTypes.UPDATE_ORDER_STATUS_SUCCESS:
			return {
				...state,
				updateOrderStatusResponse: action.payload,
				updateOrderStatus: 'Success',
			};

		case productOrderTypes.UPDATE_ORDER_STATUS_FAILURE:
			return {
				...state,
				updateOrderStatusResponse: action.payload,
				updateOrderStatus: 'Failure',
			};

		case productOrderTypes.UPDATE_ORDER_STATUS_CHANGED:
			return { ...state, updateOrderStatusResponse: null, updateOrderStatus: null };

		case productOrderTypes.VIEW_USER_TRANSCATION_LIST_SUCCESS:
			return {
				...state,
				viewTranscationListResponse: action.payload,
				viewTranscationListStatus: 'Success',
			};

		case productOrderTypes.VIEW_USER_TRANSCATION_LIST_FAILURE:
			return {
				...state,
				viewTranscationListResponse: action.payload,
				viewTranscationListStatus: 'Failure',
			};

		case productOrderTypes.VIEW_USER_TRANSCATION_LIST_CHANGED:
			return { ...state, viewTranscationListResponse: null, viewTranscationListStatus: null };

		default:
			return state;
	}
};

export default productOrderReducer;
