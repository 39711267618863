const dashboardCategoryTypes = {
	DASHBOARD_CATEGORY_LIST_START: 'DASHBOARD_CATEGORY_LIST_START',
	DASHBOARD_CATEGORY_LIST_SUCCESS: 'DASHBOARD_CATEGORY_LIST_SUCCESS',
	DASHBOARD_CATEGORY_LIST_FAILURE: 'DASHBOARD_CATEGORY_LIST_FAILURE',
	DASHBOARD_CATEGORY_LIST_RESET: 'DASHBOARD_CATEGORY_LIST_RESET',
	DASHBOARD_CATEGORY_LIST_CHANGED: 'DASHBOARD_CATEGORY_LIST_CHANGED',

	DASHBOARD_CATEGORY_CREATE_REPSONSE_START: 'DASHBOARD_CATEGORY_CREATE_REPSONSE_START',
	DASHBOARD_CATEGORY_CREATE_REPSONSE_SUCCESS: 'DASHBOARD_CATEGORY_CREATE_REPSONSE_SUCCESS',
	DASHBOARD_CATEGORY_CREATE_REPSONSE_FAILURE: 'DASHBOARD_CATEGORY_CREATE_REPSONSE_FAILURE',
	DASHBOARD_CATEGORY_CREATE_REPSONSE_CHANGED: 'DASHBOARD_CATEGORY_CREATE_REPSONSE_CHANGED',
	DASHBOARD_CATEGORY_CREATE_REPSONSE_RESET: 'DASHBOARD_CATEGORY_CREATE_REPSONSE_RESET',

	DASHBOARD_CATEGORY_UPDATE_REPSONSE_START: 'DASHBOARD_CATEGORY_UPDATE_REPSONSE_START',
	DASHBOARD_CATEGORY_UPDATE_REPSONSE_SUCCESS: 'DASHBOARD_CATEGORY_UPDATE_REPSONSE_SUCCESS',
	DASHBOARD_CATEGORY_UPDATE_REPSONSE_FAILURE: 'DASHBOARD_CATEGORY_UPDATE_REPSONSE_FAILURE',
	DASHBOARD_CATEGORY_UPDATE_REPSONSE_CHANGED: 'DASHBOARD_CATEGORY_UPDATE_REPSONSE_CHANGED',
	DASHBOARD_CATEGORY_UPDATE_REPSONSE_RESET: 'DASHBOARD_CATEGORY_UPDATE_REPSONSE_RESET',

	DASHBOARD_CATEGORY_DELETE_REPSONSE_START: 'DASHBOARD_CATEGORY_DELETE_REPSONSE_START',
	DASHBOARD_CATEGORY_DELETE_REPSONSE_SUCCESS: 'DASHBOARD_CATEGORY_DELETE_REPSONSE_SUCCESS',
	DASHBOARD_CATEGORY_DELETE_REPSONSE_FAILURE: 'DASHBOARD_CATEGORY_DELETE_REPSONSE_FAILURE',
	DASHBOARD_CATEGORY_DELETE_REPSONSE_CHANGED: 'DASHBOARD_CATEGORY_DELETE_REPSONSE_CHANGED',
	DASHBOARD_CATEGORY_DELETE_REPSONSE_RESET: 'DASHBOARD_CATEGORY_DELETE_REPSONSE_RESET',
};
export default dashboardCategoryTypes;
