const transcationListTypes = {
	TRANSCATION_LIST_START: 'TRANSCATION_LIST_START',
	TRANSCATION_LIST_SUCCESS: 'TRANSCATION_LIST_SUCCESS',
	TRANSCATION_LIST_FAILURE: 'TRANSCATION_LIST_FAILURE',
	TRANSCATION_LIST_RESET: 'TRANSCATION_LIST_RESET',
	TRANSCATION_LIST_CHANGED: 'TRANSCATION_LIST_CHANGED',

	VIEW_TRANSCATION_LIST_START: 'VIEW_TRANSCATION_LIST_START',
	VIEW_TRANSCATION_LIST_SUCCESS: 'VIEW_TRANSCATION_LIST_SUCCESS',
	VIEW_TRANSCATION_LIST_FAILURE: 'VIEW_TRANSCATION_LIST_FAILURE',
	VIEW_TRANSCATION_LIST_RESET: 'VIEW_TRANSCATION_LIST_RESET',
	VIEW_TRANSCATION_LIST_CHANGED: 'VIEW_TRANSCATION_LIST_CHANGED',

	FLOWERS_TRANSCATION_LIST_START: 'FLOWERS_TRANSCATION_LIST_START',
	FLOWERS_TRANSCATION_LIST_SUCCESS: 'FLOWERS_TRANSCATION_LIST_SUCCESS',
	FLOWERS_TRANSCATION_LIST_FAILURE: 'FLOWERS_TRANSCATION_LIST_FAILURE',
	FLOWERS_TRANSCATION_LIST_RESET: 'FLOWERS_TRANSCATION_LIST_RESET',
	FLOWERS_TRANSCATION_LIST_CHANGED: 'FLOWERS_TRANSCATION_LIST_CHANGED',
};
export default transcationListTypes;
