/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import transcationListTypes from './transcationList.types';

/* TRANSCATION LIST START */
export const transcationStart = (transcationDetails) => ({
	type: transcationListTypes.TRANSCATION_LIST_START,
	payload: transcationDetails,
});

export const transcationSuccess = (transcationListSuccess) => ({
	type: transcationListTypes.TRANSCATION_LIST_SUCCESS,
	payload: transcationListSuccess,
});

export const transcationFailure = (error) => ({
	type: transcationListTypes.TRANSCATION_LIST_FAILURE,
	payload: error,
});

export const transcationChanged = () => ({
	type: transcationListTypes.TRANSCATION_LIST_CHANGED,
});

export const transcationReset = () => ({
	type: transcationListTypes.TRANSCATION_LIST_RESET,
});
/* TRANSCATION LIST END */

/* VIEW TRANSCATION LIST START */
export const viewTranscationStart = (transcationDetails) => ({
	type: transcationListTypes.VIEW_TRANSCATION_LIST_START,
	payload: transcationDetails,
});

export const viewTranscationSuccess = (transcationListSuccess) => ({
	type: transcationListTypes.VIEW_TRANSCATION_LIST_SUCCESS,
	payload: transcationListSuccess,
});

export const viewTranscationFailure = (error) => ({
	type: transcationListTypes.VIEW_TRANSCATION_LIST_FAILURE,
	payload: error,
});

export const viewTranscationChanged = () => ({
	type: transcationListTypes.VIEW_TRANSCATION_LIST_CHANGED,
});

export const viewTranscationReset = () => ({
	type: transcationListTypes.VIEW_TRANSCATION_LIST_RESET,
});
/* VIEW TRANSCATION LIST END */

/* FLOWERS TRANSCATION LIST START */
export const flowersTranscationStart = (transcationDetails) => ({
	type: transcationListTypes.FLOWERS_TRANSCATION_LIST_START,
	payload: transcationDetails,
});

export const flowersTranscationSuccess = (transcationListSuccess) => ({
	type: transcationListTypes.FLOWERS_TRANSCATION_LIST_SUCCESS,
	payload: transcationListSuccess,
});

export const flowersTranscationFailure = (error) => ({
	type: transcationListTypes.FLOWERS_TRANSCATION_LIST_FAILURE,
	payload: error,
});

export const flowersTranscationChanged = () => ({
	type: transcationListTypes.FLOWERS_TRANSCATION_LIST_CHANGED,
});

export const flowersTranscationReset = () => ({
	type: transcationListTypes.FLOWERS_TRANSCATION_LIST_RESET,
});
/* FLOWERS TRANSCATION LIST END */
