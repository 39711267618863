/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const selectUser = (state) => state.login;

export const selectCurrentUser = createSelector([selectUser], (login) => login.loginResponse);

export const selectLoginAndLogoutLogResp = createSelector(
	[selectUser],
	(login) => login.loginAndLogoutLogResponse,
);
