/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import flowersListTypes from './addFlowers.types';

/* FLOWERS LIST START */
export const flowersListStart = (flowersListDetails) => ({
	type: flowersListTypes.GET_FLOWERS_LIST_START,
	payload: flowersListDetails,
});

export const flowersListSuccess = (flowersSuccess) => ({
	type: flowersListTypes.GET_FLOWERS_LIST_SUCCESS,
	payload: flowersSuccess,
});

export const flowersListFailure = (error) => ({
	type: flowersListTypes.GET_FLOWERS_LIST_FAILURE,
	payload: error,
});

export const flowersListChanged = () => ({
	type: flowersListTypes.GET_FLOWERS_LIST_CHANGED,
});

export const flowersListReset = () => ({
	type: flowersListTypes.GET_FLOWERS_LIST_RESET,
});
/* FLOWERS LIST END */

/* ADD FLOWERS START */
export const addFlowersStart = (flowersListDetails) => ({
	type: flowersListTypes.ADD_FLOWERS_START,
	payload: flowersListDetails,
});

export const addFlowersSuccess = (flowersSuccess) => ({
	type: flowersListTypes.ADD_FLOWERS_SUCCESS,
	payload: flowersSuccess,
});

export const addFlowersFailure = (error) => ({
	type: flowersListTypes.ADD_FLOWERS_FAILURE,
	payload: error,
});

export const addFlowersChanged = () => ({
	type: flowersListTypes.ADD_FLOWERS_CHANGED,
});

export const addFlowersReset = () => ({
	type: flowersListTypes.ADD_FLOWERS_RESET,
});

/* ADD FLOWERS END */

/* EDIT FLOWERS START */
export const editFlowersStart = (flowersListDetails) => ({
	type: flowersListTypes.EDIT_FLOWERS_START,
	payload: flowersListDetails,
});

export const editFlowersSuccess = (flowersSuccess) => ({
	type: flowersListTypes.EDIT_FLOWERS_SUCCESS,
	payload: flowersSuccess,
});

export const editFlowersFailure = (error) => ({
	type: flowersListTypes.EDIT_FLOWERS_FAILURE,
	payload: error,
});

export const editFlowersChanged = () => ({
	type: flowersListTypes.EDIT_FLOWERS_CHANGED,
});

export const editFlowersReset = () => ({
	type: flowersListTypes.EDIT_FLOWERS_RESET,
});

/* EDIT FLOWERS END */

/* REMOVE FLOWERS START */
export const removeFlowersStart = (flowersListDetails) => ({
	type: flowersListTypes.REMOVE_FLOWERS_START,
	payload: flowersListDetails,
});

export const removeFlowersSuccess = (flowersSuccess) => ({
	type: flowersListTypes.REMOVE_FLOWERS_SUCCESS,
	payload: flowersSuccess,
});

export const removeFlowersFailure = (error) => ({
	type: flowersListTypes.REMOVE_FLOWERS_FAILURE,
	payload: error,
});

export const removeFlowersChanged = () => ({
	type: flowersListTypes.REMOVE_FLOWERS_CHANGED,
});

export const removeFlowersReset = () => ({
	type: flowersListTypes.REMOVE_FLOWERS_RESET,
});

/* REMOVE FLOWERS END */
