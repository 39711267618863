/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import otpTypes from './otpList.types';

const INITIAL_STATE = {
	otpListResponse: null,
	otpListStatus: null,
};

const otpReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case otpTypes.GET_OTP_LIST_SUCCESS:
			return {
				...state,
				otpListResponse: action.payload,
				otpListStatus: 'Success',
			};

		case otpTypes.GET_OTP_LIST_FAILURE:
			return {
				...state,
				otpListResponse: action.payload,
				otpListStatus: 'Failure',
			};

		case otpTypes.GET_OTP_LIST_CHANGED:
			return { ...state, otpListResponse: null, otpListStatus: null };

		default:
			return state;
	}
};

export default otpReducer;
