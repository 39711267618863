/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import priceUpdateTypes from './priceUpdate.types';

const INITIAL_STATE = {
	priceUpdateResponse: null,
	priceUpdateResponseStatus: null,
	priceEditResponse: null,
	priceEditResponseStatus: null,
};

const priceUpdateReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case priceUpdateTypes.PRICE_UPDATE_LIST_SUCCESS:
			return {
				...state,
				priceUpdateResponse: action.payload,
				priceUpdateResponseStatus: 'Success',
			};

		case priceUpdateTypes.PRICE_UPDATE_LIST_FAILURE:
			return {
				...state,
				priceUpdateResponse: action.payload,
				priceUpdateResponseStatus: 'Failure',
			};

		case priceUpdateTypes.PRICE_UPDATE_LIST_CHANGED:
			return { ...state, priceUpdateResponse: null, priceUpdateResponseStatus: null };

		case priceUpdateTypes.PRICE_UPDATE_SUCCESS:
			return {
				...state,
				priceEditResponse: action.payload,
				priceEditResponseStatus: 'Success',
			};

		case priceUpdateTypes.PRICE_UPDATE_FAILURE:
			return {
				...state,
				priceEditResponse: action.payload,
				priceEditResponseStatus: 'Failure',
			};

		case priceUpdateTypes.PRICE_UPDATE_CHANGED:
			return { ...state, priceEditResponse: null, priceEditResponseStatus: null };

		default:
			return state;
	}
};

export default priceUpdateReducer;
