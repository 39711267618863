// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-debugger */
import { createSelector } from 'reselect';

const orderPlace = (state) => state.placeOrder;

export const selectListProductArr = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.listProductArr,
);

export const selectListProductResp = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.productListResp,
);

export const selectProductAllResp = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.productAllResponse,
);

export const selectProductListAll = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.productListAllArr,
);

export const selectPlaceOrderCartArr = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.placeOrderCartArr,
);

export const selectUserLoginCheckResp = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.userLoginCheckResponse,
);

export const selectUserLoginCheckArr = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.userLoginCheckArr,
);

export const selectOtpVerifyResp = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.otpVerifyResponse,
);

export const selectOtpVerifykArr = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.otpVerifyArr,
);

export const selectAddAddressResp = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.addAddressResponse,
);

export const selectPlaceOrderResp = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.placeOrderResponse,
);

export const selectCategoryProductTotalAmount = createSelector([orderPlace], (placeOrder) =>
	placeOrder.placeOrderCartArr.reduce(
		(accumalatedQuantity, cartItem) =>
			accumalatedQuantity + cartItem.Quantity * cartItem.SellingPrice,
		0,
	),
);

export const selectFilterProductCart = createSelector([orderPlace], (placeOrder) => {
	const { placeOrderCartArr } = placeOrder;
	const getProductCart =
		placeOrderCartArr.length > 0 &&
		placeOrderCartArr.map((item) =>
			item.ProductType === 'Combo' ||
			item.ProductType === 'FestivalCombo' ||
			item.ProductType === 'ComboFlower' ||
			item.ProductType === 'Minicher'
				? { ...item, ComboProduct: 'NULL' }
				: { ...item, OptionsList: 'NULL' },
		);
	return getProductCart;
});

export const selectOrderLocationAddress = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.orderLocationAddress,
);

export const selectPincodeFetchBackEndResp = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.fetchBackEndResponse,
);

export const selectPincodeFetchBackEndArr = createSelector(
	[orderPlace],
	(placeOrder) => placeOrder.fetchBackEndArr,
);
