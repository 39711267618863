const productComboTypes = {
	GET_PRODUCT_COMBO_LIST_START: 'GET_PRODUCT_COMBO_LIST_START',
	GET_PRODUCT_COMBO_LIST_SUCCESS: 'GET_PRODUCT_COMBO_LIST_SUCCESS',
	GET_PRODUCT_COMBO_LIST_FAILURE: 'GET_PRODUCT_COMBO_LIST_FAILURE',
	GET_PRODUCT_COMBO_LIST_RESET: 'GET_PRODUCT_COMBO_LIST_RESET',
	GET_PRODUCT_COMBO_LIST_CHANGED: 'GET_PRODUCT_COMBO_LIST_CHANGED',

	INSERT_PRODUCT_COMBO_START: 'INSERT_PRODUCT_COMBO_START',
	INSERT_PRODUCT_COMBO_SUCCESS: 'INSERT_PRODUCT_COMBO_SUCCESS',
	INSERT_PRODUCT_COMBO_FAILURE: 'INSERT_PRODUCT_COMBO_FAILURE',
	INSERT_PRODUCT_COMBO_RESET: 'INSERT_PRODUCT_COMBO_RESET',
	INSERT_PRODUCT_COMBO_CHANGED: 'INSERT_PRODUCT_COMBO_CHANGED',

	UPDATE_PRODUCT_COMBO_START: 'UPDATE_PRODUCT_COMBO_START',
	UPDATE_PRODUCT_COMBO_SUCCESS: 'UPDATE_PRODUCT_COMBO_SUCCESS',
	UPDATE_PRODUCT_COMBO_FAILURE: 'UPDATE_PRODUCT_COMBO_FAILURE',
	UPDATE_PRODUCT_COMBO_RESET: 'UPDATE_PRODUCT_COMBO_RESET',
	UPDATE_PRODUCT_COMBO_CHANGED: 'UPDATE_PRODUCT_COMBO_CHANGED',

	OFF_PRODUCT_COMBO_START: 'OFF_PRODUCT_COMBO_START',
	OFF_PRODUCT_COMBO_SUCCESS: 'OFF_PRODUCT_COMBO_SUCCESS',
	OFF_PRODUCT_COMBO_FAILURE: 'OFF_PRODUCT_COMBO_FAILURE',
	OFF_PRODUCT_COMBO_RESET: 'OFF_PRODUCT_COMBO_RESET',
	OFF_PRODUCT_COMBO_CHANGED: 'OFF_PRODUCT_COMBO_CHANGED',

	ON_PRODUCT_COMBO_START: 'ON_PRODUCT_COMBO_START',
	ON_PRODUCT_COMBO_SUCCESS: 'ON_PRODUCT_COMBO_SUCCESS',
	ON_PRODUCT_COMBO_FAILURE: 'ON_PRODUCT_COMBO_FAILURE',
	ON_PRODUCT_COMBO_RESET: 'ON_PRODUCT_COMBO_RESET',
	ON_PRODUCT_COMBO_CHANGED: 'ON_PRODUCT_COMBO_CHANGED',

	ADD_LIST_PRODUCT_START: 'ADD_LIST_PRODUCT_START',
	ADD_LIST_PRODUCT_SUCCESS: 'ADD_LIST_PRODUCT_SUCCESS',
	ADD_LIST_PRODUCT_FAILURE: 'ADD_LIST_PRODUCT_FAILURE',
	ADD_LIST_PRODUCT_RESET: 'ADD_LIST_PRODUCT_RESET',
	ADD_LIST_PRODUCT_CHANGED: 'ADD_LIST_PRODUCT_CHANGED',

	CREATE_ETHIC_PRODUCT_START: 'CREATE_ETHIC_PRODUCT_START',
	CREATE_ETHIC_PRODUCT_SUCCESS: 'CREATE_ETHIC_PRODUCT_SUCCESS',
	CREATE_ETHIC_PRODUCT_FAILURE: 'CREATE_ETHIC_PRODUCT_FAILURE',
	CREATE_ETHIC_PRODUCT_RESET: 'CREATE_ETHIC_PRODUCT_RESET',
	CREATE_ETHIC_PRODUCT_CHANGED: 'CREATE_ETHIC_PRODUCT_CHANGED',

	GET_ETHIC_PRODUCT_LIST_START: 'GET_ETHIC_PRODUCT_LIST_START',
	GET_ETHIC_PRODUCT_LIST_SUCCESS: 'GET_ETHIC_PRODUCT_LIST_SUCCESS',
	GET_ETHIC_PRODUCT_LIST_FAILURE: 'GET_ETHIC_PRODUCT_LIST_FAILURE',
	GET_ETHIC_PRODUCT_LIST_RESET: 'GET_ETHIC_PRODUCT_LIST_RESET',
	GET_ETHIC_PRODUCT_LIST_CHANGED: 'GET_ETHIC_PRODUCT_LIST_CHANGED',

	DELETE_ETHIC_PRODUCT_START: 'DELETE_ETHIC_PRODUCT_START',
	DELETE_ETHIC_PRODUCT_SUCCESS: 'DELETE_ETHIC_PRODUCT_SUCCESS',
	DELETE_ETHIC_PRODUCT_FAILURE: 'DELETE_ETHIC_PRODUCT_FAILURE',
	DELETE_ETHIC_PRODUCT_RESET: 'DELETE_ETHIC_PRODUCT_RESET',
	DELETE_ETHIC_PRODUCT_CHANGED: 'DELETE_ETHIC_PRODUCT_CHANGED',
};
export default productComboTypes;
