/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import hubTypes from './addHub.types';

/* HUB LIST START */
export const hubListStart = (hubDetails) => ({
	type: hubTypes.HUB_LIST_START,
	payload: hubDetails,
});

export const hubListSuccess = (hubSuccess) => ({
	type: hubTypes.HUB_LIST_SUCCESS,
	payload: hubSuccess,
});

export const hubListFailure = (error) => ({
	type: hubTypes.HUB_LIST_FAILURE,
	payload: error,
});

export const hubListChanged = () => ({
	type: hubTypes.HUB_LIST_CHANGED,
});

export const hubListReset = () => ({
	type: hubTypes.HUB_LIST_RESET,
});
/* HUB LIST END */

/* HUB CREATE END */
export const createHubStart = (hubDetails) => ({
	type: hubTypes.HUB_CREATE_START,
	payload: hubDetails,
});

export const createHubSuccess = (hubSuccess) => ({
	type: hubTypes.HUB_CREATE_SUCCESS,
	payload: hubSuccess,
});

export const createHubFailure = (error) => ({
	type: hubTypes.HUB_CREATE_FAILURE,
	payload: error,
});

export const createHubChanged = () => ({
	type: hubTypes.HUB_CREATE_CHANGED,
});

export const createHubReset = () => ({
	type: hubTypes.HUB_CREATE_RESET,
});

/* HUB CREATE END */

/* HUB UPDATE END */
export const updateHubStart = (hubDetails) => ({
	type: hubTypes.HUB_UPDATE_START,
	payload: hubDetails,
});

export const updateHubSuccess = (hubSuccess) => ({
	type: hubTypes.HUB_UPDATE_SUCCESS,
	payload: hubSuccess,
});

export const updateHubFailure = (error) => ({
	type: hubTypes.HUB_UPDATE_FAILURE,
	payload: error,
});

export const updateHubChanged = () => ({
	type: hubTypes.HUB_UPDATE_CHANGED,
});

export const updateHubReset = () => ({
	type: hubTypes.HUB_UPDATE_RESET,
});

/* HUB UPDATE END */
