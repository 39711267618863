/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getCouponList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${API_URL}GetAllCouponsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createCoupon = (postListValue) => {
	const data = {
		CouponName: postListValue.CouponName,
		CouponDescription: postListValue.CouponDescription,
		CouponCategory: postListValue.CouponType,
		CouponPercentage: postListValue.CouponPercentage,
		MinimumOrderAmount: postListValue.MinimumOrderAmount,
		MaxiumDiscountAmount: postListValue.MaxiumDiscountAmount,
		ExpiryDateTime: postListValue.ExpiryDateTime,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${API_URL}CreateCouponDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateCoupon = (postListValue) => {
	const data = {
		CouponID: postListValue.CouponID,
		CouponName: postListValue.CouponName,
		CouponDescription: postListValue.CouponDescription,
		CouponCategory: postListValue.CouponType,
		CouponPercentage: postListValue.CouponPercentage,
		MinimumOrderAmount: postListValue.MinimumOrderAmount,
		MaxiumDiscountAmount: postListValue.MaxiumDiscountAmount,
		ExpiryDateTime: postListValue.ExpiryDateTime,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${API_URL}UpdateCouponDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const offCoupon = (postListValue) => {
	const data = {
		CouponID: postListValue.CouponID,
	};
	const url = `${API_URL}OffCouponDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const onCoupon = (postListValue) => {
	const data = {
		CouponID: postListValue.CouponID,
	};
	const url = `${API_URL}OnCouponDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
