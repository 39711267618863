/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import transcationListTypes from './transcationList.types';
import generalTypesAction from '../genera.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	transcationResponse: null,
	transcationStatus: null,
	viewTranscationResponse: null,
	viewTranscationStatus: null,
	flowersTranscationResponse: null,
	flowersTranscationStatus: null,
	flowersTranscationResponseDecode: null,
};

const transcationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case transcationListTypes.TRANSCATION_LIST_SUCCESS:
			return {
				...state,
				transcationResponse: action.payload,
				transcationStatus: 'Success',
			};

		case transcationListTypes.TRANSCATION_LIST_FAILURE:
			return {
				...state,
				transcationResponse: action.payload,
				transcationStatus: 'Failure',
			};

		case transcationListTypes.TRANSCATION_LIST_CHANGED:
			return { ...state, transcationResponse: null, transcationStatus: null };

		case transcationListTypes.VIEW_TRANSCATION_LIST_SUCCESS:
			return {
				...state,
				viewTranscationResponse: action.payload,
				viewTranscationStatus: 'Success',
			};

		case transcationListTypes.VIEW_TRANSCATION_LIST_FAILURE:
			return {
				...state,
				viewTranscationResponse: action.payload,
				viewTranscationStatus: 'Failure',
			};

		case transcationListTypes.VIEW_TRANSCATION_LIST_CHANGED:
			return { ...state, viewTranscationResponse: null, viewTranscationStatus: null };

		case transcationListTypes.FLOWERS_TRANSCATION_LIST_SUCCESS:
			return {
				...state,
				flowersTranscationResponse: action.payload,
				flowersTranscationResponseDecode: decodeApiResponse(
					action.payload,
					generalTypesAction.FLOWERSTRANSACTIONS,
				),
				flowersTranscationStatus: 'Success',
			};

		case transcationListTypes.FLOWERS_TRANSCATION_LIST_FAILURE:
			return {
				...state,
				flowersTranscationResponse: action.payload,
				flowersTranscationResponseDecode: null,
				flowersTranscationStatus: 'Failure',
			};

		case transcationListTypes.FLOWERS_TRANSCATION_LIST_CHANGED:
			return {
				...state,
				flowersTranscationResponse: null,
				flowersTranscationResponseDecode: null,
				flowersTranscationStatus: null,
			};

		default:
			return state;
	}
};

export default transcationReducer;
