/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import CustomerDashboardTypes from './customerDashboard.types';
import {
	getTotalCustomerDashboardList,
	getDateWiseCustomerDetailsList,
} from './customerDashboard.service';
import {
	totalCustomerDetailSuccess,
	totalCustomerDetailFailure,
	totalCustomerDetailResponseChanged,
	totalCustomerDateWiseDetailSuccess,
	totalCustomerDateWiseDetailFailure,
	totalCustomerDateWiseDetailResponseChanged,
} from './customerDashboard.action';
import { encodeJson } from '../enode-decode';

/** *********************  START TOTAL CUSTOMER DETAILS  ******************** */
export function* totalCustomerDetails({ payload }) {
	try {
		const key = 'GetTotalCustomerDashboardListKey';
		const jsonData = {
			Limit: payload.limit,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getTotalCustomerDashboardList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(totalCustomerDetailFailure(responseData.data));
		} else {
			yield put(totalCustomerDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(totalCustomerDetailFailure(error));
	}
}

export function* resetTotalCustomerResponse() {
	yield put(totalCustomerDetailResponseChanged());
}

export function* onTotalCustomerDetails() {
	yield takeLatest(CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_START, totalCustomerDetails);
}

export function* onTotalCustomerResponseReset() {
	yield takeLatest(
		CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_RESPONSE_RESET_START,
		resetTotalCustomerResponse,
	);
}
/** *********************  END TOTAL CUSTOMER DETAILS ******************** */

/** *********************  START TOTAL CUSTOMER DATE WISE DETAILS  ******************** */
export function* totalCustomerDateWiseDetails({ payload }) {
	try {
		const key = 'GetDateWiseCustomerDetailsListKey';
		const jsonData = {
			Date: payload.dateSet,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getDateWiseCustomerDetailsList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(totalCustomerDateWiseDetailFailure(responseData.data));
		} else {
			yield put(totalCustomerDateWiseDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(totalCustomerDateWiseDetailFailure(error));
	}
}

export function* resetTotalCustomerDateWiseResponse() {
	yield put(totalCustomerDateWiseDetailResponseChanged());
}

export function* onTotalCustomerDateWiseDetails() {
	yield takeLatest(
		CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_START,
		totalCustomerDateWiseDetails,
	);
}

export function* onTotalCustomerDateWiseResponseReset() {
	yield takeLatest(
		CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_RESPONSE_RESET_START,
		resetTotalCustomerDateWiseResponse,
	);
}
/** *********************  END TOTAL CUSTOMER DATE WISE DETAILS ******************** */

export function* customerDashboardSaga() {
	yield all([
		call(onTotalCustomerDetails),
		call(onTotalCustomerResponseReset),
		call(onTotalCustomerDateWiseDetails),
		call(onTotalCustomerDateWiseResponseReset),
	]);
}
