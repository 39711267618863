/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import hubTypes from './addHub.types';
import { getHubList, createHub, editHub } from './addHub.service';
import {
	hubListSuccess,
	hubListFailure,
	hubListChanged,
	createHubSuccess,
	createHubFailure,
	createHubChanged,
	updateHubSuccess,
	updateHubFailure,
	updateHubChanged,
} from './addHub.action';

/* HUB  LIST START */
export function* hubListDetailsStart({ payload: { listOfHub } }) {
	try {
		const responseData = yield call(getHubList, listOfHub);
		if (responseData.data.statusCode === '100') {
			yield put(hubListFailure(responseData.data));
		} else {
			yield put(hubListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(hubListFailure(error));
	}
}

export function* hubListDetailsChanged() {
	yield put(hubListChanged());
}

export function* hubListDetails() {
	yield takeLatest(hubTypes.HUB_LIST_START, hubListDetailsStart);
}

export function* hubListResponseReset() {
	yield takeLatest(hubTypes.HUB_LIST_RESET, hubListDetailsChanged);
}
/* HUB  LIST END */

/* HUB CREATE START */
export function* createNewHub({ payload: { values, cities, states, propertytype } }) {
	try {
		const jsonData = {
			HubName: values.hubName,
			PropritorName: values.propritorName,
			PhoneNumber: values.phoneNumber,
			HubPassword: values.hubPassword,
			Gender: values.gender,
			DOB: values.dob,
			HubAddress1: values.hubAddress1,
			HubAddress2: values.hubAddress2,
			HubPincode: values.hubPincode,
			HubCityID: cities,
			HubStateID: states,
			HubPhoto: values.hubPhoto,
			HubLatitude: values.hubLatitude,
			HubLogitude: values.hubLogitude,
			IsDeliveryPatner: values.isDeliveryPatner,
			TokenPaid: values.tokenPaid,
			TypeOfHouse: values.typeOfHouse,
			Propertytype: propertytype,
			PropertyPhoto1: values.propertyPhoto1,
			PropertyPhoto2: values.propertyPhoto2,
			AlternatePhoneNumber: values.alternatePhoneNumber,
			AadharCardNumber: values.aadharCardNumber,
			PanCard: values.panCard,
			RationCard: values.rationCard,
			IsMarried: values.isMarried,
			SpousesName: values.spousesName,
			CreatedBy: values.createdBy,
		};
		const responseData = yield call(createHub, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(createHubFailure(responseData.data));
		} else {
			yield put(createHubSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createHubFailure(error));
	}
}

export function* createHubResponseReset() {
	yield put(createHubChanged());
}

export function* hubCreateStart() {
	yield takeLatest(hubTypes.HUB_CREATE_START, createNewHub);
}

export function* hubCreateResponseReset() {
	yield takeLatest(hubTypes.HUB_CREATE_RESET, createHubResponseReset);
}
/* HUB CREATE END */

/* HUB UPDATE START */
export function* editHubList({ payload: { values, cities, states, propertytype } }) {
	try {
		const jsonData = {
			HubID: values.hubID,
			HubName: values.hubName,
			PropritorName: values.propritorName,
			PhoneNumber: values.phoneNumber,
			HubPassword: values.hubPassword,
			Gender: values.gender,
			DOB: values.dob,
			HubAddress1: values.hubAddress1,
			HubAddress2: values.hubAddress2,
			HubPincode: values.hubPincode,
			HubCityID: cities,
			HubStateID: states,
			HubPhoto: values.hubPhoto,
			HubLatitude: values.hubLatitude,
			HubLogitude: values.hubLogitude,
			IsDeliveryPatner: values.isDeliveryPatner,
			TokenPaid: values.tokenPaid,
			TypeOfHouse: values.typeOfHouse,
			Propertytype: propertytype,
			PropertyPhoto1: values.propertyPhoto1,
			PropertyPhoto2: values.propertyPhoto2,
			AlternatePhoneNumber: values.alternatePhoneNumber,
			AadharCardNumber: values.aadharCardNumber,
			PanCard: values.panCard,
			RationCard: values.rationCard,
			IsMarried: values.isMarried,
			SpousesName: values.spousesName,
			CreatedBy: values.createdBy,
		};
		const responseData = yield call(editHub, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(updateHubFailure(responseData.data));
		} else {
			yield put(updateHubSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateHubFailure(error));
	}
}

export function* editHubResponseReset() {
	yield put(updateHubChanged());
}

export function* hubUpdateStart() {
	yield takeLatest(hubTypes.HUB_UPDATE_START, editHubList);
}

export function* hubUpdateResponseReset() {
	yield takeLatest(hubTypes.HUB_UPDATE_RESET, editHubResponseReset);
}
/* HUB UPDATE END */

export function* hubListSaga() {
	yield all([
		call(hubListDetails),
		call(hubListResponseReset),
		call(hubCreateStart),
		call(hubCreateResponseReset),
		call(hubUpdateStart),
		call(hubUpdateResponseReset),
	]);
}
