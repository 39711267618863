/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { encodeJson } from '../enode-decode';

import pincodeTypes from './pincode.types';
import { getPincodeList, createPincode, updatePincode, deletePincode } from './pincode.service';
import {
	pincodeListSuccess,
	pincodeListFailure,
	pincodeListChanged,
	createPincodeSuccess,
	createPincodeFailure,
	createPincodeChanged,
	updatePincodeSuccess,
	updatePincodeFailure,
	updatePincodeChanged,
	deletePincodeSuccess,
	deletePincodeFailure,
	deletePincodeChanged,
} from './pincode.action';

/* PINCODE  LIST START */
export function* pincodeListDetailsStart({ payload }) {
	try {
		const key = 'PincodeListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			SearchValueSet: payload.searchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getPincodeList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(pincodeListFailure(responseData.data));
		} else {
			yield put(pincodeListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(pincodeListFailure(error));
	}
}

export function* pincodeListDetailsChanged() {
	yield put(pincodeListChanged());
}

export function* pincodeListDetails() {
	yield takeLatest(pincodeTypes.PINCODE_LIST_START, pincodeListDetailsStart);
}

export function* pincodeListResponseReset() {
	yield takeLatest(pincodeTypes.PINCODE_LIST_RESET, pincodeListDetailsChanged);
}
/* PINCODE  LIST END */

/* PINCODE CREATE START */
export function* createNewPincode({ payload }) {
	try {
		const key = 'CreatePincodeKey';
		const jsonData = {
			Pincode: payload.Pincode,
			MinimumOrderAmount: payload.ProdOrAm,
			DeliveryCharge: payload.ProdDelCha,
			MinimumFloOrderAmount: payload.FloOrAm,
			DeliveryChargeFlo: payload.FloDelCha,
			StaffID: payload.StaffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createPincode, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createPincodeFailure(responseData.data));
		} else {
			yield put(createPincodeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createPincodeFailure(error));
	}
}

export function* createPincodeResponseReset() {
	yield put(createPincodeChanged());
}

export function* pincodeCreateStart() {
	yield takeLatest(pincodeTypes.PINCODE_CREATE_REPSONSE_START, createNewPincode);
}

export function* pincodeCreateResponseReset() {
	yield takeLatest(pincodeTypes.PINCODE_CREATE_REPSONSE_RESET, createPincodeResponseReset);
}
/* PINCODE CREATE END */

/* PINCODE UPDATE START */
export function* updatePincodeDetails({ payload }) {
	try {
		const key = 'UpdatePincodeKey';
		const jsonData = {
			PincodeID: payload.PincodeID,
			Pincode: payload.Pincode,
			MinimumOrderAmount: payload.ProdOrAm,
			DeliveryCharge: payload.ProdDelCha,
			MinimumFloOrderAmount: payload.FloOrAm,
			DeliveryChargeFlo: payload.FloDelCha,
			StaffID: payload.StaffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updatePincode, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updatePincodeFailure(responseData.data));
		} else {
			yield put(updatePincodeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updatePincodeFailure(error));
	}
}

export function* updatePincodeResponseReset() {
	yield put(updatePincodeChanged());
}

export function* pincodeUpdateStart() {
	yield takeLatest(pincodeTypes.PINCODE_UPDATE_REPSONSE_START, updatePincodeDetails);
}

export function* pincodeUpdateResponseReset() {
	yield takeLatest(pincodeTypes.PINCODE_UPDATE_REPSONSE_RESET, updatePincodeResponseReset);
}
/* PINCODE UPDATE END */

/* PINCODE DELETE START */
export function* deletePincodeDetails({ payload }) {
	try {
		const key = 'RemovePincodeKey';
		const jsonData = {
			IsActive: payload.IsActive,
			PincodeID: payload.PincodeID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deletePincode, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deletePincodeFailure(responseData.data));
		} else {
			yield put(deletePincodeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deletePincodeFailure(error));
	}
}

export function* deletePincodeResponseReset() {
	yield put(deletePincodeChanged());
}

export function* pincodeDeleteStart() {
	yield takeLatest(pincodeTypes.PINCODE_DELETE_REPSONSE_START, deletePincodeDetails);
}

export function* pincodeDeleteResponseReset() {
	yield takeLatest(pincodeTypes.PINCODE_DELETE_REPSONSE_RESET, deletePincodeResponseReset);
}
/* PINCODE DELETE END */

export function* pincodeSaga() {
	yield all([
		call(pincodeListDetails),
		call(pincodeListResponseReset),
		call(pincodeCreateStart),
		call(pincodeCreateResponseReset),
		call(pincodeUpdateStart),
		call(pincodeUpdateResponseReset),
		call(pincodeDeleteStart),
		call(pincodeDeleteResponseReset),
	]);
}
