/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import StaffReportTypes from './staff-report.types';
import { getStaffReportDetails } from './staff-report.service';
import {
	fetchStaffReportDetailSuccess,
	fetchStaffReportDetailFailure,
	fetchStaffReportDetailResponseChanged,
} from './staff-report.action';
import { encodeJson } from '../enode-decode';

/** *********************  START FETCH STAFF REPORT DETAILS  ******************** */
export function* fetchStaffReportDetails({ payload }) {
	try {
		const key = 'FetchStaffReportDetailsKey';
		const jsonData = {
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Limit: payload.limit,
			SearchValueSet: payload.searchValueSet,
			OrderFilter: payload.orderFilter,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getStaffReportDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchStaffReportDetailFailure(responseData.data));
		} else {
			yield put(fetchStaffReportDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchStaffReportDetailFailure(error));
	}
}

export function* resetFetchStaffReportResponse() {
	yield put(fetchStaffReportDetailResponseChanged());
}

export function* onFetchStaffReportDetails() {
	yield takeLatest(StaffReportTypes.STAFF_REPORT_DETAILS_START, fetchStaffReportDetails);
}

export function* onFetchStaffReportResponseReset() {
	yield takeLatest(
		StaffReportTypes.STAFF_REPORT_DETAILS_RESPONSE_RESET_START,
		resetFetchStaffReportResponse,
	);
}
/** *********************  END FETCH STAFF REPORT DETAILS ******************** */

export function* staffReportSaga() {
	yield all([call(onFetchStaffReportDetails), call(onFetchStaffReportResponseReset)]);
}
