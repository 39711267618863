/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import orderListTypes from './orderList.types';
import { getAllOrderList, getAllViewOrderList } from './orderList.service';
import {
	orderListSuccess,
	orderListFailure,
	orderListChanged,
	viewOrderListSuccess,
	viewOrderListFailure,
	viewOrderListChanged,
} from './orderList.action';
import { encodeJson } from '../enode-decode';

/* ORDER  LIST START */
export function* orderListDetailsStart({
	payload: { limit, fromDate, toDate, activeTab, searchValueSet, selectedEmptyDate, orderMode },
}) {
	try {
		const key = 'OrderListKey';
		const jsonData = {
			Limit: limit,
			FromDate: fromDate,
			ToDate: toDate,
			OrderMode: orderMode,
			OrderStatus: activeTab,
			SearchValueSet: searchValueSet,
			SelectedEmptyDate: selectedEmptyDate,
			Secratekey: key,
		};
		const OrderListData = encodeJson(jsonData, key);
		const responseData = yield call(getAllOrderList, OrderListData);
		if (responseData.data.statusCode === '100') {
			yield put(orderListFailure(responseData.data));
		} else {
			yield put(orderListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(orderListFailure(error));
	}
}

export function* orderListDetailsChanged() {
	yield put(orderListChanged());
}

export function* orderListDetails() {
	yield takeLatest(orderListTypes.ORDER_LIST_START, orderListDetailsStart);
}

export function* orderListResponseReset() {
	yield takeLatest(orderListTypes.ORDER_LIST_RESET, orderListDetailsChanged);
}
/* ORDER  LIST END */

/* ORDER VIEW LIST START */
export function* viewOrderListDetailsStart({
	payload: {
		userID,
		orderFromCustomerID,
		productOrderPlaceID,
		orderMode,
		fromDate,
		toDate,
		activeTab,
		searchValueSet,
		selectedEmptyDate,
	},
}) {
	try {
		const key = 'OrderViewListKey';
		const jsonData = {
			UserID: userID,
			OrderFromCustomerID: orderFromCustomerID,
			ProductOrderPlaceID: productOrderPlaceID,
			OrderMode: orderMode,
			FromDate: fromDate,
			ToDate: toDate,
			OrderStatus: activeTab,
			SearchValueSet: searchValueSet,
			SelectedEmptyDate: selectedEmptyDate,
			Secratekey: key,
		};
		const OrderViewListData = encodeJson(jsonData, key);
		const responseData = yield call(getAllViewOrderList, OrderViewListData);
		if (responseData.data.statusCode === '100') {
			yield put(viewOrderListFailure(responseData.data));
		} else {
			yield put(viewOrderListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewOrderListFailure(error));
	}
}

export function* viewOrderListDetailsChanged() {
	yield put(viewOrderListChanged());
}

export function* viewOrderListDetails() {
	yield takeLatest(orderListTypes.VIEW_ORDER_LIST_START, viewOrderListDetailsStart);
}

export function* viewOrderListDetailsResponseReset() {
	yield takeLatest(orderListTypes.VIEW_ORDER_LIST_RESET, viewOrderListDetailsChanged);
}
/* ORDER VIEW LIST END */

export function* orderListSaga() {
	yield all([
		call(orderListDetails),
		call(orderListResponseReset),
		call(viewOrderListDetails),
		call(viewOrderListDetailsResponseReset),
	]);
}
