const todayDeliveredTypes = {
	GET_TODAY_DELIVERED_LIST_START: 'GET_TODAY_DELIVERED_LIST_START',
	GET_TODAY_DELIVERED_LIST_SUCCESS: 'GET_TODAY_DELIVERED_LIST_SUCCESS',
	GET_TODAY_DELIVERED_LIST_FAILURE: 'GET_TODAY_DELIVERED_LIST_FAILURE',
	GET_TODAY_DELIVERED_LIST_RESET: 'GET_TODAY_DELIVERED_LIST_RESET',
	GET_TODAY_DELIVERED_LIST_CHANGED: 'GET_TODAY_DELIVERED_LIST_CHANGED',

	VIEW_TODAY_ORDERS_LIST_START: 'VIEW_TODAY_ORDERS_LIST_START',
	VIEW_TODAY_ORDERS_LIST_SUCCESS: 'VIEW_TODAY_ORDERS_LIST_SUCCESS',
	VIEW_TODAY_ORDERS_LIST_FAILURE: 'VIEW_TODAY_ORDERS_LIST_FAILURE',
	VIEW_TODAY_ORDERS_LIST_RESET: 'VIEW_TODAY_ORDERS_LIST_RESET',
	VIEW_TODAY_ORDERS_LIST_CHANGED: 'VIEW_TODAY_ORDERS_LIST_CHANGED',
};
export default todayDeliveredTypes;
