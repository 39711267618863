// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable consistent-return */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable default-param-last */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable default-case */
import BranchTypes from './branch.type';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	getBranchListResponse: null,
	getBranchListArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case BranchTypes.GET_BRANCH_LIST_SUCCESS:
			return {
				...state,
				getBranchListResponse: action.payload,
				getBranchListArr: docodeResponse(action.payload, 'Get_Branch_Details'),
			};

		case BranchTypes.GET_BRANCH_LIST_FAILURE:
			return {
				...state,
				getBranchListResponse: action.payload,
			};

		case BranchTypes.GET_BRANCH_LIST_CHANGED:
			return { ...state, getBranchListResponse: null };

		default:
			return state;
	}
};
