/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { HUB_API_URL } from '../const';

export const getPurchaseOrderList = (PurchaseOrderData) => {
	const data = {
		GetPurchaseOrderListToken: PurchaseOrderData,
	};
	const url = `${HUB_API_URL}getAllHubPurchaseOrder`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updatePurchaseOrderStatus = (PurchaseOrderData) => {
	const data = {
		UpdatePoOrderStatusToken: PurchaseOrderData,
	};
	const url = `${HUB_API_URL}UpdatePurchaseOrderStatus`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getViewPoProductsList = (PurchaseOrderData) => {
	const data = {
		GetViewPoProductsListToken: PurchaseOrderData,
	};
	const url = `${HUB_API_URL}GetViewPoProductsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
