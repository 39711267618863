const orderAssignTypes = {
	GET_PINCODE_NEW_LIST_START: 'get_pincode_new_list_start',
	GET_PINCODE_NEW_LIST_SUCCESS: 'get_pincode_new_list_success',
	GET_PINCODE_NEW_LIST_FAILURE: 'get_pincode_new_list_failure',
	GET_PINCODE_NEW_LIST_RESPONSE_RESET_START: 'get_pincode_new_list_response_reset_start',
	GET_PINCODE_NEW_LIST_RESPONSE_CHANGED: 'get_pincode_new_list_response_reset_changed',

	PINCODE_WISE_ORDER_LIST_START: 'pincode_wise_order_list_start',
	PINCODE_WISE_ORDER_LIST_SUCCESS: 'pincode_wise_order_list_success',
	PINCODE_WISE_ORDER_LIST_FAILURE: 'pincode_wise_order_list_failure',
	PINCODE_WISE_ORDER_LIST_RESPONSE_RESET_START: 'pincode_wise_order_list_response_reset_start',
	PINCODE_WISE_ORDER_LIST_RESPONSE_CHANGED: 'pincode_wise_order_list_response_reset_changed',

	ADD_ORDER_ITEM_ORDER_ASSIGN: 'add_order_item_order_assign',
};
export default orderAssignTypes;
