/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import PackingReportActionType from './packingReport.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	wholePackingHistoryResponse: null,
	wholePackingHistoryRespArr: [],
	deliveryPackingHistoryResponse: null,
	deliveryPackingHistoryRespArr: [],
};

const packingReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case PackingReportActionType.GET_WHOLE_PACKING_HISTORY_SUCCESS:
			return {
				...state,
				wholePackingHistoryResponse: action.payload,
				wholePackingHistoryRespArr: docodeResponse(
					action.payload,
					'GetWholePackingHistoryKey',
				),
			};

		case PackingReportActionType.GET_WHOLE_PACKING_HISTORY_FAILURE:
			return {
				...state,
				wholePackingHistoryResponse: action.payload,
				wholePackingHistoryRespArr: [],
			};

		case PackingReportActionType.GET_WHOLE_PACKING_HISTORY_RESPONSE_CHANGED:
			return { ...state, wholePackingHistoryResponse: null };

		case PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_SUCCESS:
			return {
				...state,
				deliveryPackingHistoryResponse: action.payload,
				deliveryPackingHistoryRespArr: docodeResponse(
					action.payload,
					'GetDeliveryPackingHistoryKey',
				),
			};

		case PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_FAILURE:
			return {
				...state,
				deliveryPackingHistoryResponse: action.payload,
				deliveryPackingHistoryRespArr: [],
			};

		case PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_RESPONSE_CHANGED:
			return { ...state, deliveryPackingHistoryResponse: null };

		default:
			return state;
	}
};

export default packingReportReducer;
