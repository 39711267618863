/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import collectionsTypes from './collections.types';
import generalTypesAction from '../genera.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	collectionsListResponse: null,
	collectionsListResponseStatus: null,
	collectionsListResponseDecode: null,
};

const collectionsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case collectionsTypes.GET_COLLECTIONS_LIST_SUCCESS:
			return {
				...state,
				collectionsListResponse: action.payload,
				collectionsListResponseDecode: decodeApiResponse(
					action.payload,
					generalTypesAction.COLLECTIONSLIST,
				),
				collectionsListResponseStatus: 'Success',
			};

		case collectionsTypes.GET_COLLECTIONS_LIST_FAILURE:
			return {
				...state,
				collectionsListResponse: action.payload,
				collectionsListResponseDecode: action.payload,
				collectionsListResponseStatus: 'Failure',
			};

		case collectionsTypes.GET_COLLECTIONS_LIST_CHANGED:
			return {
				...state,
				collectionsListResponse: null,
				collectionsListResponseDecode: null,
				collectionsListResponseStatus: null,
			};

		default:
			return state;
	}
};

export default collectionsReducer;
