/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import transcationListTypes from './transcationList.types';
import generalTypesAction from '../genera.types';
import {
	getTranscationFailureList,
	viewTranscationFailureList,
	flowersTranscationList,
} from './transcationList.service';
import {
	transcationSuccess,
	transcationFailure,
	transcationChanged,
	viewTranscationSuccess,
	viewTranscationFailure,
	viewTranscationChanged,
	flowersTranscationSuccess,
	flowersTranscationFailure,
	flowersTranscationChanged,
} from './transcationList.action';
import { encodeJson } from '../enode-decode';

/* PRODUCT  ORDER LIST START */
export function* listTranscation({ payload: { allTranscationList } }) {
	try {
		const responseData = yield call(getTranscationFailureList, allTranscationList);
		if (responseData.data.statusCode === '100') {
			yield put(transcationFailure(responseData.data));
		} else {
			yield put(transcationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(transcationFailure(error));
	}
}

export function* listTranscationReset() {
	yield put(transcationChanged());
}

export function* getTranscationListReset() {
	yield takeLatest(transcationListTypes.TRANSCATION_LIST_RESET, listTranscationReset);
}

export function* TranscationListGet() {
	yield takeLatest(transcationListTypes.TRANSCATION_LIST_START, listTranscation);
}
/* PRODUCT  ORDER LIST END */

/* VIEW PRODUCT ORDER LIST START */
export function* transcationListView({ payload: { transcationView } }) {
	try {
		const responseData = yield call(viewTranscationFailureList, transcationView);
		if (responseData.data.statusCode === '100') {
			yield put(viewTranscationFailure(responseData.data));
		} else {
			yield put(viewTranscationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewTranscationFailure(error));
	}
}

export function* transcationListViewReset() {
	yield put(viewTranscationChanged());
}

export function* viewTranscationDetails() {
	yield takeLatest(transcationListTypes.VIEW_TRANSCATION_LIST_START, transcationListView);
}

export function* resetTranscationDetails() {
	yield takeLatest(transcationListTypes.VIEW_TRANSCATION_LIST_RESET, transcationListViewReset);
}
/* VIEW PRODUCT ORDER LIST END */

/* FLOWERS TRANSCATION LIST START */
export function* transcationListFlowers({ payload: { flowersTranscationData } }) {
	try {
		const jsonData = {
			Limit: flowersTranscationData.limit,
			FromDate: flowersTranscationData.FromDate,
			ToDate: flowersTranscationData.ToDate,
			SearchValueSet: flowersTranscationData.searchValueSet,
			SelectedEmptyDate: flowersTranscationData.selectedEmptyDate,
		};
		const FlowersTransactionsData = encodeJson(
			jsonData,
			generalTypesAction.FLOWERSTRANSACTIONS,
		);
		const responseData = yield call(flowersTranscationList, FlowersTransactionsData);
		if (responseData.data.statusCode === '100') {
			yield put(flowersTranscationFailure(responseData.data));
		} else {
			yield put(flowersTranscationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(flowersTranscationFailure(error));
	}
}

export function* transcationListFlowersReset() {
	yield put(flowersTranscationChanged());
}

export function* flowersTranscationDetails() {
	yield takeLatest(transcationListTypes.FLOWERS_TRANSCATION_LIST_START, transcationListFlowers);
}

export function* resetFlowersTranscationDetails() {
	yield takeLatest(
		transcationListTypes.FLOWERS_TRANSCATION_LIST_RESET,
		transcationListFlowersReset,
	);
}
/* FLOWERS TRANSCATION LIST END */

export function* transcationSaga() {
	yield all([
		call(TranscationListGet),
		call(getTranscationListReset),
		call(viewTranscationDetails),
		call(resetTranscationDetails),
		call(flowersTranscationDetails),
		call(resetFlowersTranscationDetails),
	]);
}
