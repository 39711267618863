/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import purchaseListTypes from './purchaseList.types';
import { getPurchaseList } from './purchaseList.service';
import {
	purchaseListSuccess,
	purchaseListFailure,
	purchaseListChanged,
} from './purchaseList.action';
import { encodeJson } from '../enode-decode';

/* ORDERED USER LIST START */
export function* purchaseListDetailsStart({ payload }) {
	try {
		const key = 'PurchaseListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			OrderFilter: payload.orderFilter,
			SearchValueSet: payload.searchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getPurchaseList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(purchaseListFailure(responseData.data));
		} else {
			yield put(purchaseListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(purchaseListFailure(error));
	}
}

export function* purchaseListDetailsChanged() {
	yield put(purchaseListChanged());
}

export function* purchaseListDetails() {
	yield takeLatest(purchaseListTypes.PURCHASE_LIST_START, purchaseListDetailsStart);
}

export function* purchaseListResponseReset() {
	yield takeLatest(purchaseListTypes.PURCHASE_LIST_RESET, purchaseListDetailsChanged);
}
/* ORDERED USER LIST END */

export function* purchaseListSaga() {
	yield all([call(purchaseListDetails), call(purchaseListResponseReset)]);
}
