/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import couponAddListTypes from './couponAddList.types';
import {
	getCouponList,
	createCoupon,
	updateCoupon,
	offCoupon,
	onCoupon,
} from './couponAddList.service';
import {
	couponListSuccess,
	couponListFailure,
	couponListChanged,
	createCouponSuccess,
	createCouponFailure,
	createCouponChanged,
	updateCouponSuccess,
	updateCouponFailure,
	updateCouponChanged,
	offCouponSuccess,
	offCouponFailure,
	offCouponChanged,
	onCouponSuccess,
	onCouponFailure,
	onCouponChanged,
} from './couponAddList.action';

/* COUPON  LIST START */
export function* couponListDetailsStart({ payload: { listCouponGet } }) {
	try {
		const responseData = yield call(getCouponList, listCouponGet);
		if (responseData.data.statusCode === '100') {
			yield put(couponListFailure(responseData.data));
		} else {
			yield put(couponListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(couponListFailure(error));
	}
}

export function* couponListDetailsChanged() {
	yield put(couponListChanged());
}

export function* couponListDetails() {
	yield takeLatest(couponAddListTypes.COUPON_LIST_START, couponListDetailsStart);
}

export function* couponListResponseReset() {
	yield takeLatest(couponAddListTypes.COUPON_LIST_RESET, couponListDetailsChanged);
}
/* COUPON  LIST END */

/* COUPON CREATE START */
export function* createNewCoupon({ payload: { values, couponType, StaffID } }) {
	try {
		const jsonData = {
			CouponName: values.CouponName,
			CouponDescription: values.CouponDescription,
			CouponType: couponType,
			CouponPercentage: values.CouponPercentage,
			MinimumOrderAmount: values.MinimumOrderAmount,
			MaxiumDiscountAmount: values.MaxiumDiscountAmount,
			ExpiryDateTime: values.ExpiryDateTime,
			CreatedBy: StaffID,
		};
		const responseData = yield call(createCoupon, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(createCouponFailure(responseData.data));
		} else {
			yield put(createCouponSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createCouponFailure(error));
	}
}

export function* createCouponResponseReset() {
	yield put(createCouponChanged());
}

export function* couponCreateStart() {
	yield takeLatest(couponAddListTypes.COUPON_CREATE_REPSONSE_START, createNewCoupon);
}

export function* couponCreateResponseReset() {
	yield takeLatest(couponAddListTypes.COUPON_CREATE_REPSONSE_RESET, createCouponResponseReset);
}
/* COUPON CREATE END */

/* COUPON UPDATE START */
export function* updateCouponDetails({ payload: { values, couponType, StaffID } }) {
	try {
		const jsonData = {
			CouponID: values.CouponID,
			CouponName: values.CouponName,
			CouponDescription: values.CouponDescription,
			CouponType: couponType,
			CouponPercentage: values.CouponPercentage,
			MinimumOrderAmount: values.MinimumOrderAmount,
			MaxiumDiscountAmount: values.MaxiumDiscountAmount,
			ExpiryDateTime: values.ExpiryDateTime,
			CreatedBy: StaffID,
		};
		const responseData = yield call(updateCoupon, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(updateCouponFailure(responseData.data));
		} else {
			yield put(updateCouponSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateCouponFailure(error));
	}
}

export function* updateCouponResponseReset() {
	yield put(updateCouponChanged());
}

export function* couponUpdateStart() {
	yield takeLatest(couponAddListTypes.COUPON_UPDATE_REPSONSE_START, updateCouponDetails);
}

export function* couponUpdateResponseReset() {
	yield takeLatest(couponAddListTypes.COUPON_UPDATE_REPSONSE_RESET, updateCouponResponseReset);
}
/* COUPON UPDATE END */

/* COUPON OFF START */
export function* offCouponDetails({ payload: { couponID } }) {
	try {
		const jsonData = {
			CouponID: couponID,
		};
		const responseData = yield call(offCoupon, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(offCouponFailure(responseData.data));
		} else {
			yield put(offCouponSuccess(responseData.data));
		}
	} catch (error) {
		yield put(offCouponFailure(error));
	}
}

export function* offCouponResponseReset() {
	yield put(offCouponChanged());
}

export function* couponOffStart() {
	yield takeLatest(couponAddListTypes.COUPON_OFF_REPSONSE_START, offCouponDetails);
}

export function* couponOffResponseReset() {
	yield takeLatest(couponAddListTypes.COUPON_OFF_REPSONSE_RESET, offCouponResponseReset);
}
/* COUPON OFF END */

/* COUPON ON START */
export function* onCouponDetails({ payload: { couponID } }) {
	try {
		const jsonData = {
			CouponID: couponID,
		};
		const responseData = yield call(onCoupon, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(onCouponFailure(responseData.data));
		} else {
			yield put(onCouponSuccess(responseData.data));
		}
	} catch (error) {
		yield put(onCouponFailure(error));
	}
}

export function* onCouponResponseReset() {
	yield put(onCouponChanged());
}

export function* couponOnStart() {
	yield takeLatest(couponAddListTypes.COUPON_ON_REPSONSE_START, onCouponDetails);
}

export function* couponOnResponseReset() {
	yield takeLatest(couponAddListTypes.COUPON_ON_REPSONSE_RESET, onCouponResponseReset);
}
/* COUPON ON END */

export function* couponListSaga() {
	yield all([
		call(couponListDetails),
		call(couponListResponseReset),
		call(couponCreateStart),
		call(couponCreateResponseReset),
		call(couponUpdateStart),
		call(couponUpdateResponseReset),
		call(couponOffStart),
		call(couponOffResponseReset),
		call(couponOnStart),
		call(couponOnResponseReset),
	]);
}
