/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { INV_API_URL } from '../const';

export const getInventoryList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${INV_API_URL}GetAllInventoryList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createInventory = (postListValue) => {
	const data = {
		ProductOptions: postListValue.ProductOptions,
		VendorID: postListValue.VendorID,
		ProductID: postListValue.ProductID,
		PurchaseType: postListValue.PurchaseType,
		QuantityType: postListValue.QuantityType,
		PurchaseFrom: postListValue.PurchaseFrom,
		IsEthicPacked: postListValue.IsEthicPacked,
		Quantity: postListValue.Quantity,
		PurchasePrice: postListValue.PurchasePrice,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${INV_API_URL}CreateInventoryDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateInventory = (postListValue) => {
	const data = {
		InventoryID: postListValue.InventoryID,
		VendorID: postListValue.VendorID,
		ProductID: postListValue.ProductID,
		PurchaseType: postListValue.PurchaseType,
		QuantityType: postListValue.QuantityType,
		PurchaseFrom: postListValue.PurchaseFrom,
		IsEthicPacked: postListValue.IsEthicPacked,
		Quantity: postListValue.Quantity,
		PurchasePrice: postListValue.PurchasePrice,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${INV_API_URL}UpdateInventoryDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteInventory = (postListValue) => {
	const data = {
		InventoryID: postListValue.InventoryID,
	};
	const url = `${INV_API_URL}DeleteInventoryDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getInventoryVendorList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
	};
	const url = `${INV_API_URL}GetFetchVendorList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getInventoryProductList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		DropdownSearchValueSet: postListValue.dropdownSearchValueSet,
	};
	const url = `${INV_API_URL}GetFetchProductList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const insertInventoryOptions = (postListValue) => {
	const data = {
		InventoryOptionsInsert: postListValue,
	};
	const url = `${INV_API_URL}InsertInventoryOptions`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getInventoryOptionsList = (id, ProductID) => {
	const data = {
		VendorID: id,
		productID: ProductID,
	};
	const url = `${INV_API_URL}GetInventoryOptions`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteInventoryOptions = (postListValue) => {
	const data = {
		InventoryID: postListValue.InventoryID,
	};
	const url = `${INV_API_URL}DeleteInventoryOptions`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
