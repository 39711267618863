/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import pincodeTypes from './pincode.types';

/* PINCODE LIST START */
export const pincodeListStart = (pincodeDetails) => ({
	type: pincodeTypes.PINCODE_LIST_START,
	payload: pincodeDetails,
});

export const pincodeListSuccess = (pincodeSuccess) => ({
	type: pincodeTypes.PINCODE_LIST_SUCCESS,
	payload: pincodeSuccess,
});

export const pincodeListFailure = (error) => ({
	type: pincodeTypes.PINCODE_LIST_FAILURE,
	payload: error,
});

export const pincodeListChanged = () => ({
	type: pincodeTypes.PINCODE_LIST_CHANGED,
});

export const pincodeListReset = () => ({
	type: pincodeTypes.PINCODE_LIST_RESET,
});

export const createPincodeStart = (pincodeDetails) => ({
	type: pincodeTypes.PINCODE_CREATE_REPSONSE_START,
	payload: pincodeDetails,
});

export const createPincodeSuccess = (pincodeSuccess) => ({
	type: pincodeTypes.PINCODE_CREATE_REPSONSE_SUCCESS,
	payload: pincodeSuccess,
});

export const createPincodeFailure = (error) => ({
	type: pincodeTypes.PINCODE_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createPincodeChanged = () => ({
	type: pincodeTypes.PINCODE_CREATE_REPSONSE_CHANGED,
});

export const createPincodeReset = () => ({
	type: pincodeTypes.PINCODE_CREATE_REPSONSE_RESET,
});

export const updatePincodeStart = (pincodeDetails) => ({
	type: pincodeTypes.PINCODE_UPDATE_REPSONSE_START,
	payload: pincodeDetails,
});

export const updatePincodeSuccess = (pincodeSuccess) => ({
	type: pincodeTypes.PINCODE_UPDATE_REPSONSE_SUCCESS,
	payload: pincodeSuccess,
});

export const updatePincodeFailure = (error) => ({
	type: pincodeTypes.PINCODE_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updatePincodeChanged = () => ({
	type: pincodeTypes.PINCODE_UPDATE_REPSONSE_CHANGED,
});

export const updatePincodeReset = () => ({
	type: pincodeTypes.PINCODE_UPDATE_REPSONSE_RESET,
});

export const deletePincodeStart = (pincodeDetails) => ({
	type: pincodeTypes.PINCODE_DELETE_REPSONSE_START,
	payload: pincodeDetails,
});

export const deletePincodeSuccess = (pincodeSuccess) => ({
	type: pincodeTypes.PINCODE_DELETE_REPSONSE_SUCCESS,
	payload: pincodeSuccess,
});

export const deletePincodeFailure = (error) => ({
	type: pincodeTypes.PINCODE_DELETE_REPSONSE_FAILURE,
	payload: error,
});

export const deletePincodeChanged = () => ({
	type: pincodeTypes.PINCODE_DELETE_REPSONSE_CHANGED,
});

export const deletePincodeReset = () => ({
	type: pincodeTypes.PINCODE_DELETE_REPSONSE_RESET,
});
/* PINCODE LIST END */
