/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getEodNewOrderList = (fetchData) => {
	const data = {
		EodNewOrderListGetToken: fetchData,
	};
	const url = `${API_URL}GetEodNewOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const orderDetailsView = (fetchData) => {
	const data = {
		ViewOrderDetailsToken: fetchData,
	};
	const url = `${API_URL}OrderDetailsView`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const eodNewFetchList = (fetchData) => {
	const data = {
		NewEodFetchListToken: fetchData,
	};
	const url = `${API_URL}EodNewFetchList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addEndOfDayNew = (fetchData) => {
	const data = {
		NewEndOfDayAddToken: fetchData,
	};
	const url = `${API_URL}AddEndOfDayNew`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getEodStaffListNew = (orderDetails) => {
	const data = {
		EodStaffListNewGetToken: orderDetails,
	};
	const url = `${API_URL}GetEodStaffListNew`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const assginedToStaffOrdersNew = (assginedToStaff) => {
	const data = {
		AssginedToStaffOrdersNewData: assginedToStaff,
	};
	const url = `${API_URL}AssginedToStaffOrdersNew`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const assignedRevertOrderNew = (orderDetails) => {
	const data = {
		AssignedRevertOrderNewData: orderDetails,
	};
	const url = `${API_URL}AssignedRevertOrderNew`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const orderDateChangeUpdate = (orderDetails) => {
	const data = {
		OrderDateChangeUpdateData: orderDetails,
	};
	const url = `${API_URL}OrderDateChangeUpdate`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
