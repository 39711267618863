/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import SalesReportTypes from './sales-report.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	fetchSalesDetailsResponse: null,
	fetchSalesDetailsArr: [],
	fetchProductSalesDetailsResponse: null,
	fetchProductSalesDetailsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case SalesReportTypes.FETCH_SALES_DETAILS_SUCCESS:
			return {
				...state,
				fetchSalesDetailsResponse: action.payload,
				fetchSalesDetailsArr: decodeApiResponse(action.payload, 'FetchSalesDetailsKey'),
			};

		case SalesReportTypes.FETCH_SALES_DETAILS_FAILURE:
			return { ...state, fetchSalesDetailsResponse: action.payload };

		case SalesReportTypes.FETCH_SALES_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchSalesDetailsResponse: null };

		case SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_SUCCESS:
			return {
				...state,
				fetchProductSalesDetailsResponse: action.payload,
				fetchProductSalesDetailsArr: decodeApiResponse(
					action.payload,
					'FetchProductSalesDetailsKey',
				),
			};

		case SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_FAILURE:
			return { ...state, fetchProductSalesDetailsResponse: action.payload };

		case SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchProductSalesDetailsResponse: null };

		default:
			return state;
	}
};
