/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { INV_API_URL } from '../const';

export const getRepackedList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
	};
	const url = `${INV_API_URL}GetAllRePackedList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createRepacked = (postListValue) => {
	const data = {
		RepackedDate: postListValue.RepackedDate,
		ExpiredOn: postListValue.ExpiredOn,
	};
	const url = `${INV_API_URL}CreateRePacked`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateRepacked = (postListValue) => {
	const data = {
		RepackedID: postListValue.RepackedID,
		RepackedDate: postListValue.RepackedDate,
		ExpiredOn: postListValue.ExpiredOn,
	};
	const url = `${INV_API_URL}UpdateRePacked`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
