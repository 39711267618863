// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-debugger */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import placeOrderType from './placeOrder.types';
import {
	getListProducts,
	checkUserLogin,
	verifyOtp,
	addressAdd,
	getProductListAll,
	orderPlace,
	getAllBranchPincode,
} from './placeOrder.service';
import {
	listProductSuccess,
	listProductFailure,
	listProductChanged,
	allProductListSuccess,
	allProductListFailure,
	allProductListChanged,
	userLoginCheckSuccess,
	userLoginCheckFailure,
	userLoginCheckChanged,
	otpverifySuccess,
	otpverifyFailure,
	otpverifyChanged,
	addAddressSuccess,
	addAddressFailure,
	addAddressChanged,
	placeOrderSuccess,
	placeOrderFailure,
	placeOrderChanged,
	allBranchPincodeSuccess,
	allBranchPincodeFailure,
	allBranchPincodeChanged,
} from './placeOrder.action';
import { encodeJson } from '../enode-decode';

/* PRODUCT LIST START */
export function* productsList({ payload }) {
	try {
		const key = 'GetAllCategoryProducts';
		const jsonData = {
			BranchID: payload.branchID,
			Limit: payload.limit,
			ProductCategory: payload.dashboardProductCategory,
			SearchValueSet: payload.searchValueSet,
			Secratekey: key,
		};
		const serviceName =
			payload.dashboardProductCategory !== 'Combo'
				? 'getCategoryProducts'
				: 'GetAllProductCombosWeb';
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(getListProducts, refundData, serviceName);
		if (responseData.data.statusCode === '100') {
			yield put(listProductFailure(responseData.data));
		} else {
			yield put(listProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(listProductFailure(error));
	}
}

export function* listProductsReset() {
	yield put(listProductChanged());
}

export function* getListProductReset() {
	yield takeLatest(placeOrderType.LIST_PRODUCT_RESET, listProductsReset);
}

export function* getListProduct() {
	yield takeLatest(placeOrderType.LIST_PRODUCT_START, productsList);
}
/* PRODUCT LIST END */

/* ALL PRODUCT LIST START */
export function* listProductAll({ payload }) {
	try {
		const key = 'GetDahboardProductsKey';
		const jsonData = {
			BranchID: payload.branchID,
			Limit: payload.limit,
			Secratekey: key,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(getProductListAll, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(allProductListFailure(responseData.data));
		} else {
			yield put(allProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(allProductListFailure(error));
	}
}

export function* listProductAllReset() {
	yield put(allProductListChanged());
}

export function* getProductListAllReset() {
	yield takeLatest(placeOrderType.ALL_PRODUCT_LIST_RESET, listProductAllReset);
}

export function* getProductListsAll() {
	yield takeLatest(placeOrderType.ALL_PRODUCT_LIST_START, listProductAll);
}
/* ALL PRODUCT LIST END */

export function* userLoginCheck({ payload }) {
	try {
		const key = 'UserLogin';
		const jsonData = {
			PhoneNumber: payload.phoneNumber,
			PhoneType: 'web',
			NotificationToken: '',
			AppVersion: 'web',
			Secratekey: key,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(checkUserLogin, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(userLoginCheckFailure(responseData.data));
		} else {
			yield put(userLoginCheckSuccess(responseData.data));
		}
	} catch (error) {
		yield put(userLoginCheckFailure(error));
	}
}

export function* loginUserCheckReset() {
	yield put(userLoginCheckChanged());
}

export function* getuserLoginCheckReset() {
	yield takeLatest(placeOrderType.USER_LOGIN_CHECK_RESET, loginUserCheckReset);
}

export function* getuserLoginCheck() {
	yield takeLatest(placeOrderType.USER_LOGIN_CHECK_START, userLoginCheck);
}
/* PRODUCT LIST END */

export function* otpVerify({ payload }) {
	try {
		const key = 'LoginOtpVerify';
		const jsonData = {
			UserID: payload.userID,
			PhoneNumber: payload.phoneNumber,
			VeriyOtp: payload.otpVerify,
			Secratekey: key,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(verifyOtp, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(otpverifyFailure(responseData.data));
		} else {
			yield put(otpverifySuccess(responseData.data));
		}
	} catch (error) {
		yield put(otpverifyFailure(error));
	}
}

export function* otpVerifyReset() {
	yield put(otpverifyChanged());
}

export function* getOtpVerifyReset() {
	yield takeLatest(placeOrderType.OTP_VERIFIY_RESET, otpVerifyReset);
}

export function* getOtpVerify() {
	yield takeLatest(placeOrderType.OTP_VERIFIY_START, otpVerify);
}
/* OTP VERIFY END */

export function* addAddressDetails({ payload }) {
	try {
		const key = 'Add_Address_Details';
		const jsonData = {
			UserID: payload.UserID,
			UserName: payload.userName,
			EmailID: payload.emailID,
			LocationName: payload.locationName,
			FlatNo: payload.flatNo,
			FlatName: payload.flatName,
			Address1: payload.address1,
			Address2: payload.address2,
			PinCode: payload.pinCode,
			Latitude: '',
			Longitude: '',
			LocationLandMark: payload.landMark,
			Secratekey: key,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(addressAdd, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(addAddressFailure(responseData.data));
		} else {
			yield put(addAddressSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addAddressFailure(error));
	}
}

export function* addAddressDetailsReset() {
	yield put(addAddressChanged());
}

export function* getAddAddressReset() {
	yield takeLatest(placeOrderType.ADD_ADDRESS_RESET, addAddressDetailsReset);
}

export function* getAddAddress() {
	yield takeLatest(placeOrderType.ADD_ADDRESS_START, addAddressDetails);
}
/* ADD ADDRESS END */

export function* placeOrderDetails({ payload }) {
	try {
		const key = 'ProductPlaceOrder';
		const jsonData = {
			UserID: payload.userID,
			BranchID: payload.branchID,
			OrderType: 'Cash',
			TotalPlaceOrderAmount: payload.orderPlaceAmount,
			NoOfOrders: payload.noOfOrders,
			CartList: payload.cartList,
			ProductDeliveryDate: payload.date,
			DeliveryAddress: payload.deliveryAddress,
			CouponID: 0,
			IsSwitchOn: 0,
			DeliveryCharge: 0,
			CouponDiscountAmount: 0,
			IsUsedPkPoints: 0,
			UsedPkPoints: 0,
			Secratekey: key,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(orderPlace, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(placeOrderFailure(responseData.data));
		} else {
			yield put(placeOrderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(placeOrderFailure(error));
	}
}

export function* placeOrderDetailsReset() {
	yield put(placeOrderChanged());
}

export function* getPlaceOrderReset() {
	yield takeLatest(placeOrderType.PLACE_ORDER_RESET, placeOrderDetailsReset);
}

export function* getPlaceOrder() {
	yield takeLatest(placeOrderType.PLACE_ORDER_START, placeOrderDetails);
}
/* PLACE ORDER END */

// GET ALL PINCODE LIST START
export function* getAllBranchPincodeListDetails({ payload }) {
	try {
		const key = 'AllBranchPincodeKey';
		const jsonData = {
			Pincode: payload.pincode,
		};
		const productData = encodeJson(jsonData, key);
		const responseData = yield call(getAllBranchPincode, productData);
		if (responseData.data.statusCode === '100') {
			yield put(allBranchPincodeFailure(responseData.data));
		} else {
			yield put(allBranchPincodeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(allBranchPincodeFailure(error));
	}
}

export function* resetAllBranchPincodeList() {
	yield put(allBranchPincodeChanged());
}

export function* onAllBranchPincodeListGetStart() {
	yield takeLatest(placeOrderType.GET_ALL_PINCODE_LIST_START, getAllBranchPincodeListDetails);
}
export function* onAllBranchPincodeListResponseRest() {
	yield takeLatest(placeOrderType.GET_ALL_PINCODE_LIST_RESET_START, resetAllBranchPincodeList);
}

// GET ALL PINCODE LIST END

export function* productSaga() {
	yield all([
		call(getListProduct),
		call(getListProductReset),
		call(getProductListsAll),
		call(getProductListAllReset),
		call(getuserLoginCheck),
		call(getuserLoginCheckReset),
		call(getOtpVerify),
		call(getOtpVerifyReset),
		call(getAddAddress),
		call(getAddAddressReset),
		call(getPlaceOrder),
		call(getPlaceOrderReset),
		call(onAllBranchPincodeListGetStart),
		call(onAllBranchPincodeListResponseRest),
	]);
}
