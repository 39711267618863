/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import todayDeliveredTypes from './todayDelivered.types';
import { getTodayDelivered, viewTodayOrderList } from './todayDelivered.service';
import {
	todayDeliveredListSuccess,
	todayDeliveredListFailure,
	todayDeliveredListChanged,
	viewTodayOrderDetailsSuccess,
	viewTodayOrderDetailsFailure,
	viewTodayOrderDetailsChanged,
} from './todayDelivered.action';
import { encodeJson } from '../enode-decode';

/* TODAY DELIVERED LIST START */
export function* todayDelivered({ payload }) {
	try {
		const key = 'GetProductOrderListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getTodayDelivered, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(todayDeliveredListFailure(responseData.data));
		} else {
			yield put(todayDeliveredListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(todayDeliveredListFailure(error));
	}
}

export function* todayDeliveredReset() {
	yield put(todayDeliveredListChanged());
}

export function* getTodayDeliveredListReset() {
	yield takeLatest(todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_RESET, todayDeliveredReset);
}

export function* getTodayDeliveredList() {
	yield takeLatest(todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_START, todayDelivered);
}
/* TODAY DELIVERED LIST END */

/* VIEW ORDER LIST START */
export function* viewTodayOrderDetailsList({ payload }) {
	try {
		const key = 'OrderDetailsViewKey';
		const jsonData = {
			OrderPlaceID: payload.OrderPlaceID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(viewTodayOrderList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(viewTodayOrderDetailsFailure(responseData.data));
		} else {
			yield put(viewTodayOrderDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewTodayOrderDetailsFailure(error));
	}
}

export function* viewTodayOrderDetailsReset() {
	yield put(viewTodayOrderDetailsChanged());
}

export function* viewTodayOrderDetails() {
	yield takeLatest(todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_START, viewTodayOrderDetailsList);
}

export function* resetviewTodayOrderDetails() {
	yield takeLatest(todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_RESET, viewTodayOrderDetailsReset);
}
/* VIEW ORDER LIST END */

export function* todayDeliveredSaga() {
	yield all([
		call(getTodayDeliveredList),
		call(getTodayDeliveredListReset),
		call(viewTodayOrderDetails),
		call(resetviewTodayOrderDetails),
	]);
}
