/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { encodeJson } from '../enode-decode';

import staffListTypes from './staffList.types';
import { getStaffList, createStaff, updateStaff, deleteStaff } from './staffList.service';
import {
	staffListSuccess,
	staffListFailure,
	staffListChanged,
	createStaffSuccess,
	createStaffFailure,
	createStaffChanged,
	updateStaffSuccess,
	updateStaffFailure,
	updateStaffChanged,
	deleteStaffSuccess,
	deleteStaffFailure,
	deleteStaffChanged,
} from './staffList.action';

/* STAFF  LIST START */
export function* staffListDetailsStart({ payload }) {
	try {
		const key = 'StaffListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			SearchValueSet: payload.searchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getStaffList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(staffListFailure(responseData.data));
		} else {
			yield put(staffListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(staffListFailure(error));
	}
}

export function* staffListDetailsChanged() {
	yield put(staffListChanged());
}

export function* staffListDetails() {
	yield takeLatest(staffListTypes.STAFF_LIST_START, staffListDetailsStart);
}

export function* staffListResponseReset() {
	yield takeLatest(staffListTypes.STAFF_LIST_RESET, staffListDetailsChanged);
}
/* STAFF  LIST END */

/* STAFF CREATE START */
export function* createNewStaff({
	payload: { values, staffType, designation, deliveryType, chooseBranchID },
}) {
	try {
		const key = 'CreateStaffKey';
		const jsonData = {
			StaffName: values.StaffName,
			StaffID: values.StaffID,
			StaffType: staffType,
			Designation: designation,
			DeliveryProduct: deliveryType,
			StaffPhoneNumber: values.StaffPhoneNumber,
			StaffPassword: values.StaffPassword,
			StaffAddress: values.StaffAddress,
			StaffPhoto: values.StaffPhoto,
			BranchID: chooseBranchID,
			ReferralCode: values.ReferralCode,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createStaff, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createStaffFailure(responseData.data));
		} else {
			yield put(createStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createStaffFailure(error));
	}
}

export function* createStaffResponseReset() {
	yield put(createStaffChanged());
}

export function* staffCreateStart() {
	yield takeLatest(staffListTypes.STAFF_CREATE_REPSONSE_START, createNewStaff);
}

export function* staffCreateResponseReset() {
	yield takeLatest(staffListTypes.STAFF_CREATE_REPSONSE_RESET, createStaffResponseReset);
}
/* STAFF CREATE END */

/* STAFF UPDATE START */
export function* updateStaffDetails({
	payload: { values, staffType, designation, deliveryType, chooseBranchID },
}) {
	try {
		const key = 'UpdateStaffKey';
		const jsonData = {
			StaffName: values.StaffName,
			StaffID: values.StaffID,
			StaffType: staffType,
			Designation: designation,
			DeliveryProduct: deliveryType,
			StaffPhoneNumber: values.StaffPhoneNumber,
			StaffPassword: values.StaffPassword,
			StaffAddress: values.StaffAddress,
			StaffPhoto: values.StaffPhoto,
			BranchID: chooseBranchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateStaff, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateStaffFailure(responseData.data));
		} else {
			yield put(updateStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateStaffFailure(error));
	}
}

export function* updateStaffResponseReset() {
	yield put(updateStaffChanged());
}

export function* staffUpdateStart() {
	yield takeLatest(staffListTypes.STAFF_UPDATE_REPSONSE_START, updateStaffDetails);
}

export function* staffUpdateResponseReset() {
	yield takeLatest(staffListTypes.STAFF_UPDATE_REPSONSE_RESET, updateStaffResponseReset);
}
/* STAFF UPDATE END */

/* STAFF DELETE START */
export function* deleteStaffDetails({ payload }) {
	try {
		const key = 'RemoveStaffKey';
		const jsonData = {
			StaffID: payload.delStaffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteStaff, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteStaffFailure(responseData.data));
		} else {
			yield put(deleteStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteStaffFailure(error));
	}
}

export function* deleteStaffResponseReset() {
	yield put(deleteStaffChanged());
}

export function* staffDeleteStart() {
	yield takeLatest(staffListTypes.STAFF_DELETE_REPSONSE_START, deleteStaffDetails);
}

export function* staffDeleteResponseReset() {
	yield takeLatest(staffListTypes.STAFF_DELETE_REPSONSE_RESET, deleteStaffResponseReset);
}
/* STAFF DELETE END */

export function* staffListSaga() {
	yield all([
		call(staffListDetails),
		call(staffListResponseReset),
		call(staffCreateStart),
		call(staffCreateResponseReset),
		call(staffUpdateStart),
		call(staffUpdateResponseReset),
		call(staffDeleteStart),
		call(staffDeleteResponseReset),
	]);
}
