/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import productListTypes from './addProduct.types';
import {
	getListOfProduct,
	createProductList,
	editProduct,
	insertProductOptions,
	getProductOptionsList,
	deleteProductOptions,
	productOptionsEdit,
} from './addProduct.service';
import {
	productListSuccess,
	productListFailure,
	productListChanged,
	addProductSuccess,
	addProductFailure,
	addProductChanged,
	editProductSuccess,
	editProductFailure,
	editProductChanged,
	addProductOptionsSuccess,
	addProductOptionsFailure,
	addProductOptionsChanged,
	getProductOptionsSuccess,
	getProductOptionsFailure,
	getProductOptionsChanged,
	removeProductOptionsSuccess,
	removeProductOptionsFailure,
	removeProductOptionsChanged,
	updateProductOptionsSuccess,
	updateProductOptionsFailure,
	updateProductOptionsChanged,
} from './addProduct.action';
import { encodeJson } from '../enode-decode';

/* PRODUCT LIST START */
export function* listOfProduct({ payload }) {
	try {
		const key = 'ProductListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			CategoryProduct: payload.categoryProduct,
			SearchValueSet: payload.searchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getListOfProduct, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(productListFailure(responseData.data));
		} else {
			yield put(productListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productListFailure(error));
	}
}

export function* listOfProductReset() {
	yield put(productListChanged());
}

export function* getProductListReset() {
	yield takeLatest(productListTypes.GET_PRODUCT_LIST_RESET, listOfProductReset);
}

export function* getProductList() {
	yield takeLatest(productListTypes.GET_PRODUCT_LIST_START, listOfProduct);
}
/* PRODUCT LIST END */

/* ADD PRODUCT START */
export function* addProductList({ payload }) {
	try {
		const key = 'AddProductListKey';
		const jsonData = {
			ProductName: payload.productName,
			ProductImage: payload.productImage,
			ProductImage2: payload.productImage2,
			ProductImage3: payload.productImage3,
			ProductDesc: payload.productDesc,
			ProductCategory: payload.categoryProduct,
			ProductSubCategory: payload.productSubCategory,
			ProductOptions: payload.productOptions,
			StockLiveQunatity: payload.stockLiveQunatity,
			MrpPrice: payload.mrpPrice,
			SellingPrice: payload.sellingPrice,
			PurchasePrice: payload.purchasePrice,
			ParValue: payload.parValue,
			IsSeasonable: payload.isSeasonable,
			BranchID: payload.branchID,
			StaffID: payload.staffID,
			ExpiryDate: payload.expiryDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createProductList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addProductFailure(responseData.data));
		} else {
			yield put(addProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addProductFailure(error));
	}
}

export function* addProductListReset() {
	yield put(addProductChanged());
}

export function* productAddStart() {
	yield takeLatest(productListTypes.ADD_PRODUCT_START, addProductList);
}

export function* productAddReset() {
	yield takeLatest(productListTypes.ADD_PRODUCT_RESET, addProductListReset);
}
/* ADD PRODUCT END */

/* UPDATE PRODUCT START */
export function* updateProductList({ payload }) {
	try {
		const key = 'EditProductListKey';
		const jsonData = {
			ProductID: payload.productID,
			ProductPriceChartID: payload.productPriceChartID,
			ProductImage: payload.productImage,
			ProductImage2: payload.productImage2,
			ProductImage3: payload.productImage3,
			ProductName: payload.productName,
			ProductDesc: payload.productDesc,
			ProductCategory: payload.categoryProduct,
			ProductSubCategory: payload.productSubCategory,
			ProductOptions: payload.productOptions,
			StockLiveQunatity: payload.stockLiveQunatity,
			MrpPrice: payload.mrpPrice,
			SellingPrice: payload.sellingPrice,
			PurchasePrice: payload.purchasePrice,
			ParValue: payload.parValue,
			IsSeasonable: payload.isSeasonable,
			StaffID: payload.staffID,
			BranchID: payload.branchID,
			ExpiryDate: payload.expiryDate,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(editProduct, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(editProductFailure(responseData.data));
		} else {
			yield put(editProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(editProductFailure(error));
	}
}

export function* updateProductListReset() {
	yield put(editProductChanged());
}

export function* productUpdateStart() {
	yield takeLatest(productListTypes.EDIT_PRODUCT_START, updateProductList);
}

export function* productUpdateReset() {
	yield takeLatest(productListTypes.EDIT_PRODUCT_RESET, updateProductListReset);
}
/* UPDATE PRODUCT END */

/* GET PRODUCT OPTIONS LIST START */
export function* listOfProductOptions({ payload }) {
	try {
		const key = 'GetProductOptionsKey';
		const jsonData = {
			ProductID: payload.id,
			ClassifyID: payload.ProductClassifyID,
			CategoryProduct: payload.SelectProductCategory,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getProductOptionsList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getProductOptionsFailure(responseData.data));
		} else {
			yield put(getProductOptionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getProductOptionsFailure(error));
	}
}

export function* listOfProductOptionsReset() {
	yield put(getProductOptionsChanged());
}

export function* listProductOptionsReset() {
	yield takeLatest(productListTypes.GET_PRODUCT_OPTIONS_LIST_RESET, listOfProductOptionsReset);
}

export function* listProductOptionsStart() {
	yield takeLatest(productListTypes.GET_PRODUCT_OPTIONS_LIST_START, listOfProductOptions);
}
/* GET PRODUCT OPTIONS LIST END */

/* ADD PRODUCT OPTIONS START */
export function* addProductOptionsList({ payload }) {
	try {
		const key = 'AddProductOptionsKey';
		const jsonData = {
			ProductID: payload.ProductID,
			BranchID: payload.branchID,
			ProductClassifyID: payload.ProductClassifyID,
			ProductOptions: payload.ProductOptions,
			ProductMRP: payload.ProductMRP,
			ProductSellingPrice: payload.ProductSellingPrice,
			ProductParValue: payload.ProductParValue,
			ProductQunatity: payload.ProductQunatity,
			ProductPurchasePrice: payload.ProductPurchasePrice,
			ProductExpireDate: payload.ProductExpireDate,
			StaffID: payload.staffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(insertProductOptions, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addProductOptionsFailure(responseData.data));
		} else {
			yield put(addProductOptionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addProductOptionsFailure(error));
	}
}

export function* addProductOptionsListReset() {
	yield put(addProductOptionsChanged());
}

export function* productAddOptionsReset() {
	yield takeLatest(productListTypes.ADD_PRODUCT_OPTIONS_RESET, addProductOptionsListReset);
}

export function* productAddOptionsStart() {
	yield takeLatest(productListTypes.ADD_PRODUCT_OPTIONS_START, addProductOptionsList);
}
/* ADD PRODUCT OPTIONS END */

/* DELETE PRODUCT OPTIONS START */
export function* productOptionsRemove({ payload }) {
	try {
		const key = 'RemoveProductOptionsKey';
		const jsonData = {
			ProductPriceChartID: payload.ProductPriceChartID,
			StaffID: payload.staffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteProductOptions, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(removeProductOptionsFailure(responseData.data));
		} else {
			yield put(removeProductOptionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(removeProductOptionsFailure(error));
	}
}

export function* productOptionsRemoveResponse() {
	yield put(removeProductOptionsChanged());
}

export function* productOptionsRemoveReset() {
	yield takeLatest(productListTypes.REMOVE_PRODUCT_OPTIONS_RESET, productOptionsRemoveResponse);
}

export function* productOptionsRemoveStart() {
	yield takeLatest(productListTypes.REMOVE_PRODUCT_OPTIONS_START, productOptionsRemove);
}
/* DELETE PRODUCT OPTIONS END */

/* UPDATE PRODUCT OPTIONS START */
export function* productOptiionsUpdate({ payload }) {
	try {
		const key = 'EditProductOptionsKey';
		const jsonData = {
			ProductID: payload.productId,
			ProductPriceChartID: payload.priceChartID,
			ProductStockID: payload.stockID,
			ProductOptions: payload.options,
			MrpPrice: payload.mrpPrice,
			SellingPrice: payload.sellingPrice,
			ParValue: payload.parValue,
			StockLiveQunatity: payload.qty,
			PurchasePrice: payload.purchasePrice,
			StaffID: payload.staffID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(productOptionsEdit, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateProductOptionsFailure(responseData.data));
		} else {
			yield put(updateProductOptionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateProductOptionsFailure(error));
	}
}

export function* updateProductOptionsResponseEmt() {
	yield put(updateProductOptionsChanged());
}

export function* onUpdateProductOptionsClear() {
	yield takeLatest(
		productListTypes.UPDATE_PRODUCT_OPTIONS_RESET,
		updateProductOptionsResponseEmt,
	);
}

export function* onUpdateProductOptionsStart() {
	yield takeLatest(productListTypes.UPDATE_PRODUCT_OPTIONS_START, productOptiionsUpdate);
}
/* UPDATE PRODUCT OPTIONS END */

export function* productListSaga() {
	yield all([
		call(getProductList),
		call(getProductListReset),
		call(productAddStart),
		call(productAddReset),
		call(productUpdateStart),
		call(productUpdateReset),
		call(productAddOptionsStart),
		call(productAddOptionsReset),
		call(listProductOptionsStart),
		call(listProductOptionsReset),
		call(productOptionsRemoveStart),
		call(productOptionsRemoveReset),
		call(onUpdateProductOptionsStart),
		call(onUpdateProductOptionsClear),
	]);
}
