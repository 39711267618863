/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import minicherTypes from './addMinicher.types';

/* MINICHER LIST START */
export const minicherStart = (minicherDetails) => ({
	type: minicherTypes.GET_MINICHER_LIST_START,
	payload: minicherDetails,
});

export const minicherSuccess = (minicherListSuccess) => ({
	type: minicherTypes.GET_MINICHER_LIST_SUCCESS,
	payload: minicherListSuccess,
});

export const minicherFailure = (error) => ({
	type: minicherTypes.GET_MINICHER_LIST_FAILURE,
	payload: error,
});

export const minicherChanged = () => ({
	type: minicherTypes.GET_MINICHER_LIST_CHANGED,
});

export const minicherReset = () => ({
	type: minicherTypes.GET_MINICHER_LIST_RESET,
});
/* MINICHER LIST END */

/* INSERT MINICHER START */
export const insertMinicherStart = (minicherDetails) => ({
	type: minicherTypes.INSERT_MINICHER_START,
	payload: minicherDetails,
});

export const insertMinicherSuccess = (minicherListSuccess) => ({
	type: minicherTypes.INSERT_MINICHER_SUCCESS,
	payload: minicherListSuccess,
});

export const insertMinicherFailure = (error) => ({
	type: minicherTypes.INSERT_MINICHER_FAILURE,
	payload: error,
});

export const insertMinicherChanged = () => ({
	type: minicherTypes.INSERT_MINICHER_CHANGED,
});

export const insertMinicherReset = () => ({
	type: minicherTypes.INSERT_MINICHER_RESET,
});
/* INSERT MINICHER END */

/* UPDATE MINICHER START */
export const editMinicherStart = (minicherDetails) => ({
	type: minicherTypes.UPDATE_MINICHER_START,
	payload: minicherDetails,
});

export const editMinicherSuccess = (minicherListSuccess) => ({
	type: minicherTypes.UPDATE_MINICHER_SUCCESS,
	payload: minicherListSuccess,
});

export const editMinicherFailure = (error) => ({
	type: minicherTypes.UPDATE_MINICHER_FAILURE,
	payload: error,
});

export const editMinicherChanged = () => ({
	type: minicherTypes.UPDATE_MINICHER_CHANGED,
});

export const editMinicherReset = () => ({
	type: minicherTypes.UPDATE_MINICHER_RESET,
});
/* UPDATE MINICHER END */

/* OFF MINICHER START */
export const offMinicherStart = (minicherDetails) => ({
	type: minicherTypes.OFF_MINICHER_START,
	payload: minicherDetails,
});

export const offMinicherSuccess = (minicherListSuccess) => ({
	type: minicherTypes.OFF_MINICHER_SUCCESS,
	payload: minicherListSuccess,
});

export const offMinicherFailure = (error) => ({
	type: minicherTypes.OFF_MINICHER_FAILURE,
	payload: error,
});

export const offMinicherChanged = () => ({
	type: minicherTypes.OFF_MINICHER_CHANGED,
});

export const offMinicherReset = () => ({
	type: minicherTypes.OFF_MINICHER_RESET,
});
/* OFF MINICHER END */

/* ON MINICHER START */
export const onMinicherStart = (minicherDetails) => ({
	type: minicherTypes.ON_MINICHER_START,
	payload: minicherDetails,
});

export const onMinicherSuccess = (minicherListSuccess) => ({
	type: minicherTypes.ON_MINICHER_SUCCESS,
	payload: minicherListSuccess,
});

export const onMinicherFailure = (error) => ({
	type: minicherTypes.ON_MINICHER_FAILURE,
	payload: error,
});

export const onMinicherChanged = () => ({
	type: minicherTypes.ON_MINICHER_CHANGED,
});

export const onMinicherReset = () => ({
	type: minicherTypes.ON_MINICHER_RESET,
});
/* ON MINICHER END */

/* ADD LIST MINICHER PRODUCT START */
export const addListMinicherProductStart = (minicherDetails) => ({
	type: minicherTypes.ADD_LIST_MINICHER_PRODUCT_START,
	payload: minicherDetails,
});

export const addListMinicherProductSuccess = (minicherListSuccess) => ({
	type: minicherTypes.ADD_LIST_MINICHER_PRODUCT_SUCCESS,
	payload: minicherListSuccess,
});

export const addListMinicherProductFailure = (error) => ({
	type: minicherTypes.ADD_LIST_MINICHER_PRODUCT_FAILURE,
	payload: error,
});

export const addListMinicherProductChanged = () => ({
	type: minicherTypes.ADD_LIST_MINICHER_PRODUCT_CHANGED,
});

export const addListMinicherProductReset = () => ({
	type: minicherTypes.ADD_LIST_MINICHER_PRODUCT_RESET,
});
/* ADD LIST MINICHER PRODUCT END */

/* CREATE MINICHER PRODUCT START */
export const createMinicherProductStart = (minicherDetails) => ({
	type: minicherTypes.CREATE_MINICHER_PRODUCT_START,
	payload: minicherDetails,
});

export const createMinicherProductSuccess = (minicherListSuccess) => ({
	type: minicherTypes.CREATE_MINICHER_PRODUCT_SUCCESS,
	payload: minicherListSuccess,
});

export const createMinicherProductFailure = (error) => ({
	type: minicherTypes.CREATE_MINICHER_PRODUCT_FAILURE,
	payload: error,
});

export const createMinicherProductChanged = () => ({
	type: minicherTypes.CREATE_MINICHER_PRODUCT_CHANGED,
});

export const createMinicherProductReset = () => ({
	type: minicherTypes.CREATE_MINICHER_PRODUCT_RESET,
});
/* CREATE MINICHER PRODUCT END */

/* GET MINICHER PRODUCTS LIST START */
export const minicherProductListStart = (minicherDetails) => ({
	type: minicherTypes.GET_MINICHER_PRODUCT_LIST_START,
	payload: minicherDetails,
});

export const minicherProductListSuccess = (minicherListSuccess) => ({
	type: minicherTypes.GET_MINICHER_PRODUCT_LIST_SUCCESS,
	payload: minicherListSuccess,
});

export const minicherProductListFailure = (error) => ({
	type: minicherTypes.GET_MINICHER_PRODUCT_LIST_FAILURE,
	payload: error,
});

export const minicherProductListChanged = () => ({
	type: minicherTypes.GET_MINICHER_PRODUCT_LIST_CHANGED,
});

export const minicherProductListReset = () => ({
	type: minicherTypes.GET_MINICHER_PRODUCT_LIST_RESET,
});
/* GET MINICHER PRODUCTS LIST END */

/* DELETE MINICHER PRODUCTS START */
export const deleteMinicherProductStart = (minicherDetails) => ({
	type: minicherTypes.DELETE_MINICHER_PRODUCT_START,
	payload: minicherDetails,
});

export const deleteMinicherProductSuccess = (minicherListSuccess) => ({
	type: minicherTypes.DELETE_MINICHER_PRODUCT_SUCCESS,
	payload: minicherListSuccess,
});

export const deleteMinicherProductFailure = (error) => ({
	type: minicherTypes.DELETE_MINICHER_PRODUCT_FAILURE,
	payload: error,
});

export const deleteMinicherProductChanged = () => ({
	type: minicherTypes.DELETE_MINICHER_PRODUCT_CHANGED,
});

export const deleteMinicherProductReset = () => ({
	type: minicherTypes.DELETE_MINICHER_PRODUCT_RESET,
});
/* DELETE MINICHER PRODUCTS END */
