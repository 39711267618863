/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import inventoryTypes from './createInventory.types';

/* INVENTORY LIST START */
export const inventoryListStart = (inventoryDetails) => ({
	type: inventoryTypes.INVENTORY_LIST_START,
	payload: inventoryDetails,
});

export const inventoryListSuccess = (inventorySuccess) => ({
	type: inventoryTypes.INVENTORY_LIST_SUCCESS,
	payload: inventorySuccess,
});

export const inventoryListFailure = (error) => ({
	type: inventoryTypes.INVENTORY_LIST_FAILURE,
	payload: error,
});

export const inventoryListChanged = () => ({
	type: inventoryTypes.INVENTORY_LIST_CHANGED,
});

export const inventoryListReset = () => ({
	type: inventoryTypes.INVENTORY_LIST_RESET,
});
/* INVENTORY LIST END */

/* INVENTORY CREATE START */
export const createInventoryStart = (inventoryDetails) => ({
	type: inventoryTypes.INVENTORY_CREATE_START,
	payload: inventoryDetails,
});

export const createInventorySuccess = (inventorySuccess) => ({
	type: inventoryTypes.INVENTORY_CREATE_SUCCESS,
	payload: inventorySuccess,
});

export const createInventoryFailure = (error) => ({
	type: inventoryTypes.INVENTORY_CREATE_FAILURE,
	payload: error,
});

export const createInventoryChanged = () => ({
	type: inventoryTypes.INVENTORY_CREATE_CHANGED,
});

export const createInventoryReset = () => ({
	type: inventoryTypes.INVENTORY_CREATE_RESET,
});
/* INVENTORY CREATE END */

/* INVENTORY UPDATE START */
export const updateInventoryStart = (inventoryDetails) => ({
	type: inventoryTypes.INVENTORY_UPDATE_START,
	payload: inventoryDetails,
});

export const updateInventorySuccess = (inventorySuccess) => ({
	type: inventoryTypes.INVENTORY_UPDATE_SUCCESS,
	payload: inventorySuccess,
});

export const updateInventoryFailure = (error) => ({
	type: inventoryTypes.INVENTORY_UPDATE_FAILURE,
	payload: error,
});

export const updateInventoryChanged = () => ({
	type: inventoryTypes.INVENTORY_UPDATE_CHANGED,
});

export const updateInventoryReset = () => ({
	type: inventoryTypes.INVENTORY_UPDATE_RESET,
});
/* INVENTORY UPDATE END */

/* INVENTORY DELETE START */
export const deleteInventoryStart = (inventoryDetails) => ({
	type: inventoryTypes.INVENTORY_DELETE_START,
	payload: inventoryDetails,
});

export const deleteInventorySuccess = (inventorySuccess) => ({
	type: inventoryTypes.INVENTORY_DELETE_SUCCESS,
	payload: inventorySuccess,
});

export const deleteInventoryFailure = (error) => ({
	type: inventoryTypes.INVENTORY_DELETE_FAILURE,
	payload: error,
});

export const deleteInventoryChanged = () => ({
	type: inventoryTypes.INVENTORY_DELETE_CHANGED,
});

export const deleteInventoryReset = () => ({
	type: inventoryTypes.INVENTORY_DELETE_RESET,
});
/* INVENTORY DELETE END */

/* INVENTORY VENDOR LIST START */
export const inventoryVendorListStart = (inventoryDetails) => ({
	type: inventoryTypes.INVENTORY_VENDOR_LIST_START,
	payload: inventoryDetails,
});

export const inventoryVendorListSuccess = (inventorySuccess) => ({
	type: inventoryTypes.INVENTORY_VENDOR_LIST_SUCCESS,
	payload: inventorySuccess,
});

export const inventoryVendorListFailure = (error) => ({
	type: inventoryTypes.INVENTORY_VENDOR_LIST_FAILURE,
	payload: error,
});

export const inventoryVendorListChanged = () => ({
	type: inventoryTypes.INVENTORY_VENDOR_LIST_CHANGED,
});

export const inventoryVendorListReset = () => ({
	type: inventoryTypes.INVENTORY_VENDOR_LIST_RESET,
});
/* INVENTORY VENDOR LIST END */

/* INVENTORY PRODUCT LIST START */
export const inventoryProductListStart = (inventoryDetails) => ({
	type: inventoryTypes.INVENTORY_PRODUCT_LIST_START,
	payload: inventoryDetails,
});

export const inventoryProductListSuccess = (inventorySuccess) => ({
	type: inventoryTypes.INVENTORY_PRODUCT_LIST_SUCCESS,
	payload: inventorySuccess,
});

export const inventoryProductListFailure = (error) => ({
	type: inventoryTypes.INVENTORY_PRODUCT_LIST_FAILURE,
	payload: error,
});

export const inventoryProductListChanged = () => ({
	type: inventoryTypes.INVENTORY_PRODUCT_LIST_CHANGED,
});

export const inventoryProductListReset = () => ({
	type: inventoryTypes.INVENTORY_PRODUCT_LIST_RESET,
});
/* INVENTORY PRODUCT LIST END */

/* ADD INVENTORY OPTIONS START */
export const addInventoryOptionsStart = (inventoryDetails) => ({
	type: inventoryTypes.ADD_INVENTORY_OPTIONS_START,
	payload: inventoryDetails,
});

export const addInventoryOptionsSuccess = (inventorySuccess) => ({
	type: inventoryTypes.ADD_INVENTORY_OPTIONS_SUCCESS,
	payload: inventorySuccess,
});

export const addInventoryOptionsFailure = (error) => ({
	type: inventoryTypes.ADD_INVENTORY_OPTIONS_FAILURE,
	payload: error,
});

export const addInventoryOptionsChanged = () => ({
	type: inventoryTypes.ADD_INVENTORY_OPTIONS_CHANGED,
});

export const addInventoryOptionsReset = () => ({
	type: inventoryTypes.ADD_INVENTORY_OPTIONS_RESET,
});

/* ADD INVENTORY OPTIONS END */

/* GET INVENTORY OPTIONS LIST START */
export const getInventoryOptionsStart = (inventoryDetails) => ({
	type: inventoryTypes.GET_INVENTORY_OPTIONS_LIST_START,
	payload: inventoryDetails,
});

export const getInventoryOptionsSuccess = (inventorySuccess) => ({
	type: inventoryTypes.GET_INVENTORY_OPTIONS_LIST_SUCCESS,
	payload: inventorySuccess,
});

export const getInventoryOptionsFailure = (error) => ({
	type: inventoryTypes.GET_INVENTORY_OPTIONS_LIST_FAILURE,
	payload: error,
});

export const getInventoryOptionsChanged = () => ({
	type: inventoryTypes.GET_INVENTORY_OPTIONS_LIST_CHANGED,
});

export const getInventoryOptionsReset = () => ({
	type: inventoryTypes.GET_INVENTORY_OPTIONS_LIST_RESET,
});

/* GET INVENTORY OPTIONS LIST END */

/* REMOVE INVENTORY OPTIONS LIST START */
export const removeInventoryOptionsStart = (inventoryDetails) => ({
	type: inventoryTypes.REMOVE_INVENTORY_OPTIONS_START,
	payload: inventoryDetails,
});

export const removeInventoryOptionsSuccess = (inventorySuccess) => ({
	type: inventoryTypes.REMOVE_INVENTORY_OPTIONS_SUCCESS,
	payload: inventorySuccess,
});

export const removeInventoryOptionsFailure = (error) => ({
	type: inventoryTypes.REMOVE_INVENTORY_OPTIONS_FAILURE,
	payload: error,
});

export const removeInventoryOptionsChanged = () => ({
	type: inventoryTypes.REMOVE_INVENTORY_OPTIONS_CHANGED,
});

export const removeInventoryOptionsReset = () => ({
	type: inventoryTypes.REMOVE_INVENTORY_OPTIONS_RESET,
});

/* REMOVE INVENTORY OPTIONS LIST END */
