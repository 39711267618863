/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import productOrderTypes from './productOrder.types';
import {
	getProductOrderList,
	viewProductOrderList,
	orderStatusUpdate,
	viewTranscationList,
} from './productOrder.service';
import {
	productOrderSuccess,
	productOrderFailure,
	productOrderChanged,
	viewProductOrderSuccess,
	viewProductOrderFailure,
	viewProductOrderChanged,
	updateOrderStatusSuccess,
	updateOrderStatusFailure,
	updateOrderStatusChanged,
	viewTranscationListSuccess,
	viewTranscationListFailure,
	viewTranscationListChanged,
} from './productOrder.action';

/* PRODUCT  ORDER LIST START */
export function* listOrderProduct({ payload: { allProductOrderList } }) {
	try {
		const responseData = yield call(getProductOrderList, allProductOrderList);
		if (responseData.data.statusCode === '100') {
			yield put(productOrderFailure(responseData.data));
		} else {
			yield put(productOrderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productOrderFailure(error));
	}
}

export function* listOrderProductReset() {
	yield put(productOrderChanged());
}

export function* getProductListReset() {
	yield takeLatest(productOrderTypes.PRODUCT_ORDER_LIST_RESET, listOrderProductReset);
}

export function* getProductList() {
	yield takeLatest(productOrderTypes.PRODUCT_ORDER_LIST_START, listOrderProduct);
}
/* PRODUCT  ORDER LIST END */

/* VIEW PRODUCT ORDER LIST START */
export function* productOrderListView({ payload: { productOrderView } }) {
	try {
		const responseData = yield call(viewProductOrderList, productOrderView);
		if (responseData.data.statusCode === '100') {
			yield put(viewProductOrderFailure(responseData.data));
		} else {
			yield put(viewProductOrderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewProductOrderFailure(error));
	}
}

export function* productOrderListViewReset() {
	yield put(viewProductOrderChanged());
}

export function* viewProductOrderDetails() {
	yield takeLatest(productOrderTypes.VIEW_PRODUCT_ORDER_LIST_START, productOrderListView);
}

export function* resetProductOrderDetails() {
	yield takeLatest(productOrderTypes.VIEW_PRODUCT_ORDER_LIST_RESET, productOrderListViewReset);
}
/* VIEW PRODUCT ORDER LIST END */

/* UPDATE ORDER STATUS START */
export function* productOrderStatusUpdate({ payload: { dropOrderStatus } }) {
	try {
		const jsonData = {
			OrderStatus: dropOrderStatus.orderStatus,
			ProductOrderPlaceID: dropOrderStatus.productOrderPlaceID,
		};
		const responseData = yield call(orderStatusUpdate, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(updateOrderStatusFailure(responseData.data));
		} else {
			yield put(updateOrderStatusSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateOrderStatusFailure(error));
	}
}

export function* productOrderStatusUpdateReset() {
	yield put(updateOrderStatusChanged());
}

export function* updateOrderStatusDetails() {
	yield takeLatest(productOrderTypes.UPDATE_ORDER_STATUS_START, productOrderStatusUpdate);
}

export function* resetUpdateOrderStatusDetails() {
	yield takeLatest(productOrderTypes.UPDATE_ORDER_STATUS_RESET, productOrderStatusUpdateReset);
}
/* UPDATE ORDER STATUS END */

/* VIEW USER_TRANSCATION LIST START */
export function* transcationUserListView({ payload: { listTranscationView } }) {
	try {
		const responseData = yield call(viewTranscationList, listTranscationView);
		if (responseData.data.statusCode === '100') {
			yield put(viewTranscationListFailure(responseData.data));
		} else {
			yield put(viewTranscationListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewTranscationListFailure(error));
	}
}

export function* transcationUserListViewReset() {
	yield put(viewTranscationListChanged());
}

export function* viewUserTranscationDetails() {
	yield takeLatest(productOrderTypes.VIEW_USER_TRANSCATION_LIST_START, transcationUserListView);
}

export function* resetUserTranscationDetails() {
	yield takeLatest(
		productOrderTypes.VIEW_USER_TRANSCATION_LIST_RESET,
		transcationUserListViewReset,
	);
}
/* VIEW USER_TRANSCATION LIST END */

export function* productOrdersSaga() {
	yield all([
		call(getProductList),
		call(getProductListReset),
		call(viewProductOrderDetails),
		call(resetProductOrderDetails),
		call(updateOrderStatusDetails),
		call(resetUpdateOrderStatusDetails),
		call(viewUserTranscationDetails),
		call(resetUserTranscationDetails),
	]);
}
