/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import flowerOrderTypes from './flowerOrder.types';

/* FLOWER ORDER LIST START */
export const flowerOrderStart = (flowerOrderDetails) => ({
	type: flowerOrderTypes.FLOWER_ORDER_LIST_START,
	payload: flowerOrderDetails,
});

export const flowerOrderSuccess = (flowerOrderListSuccess) => ({
	type: flowerOrderTypes.FLOWER_ORDER_LIST_SUCCESS,
	payload: flowerOrderListSuccess,
});

export const flowerOrderFailure = (error) => ({
	type: flowerOrderTypes.FLOWER_ORDER_LIST_FAILURE,
	payload: error,
});

export const flowerOrderChanged = () => ({
	type: flowerOrderTypes.FLOWER_ORDER_LIST_CHANGED,
});

export const flowerOrderReset = () => ({
	type: flowerOrderTypes.FLOWER_ORDER_LIST_RESET,
});
/* FLOWER ORDER LIST END */

/* VIEW FLOWER ORDER LIST START */
export const viewFlowerOrderStart = (flowerOrderDetails) => ({
	type: flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_START,
	payload: flowerOrderDetails,
});

export const viewFlowerOrderSuccess = (flowerOrderListSuccess) => ({
	type: flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_SUCCESS,
	payload: flowerOrderListSuccess,
});

export const viewFlowerOrderFailure = (error) => ({
	type: flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_FAILURE,
	payload: error,
});

export const viewFlowerOrderChanged = () => ({
	type: flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_CHANGED,
});

export const viewFlowerOrderReset = () => ({
	type: flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_RESET,
});
/* VIEW FLOWER ORDER LIST END */

/* VIEW FLOWER TRANSACTIONS START */
export const viewFlowerTransactionsStart = (flowerOrderDetails) => ({
	type: flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_START,
	payload: flowerOrderDetails,
});

export const viewFlowerTransactionsSuccess = (flowerOrderListSuccess) => ({
	type: flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_SUCCESS,
	payload: flowerOrderListSuccess,
});

export const viewFlowerTransactionsFailure = (error) => ({
	type: flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_FAILURE,
	payload: error,
});

export const viewFlowerTransactionsChanged = () => ({
	type: flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_CHANGED,
});

export const viewFlowerTransactionsReset = () => ({
	type: flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_RESET,
});
/* VIEW FLOWER TRANSACTIONS END */
