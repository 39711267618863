const refundTypes = {
	REFUND_LIST_START: 'REFUND_LIST_START',
	REFUND_LIST_SUCCESS: 'REFUND_LIST_SUCCESS',
	REFUND_LIST_FAILURE: 'REFUND_LIST_FAILURE',
	REFUND_LIST_RESET: 'REFUND_LIST_RESET',
	REFUND_LIST_CHANGED: 'REFUND_LIST_CHANGED',

	REFUND_UPDATE_START: 'REFUND_UPDATE_START',
	REFUND_UPDATE_SUCCESS: 'REFUND_UPDATE_SUCCESS',
	REFUND_UPDATE_FAILURE: 'REFUND_UPDATE_FAILURE',
	REFUND_UPDATE_RESET: 'REFUND_UPDATE_RESET',
	REFUND_UPDATE_CHANGED: 'REFUND_UPDATE_CHANGED',

	VIEW_REFUNDED_LIST_START: 'VIEW_REFUNDED_LIST_START',
	VIEW_REFUNDED_LIST_SUCCESS: 'VIEW_REFUNDED_LIST_SUCCESS',
	VIEW_REFUNDED_LIST_FAILURE: 'VIEW_REFUNDED_LIST_FAILURE',
	VIEW_REFUNDED_LIST_RESET: 'VIEW_REFUNDED_LIST_RESET',
	VIEW_REFUNDED_LIST_CHANGED: 'VIEW_REFUNDED_LIST_CHANGED',
};
export default refundTypes;
