/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import endOfDayNewTypes from './EndOfDayNew.types';
import {
	getEodNewOrderList,
	orderDetailsView,
	eodNewFetchList,
	addEndOfDayNew,
	getEodStaffListNew,
	assginedToStaffOrdersNew,
	assignedRevertOrderNew,
	orderDateChangeUpdate,
} from './EndOfDayNew.service';
import {
	eodOrderListNewSuccess,
	eodOrderListNewFailure,
	eodOrderListNewChanged,
	viewEodOrderListNewSuccess,
	viewEodOrderListNewFailure,
	viewEodOrderListNewChanged,
	eodFetchListSuccess,
	eodFetchListFailure,
	eodFetchListChanged,
	addEodNewSuccess,
	addEodNewFailure,
	addEodNewChanged,
	getEodStaffListNewSuccess,
	getEodStaffListNewFailure,
	getEodStaffListNewChanged,
	staffAssignedNewSuccess,
	staffAssignedNewFailure,
	staffAssignedNewChanged,
	revertAssignedOrderNewSuccess,
	revertAssignedOrderNewFailure,
	revertAssignedOrderNewChanged,
	orderDateChangeSuccess,
	orderDateChangeFailure,
	orderDateChangeChanged,
} from './EndOfDayNew.action';
import { encodeJson } from '../enode-decode';

/* EOD ORDER LIST NEW START */
export function* eodNewOrderList({ payload }) {
	try {
		const key = 'GetEodNewOrderListKey';
		const jsonData = {
			Date: payload.sigleDate,
			SearchValueSet: payload.searchValueSet,
			BranchID: payload.branchID,
			OrderFilter: payload.orderFilter,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getEodNewOrderList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(eodOrderListNewFailure(responseData.data));
		} else {
			yield put(eodOrderListNewSuccess(responseData.data));
		}
	} catch (error) {
		yield put(eodOrderListNewFailure(error));
	}
}

export function* eodNewOrderListReset() {
	yield put(eodOrderListNewChanged());
}

export function* getOrderListEodReset() {
	yield takeLatest(endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_RESET, eodNewOrderListReset);
}

export function* getOrderListEod() {
	yield takeLatest(endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_START, eodNewOrderList);
}
/* EOD ORDER LIST NEW END */

/* VIEW EOD ORDER LIST NEW START */
export function* viewEodNewOrderList({ payload }) {
	try {
		const key = 'OrderDetailsViewKey';
		const jsonData = {
			OrderPlaceID: payload.orderPlaceID,
			OrderPlaceHeadID: payload.orderPlaceHeadID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(orderDetailsView, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(viewEodOrderListNewFailure(responseData.data));
		} else {
			yield put(viewEodOrderListNewSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewEodOrderListNewFailure(error));
	}
}

export function* viewEodNewOrderListReset() {
	yield put(viewEodOrderListNewChanged());
}

export function* getOrderListEodViewReset() {
	yield takeLatest(endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_RESET, viewEodNewOrderListReset);
}

export function* getOrderListEodView() {
	yield takeLatest(endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_START, viewEodNewOrderList);
}
/* VIEW EOD ORDER LIST NEW END */

/* EOD FETCH LIST NEW START */
export function* eodFetchListNew({ payload }) {
	try {
		const key = 'EodNewFetchListKey';
		const jsonData = {
			Date: payload.curDate,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(eodNewFetchList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(eodFetchListFailure(responseData.data));
		} else {
			yield put(eodFetchListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(eodFetchListFailure(error));
	}
}

export function* eodFetchListNewReset() {
	yield put(eodFetchListChanged());
}

export function* fetchEodNewListReset() {
	yield takeLatest(endOfDayNewTypes.EOD_FETCH_LIST_RESET, eodFetchListNewReset);
}

export function* fetchEodNewList() {
	yield takeLatest(endOfDayNewTypes.EOD_FETCH_LIST_START, eodFetchListNew);
}
/* EOD FETCH LIST NEW END */

/* ADD EOD NEW START */
export function* eodNewAddStart({ payload }) {
	try {
		const key = 'AddEndOfDayNewKey';
		const jsonData = {
			EodArr: payload.eodArr,
			BranchID: payload.branchID,
			StaffID: payload.staffID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(addEndOfDayNew, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addEodNewFailure(responseData.data));
		} else {
			yield put(addEodNewSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addEodNewFailure(error));
	}
}

export function* eodNewAddListReset() {
	yield put(addEodNewChanged());
}

export function* eodNewAddReset() {
	yield takeLatest(endOfDayNewTypes.ADD_EOD_NEW_RESET, eodNewAddListReset);
}

export function* eodNewAdd() {
	yield takeLatest(endOfDayNewTypes.ADD_EOD_NEW_START, eodNewAddStart);
}
/* ADD EOD NEW END */

/* GET EOD STAFF LIST NEW START */
export function* listEodStaffNew({ payload }) {
	try {
		const key = 'EodStaffListNewGetKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const EodStaffListData = encodeJson(jsonData, key);
		const responseData = yield call(getEodStaffListNew, EodStaffListData);
		if (responseData.data.statusCode === '100') {
			yield put(getEodStaffListNewFailure(responseData.data));
		} else {
			yield put(getEodStaffListNewSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getEodStaffListNewFailure(error));
	}
}

export function* listEodStaffNewReset() {
	yield put(getEodStaffListNewChanged());
}

export function* onGetEodStaffListNewResponse() {
	yield takeLatest(endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_RESET, listEodStaffNewReset);
}

export function* onGetEodStaffListNewStart() {
	yield takeLatest(endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_START, listEodStaffNew);
}
/* GET EOD STAFF LIST NEW END */

/* STAFF ASSIGNED NEW START */
export function* staffAssignedNewStatus({
	payload: { orderListDetails, deliveryDate, staffID, createdBy, assignedArr },
}) {
	try {
		const key = 'AssignedStaffNewKey';
		const AssignStaffOrderDetails = JSON.stringify(assignedArr).replace(/"/g, "'");
		const jsonData = {
			DeliveryDate: deliveryDate,
			NoOfDelivery: orderListDetails.NoOfDelivery,
			TotalAmount: orderListDetails.TotalAmount,
			NoOfOnlineDelivery: orderListDetails.NoOfOnlineOrders,
			NoOfCashDelivery: orderListDetails.NoOfCashOrders,
			NoOfWalletDelivery: orderListDetails.NoOfWalletOrders,
			OnlineAmount: orderListDetails.OnlineAmount,
			WalletAmount: orderListDetails.WalletAmount,
			CashAmount: orderListDetails.CashAmount,
			assignStaffOrderDetails: AssignStaffOrderDetails,
			StaffID: staffID,
			CreatedBy: createdBy,
			Secratekey: key,
		};
		const assignJson = encodeJson(jsonData, key);
		const responseData = yield call(assginedToStaffOrdersNew, assignJson);
		if (responseData.data.statusCode === '100') {
			yield put(staffAssignedNewFailure(responseData.data));
		} else {
			yield put(staffAssignedNewSuccess(responseData.data));
		}
	} catch (error) {
		yield put(staffAssignedNewFailure(error));
	}
}

export function* restStaffAssignedNewResponse() {
	yield put(staffAssignedNewChanged());
}

export function* onStaffAssignedNewStart() {
	yield takeLatest(endOfDayNewTypes.STAFF_ASSIGNED_NEW_START, staffAssignedNewStatus);
}

export function* onStaffAssignedNewResponse() {
	yield takeLatest(endOfDayNewTypes.STAFF_ASSIGNED_NEW_RESET, restStaffAssignedNewResponse);
}
/* STAFF ASSIGNED NEW END */

/* REVERT ASSIGNED STAFF NEW START */
export function* revertAssignedOrderNewStatus({ payload }) {
	try {
		const key = 'RevertAssignedOrderNewKey';
		const jsonData = {
			OrderPlaceID: payload.orderPlaceID,
			StaffID: payload.staffID,
			CreatedByID: payload.createdByID,
			DeliveryDate: payload.deliveryDate,
			OrderCategory: payload.orderCategory,
			RevertArr: payload.revertArr,
			Secratekey: key,
		};
		const revertJson = encodeJson(jsonData, key);
		const responseData = yield call(assignedRevertOrderNew, revertJson);
		if (responseData.data.statusCode === '100') {
			yield put(revertAssignedOrderNewFailure(responseData.data));
		} else {
			yield put(revertAssignedOrderNewSuccess(responseData.data));
		}
	} catch (error) {
		yield put(revertAssignedOrderNewFailure(error));
	}
}

export function* restRevertAssignedOrderNewResponse() {
	yield put(revertAssignedOrderNewChanged());
}

export function* onRevertAssignedOrderNewStart() {
	yield takeLatest(
		endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_START,
		revertAssignedOrderNewStatus,
	);
}

export function* onRevertAssignedOrderNewResponse() {
	yield takeLatest(
		endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_RESET,
		restRevertAssignedOrderNewResponse,
	);
}
/* REVERT ASSIGNED STAFF NEW END */

/* ORDER DATE CHANGE START */
export function* orderDateChangeStartData({ payload }) {
	try {
		const key = 'OrderDateChangeKey';
		const jsonData = {
			UserID: payload.userID,
			OrderPlaceID: payload.orderPlaceID,
			StaffID: payload.staffID,
			OrderChangeDate: payload.orderChangeDate,
			OrderChangeDateReason: payload.orderChangeReason,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(orderDateChangeUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(orderDateChangeFailure(responseData.data));
		} else {
			yield put(orderDateChangeSuccess(responseData.data));
		}
	} catch (error) {
		yield put(orderDateChangeFailure(error));
	}
}

export function* restOrderDateChangeStartResponse() {
	yield put(orderDateChangeChanged());
}

export function* onOrderDateChangeStart() {
	yield takeLatest(endOfDayNewTypes.ORDER_DATE_CHANGE_START, orderDateChangeStartData);
}

export function* onOrderDateChangeResponse() {
	yield takeLatest(endOfDayNewTypes.ORDER_DATE_CHANGE_RESET, restOrderDateChangeStartResponse);
}
/* ORDER DATE CHANGE END */

export function* eodNewSaga() {
	yield all([
		call(getOrderListEod),
		call(getOrderListEodReset),
		call(getOrderListEodView),
		call(getOrderListEodViewReset),
		call(fetchEodNewList),
		call(fetchEodNewListReset),
		call(eodNewAdd),
		call(eodNewAddReset),
		call(onGetEodStaffListNewStart),
		call(onGetEodStaffListNewResponse),
		call(onStaffAssignedNewStart),
		call(onStaffAssignedNewResponse),
		call(onRevertAssignedOrderNewStart),
		call(onRevertAssignedOrderNewResponse),
		call(onOrderDateChangeStart),
		call(onOrderDateChangeResponse),
	]);
}
