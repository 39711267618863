/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import walletCustomersTypes from './walletCustomers.types';
import { getWalletCustomersList, walletCustomersUpdate } from './walletCustomers.service';
import {
	walletCustomersSuccess,
	walletCustomersFailure,
	walletCustomersChanged,
	updateWalletCustomersSuccess,
	updateWalletCustomersFailure,
	updateWalletCustomersChanged,
} from './walletCustomers.action';
import { encodeJson } from '../enode-decode';

/* ORDERED USER LIST START */
export function* walletCustomersDetailsStart({ payload }) {
	try {
		const key = 'GetWalletCustomerListKey';
		const jsonData = {
			Limit: payload.limit,
			Date: payload.Date,
			SearchValueSet: payload.searchValueSet,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getWalletCustomersList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(walletCustomersFailure(responseData.data));
		} else {
			yield put(walletCustomersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(walletCustomersFailure(error));
	}
}

export function* walletCustomersDetailsChanged() {
	yield put(walletCustomersChanged());
}

export function* walletCustomersDetails() {
	yield takeLatest(walletCustomersTypes.WALLET_CUSTOMERS_LIST_START, walletCustomersDetailsStart);
}

export function* walletCustomersResponseReset() {
	yield takeLatest(
		walletCustomersTypes.WALLET_CUSTOMERS_LIST_RESET,
		walletCustomersDetailsChanged,
	);
}
/* ORDERED USER LIST END */

/* UPDATE WALLET CUSTOMERS START */
export function* walletCustomersUpdateStart({ payload }) {
	try {
		const key = 'UpdateWalletAmtKey';
		const jsonData = {
			UserID: payload.userID,
			WalletAmount: payload.walletAmount,
			Reason: payload.reason,
			StaffID: payload.staffID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(walletCustomersUpdate, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateWalletCustomersFailure(responseData.data));
		} else {
			yield put(updateWalletCustomersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateWalletCustomersFailure(error));
	}
}

export function* walletCustomersUpdateChanged() {
	yield put(updateWalletCustomersChanged());
}

export function* onUpdateWalletCustomersStart() {
	yield takeLatest(
		walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_START,
		walletCustomersUpdateStart,
	);
}

export function* onUpdateWalletCustomersClear() {
	yield takeLatest(
		walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_RESET,
		walletCustomersUpdateChanged,
	);
}
/* UPDATE WALLET CUSTOMERS END */

export function* walletCustomersSaga() {
	yield all([
		call(walletCustomersDetails),
		call(walletCustomersResponseReset),
		call(onUpdateWalletCustomersStart),
		call(onUpdateWalletCustomersClear),
	]);
}
