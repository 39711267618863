/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import orderListTypes from './orderList.types';

/* ORDER LIST START */
export const orderListStart = (orderDetails) => ({
	type: orderListTypes.ORDER_LIST_START,
	payload: orderDetails,
});

export const orderListSuccess = (orderSuccess) => ({
	type: orderListTypes.ORDER_LIST_SUCCESS,
	payload: orderSuccess,
});

export const orderListFailure = (error) => ({
	type: orderListTypes.ORDER_LIST_FAILURE,
	payload: error,
});

export const orderListChanged = () => ({
	type: orderListTypes.ORDER_LIST_CHANGED,
});

export const orderListReset = () => ({
	type: orderListTypes.ORDER_LIST_RESET,
});
/* ORDER LIST END */

/* VIEW ORDER LIST START */
export const viewOrderListStart = (orderDetails) => ({
	type: orderListTypes.VIEW_ORDER_LIST_START,
	payload: orderDetails,
});

export const viewOrderListSuccess = (orderSuccess) => ({
	type: orderListTypes.VIEW_ORDER_LIST_SUCCESS,
	payload: orderSuccess,
});

export const viewOrderListFailure = (error) => ({
	type: orderListTypes.VIEW_ORDER_LIST_FAILURE,
	payload: error,
});

export const viewOrderListChanged = () => ({
	type: orderListTypes.VIEW_ORDER_LIST_CHANGED,
});

export const viewOrderListReset = () => ({
	type: orderListTypes.VIEW_ORDER_LIST_RESET,
});
/* VIEW ORDER LIST END */
