/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const createPriceUpdate = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${API_URL}CreatePriceUpdateDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const editPriceUpdate = (PriceEditList, PriceUserID) => {
	const data = {
		priceUpdate: PriceEditList,
		UserID: PriceUserID.UserID,
	};
	const url = `${API_URL}UpdatePriceDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
