/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import flowersListTypes from './addFlowers.types';
import { getListOfFlowers, createFlowers, editFlowers, deleteFlowers } from './addFlowers.service';
import {
	flowersListSuccess,
	flowersListFailure,
	flowersListChanged,
	addFlowersSuccess,
	addFlowersFailure,
	addFlowersChanged,
	editFlowersSuccess,
	editFlowersFailure,
	editFlowersChanged,
	removeFlowersSuccess,
	removeFlowersFailure,
	removeFlowersChanged,
} from './addFlowers.action';
import { encodeJson } from '../enode-decode';

/* FLOWERS LIST START */
export function* listOfFlowers({ payload }) {
	try {
		const key = 'ComboProductsListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			SearchValueSet: payload.searchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getListOfFlowers, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(flowersListFailure(responseData.data));
		} else {
			yield put(flowersListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(flowersListFailure(error));
	}
}

export function* listOfFlowersReset() {
	yield put(flowersListChanged());
}

export function* getFlowersListReset() {
	yield takeLatest(flowersListTypes.GET_FLOWERS_LIST_RESET, listOfFlowersReset);
}

export function* getFlowersList() {
	yield takeLatest(flowersListTypes.GET_FLOWERS_LIST_START, listOfFlowers);
}
/* FLOWERS LIST END */

/* ADD FLOWERS START */
export function* addFlowersList({ payload }) {
	try {
		const key = 'CreateProductsKey';
		const jsonData = {
			ProductImage1: payload.productImage1,
			ProductName: payload.productName,
			ProductSize: payload.productSize,
			CategoryProduct: payload.categoryProduct,
			TypeProductCategory: payload.typeProductCategory,
			TypeFlowerSeasonable: payload.typeFlowerSeasonable,
			Condition1: payload.condition1,
			StaffID: payload.staffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createFlowers, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(addFlowersFailure(responseData.data));
		} else {
			yield put(addFlowersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addFlowersFailure(error));
	}
}

export function* addFlowersListReset() {
	yield put(addFlowersChanged());
}

export function* flowersAddStart() {
	yield takeLatest(flowersListTypes.ADD_FLOWERS_START, addFlowersList);
}

export function* flowersAddReset() {
	yield takeLatest(flowersListTypes.ADD_FLOWERS_RESET, addFlowersListReset);
}
/* ADD FLOWERS END */

/* UPDATE FLOWERS START */
export function* updateFlowersList({ payload }) {
	try {
		const key = 'UpdateProductsKey';
		const jsonData = {
			ProductID: payload.productID,
			ProductImage1: payload.productImage1,
			ProductName: payload.productName,
			Condition1: payload.condition1,
			StaffID: payload.staffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(editFlowers, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(editFlowersFailure(responseData.data));
		} else {
			yield put(editFlowersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(editFlowersFailure(error));
	}
}

export function* updateFlowersListReset() {
	yield put(editFlowersChanged());
}

export function* flowersUpdateStart() {
	yield takeLatest(flowersListTypes.EDIT_FLOWERS_START, updateFlowersList);
}

export function* flowersUpdateReset() {
	yield takeLatest(flowersListTypes.EDIT_FLOWERS_RESET, updateFlowersListReset);
}
/* UPDATE FLOWERS END */

/* REMOVE FLOWERS START */
export function* removeFlowersList({ payload }) {
	try {
		const key = 'RemoveProductsKey';
		const jsonData = {
			ProductID: payload.ProductID,
			StaffID: payload.staffID,
			IsActive: payload.isActive,
			IsPublished: payload.isPublished,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteFlowers, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(removeFlowersFailure(responseData.data));
		} else {
			yield put(removeFlowersSuccess(responseData.data));
		}
	} catch (error) {
		yield put(removeFlowersFailure(error));
	}
}

export function* removeFlowersListReset() {
	yield put(removeFlowersChanged());
}

export function* flowersRemoveStart() {
	yield takeLatest(flowersListTypes.REMOVE_FLOWERS_START, removeFlowersList);
}

export function* flowersRemoveReset() {
	yield takeLatest(flowersListTypes.REMOVE_FLOWERS_RESET, removeFlowersListReset);
}
/* REMOVE FLOWERS END */

export function* flowersListSaga() {
	yield all([
		call(getFlowersList),
		call(getFlowersListReset),
		call(flowersAddStart),
		call(flowersAddReset),
		call(flowersUpdateStart),
		call(flowersUpdateReset),
		call(flowersRemoveStart),
		call(flowersRemoveReset),
	]);
}
