const bannerTypes = {
	BANNER_LIST_START: 'BANNER_LIST_START',
	BANNER_LIST_SUCCESS: 'BANNER_LIST_SUCCESS',
	BANNER_LIST_FAILURE: 'BANNER_LIST_FAILURE',
	BANNER_LIST_RESET: 'BANNER_LIST_RESET',
	BANNER_LIST_CHANGED: 'BANNER_LIST_CHANGED',

	BANNER_CREATE_START: 'BANNER_CREATE_START',
	BANNER_CREATE_SUCCESS: 'BANNER_CREATE_SUCCESS',
	BANNER_CREATE_FAILURE: 'BANNER_CREATE_FAILURE',
	BANNER_CREATE_CHANGED: 'BANNER_CREATE_CHANGED',
	BANNER_CREATE_RESET: 'BANNER_CREATE_RESET',

	BANNER_UPDATE_START: 'BANNER_UPDATE_START',
	BANNER_UPDATE_SUCCESS: 'BANNER_UPDATE_SUCCESS',
	BANNER_UPDATE_FAILURE: 'BANNER_UPDATE_FAILURE',
	BANNER_UPDATE_CHANGED: 'BANNER_UPDATE_CHANGED',
	BANNER_UPDATE_RESET: 'BANNER_UPDATE_RESET',

	BANNER_DELETE_START: 'BANNER_DELETE_START',
	BANNER_DELETE_SUCCESS: 'BANNER_DELETE_SUCCESS',
	BANNER_DELETE_FAILURE: 'BANNER_DELETE_FAILURE',
	BANNER_DELETE_CHANGED: 'BANNER_DELETE_CHANGED',
	BANNER_DELETE_RESET: 'BANNER_DELETE_RESET',
};
export default bannerTypes;
