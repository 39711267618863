/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
const AssignedStaffTypes = {
	ASSIGNED_STAFF_DETAILS_START: 'assigned_staff_details_start',
	ASSIGNED_STAFF_DETAILS_SUCCESS: 'assigned_staff_details_success',
	ASSIGNED_STAFF_DETAILS_FAILURE: 'assigned_staff_details_failure',
	ASSIGNED_STAFF_DETAILS_RESPONSE_RESET_START: 'assigned_staff_details_response_reset_start',
	ASSIGNED_STAFF_DETAILS_RESPONSE_CHANGED: 'assigned_staff_details_response_reset_changed',

	ADD_STAFF_ITEM: 'add_staff_item',

	STAFF_TO_STAFF_ASSIGNED_START: 'staff_to_staff_assigned_start',
	STAFF_TO_STAFF_ASSIGNED_SUCCESS: 'staff_to_staff_assigned_success',
	STAFF_TO_STAFF_ASSIGNED_FAILURE: 'staff_to_staff_assigned_failure',
	STAFF_TO_STAFF_ASSIGNED_RESET: 'staff_to_staff_assigned_reset',
	STAFF_TO_STAFF_ASSIGNED_CHANGED: 'staff_to_staff_assigned_changed',

	REVERT_ASSIGNED_STAFF_START: 'REVERT_ASSIGNED_STAFF_START',
	REVERT_ASSIGNED_STAFF_SUCCESS: 'REVERT_ASSIGNED_STAFF_SUCCESS',
	REVERT_ASSIGNED_STAFF_FAILURE: 'REVERT_ASSIGNED_STAFF_FAILURE',
	REVERT_ASSIGNED_STAFF_RESET: 'REVERT_ASSIGNED_STAFF_RESET',
	REVERT_ASSIGNED_STAFF_CHANGED: 'REVERT_ASSIGNED_STAFF_CHANGED',
};
export default AssignedStaffTypes;
