/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import purchaseReportTypes from './purchaseReport.types';
import { getPurchaseHistoryList } from './purchaseReport.service';
import {
	purchaseReportListSuccess,
	purchaseReportListFailure,
	purchaseReportListChanged,
} from './purchaseReport.action';
import { encodeJson } from '../enode-decode';

/* PURCHASE REPORT LIST START */
export function* purchaseReportListDetailsStart({ payload }) {
	try {
		const key = 'GetPurchaseHistoryListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			DateFilter: payload.dateFilter,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getPurchaseHistoryList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(purchaseReportListFailure(responseData.data));
		} else {
			yield put(purchaseReportListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(purchaseReportListFailure(error));
	}
}

export function* purchaseReportListDetailsChanged() {
	yield put(purchaseReportListChanged());
}

export function* purchaseReportListDetails() {
	yield takeLatest(
		purchaseReportTypes.PURCHASE_REPORT_LIST_START,
		purchaseReportListDetailsStart,
	);
}

export function* purchaseReportListResponseReset() {
	yield takeLatest(
		purchaseReportTypes.PURCHASE_REPORT_LIST_RESET,
		purchaseReportListDetailsChanged,
	);
}
/* PURCHASE REPORT LIST END */

export function* purchaseReportSaga() {
	yield all([call(purchaseReportListDetails), call(purchaseReportListResponseReset)]);
}
