const hubTypes = {
	HUB_LIST_START: 'HUB_LIST_START',
	HUB_LIST_SUCCESS: 'HUB_LIST_SUCCESS',
	HUB_LIST_FAILURE: 'HUB_LIST_FAILURE',
	HUB_LIST_RESET: 'HUB_LIST_RESET',
	HUB_LIST_CHANGED: 'HUB_LIST_CHANGED',

	HUB_CREATE_START: 'HUB_CREATE_START',
	HUB_CREATE_SUCCESS: 'HUB_CREATE_SUCCESS',
	HUB_CREATE_FAILURE: 'HUB_CREATE_FAILURE',
	HUB_CREATE_CHANGED: 'HUB_CREATE_CHANGED',
	HUB_CREATE_RESET: 'HUB_CREATE_RESET',

	HUB_UPDATE_START: 'HUB_UPDATE_START',
	HUB_UPDATE_SUCCESS: 'HUB_UPDATE_SUCCESS',
	HUB_UPDATE_FAILURE: 'HUB_UPDATE_FAILURE',
	HUB_UPDATE_CHANGED: 'HUB_UPDATE_CHANGED',
	HUB_UPDATE_RESET: 'HUB_UPDATE_RESET',
};
export default hubTypes;
