/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { INV_API_URL } from '../const';

export const getVendorProductsList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${INV_API_URL}GetVendorProductsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getVendorViewProducts = (postListValue) => {
	const data = {
		ProductID: postListValue.ProductID,
		InventoryID: postListValue.InventoryID,
	};
	const url = `${INV_API_URL}ViewVendorProductsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
