const minicherTypes = {
	GET_MINICHER_LIST_START: 'GET_MINICHER_LIST_START',
	GET_MINICHER_LIST_SUCCESS: 'GET_MINICHER_LIST_SUCCESS',
	GET_MINICHER_LIST_FAILURE: 'GET_MINICHER_LIST_FAILURE',
	GET_MINICHER_LIST_RESET: 'GET_MINICHER_LIST_RESET',
	GET_MINICHER_LIST_CHANGED: 'GET_MINICHER_LIST_CHANGED',

	INSERT_MINICHER_START: 'INSERT_MINICHER_START',
	INSERT_MINICHER_SUCCESS: 'INSERT_MINICHER_SUCCESS',
	INSERT_MINICHER_FAILURE: 'INSERT_MINICHER_FAILURE',
	INSERT_MINICHER_RESET: 'INSERT_MINICHER_RESET',
	INSERT_MINICHER_CHANGED: 'INSERT_MINICHER_CHANGED',

	UPDATE_MINICHER_START: 'UPDATE_MINICHER_START',
	UPDATE_MINICHER_SUCCESS: 'UPDATE_MINICHER_SUCCESS',
	UPDATE_MINICHER_FAILURE: 'UPDATE_MINICHER_FAILURE',
	UPDATE_MINICHER_RESET: 'UPDATE_MINICHER_RESET',
	UPDATE_MINICHER_CHANGED: 'UPDATE_MINICHER_CHANGED',

	OFF_MINICHER_START: 'OFF_MINICHER_START',
	OFF_MINICHER_SUCCESS: 'OFF_MINICHER_SUCCESS',
	OFF_MINICHER_FAILURE: 'OFF_MINICHER_FAILURE',
	OFF_MINICHER_RESET: 'OFF_MINICHER_RESET',
	OFF_MINICHER_CHANGED: 'OFF_MINICHER_CHANGED',

	ON_MINICHER_START: 'ON_MINICHER_START',
	ON_MINICHER_SUCCESS: 'ON_MINICHER_SUCCESS',
	ON_MINICHER_FAILURE: 'ON_MINICHER_FAILURE',
	ON_MINICHER_RESET: 'ON_MINICHER_RESET',
	ON_MINICHER_CHANGED: 'ON_MINICHER_CHANGED',

	ADD_LIST_MINICHER_PRODUCT_START: 'ADD_LIST_MINICHER_PRODUCT_START',
	ADD_LIST_MINICHER_PRODUCT_SUCCESS: 'ADD_LIST_MINICHER_PRODUCT_SUCCESS',
	ADD_LIST_MINICHER_PRODUCT_FAILURE: 'ADD_LIST_MINICHER_PRODUCT_FAILURE',
	ADD_LIST_MINICHER_PRODUCT_RESET: 'ADD_LIST_MINICHER_PRODUCT_RESET',
	ADD_LIST_MINICHER_PRODUCT_CHANGED: 'ADD_LIST_MINICHER_PRODUCT_CHANGED',

	CREATE_MINICHER_PRODUCT_START: 'CREATE_MINICHER_PRODUCT_START',
	CREATE_MINICHER_PRODUCT_SUCCESS: 'CREATE_MINICHER_PRODUCT_SUCCESS',
	CREATE_MINICHER_PRODUCT_FAILURE: 'CREATE_MINICHER_PRODUCT_FAILURE',
	CREATE_MINICHER_PRODUCT_RESET: 'CREATE_MINICHER_PRODUCT_RESET',
	CREATE_MINICHER_PRODUCT_CHANGED: 'CREATE_MINICHER_PRODUCT_CHANGED',

	GET_MINICHER_PRODUCT_LIST_START: 'GET_MINICHER_PRODUCT_LIST_START',
	GET_MINICHER_PRODUCT_LIST_SUCCESS: 'GET_MINICHER_PRODUCT_LIST_SUCCESS',
	GET_MINICHER_PRODUCT_LIST_FAILURE: 'GET_MINICHER_PRODUCT_LIST_FAILURE',
	GET_MINICHER_PRODUCT_LIST_RESET: 'GET_MINICHER_PRODUCT_LIST_RESET',
	GET_MINICHER_PRODUCT_LIST_CHANGED: 'GET_MINICHER_PRODUCT_LIST_CHANGED',

	DELETE_MINICHER_PRODUCT_START: 'DELETE_MINICHER_PRODUCT_START',
	DELETE_MINICHER_PRODUCT_SUCCESS: 'DELETE_MINICHER_PRODUCT_SUCCESS',
	DELETE_MINICHER_PRODUCT_FAILURE: 'DELETE_MINICHER_PRODUCT_FAILURE',
	DELETE_MINICHER_PRODUCT_RESET: 'DELETE_MINICHER_PRODUCT_RESET',
	DELETE_MINICHER_PRODUCT_CHANGED: 'DELETE_MINICHER_PRODUCT_CHANGED',
};
export default minicherTypes;
