/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import endOfDayTypes from './EndOfDay.types';
import {
	getListEndOfDay,
	endOfDayUpdate,
	fetchEndOfDayDetails,
	viewOrderList,
	flowerOrderCancel,
	assginedToStaffOrders,
	assignedRevertOrder,
	getEodCancelOrder,
	getOrderPinCodeList,
	getEodStaffList,
} from './EndOfDay.service';
import {
	listEndOfDaySuccess,
	listEndOfDayFailure,
	listEndOfDayChanged,
	addEndOfDaySuccess,
	addEndOfDayFailure,
	addEndOfDayChanged,
	fetchEndOfDayDetailsSuccess,
	fetchEndOfDayDetailsFailure,
	fetchEndOfDayDetailsChanged,
	viewOrderDetailsDetailsSuccess,
	viewOrderDetailsDetailsFailure,
	viewOrderDetailsDetailsChanged,
	orderCancelledSuccess,
	orderCancelledFailure,
	orderCancelledChanged,
	staffAssignedSuccess,
	staffAssignedFailure,
	staffAssignedChanged,
	revertAssignedOrderSuccess,
	revertAssignedOrderFailure,
	revertAssignedOrderChanged,
	viewCancelledOrderListSuccess,
	viewCancelledOrderListFailure,
	viewCancelledOrderListChanged,
	getOrderPinCodeListSuccess,
	getOrderPinCodeListFailure,
	getOrderPinCodeListChanged,
	getEodStaffListSuccess,
	getEodStaffListFailure,
	getEodStaffListChanged,
} from './EndOfDay.action';
import { encodeJson } from '../enode-decode';

/* LIST ALL TO BE DELIVERED START */
export function* listAllToBeDeliverd({ payload }) {
	try {
		const key = 'EndOfDayListKey';
		const jsonData = {
			Date: payload.Date,
			SearchValueSet: payload.searchValueSet,
			OrderFilter: payload.orderFilter,
			PinCodeFilter: payload.pinCodeFilter,
			TypeOfTab: payload.typeOfTab,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getListEndOfDay, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(listEndOfDayFailure(responseData.data));
		} else {
			yield put(listEndOfDaySuccess(responseData.data));
		}
	} catch (error) {
		yield put(listEndOfDayFailure(error));
	}
}

export function* getListAllToBeDeliverdReset() {
	yield put(listEndOfDayChanged());
}

export function* getListAllDeliverdReset() {
	yield takeLatest(endOfDayTypes.GET_LIST_END_OF_DAY_RESET, getListAllToBeDeliverdReset);
}

export function* getListAllDeliverd() {
	yield takeLatest(endOfDayTypes.GET_LIST_END_OF_DAY_START, listAllToBeDeliverd);
}
/* LIST ALL TO BE DELIVERED END */

/* END OF DAY START */
export function* insertEndOfDay({ payload: { userId } }) {
	try {
		const responseData = yield call(endOfDayUpdate, userId);
		if (responseData.data.statusCode === '100') {
			yield put(addEndOfDayFailure(responseData.data));
		} else {
			yield put(addEndOfDaySuccess(responseData.data));
		}
	} catch (error) {
		yield put(addEndOfDayFailure(error));
	}
}

export function* insertEndOfDayReset() {
	yield put(addEndOfDayChanged());
}

export function* updateEndOfDay() {
	yield takeLatest(endOfDayTypes.ADD_END_OF_DAY_START, insertEndOfDay);
}

export function* resetEndOfDay() {
	yield takeLatest(endOfDayTypes.ADD_END_OF_DAY_RESET, insertEndOfDayReset);
}
/* END OF DAY END */

/* FETCH END OF DAY DETAILS START */
export function* getEndOfDayDetails({ payload: { listToBeDelivered } }) {
	try {
		const responseData = yield call(fetchEndOfDayDetails, listToBeDelivered);
		if (responseData.data.statusCode === '100') {
			yield put(fetchEndOfDayDetailsFailure(responseData.data));
		} else {
			yield put(fetchEndOfDayDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchEndOfDayDetailsFailure(error));
	}
}

export function* fetchEndOfDayDetailsReset() {
	yield put(fetchEndOfDayDetailsChanged());
}

export function* fetchEndOfDay() {
	yield takeLatest(endOfDayTypes.FETCH_END_OF_DAY_START, getEndOfDayDetails);
}

export function* resetFetchEndOfDay() {
	yield takeLatest(endOfDayTypes.FETCH_END_OF_DAY_RESET, fetchEndOfDayDetailsReset);
}
/* FETCH END OF DAY DETAILS END */

/* VIEW ORDER LIST START */
export function* viewOrderDetailsList({ payload }) {
	try {
		const key = 'ViewOrdersDetailsKey';
		const jsonData = {
			UserID: payload.UserID,
			OrderModeID: payload.OrderModeID,
			OrderMode: payload.OrderMode,
			OrderFromCustomerID: payload.OrderFromCustomerID,
			Date: payload.Date,
			ProductOrderPlaceID: payload.ProductOrderPlaceID,
			Secratekey: key,
		};
		const viewJson = encodeJson(jsonData, key);
		const responseData = yield call(viewOrderList, viewJson);
		if (responseData.data.statusCode === '100') {
			yield put(viewOrderDetailsDetailsFailure(responseData.data));
		} else {
			yield put(viewOrderDetailsDetailsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewOrderDetailsDetailsFailure(error));
	}
}

export function* viewOrderDetailsReset() {
	yield put(viewOrderDetailsDetailsChanged());
}

export function* viewOrderDetails() {
	yield takeLatest(endOfDayTypes.VIEW_ORDERS_LIST_START, viewOrderDetailsList);
}

export function* resetviewOrderDetails() {
	yield takeLatest(endOfDayTypes.VIEW_ORDERS_LIST_RESET, viewOrderDetailsReset);
}
/* VIEW ORDER LIST END */

/* ORDER CANCELLED START */
export function* cancelledOrdersStatus({ payload: { userID, orderModeID } }) {
	try {
		const key = 'CancelFlowerOrder';
		const jsonData = {
			UserID: userID,
			OrderModeID: orderModeID,
			Secratekey: key,
		};
		const cancelJson = encodeJson(jsonData, key);
		const responseData = yield call(flowerOrderCancel, cancelJson);
		if (responseData.data.statusCode === '100') {
			yield put(orderCancelledFailure(responseData.data));
		} else {
			yield put(orderCancelledSuccess(responseData.data));
		}
	} catch (error) {
		yield put(orderCancelledFailure(error));
	}
}

export function* restCancelledOrdersResponse() {
	yield put(orderCancelledChanged());
}

export function* onCancelledOrdersStart() {
	yield takeLatest(endOfDayTypes.ORDERS_CANCELLED_START, cancelledOrdersStatus);
}

export function* onCancelledOrdersResponse() {
	yield takeLatest(endOfDayTypes.ORDERS_CANCELLED_RESET, restCancelledOrdersResponse);
}
/* ORDER CANCELLED END */

/* STAFF ASSIGNED START */
export function* staffAssignedStatus({
	payload: { orderListDetails, deliveryDate, staffID, createdBy, assignedArr },
}) {
	try {
		const key = 'AssignedStaffKey';
		const AssignStaffOrderDetails = JSON.stringify(assignedArr).replace(/"/g, "'");
		const jsonData = {
			DeliveryDate: deliveryDate,
			NoOfDelivery: orderListDetails.NoOfDelivery,
			TotalAmount: orderListDetails.TotalAmount,
			NoOfOnlineDelivery: orderListDetails.NoOfOnlineOrders,
			NoOfCashDelivery: orderListDetails.NoOfCashOrders,
			NoOfWalletDelivery: orderListDetails.NoOfWalletOrders,
			OnlineAmount: orderListDetails.OnlineAmount,
			WalletAmount: orderListDetails.WalletAmount,
			CashAmount: orderListDetails.CashAmount,
			assignStaffOrderDetails: AssignStaffOrderDetails,
			StaffID: staffID,
			CreatedBy: createdBy,
			Secratekey: key,
		};
		const assignJson = encodeJson(jsonData, key);
		const responseData = yield call(assginedToStaffOrders, assignJson);
		if (responseData.data.statusCode === '100') {
			yield put(staffAssignedFailure(responseData.data));
		} else {
			yield put(staffAssignedSuccess(responseData.data));
		}
	} catch (error) {
		yield put(staffAssignedFailure(error));
	}
}

export function* restStaffAssignedResponse() {
	yield put(staffAssignedChanged());
}

export function* onStaffAssignedStart() {
	yield takeLatest(endOfDayTypes.STAFF_ASSIGNED_START, staffAssignedStatus);
}

export function* onStaffAssignedResponse() {
	yield takeLatest(endOfDayTypes.STAFF_ASSIGNED_RESET, restStaffAssignedResponse);
}
/* STAFF ASSIGNED END */

/* REVERT ASSIGNED STAFF START */
export function* revertAssignedOrderStatus({ payload }) {
	try {
		const key = 'RevertAssignedOrderKey';
		const jsonData = {
			OrderModeID: payload.orderID,
			StaffID: payload.staffID,
			CreatedByID: payload.createdByID,
			DeliveryDate: payload.deliveryDate,
			OrderCategory: payload.orderCategory,
			Secratekey: key,
		};
		const revertJson = encodeJson(jsonData, key);
		const responseData = yield call(assignedRevertOrder, revertJson);
		if (responseData.data.statusCode === '100') {
			yield put(revertAssignedOrderFailure(responseData.data));
		} else {
			yield put(revertAssignedOrderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(revertAssignedOrderFailure(error));
	}
}

export function* restRevertAssignedOrderResponse() {
	yield put(revertAssignedOrderChanged());
}

export function* onRevertAssignedOrderStart() {
	yield takeLatest(endOfDayTypes.REVERT_ASSIGNED_ORDER_START, revertAssignedOrderStatus);
}

export function* onRevertAssignedOrderResponse() {
	yield takeLatest(endOfDayTypes.REVERT_ASSIGNED_ORDER_RESET, restRevertAssignedOrderResponse);
}
/* REVERT ASSIGNED STAFF END */

/* GET CANCELLED ORDER LIST START */
export function* listCancelledOrder({ payload }) {
	try {
		const key = 'GetEodCancelOrderKey';
		const jsonData = {
			Date: payload.Date,
			Secratekey: key,
		};
		const viewCancelOrderListData = encodeJson(jsonData, key);
		const responseData = yield call(getEodCancelOrder, viewCancelOrderListData);
		if (responseData.data.statusCode === '100') {
			yield put(viewCancelledOrderListFailure(responseData.data));
		} else {
			yield put(viewCancelledOrderListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewCancelledOrderListFailure(error));
	}
}

export function* listCancelledOrderReset() {
	yield put(viewCancelledOrderListChanged());
}

export function* onCancelledOrderListResponse() {
	yield takeLatest(endOfDayTypes.GET_CANCELLED_ORDER_LIST_RESET, listCancelledOrderReset);
}

export function* onCancelledOrderListStart() {
	yield takeLatest(endOfDayTypes.GET_CANCELLED_ORDER_LIST_START, listCancelledOrder);
}
/* GET CANCELLED ORDER LIST END */

/* GET ORDER PINCODE LIST START */
export function* listOrderPinCode({ payload }) {
	try {
		const key = 'GetOrderPinCodeListKey';
		const jsonData = {
			Date: payload.Date,
			Secratekey: key,
		};
		const OrderPinCodeListData = encodeJson(jsonData, key);
		const responseData = yield call(getOrderPinCodeList, OrderPinCodeListData);
		if (responseData.data.statusCode === '100') {
			yield put(getOrderPinCodeListFailure(responseData.data));
		} else {
			yield put(getOrderPinCodeListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getOrderPinCodeListFailure(error));
	}
}

export function* listOrderPinCodeReset() {
	yield put(getOrderPinCodeListChanged());
}

export function* onGetOrderPinCodeListResponse() {
	yield takeLatest(endOfDayTypes.GET_ORDER_PINCODE_LIST_RESET, listOrderPinCodeReset);
}

export function* onGetOrderPinCodeListStart() {
	yield takeLatest(endOfDayTypes.GET_ORDER_PINCODE_LIST_START, listOrderPinCode);
}
/* GET ORDER PINCODE LIST END */

/* GET EOD STAFF LIST START */
export function* listEodStaff({ payload }) {
	try {
		const key = 'EodStaffListGetKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			Secratekey: key,
		};
		const EodStaffListData = encodeJson(jsonData, key);
		const responseData = yield call(getEodStaffList, EodStaffListData);
		if (responseData.data.statusCode === '100') {
			yield put(getEodStaffListFailure(responseData.data));
		} else {
			yield put(getEodStaffListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getEodStaffListFailure(error));
	}
}

export function* listEodStaffReset() {
	yield put(getEodStaffListChanged());
}

export function* onGetEodStaffListResponse() {
	yield takeLatest(endOfDayTypes.GET_EOD_STAFF_LIST_RESET, listEodStaffReset);
}

export function* onGetEodStaffListStart() {
	yield takeLatest(endOfDayTypes.GET_EOD_STAFF_LIST_START, listEodStaff);
}
/* GET EOD STAFF LIST END */

export function* listEndOfDaySaga() {
	yield all([
		call(getListAllDeliverd),
		call(getListAllDeliverdReset),
		call(updateEndOfDay),
		call(resetEndOfDay),
		call(fetchEndOfDay),
		call(resetFetchEndOfDay),
		call(viewOrderDetails),
		call(resetviewOrderDetails),
		call(onCancelledOrdersStart),
		call(onCancelledOrdersResponse),
		call(onStaffAssignedStart),
		call(onStaffAssignedResponse),
		call(onRevertAssignedOrderStart),
		call(onRevertAssignedOrderResponse),
		call(onCancelledOrderListStart),
		call(onCancelledOrderListResponse),
		call(onGetOrderPinCodeListStart),
		call(onGetOrderPinCodeListResponse),
		call(onGetEodStaffListStart),
		call(onGetEodStaffListResponse),
	]);
}
