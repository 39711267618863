const couponAddListTypes = {
	COUPON_LIST_START: 'COUPON_LIST_START',
	COUPON_LIST_SUCCESS: 'COUPON_LIST_SUCCESS',
	COUPON_LIST_FAILURE: 'COUPON_LIST_FAILURE',
	COUPON_LIST_RESET: 'COUPON_LIST_RESET',
	COUPON_LIST_CHANGED: 'COUPON_LIST_CHANGED',

	COUPON_CREATE_REPSONSE_START: 'COUPON_CREATE_REPSONSE_START',
	COUPON_CREATE_REPSONSE_SUCCESS: 'COUPON_CREATE_REPSONSE_SUCCESS',
	COUPON_CREATE_REPSONSE_FAILURE: 'COUPON_CREATE_REPSONSE_FAILURE',
	COUPON_CREATE_REPSONSE_CHANGED: 'COUPON_CREATE_REPSONSE_CHANGED',
	COUPON_CREATE_REPSONSE_RESET: 'COUPON_CREATE_REPSONSE_RESET',

	COUPON_UPDATE_REPSONSE_START: 'COUPON_UPDATE_REPSONSE_START',
	COUPON_UPDATE_REPSONSE_SUCCESS: 'COUPON_UPDATE_REPSONSE_SUCCESS',
	COUPON_UPDATE_REPSONSE_FAILURE: 'COUPON_UPDATE_REPSONSE_FAILURE',
	COUPON_UPDATE_REPSONSE_CHANGED: 'COUPON_UPDATE_REPSONSE_CHANGED',
	COUPON_UPDATE_REPSONSE_RESET: 'COUPON_UPDATE_REPSONSE_RESET',

	COUPON_OFF_REPSONSE_START: 'COUPON_OFF_REPSONSE_START',
	COUPON_OFF_REPSONSE_SUCCESS: 'COUPON_OFF_REPSONSE_SUCCESS',
	COUPON_OFF_REPSONSE_FAILURE: 'COUPON_OFF_REPSONSE_FAILURE',
	COUPON_OFF_REPSONSE_CHANGED: 'COUPON_OFF_REPSONSE_CHANGED',
	COUPON_OFF_REPSONSE_RESET: 'COUPON_OFF_REPSONSE_RESET',

	COUPON_ON_REPSONSE_START: 'COUPON_ON_REPSONSE_START',
	COUPON_ON_REPSONSE_SUCCESS: 'COUPON_ON_REPSONSE_SUCCESS',
	COUPON_ON_REPSONSE_FAILURE: 'COUPON_ON_REPSONSE_FAILURE',
	COUPON_ON_REPSONSE_CHANGED: 'COUPON_ON_REPSONSE_CHANGED',
	COUPON_ON_REPSONSE_RESET: 'COUPON_ON_REPSONSE_RESET',
};
export default couponAddListTypes;
