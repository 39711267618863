const festivalTypes = {
	FESTIVAL_LIST_START: 'FESTIVAL_LIST_START',
	FESTIVAL_LIST_SUCCESS: 'FESTIVAL_LIST_SUCCESS',
	FESTIVAL_LIST_FAILURE: 'FESTIVAL_LIST_FAILURE',
	FESTIVAL_LIST_RESET: 'FESTIVAL_LIST_RESET',
	FESTIVAL_LIST_CHANGED: 'FESTIVAL_LIST_CHANGED',

	INSERT_FESTIVAL_LIST_START: 'INSERT_FESTIVAL_LIST_START',
	INSERT_FESTIVAL_LIST_SUCCESS: 'INSERT_FESTIVAL_LIST_SUCCESS',
	INSERT_FESTIVAL_LIST_FAILURE: 'INSERT_FESTIVAL_LIST_FAILURE',
	INSERT_FESTIVAL_LIST_RESET: 'INSERT_FESTIVAL_LIST_RESET',
	INSERT_FESTIVAL_LIST_CHANGED: 'INSERT_FESTIVAL_LIST_CHANGED',

	UPDATE_FESTIVAL_LIST_START: 'UPDATE_FESTIVAL_LIST_START',
	UPDATE_FESTIVAL_LIST_SUCCESS: 'UPDATE_FESTIVAL_LIST_SUCCESS',
	UPDATE_FESTIVAL_LIST_FAILURE: 'UPDATE_FESTIVAL_LIST_FAILURE',
	UPDATE_FESTIVAL_LIST_RESET: 'UPDATE_FESTIVAL_LIST_RESET',
	UPDATE_FESTIVAL_LIST_CHANGED: 'UPDATE_FESTIVAL_LIST_CHANGED',

	DELETE_FESTIVAL_LIST_START: 'DELETE_FESTIVAL_LIST_START',
	DELETE_FESTIVAL_LIST_SUCCESS: 'DELETE_FESTIVAL_LIST_SUCCESS',
	DELETE_FESTIVAL_LIST_FAILURE: 'DELETE_FESTIVAL_LIST_FAILURE',
	DELETE_FESTIVAL_LIST_RESET: 'DELETE_FESTIVAL_LIST_RESET',
	DELETE_FESTIVAL_LIST_CHANGED: 'DELETE_FESTIVAL_LIST_CHANGED',

	PRODUCT_LIST_START: 'PRODUCT_LIST_START',
	PRODUCT_LIST_SUCCESS: 'PRODUCT_LIST_SUCCESS',
	PRODUCT_LIST_FAILURE: 'PRODUCT_LIST_FAILURE',
	PRODUCT_LIST_RESET: 'PRODUCT_LIST_RESET',
	PRODUCT_LIST_CHANGED: 'PRODUCT_LIST_CHANGED',

	CREATE_COMBO_PRODUCT_START: 'CREATE_COMBO_PRODUCT_START',
	CREATE_COMBO_PRODUCT_SUCCESS: 'CREATE_COMBO_PRODUCT_SUCCESS',
	CREATE_COMBO_PRODUCT_FAILURE: 'CREATE_COMBO_PRODUCT_FAILURE',
	CREATE_COMBO_PRODUCT_RESET: 'CREATE_COMBO_PRODUCT_RESET',
	CREATE_COMBO_PRODUCT_CHANGED: 'CREATE_COMBO_PRODUCT_CHANGED',

	COMBO_PRODUCT_LIST_START: 'COMBO_PRODUCT_LIST_START',
	COMBO_PRODUCT_LIST_SUCCESS: 'COMBO_PRODUCT_LIST_SUCCESS',
	COMBO_PRODUCT_LIST_FAILURE: 'COMBO_PRODUCT_LIST_FAILURE',
	COMBO_PRODUCT_LIST_RESET: 'COMBO_PRODUCT_LIST_RESET',
	COMBO_PRODUCT_LIST_CHANGED: 'COMBO_PRODUCT_LIST_CHANGED',

	DELETE_COMBO_START: 'DELETE_COMBO_START',
	DELETE_COMBO_SUCCESS: 'DELETE_COMBO_SUCCESS',
	DELETE_COMBO_FAILURE: 'DELETE_COMBO_FAILURE',
	DELETE_COMBO_RESET: 'DELETE_COMBO_RESET',
	DELETE_COMBO_CHANGED: 'DELETE_COMBO_CHANGED',
};
export default festivalTypes;
