/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import productComboTypes from './productCombo.types';

const INITIAL_STATE = {
	productComboResponse: null,
	productComboStatus: null,
	insertProductComboResponse: null,
	insertProductComboStatus: null,
	editProductComboResponse: null,
	editProductComboStatus: null,
	offProductComboResponse: null,
	offProductComboStatus: null,
	onProductComboResponse: null,
	onProductComboStatus: null,
	addListProductResponse: null,
	addListProductStatus: null,
	createEthicProductResponse: null,
	createEthicProductStatus: null,
	getEthicProductListResponse: null,
	getEthicProductListStatus: null,
	deleteEthicProductsResponse: null,
	deleteEthicProductsStatus: null,
};

const productComboReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case productComboTypes.GET_PRODUCT_COMBO_LIST_SUCCESS:
			return {
				...state,
				productComboResponse: action.payload,
				productComboStatus: 'Success',
			};

		case productComboTypes.GET_PRODUCT_COMBO_LIST_FAILURE:
			return {
				...state,
				productComboResponse: action.payload,
				productComboStatus: 'Failure',
			};

		case productComboTypes.GET_PRODUCT_COMBO_LIST_CHANGED:
			return { ...state, productComboResponse: null, productComboStatus: null };

		case productComboTypes.INSERT_PRODUCT_COMBO_SUCCESS:
			return {
				...state,
				insertProductComboResponse: action.payload,
				insertProductComboStatus: 'Success',
			};
		case productComboTypes.INSERT_PRODUCT_COMBO_FAILURE:
			return {
				...state,
				insertProductComboResponse: action.payload,
				insertProductComboStatus: 'Failure',
			};
		case productComboTypes.INSERT_PRODUCT_COMBO_CHANGED:
			return { ...state, insertProductComboResponse: null, insertProductComboStatus: null };

		case productComboTypes.UPDATE_PRODUCT_COMBO_SUCCESS:
			return {
				...state,
				editProductComboResponse: action.payload,
				editProductComboStatus: 'Success',
			};
		case productComboTypes.UPDATE_PRODUCT_COMBO_FAILURE:
			return {
				...state,
				editProductComboResponse: action.payload,
				editProductComboStatus: 'Failure',
			};
		case productComboTypes.UPDATE_PRODUCT_COMBO_CHANGED:
			return { ...state, editProductComboResponse: null, editProductComboStatus: null };

		case productComboTypes.OFF_PRODUCT_COMBO_SUCCESS:
			return {
				...state,
				offProductComboResponse: action.payload,
				offProductComboStatus: 'Success',
			};
		case productComboTypes.OFF_PRODUCT_COMBO_FAILURE:
			return {
				...state,
				offProductComboResponse: action.payload,
				offProductComboStatus: 'Failure',
			};
		case productComboTypes.OFF_PRODUCT_COMBO_CHANGED:
			return { ...state, offProductComboResponse: null, offProductComboStatus: null };

		case productComboTypes.ON_PRODUCT_COMBO_SUCCESS:
			return {
				...state,
				onProductComboResponse: action.payload,
				onProductComboStatus: 'Success',
			};
		case productComboTypes.ON_PRODUCT_COMBO_FAILURE:
			return {
				...state,
				onProductComboResponse: action.payload,
				onProductComboStatus: 'Failure',
			};
		case productComboTypes.ON_PRODUCT_COMBO_CHANGED:
			return { ...state, onProductComboResponse: null, onProductComboStatus: null };

		case productComboTypes.ADD_LIST_PRODUCT_SUCCESS:
			return {
				...state,
				addListProductResponse: action.payload,
				addListProductStatus: 'Success',
			};
		case productComboTypes.ADD_LIST_PRODUCT_FAILURE:
			return {
				...state,
				addListProductResponse: action.payload,
				addListProductStatus: 'Failure',
			};
		case productComboTypes.ADD_LIST_PRODUCT_CHANGED:
			return { ...state, addListProductResponse: null, addListProductStatus: null };

		case productComboTypes.CREATE_ETHIC_PRODUCT_SUCCESS:
			return {
				...state,
				createEthicProductResponse: action.payload,
				createEthicProductStatus: 'Success',
			};
		case productComboTypes.CREATE_ETHIC_PRODUCT_FAILURE:
			return {
				...state,
				createEthicProductResponse: action.payload,
				createEthicProductStatus: 'Failure',
			};
		case productComboTypes.CREATE_ETHIC_PRODUCT_CHANGED:
			return { ...state, createEthicProductResponse: null, createEthicProductStatus: null };

		case productComboTypes.GET_ETHIC_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				getEthicProductListResponse: action.payload,
				getEthicProductListStatus: 'Success',
			};
		case productComboTypes.GET_ETHIC_PRODUCT_LIST_FAILURE:
			return {
				...state,
				getEthicProductListResponse: action.payload,
				getEthicProductListStatus: 'Failure',
			};
		case productComboTypes.GET_ETHIC_PRODUCT_LIST_CHANGED:
			return { ...state, getEthicProductListResponse: null, getEthicProductListStatus: null };

		case productComboTypes.DELETE_ETHIC_PRODUCT_SUCCESS:
			return {
				...state,
				deleteEthicProductsResponse: action.payload,
				deleteEthicProductsStatus: 'Success',
			};
		case productComboTypes.DELETE_ETHIC_PRODUCT_FAILURE:
			return {
				...state,
				deleteEthicProductsResponse: action.payload,
				deleteEthicProductsStatus: 'Failure',
			};
		case productComboTypes.DELETE_ETHIC_PRODUCT_CHANGED:
			return { ...state, deleteEthicProductsResponse: null, deleteEthicProductsStatus: null };

		default:
			return state;
	}
};

export default productComboReducer;
