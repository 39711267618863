/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import reconciliationTypes from './reconciliation.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	reconciliationResponse: null,
	reconciliationList: [],
	updateTransactionStatusResp: null,
};

const reconciliationReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case reconciliationTypes.RECONCILIATION_LIST_SUCCESS:
			return {
				...state,
				reconciliationResponse: action.payload,
				reconciliationList: decodeApiResponse(action.payload, 'TransactionStatusGetKey'),
			};

		case reconciliationTypes.RECONCILIATION_LIST_FAILURE:
			return {
				...state,
				reconciliationResponse: action.payload,
			};

		case reconciliationTypes.RECONCILIATION_LIST_CHANGED:
			return { ...state, reconciliationResponse: null };

		case reconciliationTypes.UPDATE_TRANSACTION_STATUS_SUCCESS:
			return { ...state, updateTransactionStatusResp: action.payload };

		case reconciliationTypes.UPDATE_TRANSACTION_STATUS_FAILURE:
			return { ...state, updateTransactionStatusResp: action.payload };

		case reconciliationTypes.UPDATE_TRANSACTION_STATUS_CHANGED:
			return { ...state, updateTransactionStatusResp: null };

		default:
			return state;
	}
};

export default reconciliationReducer;
