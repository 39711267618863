const orderListTypes = {
	ORDER_LIST_START: 'ORDER_LIST_START',
	ORDER_LIST_SUCCESS: 'ORDER_LIST_SUCCESS',
	ORDER_LIST_FAILURE: 'ORDER_LIST_FAILURE',
	ORDER_LIST_RESET: 'ORDER_LIST_RESET',
	ORDER_LIST_CHANGED: 'ORDER_LIST_CHANGED',

	VIEW_ORDER_LIST_START: 'VIEW_ORDER_LIST_START',
	VIEW_ORDER_LIST_SUCCESS: 'VIEW_ORDER_LIST_SUCCESS',
	VIEW_ORDER_LIST_FAILURE: 'VIEW_ORDER_LIST_FAILURE',
	VIEW_ORDER_LIST_RESET: 'VIEW_ORDER_LIST_RESET',
	VIEW_ORDER_LIST_CHANGED: 'VIEW_ORDER_LIST_CHANGED',
};
export default orderListTypes;
