/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import walletCustomersTypes from './walletCustomers.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	walletCustomersResponse: null,
	walletCustomersResponseArr: [],
	updateWalletCustomerResp: null,
};

const walletCustomersReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case walletCustomersTypes.WALLET_CUSTOMERS_LIST_SUCCESS:
			return {
				...state,
				walletCustomersResponse: action.payload,
				walletCustomersResponseArr: docodeResponse(
					action.payload,
					'GetWalletCustomerListKey',
				),
			};

		case walletCustomersTypes.WALLET_CUSTOMERS_LIST_FAILURE:
			return {
				...state,
				walletCustomersResponse: action.payload,
				walletCustomersResponseArr: [],
			};

		case walletCustomersTypes.WALLET_CUSTOMERS_LIST_CHANGED:
			return { ...state, walletCustomersResponse: null };

		case walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_SUCCESS:
			return {
				...state,
				updateWalletCustomerResp: action.payload,
			};

		case walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_FAILURE:
			return {
				...state,
				updateWalletCustomerResp: action.payload,
			};

		case walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_CHANGED:
			return { ...state, updateWalletCustomerResp: null };

		default:
			return state;
	}
};

export default walletCustomersReducer;
