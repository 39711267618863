/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import orderAssignTypes from './OrderAssign.types';
import { getPincodeNewList, pincodeWiseOrderList } from './OrderAssign.service';
import {
	getPincodeNewListSuccess,
	getPincodeNewListFailure,
	getPincodeNewListChanged,
	pincodeWiseOrderListSuccess,
	pincodeWiseOrderListFailure,
	pincodeWiseOrderListChanged,
} from './OrderAssign.action';
import { encodeJson } from '../enode-decode';

/* PINCODE ORDER LIST NEW START */
export function* newPincodeList({ payload }) {
	try {
		const key = 'GetPincodeNewListKey';
		const jsonData = {
			Date: payload.sigleDate,
			BranchID: payload.branchID,
			SearchValueSet: payload.searchValueSet,
			OrderFilter: payload.orderFilter,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getPincodeNewList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(getPincodeNewListFailure(responseData.data));
		} else {
			yield put(getPincodeNewListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getPincodeNewListFailure(error));
	}
}

export function* newPincodeListReset() {
	yield put(getPincodeNewListChanged());
}

export function* getPincodeNewListAssignReset() {
	yield takeLatest(
		orderAssignTypes.GET_PINCODE_NEW_LIST_RESPONSE_RESET_START,
		newPincodeListReset,
	);
}

export function* getPincodeNewListAssign() {
	yield takeLatest(orderAssignTypes.GET_PINCODE_NEW_LIST_START, newPincodeList);
}
/* PINCODE ORDER LIST NEW END */

/* PINCODE WISE ORDER LIST START */
export function* orderListPincodeWise({ payload }) {
	try {
		const key = 'PincodeWiseOrderListKey';
		const jsonData = {
			Date: payload.sigleDate,
			SearchValueSet: payload.searchValueSet,
			Pincode: payload.pincode,
			BranchID: payload.branchID,
			OrderFilter: payload.orderFilter,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(pincodeWiseOrderList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(pincodeWiseOrderListFailure(responseData.data));
		} else {
			yield put(pincodeWiseOrderListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(pincodeWiseOrderListFailure(error));
	}
}

export function* orderListPincodeWiseReset() {
	yield put(pincodeWiseOrderListChanged());
}

export function* onPincodeWiseOrderListReset() {
	yield takeLatest(
		orderAssignTypes.PINCODE_WISE_ORDER_LIST_RESPONSE_RESET_START,
		orderListPincodeWiseReset,
	);
}

export function* onPincodeWiseOrderList() {
	yield takeLatest(orderAssignTypes.PINCODE_WISE_ORDER_LIST_START, orderListPincodeWise);
}
/* PINCODE WISE ORDER LIST END */

export function* orderAssignSaga() {
	yield all([
		call(getPincodeNewListAssign),
		call(getPincodeNewListAssignReset),
		call(onPincodeWiseOrderList),
		call(onPincodeWiseOrderListReset),
	]);
}
