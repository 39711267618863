const ruleAddListTypes = {
	RULE_LIST_START: 'RULE_LIST_START',
	RULE_LIST_SUCCESS: 'RULE_LIST_SUCCESS',
	RULE_LIST_FAILURE: 'RULE_LIST_FAILURE',
	RULE_LIST_RESET: 'RULE_LIST_RESET',
	RULE_LIST_CHANGED: 'RULE_LIST_CHANGED',

	RULE_CREATE_REPSONSE_START: 'RULE_CREATE_REPSONSE_START',
	RULE_CREATE_REPSONSE_SUCCESS: 'RULE_CREATE_REPSONSE_SUCCESS',
	RULE_CREATE_REPSONSE_FAILURE: 'RULE_CREATE_REPSONSE_FAILURE',
	RULE_CREATE_REPSONSE_CHANGED: 'RULE_CREATE_REPSONSE_CHANGED',
	RULE_CREATE_REPSONSE_RESET: 'RULE_CREATE_REPSONSE_RESET',

	RULE_UPDATE_REPSONSE_START: 'RULE_UPDATE_REPSONSE_START',
	RULE_UPDATE_REPSONSE_SUCCESS: 'RULE_UPDATE_REPSONSE_SUCCESS',
	RULE_UPDATE_REPSONSE_FAILURE: 'RULE_UPDATE_REPSONSE_FAILURE',
	RULE_UPDATE_REPSONSE_CHANGED: 'RULE_UPDATE_REPSONSE_CHANGED',
	RULE_UPDATE_REPSONSE_RESET: 'RULE_UPDATE_REPSONSE_RESET',
};
export default ruleAddListTypes;
