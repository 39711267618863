/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const getAssignedStaffDetails = (fetchData) => {
	const data = {
		FetchAssignedStaffDetailsToken: fetchData,
	};
	const url = `${API_URL}fetchAssignedStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const assginedStaffToStaff = (fetchData) => {
	const data = {
		AssignedStaffToStaffToken: fetchData,
	};
	const url = `${API_URL}AssginedStaffToStaff`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const assignedRevertStaff = (fetchData) => {
	const data = {
		AssignedRevertStaffToken: fetchData,
	};
	const url = `${API_URL}AssignedRevertStaff`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
