// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/namespace */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable default-param-last */
import placeOrderType from './placeOrder.types';
import {
	docodeResponse,
	updateCategoryProductOnCartList,
	addProductCategoryItemToCart,
	updateAddItemCatetoryProductList,
	removeProductCategoryItemFromCart,
	updateRemoveCatetoryProductList,
	updateCartItemProductList,
} from '../common/common.utils';

const INITIAL_STATE = {
	productListResp: null,
	productAllResponse: null,
	productListAllArr: [],
	userLoginCheckResponse: null,
	userLoginCheckArr: [],
	otpVerifyResponse: null,
	otpVerifyArr: [],
	listProductArr: [],
	placeOrderCartArr: [],
	addAddressResponse: null,
	placeOrderResponse: null,
	orderLocationAddress: null,
	orderLocationID: null,
	fetchBackEndArr: [],
	fetchBackEndResponse: null,
};

const placeOrderReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case placeOrderType.LIST_PRODUCT_SUCCESS:
			return {
				...state,
				productListResp: action.payload,
				listProductArr: docodeResponse(action.payload, 'GetAllCategoryProducts'),
			};

		case placeOrderType.LIST_PRODUCT_FAILURE:
			return {
				...state,
				productListResp: action.payload,
				listProductArr: [],
			};

		case placeOrderType.LIST_PRODUCT_CHANGED:
			return { ...state, productListResp: null };

		/** *** PRODUCT LIST ***** */

		case placeOrderType.ALL_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				productAllResponse: action.payload,
				productListAllArr: docodeResponse(action.payload, 'GetDahboardProductsKey'),
			};

		case placeOrderType.ALL_PRODUCT_LIST_FAILURE:
			return {
				...state,
				productAllResponse: action.payload,
				productListAllArr: [],
			};

		case placeOrderType.ALL_PRODUCT_LIST_CHANGED:
			return { ...state, productAllResponse: null };

		/** *** PRODUCT LIST ALL END ***** */

		case placeOrderType.USER_LOGIN_CHECK_SUCCESS:
			return {
				...state,
				userLoginCheckResponse: action.payload,
				userLoginCheckArr: docodeResponse(action.payload, 'UserLogin'),
			};

		case placeOrderType.USER_LOGIN_CHECK_FAILURE:
			return {
				...state,
				userLoginCheckResponse: action.payload,
				userLoginCheckArr: [],
			};

		case placeOrderType.USER_LOGIN_CHECK_CHANGED:
			return { ...state, userLoginCheckResponse: null };

		/** *** USER LOGN CHECK ***** */

		case placeOrderType.OTP_VERIFIY_SUCCESS:
			return {
				...state,
				otpVerifyResponse: action.payload,
				otpVerifyArr: docodeResponse(action.payload, 'LoginOtpVerify'),
			};

		case placeOrderType.OTP_VERIFIY_FAILURE:
			return {
				...state,
				otpVerifyResponse: action.payload,
				otpVerifyArr: [],
			};

		case placeOrderType.OTP_VERIFIY_CHANGED:
			return { ...state, otpVerifyResponse: null };

		/** *** OTP VERIFY ***** */

		case placeOrderType.ADD_ADDRESS_SUCCESS:
			return {
				...state,
				addAddressResponse: action.payload,
			};

		case placeOrderType.ADD_ADDRESS_FAILURE:
			return {
				...state,
				addAddressResponse: action.payload,
			};

		case placeOrderType.ADD_ADDRESS_CHANGED:
			return { ...state, addAddressResponse: null };

		/** *** ADD ADDRESS END ***** */

		case placeOrderType.PLACE_ORDER_SUCCESS:
			return {
				...state,
				placeOrderResponse: action.payload,
				placeOrderCartArr: [],
			};

		case placeOrderType.PLACE_ORDER_FAILURE:
			return {
				...state,
				placeOrderResponse: action.payload,
			};

		case placeOrderType.PLACE_ORDER_CHANGED:
			return { ...state, placeOrderResponse: null };

		/** *** PLACE ORDER END ***** */

		case placeOrderType.UPDATE_CATEGORY_PRODUCT_LIST:
			return {
				...state,
				placeOrderCartArr: updateCategoryProductOnCartList(
					state.placeOrderCartArr,
					action.payload,
				),
				listProductArr: updateCategoryProductOnCartList(
					state.listProductArr,
					action.payload,
				),
			};

		case placeOrderType.ADD_PRODUSCT_CATEGORY_CART_ITEM:
			return {
				...state,
				placeOrderCartArr: addProductCategoryItemToCart(
					state.placeOrderCartArr,
					action.payload,
				),
				listProductArr: updateAddItemCatetoryProductList(
					state.listProductArr,
					action.payload,
				),
			};

		case placeOrderType.REMOVE_PRODUSCT_CATEGORY_CART_ITEM:
			return {
				...state,
				placeOrderCartArr: removeProductCategoryItemFromCart(
					state.placeOrderCartArr,
					action.payload,
				),
				listProductArr: updateRemoveCatetoryProductList(
					state.listProductArr,
					action.payload,
				),
			};

		case placeOrderType.UPDATE_PRODUCT_LIST_CATEGORY:
			return {
				...state,
				listProductArr: updateCartItemProductList(state.listProductArr, action.payload),
			};

		case placeOrderType.ORDER_LOCATION_ADDRESS_UPDATE:
			return {
				...state,
				userLoginCheckArr: action.payload,
			};

		case placeOrderType.GET_ALL_PINCODE_LIST_SUCCESS:
			return {
				...state,
				fetchBackEndResponse: action.payload,
				fetchBackEndArr: docodeResponse(action.payload, 'AllBranchPincodeKey'),
			};

		case placeOrderType.GET_ALL_PINCODE_LIST_FAILURE:
			return {
				...state,
				fetchBackEndResponse: action.payload,
				fetchBackEndArr: [],
			};

		case placeOrderType.GET_ALL_PINCODE_LIST_CHANGED:
			return {
				...state,
				fetchBackEndResponse: null,
			};

		default:
			return state;
	}
};

export default placeOrderReducer;
