/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import pincodeTypes from './pincode.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	pincodeListResponse: null,
	pincodeListArr: [],
	pincodeCreateResponse: null,
	pincodeUpdateResponse: null,
	pincodeDeleteResponse: null,
};

const pincodeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case pincodeTypes.PINCODE_LIST_SUCCESS:
			return {
				...state,
				pincodeListResponse: action.payload,
				pincodeListArr: docodeResponse(action.payload, 'PincodeListKey'),
			};

		case pincodeTypes.PINCODE_LIST_FAILURE:
			return {
				...state,
				pincodeListResponse: action.payload,
				pincodeListArr: [],
			};

		case pincodeTypes.PINCODE_LIST_CHANGED:
			return { ...state, pincodeListResponse: null };

		case pincodeTypes.PINCODE_CREATE_REPSONSE_SUCCESS:
			return { ...state, pincodeCreateResponse: action.payload };
		case pincodeTypes.PINCODE_CREATE_REPSONSE_FAILURE:
			return { ...state, pincodeCreateResponse: action.payload };
		case pincodeTypes.PINCODE_CREATE_REPSONSE_CHANGED:
			return { ...state, pincodeCreateResponse: null };

		case pincodeTypes.PINCODE_UPDATE_REPSONSE_SUCCESS:
			return { ...state, pincodeUpdateResponse: action.payload };
		case pincodeTypes.PINCODE_UPDATE_REPSONSE_FAILURE:
			return { ...state, pincodeUpdateResponse: action.payload };
		case pincodeTypes.PINCODE_UPDATE_REPSONSE_CHANGED:
			return { ...state, pincodeUpdateResponse: null };

		case pincodeTypes.PINCODE_DELETE_REPSONSE_SUCCESS:
			return { ...state, pincodeDeleteResponse: action.payload };
		case pincodeTypes.PINCODE_DELETE_REPSONSE_FAILURE:
			return { ...state, pincodeDeleteResponse: action.payload };
		case pincodeTypes.PINCODE_DELETE_REPSONSE_CHANGED:
			return { ...state, pincodeDeleteResponse: null };

		default:
			return state;
	}
};

export default pincodeReducer;
