const endOfDayTypes = {
	GET_LIST_END_OF_DAY_START: 'GET_LIST_END_OF_DAY_START',
	GET_LIST_END_OF_DAY_SUCCESS: 'GET_LIST_END_OF_DAY_SUCCESS',
	GET_LIST_END_OF_DAY_FAILURE: 'GET_LIST_END_OF_DAY_FAILURE',
	GET_LIST_END_OF_DAY_RESET: 'GET_LIST_END_OF_DAY_RESET',
	GET_LIST_END_OF_DAY_CHANGED: 'GET_LIST_END_OF_DAY_CHANGED',

	ADD_END_OF_DAY_START: 'ADD_END_OF_DAY_START',
	ADD_END_OF_DAY_SUCCESS: 'ADD_END_OF_DAY_SUCCESS',
	ADD_END_OF_DAY_FAILURE: 'ADD_END_OF_DAY_FAILURE',
	ADD_END_OF_DAY_RESET: 'ADD_END_OF_DAY_RESET',
	ADD_END_OF_DAY_CHANGED: 'ADD_END_OF_DAY_CHANGED',

	FETCH_END_OF_DAY_START: 'FETCH_END_OF_DAY_START',
	FETCH_END_OF_DAY_SUCCESS: 'FETCH_END_OF_DAY_SUCCESS',
	FETCH_END_OF_DAY_FAILURE: 'FETCH_END_OF_DAY_FAILURE',
	FETCH_END_OF_DAY_RESET: 'FETCH_END_OF_DAY_RESET',
	FETCH_END_OF_DAY_CHANGED: 'FETCH_END_OF_DAY_CHANGED',

	VIEW_ORDERS_LIST_START: 'VIEW_ORDERS_LIST_START',
	VIEW_ORDERS_LIST_SUCCESS: 'VIEW_ORDERS_LIST_SUCCESS',
	VIEW_ORDERS_LIST_FAILURE: 'VIEW_ORDERS_LIST_FAILURE',
	VIEW_ORDERS_LIST_RESET: 'VIEW_ORDERS_LIST_RESET',
	VIEW_ORDERS_LIST_CHANGED: 'VIEW_ORDERS_LIST_CHANGED',

	ORDERS_CANCELLED_START: 'ORDERS_CANCELLED_START',
	ORDERS_CANCELLED_SUCCESS: 'ORDERS_CANCELLED_SUCCESS',
	ORDERS_CANCELLED_FAILURE: 'ORDERS_CANCELLED_FAILURE',
	ORDERS_CANCELLED_RESET: 'ORDERS_CANCELLED_RESET',
	ORDERS_CANCELLED_CHANGED: 'ORDERS_CANCELLED_CHANGED',

	ADD_ORDER_ITEM: 'ADD_ORDER_ITEM',

	STAFF_ASSIGNED_START: 'STAFF_ASSIGNED_START',
	STAFF_ASSIGNED_SUCCESS: 'STAFF_ASSIGNED_SUCCESS',
	STAFF_ASSIGNED_FAILURE: 'STAFF_ASSIGNED_FAILURE',
	STAFF_ASSIGNED_RESET: 'STAFF_ASSIGNED_RESET',
	STAFF_ASSIGNED_CHANGED: 'STAFF_ASSIGNED_CHANGED',

	REVERT_ASSIGNED_ORDER_START: 'REVERT_ASSIGNED_ORDER_START',
	REVERT_ASSIGNED_ORDER_SUCCESS: 'REVERT_ASSIGNED_ORDER_SUCCESS',
	REVERT_ASSIGNED_ORDER_FAILURE: 'REVERT_ASSIGNED_ORDER_FAILURE',
	REVERT_ASSIGNED_ORDER_RESET: 'REVERT_ASSIGNED_ORDER_RESET',
	REVERT_ASSIGNED_ORDER_CHANGED: 'REVERT_ASSIGNED_ORDER_CHANGED',

	GET_CANCELLED_ORDER_LIST_START: 'GET_CANCELLED_ORDER_LIST_START',
	GET_CANCELLED_ORDER_LIST_SUCCESS: 'GET_CANCELLED_ORDER_LIST_SUCCESS',
	GET_CANCELLED_ORDER_LIST_FAILURE: 'GET_CANCELLED_ORDER_LIST_FAILURE',
	GET_CANCELLED_ORDER_LIST_RESET: 'GET_CANCELLED_ORDER_LIST_RESET',
	GET_CANCELLED_ORDER_LIST_CHANGED: 'GET_CANCELLED_ORDER_LIST_CHANGED',

	GET_ORDER_PINCODE_LIST_START: 'GET_ORDER_PINCODE_LIST_START',
	GET_ORDER_PINCODE_LIST_SUCCESS: 'GET_ORDER_PINCODE_LIST_SUCCESS',
	GET_ORDER_PINCODE_LIST_FAILURE: 'GET_ORDER_PINCODE_LIST_FAILURE',
	GET_ORDER_PINCODE_LIST_RESET: 'GET_ORDER_PINCODE_LIST_RESET',
	GET_ORDER_PINCODE_LIST_CHANGED: 'GET_ORDER_PINCODE_LIST_CHANGED',

	GET_EOD_STAFF_LIST_START: 'GET_EOD_STAFF_LIST_START',
	GET_EOD_STAFF_LIST_SUCCESS: 'GET_EOD_STAFF_LIST_SUCCESS',
	GET_EOD_STAFF_LIST_FAILURE: 'GET_EOD_STAFF_LIST_FAILURE',
	GET_EOD_STAFF_LIST_RESET: 'GET_EOD_STAFF_LIST_RESET',
	GET_EOD_STAFF_LIST_CHANGED: 'GET_EOD_STAFF_LIST_CHANGED',
};
export default endOfDayTypes;
