const flowerOrderTypes = {
	FLOWER_ORDER_LIST_START: 'FLOWER_ORDER_LIST_START',
	FLOWER_ORDER_LIST_SUCCESS: 'FLOWER_ORDER_LIST_SUCCESS',
	FLOWER_ORDER_LIST_FAILURE: 'FLOWER_ORDER_LIST_FAILURE',
	FLOWER_ORDER_LIST_RESET: 'FLOWER_ORDER_LIST_RESET',
	FLOWER_ORDER_LIST_CHANGED: 'FLOWER_ORDER_LIST_CHANGED',

	VIEW_FLOWER_ORDER_LIST_START: 'VIEW_FLOWER_ORDER_LIST_START',
	VIEW_FLOWER_ORDER_LIST_SUCCESS: 'VIEW_FLOWER_ORDER_LIST_SUCCESS',
	VIEW_FLOWER_ORDER_LIST_FAILURE: 'VIEW_FLOWER_ORDER_LIST_FAILURE',
	VIEW_FLOWER_ORDER_LIST_RESET: 'VIEW_FLOWER_ORDER_LIST_RESET',
	VIEW_FLOWER_ORDER_LIST_CHANGED: 'VIEW_FLOWER_ORDER_LIST_CHANGED',

	VIEW_FLOWER_TRANSACTIONS_START: 'VIEW_FLOWER_TRANSACTIONS_START',
	VIEW_FLOWER_TRANSACTIONS_SUCCESS: 'VIEW_FLOWER_TRANSACTIONS_SUCCESS',
	VIEW_FLOWER_TRANSACTIONS_FAILURE: 'VIEW_FLOWER_TRANSACTIONS_FAILURE',
	VIEW_FLOWER_TRANSACTIONS_RESET: 'VIEW_FLOWER_TRANSACTIONS_RESET',
	VIEW_FLOWER_TRANSACTIONS_CHANGED: 'VIEW_FLOWER_TRANSACTIONS_CHANGED',
};
export default flowerOrderTypes;
