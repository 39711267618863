/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import AssignedStaffTypes from './assigned-staff.types';
import { docodeResponse } from '../common/common.utils';
import { addStaffItem } from './assigned-staff.utils';

const INITIAL_STATE = {
	fetchAssignedStaffDetailsResponse: null,
	fetchAssignedStaffDetailsArr: [],
	staffToStaffAssginedResponse: null,
	revertAssignedStaffResponse: null,
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_SUCCESS:
			return {
				...state,
				fetchAssignedStaffDetailsResponse: action.payload,
				fetchAssignedStaffDetailsArr: docodeResponse(
					action.payload,
					'FetchAssignedStaffDetailsKey',
				),
			};

		case AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_FAILURE:
			return {
				...state,
				fetchAssignedStaffDetailsResponse: action.payload,
				fetchAssignedStaffDetailsArr: [],
			};

		case AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchAssignedStaffDetailsResponse: null };

		case AssignedStaffTypes.ADD_STAFF_ITEM:
			return {
				...state,
				fetchAssignedStaffDetailsArr: addStaffItem(
					state.fetchAssignedStaffDetailsArr,
					action.payload,
				),
			};

		case AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_SUCCESS:
			return { ...state, staffToStaffAssginedResponse: action.payload };

		case AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_FAILURE:
			return { ...state, staffToStaffAssginedResponse: null };

		case AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_CHANGED:
			return { ...state, staffToStaffAssginedResponse: null };

		case AssignedStaffTypes.REVERT_ASSIGNED_STAFF_SUCCESS:
			return { ...state, revertAssignedStaffResponse: action.payload };

		case AssignedStaffTypes.REVERT_ASSIGNED_STAFF_FAILURE:
			return { ...state, revertAssignedStaffResponse: null };

		case AssignedStaffTypes.REVERT_ASSIGNED_STAFF_CHANGED:
			return { ...state, revertAssignedStaffResponse: null };

		default:
			return state;
	}
};
