const purchaseOrderTypes = {
	PURCHASE_ORDER_LIST_START: 'PURCHASE_ORDER_LIST_START',
	PURCHASE_ORDER_LIST_SUCCESS: 'PURCHASE_ORDER_LIST_SUCCESS',
	PURCHASE_ORDER_LIST_FAILURE: 'PURCHASE_ORDER_LIST_FAILURE',
	PURCHASE_ORDER_LIST_RESET: 'PURCHASE_ORDER_LIST_RESET',
	PURCHASE_ORDER_LIST_CHANGED: 'PURCHASE_ORDER_LIST_CHANGED',

	UPDATE_PURCHASE_ORDER_STATUS_START: 'UPDATE_PURCHASE_ORDER_STATUS_START',
	UPDATE_PURCHASE_ORDER_STATUS_SUCCESS: 'UPDATE_PURCHASE_ORDER_STATUS_SUCCESS',
	UPDATE_PURCHASE_ORDER_STATUS_FAILURE: 'UPDATE_PURCHASE_ORDER_STATUS_FAILURE',
	UPDATE_PURCHASE_ORDER_STATUS_RESET: 'UPDATE_PURCHASE_ORDER_STATUS_RESET',
	UPDATE_PURCHASE_ORDER_STATUS_CHANGED: 'UPDATE_PURCHASE_ORDER_STATUS_CHANGED',

	VIEW_PO_PRODUCTS_LIST_START: 'VIEW_PO_PRODUCTS_LIST_START',
	VIEW_PO_PRODUCTS_LIST_SUCCESS: 'VIEW_PO_PRODUCTS_LIST_SUCCESS',
	VIEW_PO_PRODUCTS_LIST_FAILURE: 'VIEW_PO_PRODUCTS_LIST_FAILURE',
	VIEW_PO_PRODUCTS_LIST_RESET: 'VIEW_PO_PRODUCTS_LIST_RESET',
	VIEW_PO_PRODUCTS_LIST_CHANGED: 'VIEW_PO_PRODUCTS_LIST_CHANGED',
};
export default purchaseOrderTypes;
