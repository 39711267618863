/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import festivalTypes from './festival.types';

/* FESTIVAL LIST START */
export const festivalListStart = (festivalListDetails) => ({
	type: festivalTypes.FESTIVAL_LIST_START,
	payload: festivalListDetails,
});

export const festivalListSuccess = (festivalSuccess) => ({
	type: festivalTypes.FESTIVAL_LIST_SUCCESS,
	payload: festivalSuccess,
});

export const festivalListFailure = (error) => ({
	type: festivalTypes.FESTIVAL_LIST_FAILURE,
	payload: error,
});

export const festivalListChanged = () => ({
	type: festivalTypes.FESTIVAL_LIST_CHANGED,
});

export const festivalListReset = () => ({
	type: festivalTypes.FESTIVAL_LIST_RESET,
});
/* FESTIVAL LIST END */

/* INSERT FESTIVAL COMBO START */
export const insertFestivalComboStart = (festivalListDetails) => ({
	type: festivalTypes.INSERT_FESTIVAL_LIST_START,
	payload: festivalListDetails,
});

export const insertFestivalComboSuccess = (festivalSuccess) => ({
	type: festivalTypes.INSERT_FESTIVAL_LIST_SUCCESS,
	payload: festivalSuccess,
});

export const insertFestivalComboFailure = (error) => ({
	type: festivalTypes.INSERT_FESTIVAL_LIST_FAILURE,
	payload: error,
});

export const insertFestivalComboChanged = () => ({
	type: festivalTypes.INSERT_FESTIVAL_LIST_CHANGED,
});

export const insertFestivalComboReset = () => ({
	type: festivalTypes.INSERT_FESTIVAL_LIST_RESET,
});
/* INSERT FESTIVAL COMBO END */

/* UPDATE FESTIVAL COMBO START */
export const editFestivalComboStart = (festivalListDetails) => ({
	type: festivalTypes.UPDATE_FESTIVAL_LIST_START,
	payload: festivalListDetails,
});

export const editFestivalComboSuccess = (festivalSuccess) => ({
	type: festivalTypes.UPDATE_FESTIVAL_LIST_SUCCESS,
	payload: festivalSuccess,
});

export const editFestivalComboFailure = (error) => ({
	type: festivalTypes.UPDATE_FESTIVAL_LIST_FAILURE,
	payload: error,
});

export const editFestivalComboChanged = () => ({
	type: festivalTypes.UPDATE_FESTIVAL_LIST_CHANGED,
});

export const editFestivalComboReset = () => ({
	type: festivalTypes.UPDATE_FESTIVAL_LIST_RESET,
});
/* UPDATE FESTIVAL COMBO END */

/* DELETE FESTIVAL COMBO START */
export const removeFestivalComboStart = (festivalListDetails) => ({
	type: festivalTypes.DELETE_FESTIVAL_LIST_START,
	payload: festivalListDetails,
});

export const removeFestivalComboSuccess = (festivalSuccess) => ({
	type: festivalTypes.DELETE_FESTIVAL_LIST_SUCCESS,
	payload: festivalSuccess,
});

export const removeFestivalComboFailure = (error) => ({
	type: festivalTypes.DELETE_FESTIVAL_LIST_FAILURE,
	payload: error,
});

export const removeFestivalComboChanged = () => ({
	type: festivalTypes.DELETE_FESTIVAL_LIST_CHANGED,
});

export const removeFestivalComboReset = () => ({
	type: festivalTypes.DELETE_FESTIVAL_LIST_RESET,
});
/* DELETE FESTIVAL COMBO END */

/* PRODUCT LIST START */
export const productListStart = (festivalListDetails) => ({
	type: festivalTypes.PRODUCT_LIST_START,
	payload: festivalListDetails,
});

export const productListSuccess = (festivalSuccess) => ({
	type: festivalTypes.PRODUCT_LIST_SUCCESS,
	payload: festivalSuccess,
});

export const productListFailure = (error) => ({
	type: festivalTypes.PRODUCT_LIST_FAILURE,
	payload: error,
});

export const productListChanged = () => ({
	type: festivalTypes.PRODUCT_LIST_CHANGED,
});

export const productListReset = () => ({
	type: festivalTypes.PRODUCT_LIST_RESET,
});
/* PRODUCT LIST END */

/* CREATE COMBO PRODUCT START */
export const createComboProductStart = (festivalListDetails) => ({
	type: festivalTypes.CREATE_COMBO_PRODUCT_START,
	payload: festivalListDetails,
});

export const createComboProductSuccess = (festivalSuccess) => ({
	type: festivalTypes.CREATE_COMBO_PRODUCT_SUCCESS,
	payload: festivalSuccess,
});

export const createComboProductFailure = (error) => ({
	type: festivalTypes.CREATE_COMBO_PRODUCT_FAILURE,
	payload: error,
});

export const createComboProductChanged = () => ({
	type: festivalTypes.CREATE_COMBO_PRODUCT_CHANGED,
});

export const createComboProductReset = () => ({
	type: festivalTypes.CREATE_COMBO_PRODUCT_RESET,
});
/* CREATE COMBO PRODUCT END */

/* COMBO PRODUCTS LIST START */
export const listComboProductStart = (festivalListDetails) => ({
	type: festivalTypes.COMBO_PRODUCT_LIST_START,
	payload: festivalListDetails,
});

export const listComboProductSuccess = (festivalSuccess) => ({
	type: festivalTypes.COMBO_PRODUCT_LIST_SUCCESS,
	payload: festivalSuccess,
});

export const listComboProductFailure = (error) => ({
	type: festivalTypes.COMBO_PRODUCT_LIST_FAILURE,
	payload: error,
});

export const listComboProductChanged = () => ({
	type: festivalTypes.COMBO_PRODUCT_LIST_CHANGED,
});

export const listComboProductReset = () => ({
	type: festivalTypes.COMBO_PRODUCT_LIST_RESET,
});
/* COMBO PRODUCTS LIST END */

/* DELETE COMBO PRODUCT START */
export const deleteComboStart = (festivalListDetails) => ({
	type: festivalTypes.DELETE_COMBO_START,
	payload: festivalListDetails,
});

export const deleteComboSuccess = (festivalSuccess) => ({
	type: festivalTypes.DELETE_COMBO_SUCCESS,
	payload: festivalSuccess,
});

export const deleteComboFailure = (error) => ({
	type: festivalTypes.DELETE_COMBO_FAILURE,
	payload: error,
});

export const deleteComboChanged = () => ({
	type: festivalTypes.DELETE_COMBO_CHANGED,
});

export const deleteComboReset = () => ({
	type: festivalTypes.DELETE_COMBO_RESET,
});
/* DELETE COMBO PRODUCT END */
