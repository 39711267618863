// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
import { all, call } from 'redux-saga/effects';

import { loginSaga } from './login/login.sagas';
import { deliveryListSaga } from './deliveryProcess/deliveryProcess.sagas';
import { listEndOfDaySaga } from './EndOfDay/EndOfDay.sagas';
import { dashBoardListSaga } from './dashboard/DashBoard.sagas';
import { priceUpdateSaga } from './priceUpdate/priceUpdate.sagas';
import { purchaseListSaga } from './PurchaseList/purchaseList.sagas';
import { listOfCustomersSaga } from './ListOfCustomer/listOfCustomer.sagas';
import { otpSaga } from './OtpList/otpList.sagas';
import { todayDeliveredSaga } from './TodayDelivered/todayDelivered.sagas';
import { staffListSaga } from './staff/staffList.sagas';
import { walletCustomersSaga } from './walletCustomers/walletCustomers.sagas';
import { comboListSaga } from './Combo/comboList.sagas';
import { festivalListSaga } from './Festival/festival.sagas';
import { productListSaga } from './AddProduct/addProduct.sagas';
import { productOrdersSaga } from './ProductOrder/productOrder.sagas';
import { stockListSaga } from './StockList/stockList.sagas';
import { productComboSaga } from './ProductCombo/productCombo.sagas';
import { couponListSaga } from './Coupon/couponAddList.sagas';
import { transcationSaga } from './Transcation/transcationList.sagas';
import { vendorListSaga } from './vendor/vendor.sagas';
import { inventoryListSaga } from './CreateInventory/createInventory.sagas';
import { bannerListSaga } from './Banner/banner.sagas';
import { vendorProductsListSaga } from './ProductsDealsByVendor/productsDealsByVendor.sagas';
import { flowerOrdersSaga } from './FlowerOrder/flowerOrder.sagas';
import { flowersListSaga } from './AddFlowers/addFlowers.sagas';
import { ruleListSaga } from './Rule/ruleAddList.sagas';
import { repackedListSaga } from './RePacked/rePacked.sagas';
import { hubListSaga } from './AddHub/addHub.sagas';
import { purchaseOrderListSaga } from './PurchaseOrder/purchaseOrder.sagas';
import { collectionsSaga } from './Collections/collections.sagas';
import { orderListSaga } from './OrderList/orderList.sagas';
import { minicherSaga } from './AddMinicher/addMinicher.sagas';
import { reconciliationSaga } from './Reconciliation/reconciliation.sagas';
import { refundSaga } from './Refund/refund.sagas';
import { productSaga } from './PlaceOrder/placeOrder.saga';
import { salesSaga } from './sales-report/sales-report.saga';
import { pushNotificationSaga } from './PushNotification/pushNotification.sagas';
import { customerDashboardSaga } from './CustomerDashboard/customerDashboard.saga';
import { staffReportSaga } from './staff-report/staff-report.saga';
import { orderReportSaga } from './OrderReport/orderReport.saga';
import { assignedStaffSaga } from './assigned-staff/assigned-staff.saga';
import { todayEodsSaga } from './today-eods/today-eods.saga';
import { branchDetailsSaga } from './Branch/branch.saga';
import { eodNewSaga } from './EndOfDayNew/EndOfDayNew.sagas';
import { orderAssignSaga } from './OrderAssign/OrderAssign.sagas';
import { priceUpdateAllProductsSaga } from './PriceUpdateAllProducts/priceUpdateAllProducts.sagas';
import { purchaseReportSaga } from './PurchaseReport/purchaseReport.sagas';
import { packingReportSaga } from './PackingReport/packingReport.sagas';
import { pincodeSaga } from './pincode/pincode.sagas';
import { dashboardCategorySaga } from './dashboardCategory/dashboardCategory.sagas';

export default function* rootSaga() {
	yield all([
		call(loginSaga),
		call(deliveryListSaga),
		call(listEndOfDaySaga),
		call(dashBoardListSaga),
		call(priceUpdateSaga),
		call(purchaseListSaga),
		call(listOfCustomersSaga),
		call(otpSaga),
		call(todayDeliveredSaga),
		call(staffListSaga),
		call(walletCustomersSaga),
		call(comboListSaga),
		call(festivalListSaga),
		call(productListSaga),
		call(productOrdersSaga),
		call(stockListSaga),
		call(productComboSaga),
		call(couponListSaga),
		call(transcationSaga),
		call(vendorListSaga),
		call(inventoryListSaga),
		call(bannerListSaga),
		call(vendorProductsListSaga),
		call(flowerOrdersSaga),
		call(flowersListSaga),
		call(ruleListSaga),
		call(repackedListSaga),
		call(hubListSaga),
		call(purchaseOrderListSaga),
		call(collectionsSaga),
		call(orderListSaga),
		call(minicherSaga),
		call(reconciliationSaga),
		call(refundSaga),
		call(productSaga),
		call(salesSaga),
		call(pushNotificationSaga),
		call(customerDashboardSaga),
		call(staffReportSaga),
		call(orderReportSaga),
		call(assignedStaffSaga),
		call(todayEodsSaga),
		call(branchDetailsSaga),
		call(eodNewSaga),
		call(orderAssignSaga),
		call(priceUpdateAllProductsSaga),
		call(purchaseReportSaga),
		call(packingReportSaga),
		call(pincodeSaga),
		call(dashboardCategorySaga),
	]);
}
