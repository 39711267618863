/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import vendorProductsTypes from './productsDealsByVendor.types';
import { getVendorProductsList, getVendorViewProducts } from './productsDealsByVendor.service';
import {
	vendorProductsListSuccess,
	vendorProductsListFailure,
	vendorProductsListChanged,
	vendorViewProductsSuccess,
	vendorViewProductsFailure,
	vendorViewProductsChanged,
} from './productsDealsByVendor.action';

/* VENDOR PRODUCTS LIST START */
export function* vendorProductsListDetailsStart({ payload: { listOfVendor } }) {
	try {
		const responseData = yield call(getVendorProductsList, listOfVendor);
		if (responseData.data.statusCode === '100') {
			yield put(vendorProductsListFailure(responseData.data));
		} else {
			yield put(vendorProductsListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(vendorProductsListFailure(error));
	}
}

export function* vendorProductsListDetailsChanged() {
	yield put(vendorProductsListChanged());
}

export function* vendorProductsListDetails() {
	yield takeLatest(
		vendorProductsTypes.VENDOR_PRODUCTS_LIST_START,
		vendorProductsListDetailsStart,
	);
}

export function* vendorProductsListResponseReset() {
	yield takeLatest(
		vendorProductsTypes.VENDOR_PRODUCTS_LIST_RESET,
		vendorProductsListDetailsChanged,
	);
}
/* VENDOR PRODUCTS LIST END */

/* VENDOR VIEW PRODUCTS LIST START */
export function* vendorViewProductsDetailsStart({ payload: { vendorProductView } }) {
	try {
		const responseData = yield call(getVendorViewProducts, vendorProductView);
		if (responseData.data.statusCode === '100') {
			yield put(vendorViewProductsFailure(responseData.data));
		} else {
			yield put(vendorViewProductsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(vendorViewProductsFailure(error));
	}
}

export function* vendorViewProductsDetailsChanged() {
	yield put(vendorViewProductsChanged());
}

export function* vendorViewProductsDetails() {
	yield takeLatest(
		vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_START,
		vendorViewProductsDetailsStart,
	);
}

export function* vendorViewProductsResponseReset() {
	yield takeLatest(
		vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_RESET,
		vendorViewProductsDetailsChanged,
	);
}
/* VENDOR VIEW PRODUCTS LIST END */

export function* vendorProductsListSaga() {
	yield all([
		call(vendorProductsListDetails),
		call(vendorProductsListResponseReset),
		call(vendorViewProductsDetails),
		call(vendorViewProductsResponseReset),
	]);
}
