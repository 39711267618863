/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import couponAddListTypes from './couponAddList.types';

/* COUPON LIST START */
export const couponListStart = (couponDetails) => ({
	type: couponAddListTypes.COUPON_LIST_START,
	payload: couponDetails,
});

export const couponListSuccess = (couponSuccess) => ({
	type: couponAddListTypes.COUPON_LIST_SUCCESS,
	payload: couponSuccess,
});

export const couponListFailure = (error) => ({
	type: couponAddListTypes.COUPON_LIST_FAILURE,
	payload: error,
});

export const couponListChanged = () => ({
	type: couponAddListTypes.COUPON_LIST_CHANGED,
});

export const couponListReset = () => ({
	type: couponAddListTypes.COUPON_LIST_RESET,
});
/* COUPON LIST END */

/* COUPON CREATE START */
export const createCouponStart = (couponDetails) => ({
	type: couponAddListTypes.COUPON_CREATE_REPSONSE_START,
	payload: couponDetails,
});

export const createCouponSuccess = (couponSuccess) => ({
	type: couponAddListTypes.COUPON_CREATE_REPSONSE_SUCCESS,
	payload: couponSuccess,
});

export const createCouponFailure = (error) => ({
	type: couponAddListTypes.COUPON_CREATE_REPSONSE_FAILURE,
	payload: error,
});

export const createCouponChanged = () => ({
	type: couponAddListTypes.COUPON_CREATE_REPSONSE_CHANGED,
});

export const createCouponReset = () => ({
	type: couponAddListTypes.COUPON_CREATE_REPSONSE_RESET,
});
/* COUPON CREATE END */

/* COUPON UPDATE START */
export const updateCouponStart = (couponDetails) => ({
	type: couponAddListTypes.COUPON_UPDATE_REPSONSE_START,
	payload: couponDetails,
});

export const updateCouponSuccess = (couponSuccess) => ({
	type: couponAddListTypes.COUPON_UPDATE_REPSONSE_SUCCESS,
	payload: couponSuccess,
});

export const updateCouponFailure = (error) => ({
	type: couponAddListTypes.COUPON_UPDATE_REPSONSE_FAILURE,
	payload: error,
});

export const updateCouponChanged = () => ({
	type: couponAddListTypes.COUPON_UPDATE_REPSONSE_CHANGED,
});

export const updateCouponReset = () => ({
	type: couponAddListTypes.COUPON_UPDATE_REPSONSE_RESET,
});
/* COUPON UPDATE END */

/* COUPON OFF START */
export const offCouponStart = (couponDetails) => ({
	type: couponAddListTypes.COUPON_OFF_REPSONSE_START,
	payload: couponDetails,
});

export const offCouponSuccess = (couponSuccess) => ({
	type: couponAddListTypes.COUPON_OFF_REPSONSE_SUCCESS,
	payload: couponSuccess,
});

export const offCouponFailure = (error) => ({
	type: couponAddListTypes.COUPON_OFF_REPSONSE_FAILURE,
	payload: error,
});

export const offCouponChanged = () => ({
	type: couponAddListTypes.COUPON_OFF_REPSONSE_CHANGED,
});

export const offCouponReset = () => ({
	type: couponAddListTypes.COUPON_OFF_REPSONSE_RESET,
});
/* COUPON OFF END */

/* COUPON ON START */
export const onCouponStart = (couponDetails) => ({
	type: couponAddListTypes.COUPON_ON_REPSONSE_START,
	payload: couponDetails,
});

export const onCouponSuccess = (couponSuccess) => ({
	type: couponAddListTypes.COUPON_ON_REPSONSE_SUCCESS,
	payload: couponSuccess,
});

export const onCouponFailure = (error) => ({
	type: couponAddListTypes.COUPON_ON_REPSONSE_FAILURE,
	payload: error,
});

export const onCouponChanged = () => ({
	type: couponAddListTypes.COUPON_ON_REPSONSE_CHANGED,
});

export const onCouponReset = () => ({
	type: couponAddListTypes.COUPON_ON_REPSONSE_RESET,
});
/* COUPON ON END */
