/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getDashboardOrderList = (fetchData) => {
	const data = {
		GetDashboardListToken: fetchData,
	};
	// const url = `${API_URL}GetDashboardList`;
	const url = `${API_URL}GetDashboardListNew`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getListViewDashboard = (fetchData) => {
	const data = {
		GetDashboardViewToken: fetchData,
	};
	// const url = `${API_URL}GetViewDashboardList`;
	const url = `${API_URL}GetViewDashboardNewList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getListCustomerDashboard = (fetchData) => {
	const data = {
		GetDashboardCustomerToken: fetchData,
	};
	const url = `${API_URL}GetCustomerDashboardList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getSalesOrderDetails = (fetchData) => {
	const data = {
		FetchOrderSalesDetailsData: fetchData,
	};
	const url = `${API_URL}fetchOrderSalesGraphDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getTotalOnlineCreatedList = (fetchData) => {
	const data = {
		GetTotalOnlineCreatedListData: fetchData,
	};
	const url = `${API_URL}GetTotalOnlineCreatedList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const dashboardReconciliationUpdate = (fetchData) => {
	const data = {
		DashboardReconciliationUpdateData: fetchData,
	};
	const url = `${API_URL}DashboardReconciliationUpdate`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
