const inventoryTypes = {
	INVENTORY_LIST_START: 'INVENTORY_LIST_START',
	INVENTORY_LIST_SUCCESS: 'INVENTORY_LIST_SUCCESS',
	INVENTORY_LIST_FAILURE: 'INVENTORY_LIST_FAILURE',
	INVENTORY_LIST_RESET: 'INVENTORY_LIST_RESET',
	INVENTORY_LIST_CHANGED: 'INVENTORY_LIST_CHANGED',

	INVENTORY_CREATE_START: 'INVENTORY_CREATE_START',
	INVENTORY_CREATE_SUCCESS: 'INVENTORY_CREATE_SUCCESS',
	INVENTORY_CREATE_FAILURE: 'INVENTORY_CREATE_FAILURE',
	INVENTORY_CREATE_CHANGED: 'INVENTORY_CREATE_CHANGED',
	INVENTORY_CREATE_RESET: 'INVENTORY_CREATE_RESET',

	INVENTORY_UPDATE_START: 'INVENTORY_UPDATE_START',
	INVENTORY_UPDATE_SUCCESS: 'INVENTORY_UPDATE_SUCCESS',
	INVENTORY_UPDATE_FAILURE: 'INVENTORY_UPDATE_FAILURE',
	INVENTORY_UPDATE_CHANGED: 'INVENTORY_UPDATE_CHANGED',
	INVENTORY_UPDATE_RESET: 'INVENTORY_UPDATE_RESET',

	INVENTORY_DELETE_START: 'INVENTORY_DELETE_START',
	INVENTORY_DELETE_SUCCESS: 'INVENTORY_DELETE_SUCCESS',
	INVENTORY_DELETE_FAILURE: 'INVENTORY_DELETE_FAILURE',
	INVENTORY_DELETE_CHANGED: 'INVENTORY_DELETE_CHANGED',
	INVENTORY_DELETE_RESET: 'INVENTORY_DELETE_RESET',

	INVENTORY_VENDOR_LIST_START: 'INVENTORY_VENDOR_LIST_START',
	INVENTORY_VENDOR_LIST_SUCCESS: 'INVENTORY_VENDOR_LIST_SUCCESS',
	INVENTORY_VENDOR_LIST_FAILURE: 'INVENTORY_VENDOR_LIST_FAILURE',
	INVENTORY_VENDOR_LIST_CHANGED: 'INVENTORY_VENDOR_LIST_CHANGED',
	INVENTORY_VENDOR_LIST_RESET: 'INVENTORY_VENDOR_LIST_RESET',

	INVENTORY_PRODUCT_LIST_START: 'INVENTORY_PRODUCT_LIST_START',
	INVENTORY_PRODUCT_LIST_SUCCESS: 'INVENTORY_PRODUCT_LIST_SUCCESS',
	INVENTORY_PRODUCT_LIST_FAILURE: 'INVENTORY_PRODUCT_LIST_FAILURE',
	INVENTORY_PRODUCT_LIST_CHANGED: 'INVENTORY_PRODUCT_LIST_CHANGED',
	INVENTORY_PRODUCT_LIST_RESET: 'INVENTORY_PRODUCT_LIST_RESET',

	ADD_INVENTORY_OPTIONS_START: 'ADD_INVENTORY_OPTIONS_START',
	ADD_INVENTORY_OPTIONS_SUCCESS: 'ADD_INVENTORY_OPTIONS_SUCCESS',
	ADD_INVENTORY_OPTIONS_FAILURE: 'ADD_INVENTORY_OPTIONS_FAILURE',
	ADD_INVENTORY_OPTIONS_RESET: 'ADD_INVENTORY_OPTIONS_RESET',
	ADD_INVENTORY_OPTIONS_CHANGED: 'ADD_INVENTORY_OPTIONS_CHANGED',

	GET_INVENTORY_OPTIONS_LIST_START: 'GET_INVENTORY_OPTIONS_LIST_START',
	GET_INVENTORY_OPTIONS_LIST_SUCCESS: 'GET_INVENTORY_OPTIONS_LIST_SUCCESS',
	GET_INVENTORY_OPTIONS_LIST_FAILURE: 'GET_INVENTORY_OPTIONS_LIST_FAILURE',
	GET_INVENTORY_OPTIONS_LIST_RESET: 'GET_INVENTORY_OPTIONS_LIST_RESET',
	GET_INVENTORY_OPTIONS_LIST_CHANGED: 'GET_INVENTORY_OPTIONS_LIST_CHANGED',

	REMOVE_INVENTORY_OPTIONS_START: 'REMOVE_INVENTORY_OPTIONS_START',
	REMOVE_INVENTORY_OPTIONS_SUCCESS: 'REMOVE_INVENTORY_OPTIONS_SUCCESS',
	REMOVE_INVENTORY_OPTIONS_FAILURE: 'REMOVE_INVENTORY_OPTIONS_FAILURE',
	REMOVE_INVENTORY_OPTIONS_RESET: 'REMOVE_INVENTORY_OPTIONS_RESET',
	REMOVE_INVENTORY_OPTIONS_CHANGED: 'REMOVE_INVENTORY_OPTIONS_CHANGED',
};
export default inventoryTypes;
