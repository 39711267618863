/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
const SalesReportTypes = {
	FETCH_SALES_DETAILS_START: 'fetch_sales_report_start',
	FETCH_SALES_DETAILS_SUCCESS: 'fetch_sales_report_success',
	FETCH_SALES_DETAILS_FAILURE: 'fetch_sales_report_failure',
	FETCH_SALES_DETAILS_RESPONSE_RESET_START: 'fetch_sales_report_response_reset_start',
	FETCH_SALES_DETAILS_RESPONSE_CHANGED: 'fetch_sales_report_response_reset_changed',

	FETCH_PRODUCT_SALES_DETAILS_START: 'fetch_product_sales_report_start',
	FETCH_PRODUCT_SALES_DETAILS_SUCCESS: 'fetch_product_sales_report_success',
	FETCH_PRODUCT_SALES_DETAILS_FAILURE: 'fetch_product_sales_report_failure',
	FETCH_PRODUCT_SALES_DETAILS_RESPONSE_RESET_START: 'fetch_product_sales_report_response_reset_start',
	FETCH_PRODUCT_SALES_DETAILS_RESPONSE_CHANGED: 'fetch_product_sales_report_response_reset_changed',
};
export default SalesReportTypes;
