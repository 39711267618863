/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import vendorTypes from './vendor.types';

/* VENDOR LIST START */
export const vendorListStart = (vendorDetails) => ({
	type: vendorTypes.VENDOR_LIST_START,
	payload: vendorDetails,
});

export const vendorListSuccess = (vendorSuccess) => ({
	type: vendorTypes.VENDOR_LIST_SUCCESS,
	payload: vendorSuccess,
});

export const vendorListFailure = (error) => ({
	type: vendorTypes.VENDOR_LIST_FAILURE,
	payload: error,
});

export const vendorListChanged = () => ({
	type: vendorTypes.VENDOR_LIST_CHANGED,
});

export const vendorListReset = () => ({
	type: vendorTypes.VENDOR_LIST_RESET,
});

export const createVendorStart = (vendorDetails) => ({
	type: vendorTypes.VENDOR_CREATE_START,
	payload: vendorDetails,
});

export const createVendorSuccess = (vendorSuccess) => ({
	type: vendorTypes.VENDOR_CREATE_SUCCESS,
	payload: vendorSuccess,
});

export const createVendorFailure = (error) => ({
	type: vendorTypes.VENDOR_CREATE_FAILURE,
	payload: error,
});

export const createVendorChanged = () => ({
	type: vendorTypes.VENDOR_CREATE_CHANGED,
});

export const createVendorReset = () => ({
	type: vendorTypes.VENDOR_CREATE_RESET,
});

export const updateVendorStart = (vendorDetails) => ({
	type: vendorTypes.VENDOR_UPDATE_START,
	payload: vendorDetails,
});

export const updateVendorSuccess = (vendorSuccess) => ({
	type: vendorTypes.VENDOR_UPDATE_SUCCESS,
	payload: vendorSuccess,
});

export const updateVendorFailure = (error) => ({
	type: vendorTypes.VENDOR_UPDATE_FAILURE,
	payload: error,
});

export const updateVendorChanged = () => ({
	type: vendorTypes.VENDOR_UPDATE_CHANGED,
});

export const updateVendorReset = () => ({
	type: vendorTypes.VENDOR_UPDATE_RESET,
});

export const deleteVendorStart = (vendorDetails) => ({
	type: vendorTypes.VENDOR_DELETE_START,
	payload: vendorDetails,
});

export const deleteVendorSuccess = (vendorSuccess) => ({
	type: vendorTypes.VENDOR_DELETE_SUCCESS,
	payload: vendorSuccess,
});

export const deleteVendorFailure = (error) => ({
	type: vendorTypes.VENDOR_DELETE_FAILURE,
	payload: error,
});

export const deleteVendorChanged = () => ({
	type: vendorTypes.VENDOR_DELETE_CHANGED,
});

export const deleteVendorReset = () => ({
	type: vendorTypes.VENDOR_DELETE_RESET,
});

export const vendorStateListStart = (vendorDetails) => ({
	type: vendorTypes.VENDOR_STATE_LIST_START,
	payload: vendorDetails,
});

export const vendorStateListSuccess = (vendorSuccess) => ({
	type: vendorTypes.VENDOR_STATE_LIST_SUCCESS,
	payload: vendorSuccess,
});

export const vendorStateListFailure = (error) => ({
	type: vendorTypes.VENDOR_STATE_LIST_FAILURE,
	payload: error,
});

export const vendorStateListChanged = () => ({
	type: vendorTypes.VENDOR_STATE_LIST_CHANGED,
});

export const vendorStateListReset = () => ({
	type: vendorTypes.VENDOR_STATE_LIST_RESET,
});

export const vendorCityListStart = (vendorDetails) => ({
	type: vendorTypes.VENDOR_CITY_LIST_START,
	payload: vendorDetails,
});

export const vendorCityListSuccess = (vendorSuccess) => ({
	type: vendorTypes.VENDOR_CITY_LIST_SUCCESS,
	payload: vendorSuccess,
});

export const vendorCityListFailure = (error) => ({
	type: vendorTypes.VENDOR_CITY_LIST_FAILURE,
	payload: error,
});

export const vendorCityListChanged = () => ({
	type: vendorTypes.VENDOR_CITY_LIST_CHANGED,
});

export const vendorCityListReset = () => ({
	type: vendorTypes.VENDOR_CITY_LIST_RESET,
});
/* VENDOR LIST END */
