/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import flowerOrderTypes from './flowerOrder.types';
import generalTypesAction from '../genera.types';
import {
	getFlowerOrderList,
	viewFlowerOrderList,
	viewFlowerTransactionsList,
} from './flowerOrder.service';
import {
	flowerOrderSuccess,
	flowerOrderFailure,
	flowerOrderChanged,
	viewFlowerOrderSuccess,
	viewFlowerOrderFailure,
	viewFlowerOrderChanged,
	viewFlowerTransactionsSuccess,
	viewFlowerTransactionsFailure,
	viewFlowerTransactionsChanged,
} from './flowerOrder.action';
import { encodeJson } from '../enode-decode';

/* FLOWER  ORDER LIST START */
export function* listOrderFlower({ payload: { allFlowerOrderList } }) {
	try {
		const responseData = yield call(getFlowerOrderList, allFlowerOrderList);
		if (responseData.data.statusCode === '100') {
			yield put(flowerOrderFailure(responseData.data));
		} else {
			yield put(flowerOrderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(flowerOrderFailure(error));
	}
}

export function* listOrderFlowerReset() {
	yield put(flowerOrderChanged());
}

export function* getFlowerListReset() {
	yield takeLatest(flowerOrderTypes.FLOWER_ORDER_LIST_RESET, listOrderFlowerReset);
}

export function* getFlowerList() {
	yield takeLatest(flowerOrderTypes.FLOWER_ORDER_LIST_START, listOrderFlower);
}
/* FLOWER  ORDER LIST END */

/* VIEW FLOWER ORDER LIST START */
export function* flowerOrderListView({ payload: { flowerOrderView } }) {
	try {
		const responseData = yield call(viewFlowerOrderList, flowerOrderView);
		if (responseData.data.statusCode === '100') {
			yield put(viewFlowerOrderFailure(responseData.data));
		} else {
			yield put(viewFlowerOrderSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewFlowerOrderFailure(error));
	}
}

export function* flowerOrderListViewReset() {
	yield put(viewFlowerOrderChanged());
}

export function* viewFlowerOrderDetails() {
	yield takeLatest(flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_START, flowerOrderListView);
}

export function* resetFlowerOrderDetails() {
	yield takeLatest(flowerOrderTypes.VIEW_FLOWER_ORDER_LIST_RESET, flowerOrderListViewReset);
}
/* VIEW FLOWER ORDER LIST END */

/* VIEW FLOWER TRANSACTIONS START */
export function* flowerTransactionsListView({ payload: { flowersTranscationViewData } }) {
	try {
		const jsonData = {
			UserID: flowersTranscationViewData.UserID,
			OrderFromCustomerID: flowersTranscationViewData.OrderFromCustomerID,
			FromDate: flowersTranscationViewData.FromDate,
			ToDate: flowersTranscationViewData.ToDate,
		};
		const ViewFlowersTransactionsData = encodeJson(
			jsonData,
			generalTypesAction.FLOWERSTRANSACTIONS,
		);
		const responseData = yield call(viewFlowerTransactionsList, ViewFlowersTransactionsData);
		if (responseData.data.statusCode === '100') {
			yield put(viewFlowerTransactionsFailure(responseData.data));
		} else {
			yield put(viewFlowerTransactionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewFlowerTransactionsFailure(error));
	}
}

export function* flowerTransactionsListViewReset() {
	yield put(viewFlowerTransactionsChanged());
}

export function* viewFlowerTransactionsDetails() {
	yield takeLatest(flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_START, flowerTransactionsListView);
}

export function* resetViewFlowerTransactionsDetails() {
	yield takeLatest(
		flowerOrderTypes.VIEW_FLOWER_TRANSACTIONS_RESET,
		flowerTransactionsListViewReset,
	);
}
/* VIEW FLOWER TRANSACTIONS END */

export function* flowerOrdersSaga() {
	yield all([
		call(getFlowerList),
		call(getFlowerListReset),
		call(viewFlowerOrderDetails),
		call(resetFlowerOrderDetails),
		call(viewFlowerTransactionsDetails),
		call(resetViewFlowerTransactionsDetails),
	]);
}
