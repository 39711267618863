/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import reconciliationTypes from './reconciliation.types';
import { getUserTransactionStatusList, transactionStatusUpdate } from './reconciliation.service';
import {
	reconciliationSuccess,
	reconciliationFailure,
	reconciliationChanged,
	updateTransactionSuccess,
	updateTransactionFailure,
	updateTransactionChanged,
} from './reconciliation.action';
import { encodeJson } from '../enode-decode';

/* RECONCILIATION FLOWER LIST START */
export function* listReconciliation({ payload: { allReconcillationList } }) {
	try {
		const key = 'TransactionStatusGetKey';
		const jsonData = {
			TransactionStatus: allReconcillationList.activeTab,
			FromDate: allReconcillationList.FromDate,
			ToDate: allReconcillationList.ToDate,
			SearchValueSet: allReconcillationList.searchValueSet,
			Secratekey: key,
		};
		const ServiceName = allReconcillationList.serviceName;
		const reconciliationData = encodeJson(jsonData, key);
		const responseData = yield call(
			getUserTransactionStatusList,
			reconciliationData,
			ServiceName,
		);
		if (responseData.data.statusCode === '100') {
			yield put(reconciliationFailure(responseData.data));
		} else {
			yield put(reconciliationSuccess(responseData.data));
		}
	} catch (error) {
		yield put(reconciliationFailure(error));
	}
}

export function* listReconciliationReset() {
	yield put(reconciliationChanged());
}

export function* getReconciliationListReset() {
	yield takeLatest(reconciliationTypes.RECONCILIATION_LIST_RESET, listReconciliationReset);
}

export function* getReconciliationList() {
	yield takeLatest(reconciliationTypes.RECONCILIATION_LIST_START, listReconciliation);
}
/* RECONCILIATION LIST END */

/* UPDATE TRANSACTION STATUS START */
export function* updateTransactionStatus({ payload: { fromDate, toDate, serviceNameJa } }) {
	try {
		const key = 'TransactionStatusUpdateKey';
		const jsonData = {
			FromDate: fromDate,
			ToDate: toDate,
			Secratekey: key,
		};
		const ServiceName = serviceNameJa.serviceName;
		const transactionData = encodeJson(jsonData, key);
		const responseData = yield call(transactionStatusUpdate, transactionData, ServiceName);
		if (responseData.data.statusCode === '100') {
			yield put(updateTransactionFailure(responseData.data));
		} else {
			yield put(updateTransactionSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateTransactionFailure(error));
	}
}

export function* restUpdateTransactionStatusResponse() {
	yield put(updateTransactionChanged());
}

export function* onUpdateTransactionStart() {
	yield takeLatest(reconciliationTypes.UPDATE_TRANSACTION_STATUS_START, updateTransactionStatus);
}

export function* onUpdateTransactionResponse() {
	yield takeLatest(
		reconciliationTypes.UPDATE_TRANSACTION_STATUS_REST,
		restUpdateTransactionStatusResponse,
	);
}
/* UPDATE TRANSACTION STATUS END */

export function* reconciliationSaga() {
	yield all([
		call(getReconciliationList),
		call(getReconciliationListReset),
		call(onUpdateTransactionStart),
		call(onUpdateTransactionResponse),
	]);
}
