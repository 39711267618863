/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const getTodayEodsStaffList = (fetchData) => {
	const data = {
		GetTodayEodsStaffListToken: fetchData,
	};
	const url = `${API_URL}getTodayEodsStaffList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getTodayEodsOrderList = (fetchData) => {
	const data = {
		GetTodayEodsOrderToken: fetchData,
	};
	const url = `${API_URL}getTodayEodsOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const orderStatusUpdate = (fetchData, apiName) => {
	const data = {
		OrderStatusUpdateToken: fetchData,
	};
	const url = `${API_URL}${apiName}`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
