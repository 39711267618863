/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import hubTypes from './addHub.types';

const INITIAL_STATE = {
	hubListResponse: null,
	hubListStatus: null,
	hubCreateResponse: null,
	hubCreateStatus: null,
	hubUpdateResponse: null,
	hubUpdateStatus: null,
};

const hubListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case hubTypes.HUB_LIST_SUCCESS:
			return {
				...state,
				hubListResponse: action.payload,
				hubListStatus: 'Success',
			};

		case hubTypes.HUB_LIST_FAILURE:
			return {
				...state,
				hubListResponse: action.payload,
				hubListStatus: 'Failure',
			};

		case hubTypes.HUB_LIST_CHANGED:
			return { ...state, hubListResponse: null, hubListStatus: null };

		case hubTypes.HUB_CREATE_SUCCESS:
			return {
				...state,
				hubCreateResponse: action.payload,
				hubCreateStatus: 'Success',
			};
		case hubTypes.HUB_CREATE_FAILURE:
			return {
				...state,
				hubCreateResponse: action.payload,
				hubCreateStatus: 'Failure',
			};
		case hubTypes.HUB_CREATE_CHANGED:
			return { ...state, hubCreateResponse: null, hubCreateStatus: null };

		case hubTypes.HUB_UPDATE_SUCCESS:
			return {
				...state,
				hubUpdateResponse: action.payload,
				hubUpdateStatus: 'Success',
			};
		case hubTypes.HUB_UPDATE_FAILURE:
			return {
				...state,
				hubUpdateResponse: action.payload,
				hubUpdateStatus: 'Failure',
			};
		case hubTypes.HUB_UPDATE_CHANGED:
			return { ...state, hubUpdateResponse: null, hubUpdateStatus: null };

		default:
			return state;
	}
};

export default hubListReducer;
