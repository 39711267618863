/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import inventoryTypes from './createInventory.types';
import {
	getInventoryList,
	createInventory,
	updateInventory,
	deleteInventory,
	getInventoryVendorList,
	getInventoryProductList,
	insertInventoryOptions,
	getInventoryOptionsList,
	deleteInventoryOptions,
} from './createInventory.service';
import {
	inventoryListSuccess,
	inventoryListFailure,
	inventoryListChanged,
	createInventorySuccess,
	createInventoryFailure,
	createInventoryChanged,
	updateInventorySuccess,
	updateInventoryFailure,
	updateInventoryChanged,
	deleteInventorySuccess,
	deleteInventoryFailure,
	deleteInventoryChanged,
	inventoryVendorListSuccess,
	inventoryVendorListFailure,
	inventoryVendorListChanged,
	inventoryProductListSuccess,
	inventoryProductListFailure,
	inventoryProductListChanged,
	addInventoryOptionsSuccess,
	addInventoryOptionsFailure,
	addInventoryOptionsChanged,
	getInventoryOptionsSuccess,
	getInventoryOptionsFailure,
	getInventoryOptionsChanged,
	removeInventoryOptionsSuccess,
	removeInventoryOptionsFailure,
	removeInventoryOptionsChanged,
} from './createInventory.action';

/* INVENTORY  LIST START */
export function* inventoryListDetailsStart({ payload: { listOfInventory } }) {
	try {
		const responseData = yield call(getInventoryList, listOfInventory);
		if (responseData.data.statusCode === '100') {
			yield put(inventoryListFailure(responseData.data));
		} else {
			yield put(inventoryListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(inventoryListFailure(error));
	}
}

export function* inventoryListDetailsChanged() {
	yield put(inventoryListChanged());
}

export function* inventoryListDetails() {
	yield takeLatest(inventoryTypes.INVENTORY_LIST_START, inventoryListDetailsStart);
}

export function* inventoryListResponseReset() {
	yield takeLatest(inventoryTypes.INVENTORY_LIST_RESET, inventoryListDetailsChanged);
}
/* INVENTORY  LIST END */

/* INVENTORY CREATE START */
export function* createNewInventory({
	payload: { values, vendorID, productID, purchaseType, quantityType },
}) {
	try {
		const jsonData = {
			ProductOptions: values.productOptions,
			VendorID: vendorID,
			ProductID: productID,
			PurchaseType: purchaseType,
			QuantityType: quantityType,
			PurchaseFrom: values.purchaseFrom,
			IsEthicPacked: values.isEthicPacked,
			Quantity: values.quantity,
			PurchasePrice: values.purchasePrice,
			CreatedBy: values.createdBy,
		};
		const responseData = yield call(createInventory, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(createInventoryFailure(responseData.data));
		} else {
			yield put(createInventorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(createInventoryFailure(error));
	}
}

export function* createInventoryResponseReset() {
	yield put(createInventoryChanged());
}

export function* inventoryCreateStart() {
	yield takeLatest(inventoryTypes.INVENTORY_CREATE_START, createNewInventory);
}

export function* inventoryCreateResponseReset() {
	yield takeLatest(inventoryTypes.INVENTORY_CREATE_RESET, createInventoryResponseReset);
}
/* INVENTORY CREATE END */

/* INVENTORY UPDATE START */
export function* updateInventoryDetails({
	payload: { values, vendorID, productID, purchaseType, quantityType },
}) {
	try {
		const jsonData = {
			InventoryID: values.inventoryID,
			ProductOptions: values.productOptions,
			VendorID: vendorID,
			ProductID: productID,
			PurchaseType: purchaseType,
			QuantityType: quantityType,
			PurchaseFrom: values.purchaseFrom,
			IsEthicPacked: values.isEthicPacked,
			Quantity: values.quantity,
			PurchasePrice: values.purchasePrice,
		};
		const responseData = yield call(updateInventory, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(updateInventoryFailure(responseData.data));
		} else {
			yield put(updateInventorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateInventoryFailure(error));
	}
}

export function* updateInventoryResponseReset() {
	yield put(updateInventoryChanged());
}

export function* inventoryUpdateStart() {
	yield takeLatest(inventoryTypes.INVENTORY_UPDATE_START, updateInventoryDetails);
}

export function* inventoryUpdateResponseReset() {
	yield takeLatest(inventoryTypes.INVENTORY_UPDATE_RESET, updateInventoryResponseReset);
}
/* INVENTORY UPDATE END */

/* INVENTORY DELETE START */
export function* deleteInventoryDetails({ payload: { inventoryID } }) {
	try {
		const jsonData = {
			InventoryID: inventoryID,
		};
		const responseData = yield call(deleteInventory, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteInventoryFailure(responseData.data));
		} else {
			yield put(deleteInventorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteInventoryFailure(error));
	}
}

export function* deleteInventoryResponseReset() {
	yield put(deleteInventoryChanged());
}

export function* inventoryDeleteStart() {
	yield takeLatest(inventoryTypes.INVENTORY_DELETE_START, deleteInventoryDetails);
}

export function* inventoryDeleteResponseReset() {
	yield takeLatest(inventoryTypes.INVENTORY_DELETE_RESET, deleteInventoryResponseReset);
}
/* INVENTORY DELETE END */

/* INVENTORY VENDOR LIST START */
export function* inventoryVendorDetails({ payload: { vendorList } }) {
	try {
		const responseData = yield call(getInventoryVendorList, vendorList);
		if (responseData.data.statusCode === '100') {
			yield put(inventoryVendorListFailure(responseData.data));
		} else {
			yield put(inventoryVendorListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(inventoryVendorListFailure(error));
	}
}

export function* inventoryVendorResponseReset() {
	yield put(inventoryVendorListChanged());
}

export function* vendorListInventoryStart() {
	yield takeLatest(inventoryTypes.INVENTORY_VENDOR_LIST_START, inventoryVendorDetails);
}

export function* vendorListInventoryResponseReset() {
	yield takeLatest(inventoryTypes.INVENTORY_VENDOR_LIST_RESET, inventoryVendorResponseReset);
}
/* INVENTORY VENDOR LIST END */

/* INVENTORY PRODUCT LIST START */
export function* inventoryProductDetails({ payload: { productList } }) {
	try {
		const responseData = yield call(getInventoryProductList, productList);
		if (responseData.data.statusCode === '100') {
			yield put(inventoryProductListFailure(responseData.data));
		} else {
			yield put(inventoryProductListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(inventoryProductListFailure(error));
	}
}

export function* inventoryProductResponseReset() {
	yield put(inventoryProductListChanged());
}

export function* productListInventoryStart() {
	yield takeLatest(inventoryTypes.INVENTORY_PRODUCT_LIST_START, inventoryProductDetails);
}

export function* productListInventoryResponseReset() {
	yield takeLatest(inventoryTypes.INVENTORY_PRODUCT_LIST_RESET, inventoryProductResponseReset);
}
/* INVENTORY PRODUCT LIST END */

/* ADD INVENTORY OPTIONS START */
export function* addInventoryOptionsList({ payload: { productCredentials } }) {
	try {
		const jsonData = JSON.stringify(productCredentials).replace(/"/g, "'");
		const responseData = yield call(insertInventoryOptions, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(addInventoryOptionsFailure(responseData.data));
		} else {
			yield put(addInventoryOptionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(addInventoryOptionsFailure(error));
	}
}

export function* addInventoryOptionsListReset() {
	yield put(addInventoryOptionsChanged());
}

export function* inventoryAddOptionsReset() {
	yield takeLatest(inventoryTypes.ADD_INVENTORY_OPTIONS_RESET, addInventoryOptionsListReset);
}

export function* inventoryAddOptionsStart() {
	yield takeLatest(inventoryTypes.ADD_INVENTORY_OPTIONS_START, addInventoryOptionsList);
}
/* ADD INVENTORY OPTIONS END */

/* GET INVENTORY OPTIONS LIST START */
export function* listOfProductOptions({ payload: { id, ProductID } }) {
	try {
		const responseData = yield call(getInventoryOptionsList, id, ProductID);
		if (responseData.data.statusCode === '100') {
			yield put(getInventoryOptionsFailure(responseData.data));
		} else {
			yield put(getInventoryOptionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(getInventoryOptionsFailure(error));
	}
}

export function* listOfProductOptionsReset() {
	yield put(getInventoryOptionsChanged());
}

export function* listInventoryOptionsReset() {
	yield takeLatest(inventoryTypes.GET_INVENTORY_OPTIONS_LIST_RESET, listOfProductOptionsReset);
}

export function* listInventoryOptionsStart() {
	yield takeLatest(inventoryTypes.GET_INVENTORY_OPTIONS_LIST_START, listOfProductOptions);
}
/* GET INVENTORY OPTIONS LIST END */

/* DELETE INVENTORY OPTIONS START */
export function* productOptionsRemove({ payload: { InventoryOptionsValue } }) {
	try {
		const responseData = yield call(deleteInventoryOptions, InventoryOptionsValue);
		if (responseData.data.statusCode === '100') {
			yield put(removeInventoryOptionsFailure(responseData.data));
		} else {
			yield put(removeInventoryOptionsSuccess(responseData.data));
		}
	} catch (error) {
		yield put(removeInventoryOptionsFailure(error));
	}
}

export function* productOptionsRemoveResponse() {
	yield put(removeInventoryOptionsChanged());
}

export function* inventoryOptionsRemoveReset() {
	yield takeLatest(inventoryTypes.REMOVE_INVENTORY_OPTIONS_RESET, productOptionsRemoveResponse);
}

export function* inventoryOptionsRemoveStart() {
	yield takeLatest(inventoryTypes.REMOVE_INVENTORY_OPTIONS_START, productOptionsRemove);
}
/* DELETE INVENTORY OPTIONS END */

export function* inventoryListSaga() {
	yield all([
		call(inventoryListDetails),
		call(inventoryListResponseReset),
		call(inventoryCreateStart),
		call(inventoryCreateResponseReset),
		call(inventoryUpdateStart),
		call(inventoryUpdateResponseReset),
		call(inventoryDeleteStart),
		call(inventoryDeleteResponseReset),
		call(vendorListInventoryStart),
		call(vendorListInventoryResponseReset),
		call(productListInventoryStart),
		call(productListInventoryResponseReset),
		call(inventoryAddOptionsStart),
		call(inventoryAddOptionsReset),
		call(listInventoryOptionsStart),
		call(listInventoryOptionsReset),
		call(inventoryOptionsRemoveStart),
		call(inventoryOptionsRemoveReset),
	]);
}
