/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/namespace */
/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import endOfDayTypes from './EndOfDay.types';
import { addOrderItem } from './EndOfDay.utils';
import { decodeApiResponse, docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	listEndOfDayArr: [],
	listEndOfDayResponse: null,
	addEndOfDayResponse: null,
	fetchEndOfDayResponse: null,
	viewOrderDetailsArr: [],
	viewOrderDetailsResponse: null,
	cancelledOrderResponse: null,
	staffAssginedResponse: null,

	orderItems: [],
	revertAssignedOrderResponse: null,

	viewCancelledOrderListArr: [],
	viewCancelledOrderListResponse: null,

	getOrderPinCodeListArr: [],
	getOrderPinCodeListResponse: null,

	getEodStaffListArr: [],
	getEodStaffListResponse: null,
};

const endOfDayReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case endOfDayTypes.GET_LIST_END_OF_DAY_SUCCESS:
			return {
				...state,
				listEndOfDayResponse: action.payload,
				listEndOfDayArr: docodeResponse(action.payload, 'EndOfDayListKey'),
			};

		case endOfDayTypes.GET_LIST_END_OF_DAY_FAILURE:
			return {
				...state,
				listEndOfDayResponse: action.payload,
				listEndOfDayArr: [],
			};

		case endOfDayTypes.GET_LIST_END_OF_DAY_CHANGED:
			return { ...state, listEndOfDayResponse: null };

		case endOfDayTypes.ADD_END_OF_DAY_SUCCESS:
			return { ...state, addEndOfDayResponse: action.payload };

		case endOfDayTypes.ADD_END_OF_DAY_FAILURE:
			return { ...state, addEndOfDayResponse: action.payload };

		case endOfDayTypes.ADD_END_OF_DAY_CHANGED:
			return { ...state, addEndOfDayResponse: null };

		case endOfDayTypes.FETCH_END_OF_DAY_SUCCESS:
			return { ...state, fetchEndOfDayResponse: action.payload };

		case endOfDayTypes.FETCH_END_OF_DAY_FAILURE:
			return { ...state, fetchEndOfDayResponse: action.payload };

		case endOfDayTypes.FETCH_END_OF_DAY_CHANGED:
			return { ...state, fetchEndOfDayResponse: null };

		case endOfDayTypes.VIEW_ORDERS_LIST_SUCCESS:
			return {
				...state,
				viewOrderDetailsResponse: action.payload,
				viewOrderDetailsArr: docodeResponse(action.payload, 'ViewOrdersDetailsKey'),
			};

		case endOfDayTypes.VIEW_ORDERS_LIST_FAILURE:
			return { ...state, viewOrderDetailsResponse: action.payload, viewOrderDetailsArr: [] };

		case endOfDayTypes.VIEW_ORDERS_LIST_CHANGED:
			return { ...state, viewOrderDetailsResponse: null };

		case endOfDayTypes.ORDERS_CANCELLED_SUCCESS:
			return { ...state, cancelledOrderResponse: action.payload };

		case endOfDayTypes.ORDERS_CANCELLED_FAILURE:
			return { ...state, cancelledOrderResponse: null };

		case endOfDayTypes.ORDERS_CANCELLED_CHANGED:
			return { ...state, cancelledOrderResponse: null };

		case endOfDayTypes.ADD_ORDER_ITEM:
			return {
				...state,
				listEndOfDayArr: addOrderItem(state.listEndOfDayArr, action.payload),
			};

		case endOfDayTypes.STAFF_ASSIGNED_SUCCESS:
			return { ...state, staffAssginedResponse: action.payload };

		case endOfDayTypes.STAFF_ASSIGNED_FAILURE:
			return { ...state, staffAssginedResponse: null };

		case endOfDayTypes.STAFF_ASSIGNED_CHANGED:
			return { ...state, staffAssginedResponse: null };

		case endOfDayTypes.REVERT_ASSIGNED_ORDER_SUCCESS:
			return { ...state, revertAssignedOrderResponse: action.payload };

		case endOfDayTypes.REVERT_ASSIGNED_ORDER_FAILURE:
			return { ...state, revertAssignedOrderResponse: null };

		case endOfDayTypes.REVERT_ASSIGNED_ORDER_CHANGED:
			return { ...state, revertAssignedOrderResponse: null };

		case endOfDayTypes.GET_CANCELLED_ORDER_LIST_SUCCESS:
			return {
				...state,
				viewCancelledOrderListResponse: action.payload,
				viewCancelledOrderListArr: decodeApiResponse(
					action.payload,
					'GetEodCancelOrderKey',
				),
			};

		case endOfDayTypes.GET_CANCELLED_ORDER_LIST_FAILURE:
			return { ...state, viewCancelledOrderListResponse: action.payload };

		case endOfDayTypes.GET_CANCELLED_ORDER_LIST_CHANGED:
			return { ...state, viewCancelledOrderListResponse: null };

		case endOfDayTypes.GET_ORDER_PINCODE_LIST_SUCCESS:
			return {
				...state,
				getOrderPinCodeListResponse: action.payload,
				getOrderPinCodeListArr: decodeApiResponse(action.payload, 'GetOrderPinCodeListKey'),
			};

		case endOfDayTypes.GET_ORDER_PINCODE_LIST_FAILURE:
			return { ...state, getOrderPinCodeListResponse: action.payload };

		case endOfDayTypes.GET_ORDER_PINCODE_LIST_CHANGED:
			return { ...state, getOrderPinCodeListResponse: null };

		case endOfDayTypes.GET_EOD_STAFF_LIST_SUCCESS:
			return {
				...state,
				getEodStaffListResponse: action.payload,
				getEodStaffListArr: docodeResponse(action.payload, 'EodStaffListGetKey'),
			};

		case endOfDayTypes.GET_EOD_STAFF_LIST_FAILURE:
			return { ...state, getEodStaffListResponse: action.payload };

		case endOfDayTypes.GET_EOD_STAFF_LIST_CHANGED:
			return { ...state, getEodStaffListResponse: null };

		default:
			return state;
	}
};

export default endOfDayReducer;
