/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import priceUpdateAllProductsTypes from './priceUpdateAllProducts.types';

/* GET ALL PRODUCTS START */
export const getAllProductsListStart = (start) => ({
	type: priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_START,
	payload: start,
});

export const getAllProductsListSuccess = (success) => ({
	type: priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_SUCCESS,
	payload: success,
});

export const getAllProductsListFailure = (error) => ({
	type: priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_FAILURE,
	payload: error,
});

export const getAllProductsListReset = () => ({
	type: priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_RESPONSE_RESET_START,
});

export const getAllProductsListChanged = () => ({
	type: priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_RESPONSE_CHANGED,
});
/* GET ALL PRODUCTS END */

/* UPDATE PRICE ALL PRODUCTS START */
export const updatePriceAllProductsStart = (start) => ({
	type: priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_START,
	payload: start,
});

export const updatePriceAllProductsSuccess = (success) => ({
	type: priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_SUCCESS,
	payload: success,
});

export const updatePriceAllProductsFailure = (error) => ({
	type: priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_FAILURE,
	payload: error,
});

export const updatePriceAllProductsReset = () => ({
	type: priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_RESPONSE_RESET_START,
});

export const updatePriceAllProductsChanged = () => ({
	type: priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_RESPONSE_CHANGED,
});
/* UPDATE PRICE ALL PRODUCTS END */
