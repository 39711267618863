// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
import orderReportType from './orderReport.types';

/* ORDER REPORT LIST START */
export const oderReportListStart = (orderReport) => ({
	type: orderReportType.ORDER_REPORT_LIST_START,
	payload: orderReport,
});

export const oderReportListSuccess = (orderReportSuccess) => ({
	type: orderReportType.ORDER_REPORT_LIST_SUCCESS,
	payload: orderReportSuccess,
});

export const oderReportListFailure = (error) => ({
	type: orderReportType.ORDER_REPORT_LIST_FAILURE,
	payload: error,
});

export const oderReportListReset = () => ({
	type: orderReportType.ORDER_REPORT_LIST_RESET,
});

export const oderReportListChanged = () => ({
	type: orderReportType.ORDER_REPORT_LIST_CHANGED,
});

/*  ORDER REPORT LIST END */

/* ORDER REPORT VIEW START */
export const oderReportViewStart = (orderReport) => ({
	type: orderReportType.ORDER_REPORT_VIEW_START,
	payload: orderReport,
});

export const oderReportViewSuccess = (orderReportViewSuccess) => ({
	type: orderReportType.ORDER_REPORT_VIEW_SUCCESS,
	payload: orderReportViewSuccess,
});

export const oderReportViewFailure = (error) => ({
	type: orderReportType.ORDER_REPORT_VIEW_FAILURE,
	payload: error,
});

export const oderReportViewReset = () => ({
	type: orderReportType.ORDER_REPORT_VIEW_RESET,
});

export const oderReportViewChanged = () => ({
	type: orderReportType.ORDER_REPORT_VIEW_CHANGED,
});

/*  ORDER REPORT VIEW END */
