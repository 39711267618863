// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import listOfCustomerTypes from './listOfCustomer.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	listOfCustomerResponse: null,
	listOfCustomerResponseArr: [],
	listOfCustomerResponseStatus: null,
	onCodResponse: null,
	onCodStatus: null,
	offCodResponse: null,
	offCodStatus: null,
	viewCustomerHistoryResponse: null,
	viewCustomerHistoryArr: [],
	viewCustomerHistoryStatus: null,
	removeCustomerResponse: null,
	viewCustomerOrderListResponse: null,
	viewCustomerOrderListArr: [],
	viewCustomerOrderListStatus: null,
	addCustomerResponse: null,
};

const listOfCustomerReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case listOfCustomerTypes.GET_LIST_OF_CUSTOMER_SUCCESS:
			return {
				...state,
				listOfCustomerResponse: action.payload,
				listOfCustomerResponseArr: docodeResponse(action.payload, 'Get_Customer_Details'),
				listOfCustomerResponseStatus: 'Success',
			};

		case listOfCustomerTypes.GET_LIST_OF_CUSTOMER_FAILURE:
			return {
				...state,
				listOfCustomerResponse: action.payload,
				listOfCustomerResponseStatus: 'Failure',
			};

		case listOfCustomerTypes.GET_LIST_OF_CUSTOMER_CHANGED:
			return { ...state, listOfCustomerResponse: null, listOfCustomerResponseStatus: null };

		case listOfCustomerTypes.ON_COD_SUCCESS:
			return {
				...state,
				onCodResponse: action.payload,
				onCodStatus: 'Success',
			};

		case listOfCustomerTypes.ON_COD_FAILURE:
			return {
				...state,
				onCodResponse: action.payload,
				onCodStatus: 'Failure',
			};

		case listOfCustomerTypes.ON_COD_CHANGED:
			return { ...state, onCodResponse: null, onCodStatus: null };

		case listOfCustomerTypes.OFF_COD_SUCCESS:
			return {
				...state,
				offCodResponse: action.payload,
				offCodStatus: 'Success',
			};

		case listOfCustomerTypes.OFF_COD_FAILURE:
			return {
				...state,
				offCodResponse: action.payload,
				offCodStatus: 'Failure',
			};

		case listOfCustomerTypes.OFF_COD_CHANGED:
			return { ...state, offCodResponse: null, offCodStatus: null };

		case listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_SUCCESS:
			return {
				...state,
				viewCustomerHistoryResponse: action.payload,
				viewCustomerHistoryArr: docodeResponse(action.payload, 'View_Cutomer_History'),
				viewCustomerHistoryStatus: 'Success',
			};

		case listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_FAILURE:
			return {
				...state,
				viewCustomerHistoryResponse: action.payload,
				viewCustomerHistoryArr: [],
				viewCustomerHistoryStatus: 'Failure',
			};

		case listOfCustomerTypes.VIEW_CUSTOMER_ORDER_HISTORY_CHANGED:
			return { ...state, viewCustomerHistoryResponse: null, viewCustomerHistoryStatus: null };

		case listOfCustomerTypes.REMOVE_CUSTOMER_SUCCESS:
			return {
				...state,
				removeCustomerResponse: action.payload,
			};

		case listOfCustomerTypes.REMOVE_CUSTOMER_FAILURE:
			return {
				...state,
				removeCustomerResponse: action.payload,
			};

		case listOfCustomerTypes.REMOVE_CUSTOMER_CHANGED:
			return { ...state, removeCustomerResponse: null };

		case listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_SUCCESS:
			return {
				...state,
				viewCustomerOrderListResponse: action.payload,
				viewCustomerOrderListArr: docodeResponse(action.payload, 'View_Customer_GetKey'),
				viewCustomerOrderListStatus: 'Success',
			};

		case listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_FAILURE:
			return {
				...state,
				viewCustomerOrderListResponse: action.payload,
				viewCustomerOrderListStatus: 'Failure',
			};

		case listOfCustomerTypes.VIEW_CUSTOMER_ORDER_LIST_CHANGED:
			return {
				...state,
				viewCustomerOrderListResponse: null,
				viewCustomerOrderListStatus: null,
			};

		case listOfCustomerTypes.ADD_CUSTOMER_SUCCESS:
			return {
				...state,
				addCustomerResponse: action.payload,
			};

		case listOfCustomerTypes.ADD_CUSTOMER_FAILURE:
			return {
				...state,
				addCustomerResponse: action.payload,
			};

		case listOfCustomerTypes.ADD_CUSTOMER_CHANGED:
			return { ...state, addCustomerResponse: null };

		default:
			return state;
	}
};

export default listOfCustomerReducer;
