/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import priceUpdateTypes from './priceUpdate.types';
import { createPriceUpdate, editPriceUpdate } from './priceUpdate.service';
import {
	priceUpdateSuccess,
	priceUpdateFailure,
	priceUpdateChanged,
	priceEditSuccess,
	priceEditFailure,
	priceEditChanged,
} from './priceUpdate.action';

/* PRICE UPDATE LIST START */
export function* priceUpdateDetails({ payload: { priceList } }) {
	try {
		const responseData = yield call(createPriceUpdate, priceList);
		if (responseData.data.statusCode === '100') {
			yield put(priceUpdateFailure(responseData.data));
		} else {
			yield put(priceUpdateSuccess(responseData.data));
		}
	} catch (error) {
		yield put(priceUpdateFailure(error));
	}
}

export function* priceUpdateResponseChnaged() {
	yield put(priceUpdateChanged());
}

export function* priceEditDetails({ payload: { StatepriceUpdateUserDetails, UserID } }) {
	try {
		const JsonData = JSON.stringify(StatepriceUpdateUserDetails).replace(/"/g, "'");
		const responseData = yield call(editPriceUpdate, JsonData, UserID);
		if (responseData.data.statusCode === '100') {
			yield put(priceEditFailure(responseData.data));
		} else {
			yield put(priceEditSuccess(responseData.data));
		}
	} catch (error) {
		yield put(priceEditFailure(error));
	}
}

export function* priceEditResponseChnaged() {
	yield put(priceEditChanged());
}

export function* getPriceUpdateDetails() {
	yield takeLatest(priceUpdateTypes.PRICE_UPDATE_LIST_START, priceUpdateDetails);
}

export function* priceUpdateResponseReset() {
	yield takeLatest(priceUpdateTypes.PRICE_UPDATE_LIST_RESET, priceUpdateResponseChnaged);
}
/* PRICE UPDATE LIST END */

export function* getPriceEditDetails() {
	yield takeLatest(priceUpdateTypes.PRICE_UPDATE_START, priceEditDetails);
}

export function* priceEditResponseReset() {
	yield takeLatest(priceUpdateTypes.PRICE_UPDATE_RESET, priceEditResponseChnaged);
}

export function* priceUpdateSaga() {
	yield all([
		call(getPriceUpdateDetails),
		call(priceUpdateResponseReset),
		call(getPriceEditDetails),
		call(priceEditResponseReset),
	]);
}
