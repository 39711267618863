const loginActionTypes = {
	SET_CURRENT_USER: 'SET_CURRENT_USER',
	SIGN_IN_START: 'SIGN_IN_START',
	SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
	SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
	SIGN_IN_LOGOUT: 'SING_IN_LOGOUT',
	SIGN_IN_RESET: 'SIGN_IN_RESET',

	LOGIN_AND_LOGOUT_LOG_START: 'login_and_logout_log_start',
	LOGIN_AND_LOGOUT_LOG_SUCCESS: 'login_and_logout_log_success',
	LOGIN_AND_LOGOUT_LOG_FAILURE: 'login_and_logout_log_failure',
	LOGIN_AND_LOGOUT_LOG_RESPONSE_RESET_START: 'login_and_logout_log_response_reset_start',
	LOGIN_AND_LOGOUT_LOG_RESPONSE_CHANGED: 'login_and_logout_log_response_reset_changed',
};
export default loginActionTypes;
