/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import { encodeJson } from '../enode-decode';

import dashboardCategoryTypes from './dashboardCategory.types';
import {
	getDashboardCategoryList,
	createDashboardCategory,
	updateDashboardCategory,
	deleteDashboardCategory,
} from './dashboardCategory.service';
import {
	dashboardCategoryListSuccess,
	dashboardCategoryListFailure,
	dashboardCategoryListChanged,
	createDashboardCategorySuccess,
	createDashboardCategoryFailure,
	createDashboardCategoryChanged,
	updateDashboardCategorySuccess,
	updateDashboardCategoryFailure,
	updateDashboardCategoryChanged,
	deleteDashboardCategorySuccess,
	deleteDashboardCategoryFailure,
	deleteDashboardCategoryChanged,
} from './dashboardCategory.action';

/* DASHBOARD CATEGORY  LIST START */
export function* dashboardCategoryListDetailsStart({ payload }) {
	try {
		const key = 'DashboardCategoryListKey';
		const jsonData = {
			Limit: payload.limit,
			BranchID: payload.branchID,
			SearchValueSet: payload.searchValueSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getDashboardCategoryList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(dashboardCategoryListFailure(responseData.data));
		} else {
			yield put(dashboardCategoryListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(dashboardCategoryListFailure(error));
	}
}

export function* dashboardCategoryListDetailsChanged() {
	yield put(dashboardCategoryListChanged());
}

export function* dashboardCategoryListDetails() {
	yield takeLatest(
		dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_START,
		dashboardCategoryListDetailsStart,
	);
}

export function* dashboardCategoryListResponseReset() {
	yield takeLatest(
		dashboardCategoryTypes.DASHBOARD_CATEGORY_LIST_RESET,
		dashboardCategoryListDetailsChanged,
	);
}
/* DASHBOARD CATEGORY  LIST END */

/* DASHBOARD CATEGORY CREATE START */
export function* createNewDashboardCategory({ payload }) {
	try {
		const key = 'CreateDashboardCategoryKey';
		const jsonData = {
			DashboardProductName: payload.dashboardProductName,
			DashboardProductCategory: payload.dashboardProductCategory,
			DashboardProductImage: payload.dashboardProductImage,
			DashboardProductColor: payload.dashboardProductColor,
			RedirectPage: payload.redirectPage,
			StaffID: payload.StaffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(createDashboardCategory, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(createDashboardCategoryFailure(responseData.data));
		} else {
			yield put(createDashboardCategorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(createDashboardCategoryFailure(error));
	}
}

export function* createDashboardCategoryResponseReset() {
	yield put(createDashboardCategoryChanged());
}

export function* dashboardCategoryCreateStart() {
	yield takeLatest(
		dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_START,
		createNewDashboardCategory,
	);
}

export function* dashboardCategoryCreateResponseReset() {
	yield takeLatest(
		dashboardCategoryTypes.DASHBOARD_CATEGORY_CREATE_REPSONSE_RESET,
		createDashboardCategoryResponseReset,
	);
}
/* DASHBOARD CATEGORY CREATE END */

/* DASHBOARD CATEGORY UPDATE START */
export function* updateDashboardCategoryDetails({ payload }) {
	try {
		const key = 'UpdateDashboardCategoryKey';
		const jsonData = {
			DashboardID: payload.dashboardID,
			DashboardProductName: payload.dashboardProductName,
			DashboardProductCategory: payload.dashboardProductCategory,
			DashboardProductImage: payload.dashboardProductImage,
			DashboardProductColor: payload.dashboardProductColor,
			RedirectPage: payload.redirectPage,
			StaffID: payload.StaffID,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(updateDashboardCategory, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(updateDashboardCategoryFailure(responseData.data));
		} else {
			yield put(updateDashboardCategorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateDashboardCategoryFailure(error));
	}
}

export function* updateDashboardCategoryResponseReset() {
	yield put(updateDashboardCategoryChanged());
}

export function* dashboardCategoryUpdateStart() {
	yield takeLatest(
		dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_START,
		updateDashboardCategoryDetails,
	);
}

export function* dashboardCategoryUpdateResponseReset() {
	yield takeLatest(
		dashboardCategoryTypes.DASHBOARD_CATEGORY_UPDATE_REPSONSE_RESET,
		updateDashboardCategoryResponseReset,
	);
}
/* DASHBOARD CATEGORY UPDATE END */

/* DASHBOARD CATEGORY DELETE START */
export function* deleteDashboardCategoryDetails({ payload }) {
	try {
		const key = 'RemoveDashboardCategoryKey';
		const jsonData = {
			IsActive: payload.IsActive,
			DashboardID: payload.DashboardID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(deleteDashboardCategory, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteDashboardCategoryFailure(responseData.data));
		} else {
			yield put(deleteDashboardCategorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteDashboardCategoryFailure(error));
	}
}

export function* deleteDashboardCategoryResponseReset() {
	yield put(deleteDashboardCategoryChanged());
}

export function* dashboardCategoryDeleteStart() {
	yield takeLatest(
		dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_START,
		deleteDashboardCategoryDetails,
	);
}

export function* dashboardCategoryDeleteResponseReset() {
	yield takeLatest(
		dashboardCategoryTypes.DASHBOARD_CATEGORY_DELETE_REPSONSE_RESET,
		deleteDashboardCategoryResponseReset,
	);
}
/* DASHBOARD CATEGORY DELETE END */

export function* dashboardCategorySaga() {
	yield all([
		call(dashboardCategoryListDetails),
		call(dashboardCategoryListResponseReset),
		call(dashboardCategoryCreateStart),
		call(dashboardCategoryCreateResponseReset),
		call(dashboardCategoryUpdateStart),
		call(dashboardCategoryUpdateResponseReset),
		call(dashboardCategoryDeleteStart),
		call(dashboardCategoryDeleteResponseReset),
	]);
}
