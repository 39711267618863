import BranchTypes from './branch.type';

/** ***************** FETCH BRANCH START *********************** */
export const getBranchListStart = (fetchdata) => ({
	type: BranchTypes.GET_BRANCH_LIST_START,
	payload: fetchdata,
});

export const getBranchListSuccess = (successData) => ({
	type: BranchTypes.GET_BRANCH_LIST_SUCCESS,
	payload: successData,
});

export const getBranchListFailure = (failureData) => ({
	type: BranchTypes.GET_BRANCH_LIST_FAILURE,
	payload: failureData,
});

export const getBranchListResponseResetStart = () => ({
	type: BranchTypes.GET_BRANCH_LIST_RESET,
});

export const getBranchListResponseChanged = () => ({
	type: BranchTypes.GET_BRANCH_LIST_CHANGED,
});

/** ***************** FETCH BRANCH END *********************** */
