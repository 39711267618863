const stockListTypes = {
	STOCK_LIST_START: 'STOCK_LIST_START',
	STOCK_LIST_SUCCESS: 'STOCK_LIST_SUCCESS',
	STOCK_LIST_FAILURE: 'STOCK_LIST_FAILURE',
	STOCK_LIST_RESET: 'STOCK_LIST_RESET',
	STOCK_LIST_CHANGED: 'STOCK_LIST_CHANGED',

	REDUCE_STOCK_START: 'REDUCE_STOCK_START',
	REDUCE_STOCK_SUCCESS: 'REDUCE_STOCK_SUCCESS',
	REDUCE_STOCK_FAILURE: 'REDUCE_STOCK_FAILURE',
	REDUCE_STOCK_RESET: 'REDUCE_STOCK_RESET',
	REDUCE_STOCK_CHANGED: 'REDUCE_STOCK_CHANGED',

	ADD_STOCK_START: 'ADD_STOCK_START',
	ADD_STOCK_SUCCESS: 'ADD_STOCK_SUCCESS',
	ADD_STOCK_FAILURE: 'ADD_STOCK_FAILURE',
	ADD_STOCK_RESET: 'ADD_STOCK_RESET',
	ADD_STOCK_CHANGED: 'ADD_STOCK_CHANGED',

	TOP_SELLING_SWITCH_START: 'TOP_SELLING_SWITCH_START',
	TOP_SELLING_SWITCH_SUCCESS: 'TOP_SELLING_SWITCH_SUCCESS',
	TOP_SELLING_SWITCH_FAILURE: 'TOP_SELLING_SWITCH_FAILURE',
	TOP_SELLING_SWITCH_RESET: 'TOP_SELLING_SWITCH_RESET',
	TOP_SELLING_SWITCH_CHANGED: 'TOP_SELLING_SWITCH_CHANGED',

	VIEW_STOCK_EXPIRY_START: 'VIEW_STOCK_EXPIRY_START',
	VIEW_STOCK_EXPIRY_SUCCESS: 'VIEW_STOCK_EXPIRY_SUCCESS',
	VIEW_STOCK_EXPIRY_FAILURE: 'VIEW_STOCK_EXPIRY_FAILURE',
	VIEW_STOCK_EXPIRY_RESET: 'VIEW_STOCK_EXPIRY_RESET',
	VIEW_STOCK_EXPIRY_CHANGED: 'VIEW_STOCK_EXPIRY_CHANGED',
};
export default stockListTypes;
