/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import orderListTypes from './orderList.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	orderListResponse: null,
	orderListArr: null,
	orderViewListResponse: null,
	orderViewListArr: null,
};

const orderListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case orderListTypes.ORDER_LIST_SUCCESS:
			return {
				...state,
				orderListResponse: action.payload,
				orderListArr: decodeApiResponse(action.payload, 'OrderListKey'),
			};

		case orderListTypes.ORDER_LIST_FAILURE:
			return {
				...state,
				orderListResponse: null,
			};

		case orderListTypes.ORDER_LIST_CHANGED:
			return {
				...state,
				orderListResponse: null,
			};

		case orderListTypes.VIEW_ORDER_LIST_SUCCESS:
			return {
				...state,
				orderViewListResponse: action.payload,
				orderViewListArr: decodeApiResponse(action.payload, 'OrderViewListKey'),
			};

		case orderListTypes.VIEW_ORDER_LIST_FAILURE:
			return {
				...state,
				orderViewListResponse: null,
			};

		case orderListTypes.VIEW_ORDER_LIST_CHANGED:
			return {
				...state,
				orderViewListResponse: null,
			};

		default:
			return state;
	}
};

export default orderListReducer;
