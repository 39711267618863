/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import reconciliationTypes from './reconciliation.types';

/* RECONCILIATION LIST START */
export const reconciliationStart = (reconciliationDetails) => ({
	type: reconciliationTypes.RECONCILIATION_LIST_START,
	payload: reconciliationDetails,
});

export const reconciliationSuccess = (reconciliationListSuccess) => ({
	type: reconciliationTypes.RECONCILIATION_LIST_SUCCESS,
	payload: reconciliationListSuccess,
});

export const reconciliationFailure = (error) => ({
	type: reconciliationTypes.RECONCILIATION_LIST_FAILURE,
	payload: error,
});

export const reconciliationChanged = () => ({
	type: reconciliationTypes.RECONCILIATION_LIST_CHANGED,
});

export const reconciliationReset = () => ({
	type: reconciliationTypes.RECONCILIATION_LIST_RESET,
});
/* RECONCILIATION LIST END */

/* UPDATE TRANSACTION STATUS START */
export const updateTransactionStart = (reconciliationDetails) => ({
	type: reconciliationTypes.UPDATE_TRANSACTION_STATUS_START,
	payload: reconciliationDetails,
});

export const updateTransactionSuccess = (reconciliationDetails) => ({
	type: reconciliationTypes.UPDATE_TRANSACTION_STATUS_SUCCESS,
	payload: reconciliationDetails,
});

export const updateTransactionFailure = (error) => ({
	type: reconciliationTypes.UPDATE_TRANSACTION_STATUS_FAILURE,
	payload: error,
});

export const updateTransactionChanged = () => ({
	type: reconciliationTypes.UPDATE_TRANSACTION_STATUS_CHANGED,
});

export const resetUpdateTransactionResponse = () => ({
	type: reconciliationTypes.UPDATE_TRANSACTION_STATUS_REST,
});
/* UPDATE TRANSACTION STATUS END */
