const comboListTypes = {
	GET_COMBO_LIST_START: 'GET_COMBO_LIST_START',
	GET_COMBO_LIST_SUCCESS: 'GET_COMBO_LIST_SUCCESS',
	GET_COMBO_LIST_FAILURE: 'GET_COMBO_LIST_FAILURE',
	GET_COMBO_LIST_RESET: 'GET_COMBO_LIST_RESET',
	GET_COMBO_LIST_CHANGED: 'GET_COMBO_LIST_CHANGED',

	INSERT_COMBO_RESPONSE_START: 'INSERT_COMBO_RESPONSE_START',
	INSERT_COMBO_RESPONSE_SUCCESS: 'INSERT_COMBO_RESPONSE_SUCCESS',
	INSERT_COMBO_RESPONSE_FAILURE: 'INSERT_COMBO_RESPONSE_FAILURE',
	INSERT_COMBO_RESPONSE_RESET: 'INSERT_COMBO_RESPONSE_RESET',
	INSERT_COMBO_RESPONSE_CHANGED: 'INSERT_COMBO_RESPONSE_CHANGED',

	UPDATE_COMBO_RESPONSE_START: 'UPDATE_COMBO_RESPONSE_START',
	UPDATE_COMBO_RESPONSE_SUCCESS: 'UPDATE_COMBO_RESPONSE_SUCCESS',
	UPDATE_COMBO_RESPONSE_FAILURE: 'UPDATE_COMBO_RESPONSE_FAILURE',
	UPDATE_COMBO_RESPONSE_RESET: 'UPDATE_COMBO_RESPONSE_RESET',
	UPDATE_COMBO_RESPONSE_CHANGED: 'UPDATE_COMBO_RESPONSE_CHANGED',

	DELETE_COMBO_RESPONSE_START: 'DELETE_COMBO_RESPONSE_START',
	DELETE_COMBO_RESPONSE_SUCCESS: 'DELETE_COMBO_RESPONSE_SUCCESS',
	DELETE_COMBO_RESPONSE_FAILURE: 'DELETE_COMBO_RESPONSE_FAILURE',
	DELETE_COMBO_RESPONSE_RESET: 'DELETE_COMBO_RESPONSE_RESET',
	DELETE_COMBO_RESPONSE_CHANGED: 'DELETE_COMBO_RESPONSE_CHANGED',

	ADD_COMBO_PRODUCT_RESPONSE_START: 'ADD_COMBO_PRODUCT_RESPONSE_START',
	ADD_COMBO_PRODUCT_RESPONSE_SUCCESS: 'ADD_COMBO_PRODUCT_RESPONSE_SUCCESS',
	ADD_COMBO_PRODUCT_RESPONSE_FAILURE: 'ADD_COMBO_PRODUCT_RESPONSE_FAILURE',
	ADD_COMBO_PRODUCT_RESPONSE_RESET: 'ADD_COMBO_PRODUCT_RESPONSE_RESET',
	ADD_COMBO_PRODUCT_RESPONSE_CHANGED: 'ADD_COMBO_PRODUCT_RESPONSE_CHANGED',

	CREATE_PRODUCT_RESPONSE_START: 'CREATE_PRODUCT_RESPONSE_START',
	CREATE_PRODUCT_RESPONSE_SUCCESS: 'CREATE_PRODUCT_RESPONSE_SUCCESS',
	CREATE_PRODUCT_RESPONSE_FAILURE: 'CREATE_PRODUCT_RESPONSE_FAILURE',
	CREATE_PRODUCT_RESPONSE_RESET: 'CREATE_PRODUCT_RESPONSE_RESET',
	CREATE_PRODUCT_RESPONSE_CHANGED: 'CREATE_PRODUCT_RESPONSE_CHANGED',

	GET_PRODUCT_LIST_RESPONSE_START: 'GET_PRODUCT_LIST_RESPONSE_START',
	GET_PRODUCT_LIST_RESPONSE_SUCCESS: 'GET_PRODUCT_LIST_RESPONSE_SUCCESS',
	GET_PRODUCT_LIST_RESPONSE_FAILURE: 'GET_PRODUCT_LIST_RESPONSE_FAILURE',
	GET_PRODUCT_LIST_RESPONSE_RESET: 'GET_PRODUCT_LIST_RESPONSE_RESET',
	GET_PRODUCT_LIST_RESPONSE_CHANGED: 'GET_PRODUCT_LIST_RESPONSE_CHANGED',

	DELETE_PRODUCT_START: 'DELETE_PRODUCT_START',
	DELETE_PRODUCT_SUCCESS: 'DELETE_PRODUCT_SUCCESS',
	DELETE_PRODUCT_FAILURE: 'DELETE_PRODUCT_FAILURE',
	DELETE_PRODUCT_RESET: 'DELETE_PRODUCT_RESET',
	DELETE_PRODUCT_CHANGED: 'DELETE_PRODUCT_CHANGED',

	IS_FESTIVAL_SWITCH_START: 'IS_FESTIVAL_SWITCH_START',
	IS_FESTIVAL_SWITCH_SUCCESS: 'IS_FESTIVAL_SWITCH_SUCCESS',
	IS_FESTIVAL_SWITCH_FAILURE: 'IS_FESTIVAL_SWITCH_FAILURE',
	IS_FESTIVAL_SWITCH_RESET: 'IS_FESTIVAL_SWITCH_RESET',
	IS_FESTIVAL_SWITCH_CHANGED: 'IS_FESTIVAL_SWITCH_CHANGED',
};
export default comboListTypes;
