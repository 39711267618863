const pushNotificationTypes = {
	SEND_PUSH_NOTIFICATION_START: 'send_push_notification_start',
	SEND_PUSH_NOTIFICATION_SUCCESS: 'send_push_notification_success',
	SEND_PUSH_NOTIFICATION_FAILURE: 'send_push_notification_failure',
	SEND_PUSH_NOTIFICATION_RESET: 'send_push_notification_reset',
	SEND_PUSH_NOTIFICATION_CHANGED: 'send_push_notification_changed',

	NOTIFICATION_CUSTOMER_LIST_START: 'notification_customer_list_start',
	NOTIFICATION_CUSTOMER_LIST_SUCCESS: 'notification_customer_list_success',
	NOTIFICATION_CUSTOMER_LIST_FAILURE: 'notification_customer_list_failure',
	NOTIFICATION_CUSTOMER_LIST_RESET: 'notification_customer_list_reset',
	NOTIFICATION_CUSTOMER_LIST_CHANGED: 'notification_customer_list_changed',
};
export default pushNotificationTypes;
