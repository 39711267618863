/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import repackedTypes from './rePacked.types';

/* REPACKED LIST START */
export const repackedListStart = (repackedDetails) => ({
	type: repackedTypes.REPACKED_LIST_START,
	payload: repackedDetails,
});

export const repackedListSuccess = (repackedSuccess) => ({
	type: repackedTypes.REPACKED_LIST_SUCCESS,
	payload: repackedSuccess,
});

export const repackedListFailure = (error) => ({
	type: repackedTypes.REPACKED_LIST_FAILURE,
	payload: error,
});

export const repackedListChanged = () => ({
	type: repackedTypes.REPACKED_LIST_CHANGED,
});

export const repackedListReset = () => ({
	type: repackedTypes.REPACKED_LIST_RESET,
});
/* REPACKED LIST END */

/* REPACKED CREATE START */
export const createRepackedStart = (repackedDetails) => ({
	type: repackedTypes.REPACKED_CREATE_START,
	payload: repackedDetails,
});

export const createRepackedSuccess = (repackedSuccess) => ({
	type: repackedTypes.REPACKED_CREATE_SUCCESS,
	payload: repackedSuccess,
});

export const createRepackedFailure = (error) => ({
	type: repackedTypes.REPACKED_CREATE_FAILURE,
	payload: error,
});

export const createRepackedChanged = () => ({
	type: repackedTypes.REPACKED_CREATE_CHANGED,
});

export const createRepackedReset = () => ({
	type: repackedTypes.REPACKED_CREATE_RESET,
});
/* REPACKED CREATE END */

/* REPACKED UPDATE START */
export const updateRepackedStart = (repackedDetails) => ({
	type: repackedTypes.REPACKED_UPDATE_START,
	payload: repackedDetails,
});

export const updateRepackedSuccess = (repackedSuccess) => ({
	type: repackedTypes.REPACKED_UPDATE_SUCCESS,
	payload: repackedSuccess,
});

export const updateRepackedFailure = (error) => ({
	type: repackedTypes.REPACKED_UPDATE_FAILURE,
	payload: error,
});

export const updateRepackedChanged = () => ({
	type: repackedTypes.REPACKED_UPDATE_CHANGED,
});

export const updateRepackedReset = () => ({
	type: repackedTypes.REPACKED_UPDATE_RESET,
});
/* REPACKED UPDATE END */
