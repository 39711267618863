/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import endOfDayNewTypes from './EndOfDayNew.types';

/* EOD ORDER LIST NEW START */
export const eodOrderListNewStart = (eodNewDetails) => ({
	type: endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_START,
	payload: eodNewDetails,
});

export const eodOrderListNewSuccess = (eodNewSuccess) => ({
	type: endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_SUCCESS,
	payload: eodNewSuccess,
});

export const eodOrderListNewFailure = (error) => ({
	type: endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_FAILURE,
	payload: error,
});

export const eodOrderListNewReset = () => ({
	type: endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_RESET,
});

export const eodOrderListNewChanged = () => ({
	type: endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_CHANGED,
});
/* EOD ORDER LIST NEW END */

/* VIEW EOD ORDER LIST NEW START */
export const viewEodOrderListNewStart = (viewDetails) => ({
	type: endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_START,
	payload: viewDetails,
});

export const viewEodOrderListNewSuccess = (eodNewViewSuccess) => ({
	type: endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_SUCCESS,
	payload: eodNewViewSuccess,
});

export const viewEodOrderListNewFailure = (error) => ({
	type: endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_FAILURE,
	payload: error,
});

export const viewEodOrderListNewReset = () => ({
	type: endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_RESET,
});

export const viewEodOrderListNewChanged = () => ({
	type: endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_CHANGED,
});
/* VIEW EOD ORDER LIST NEW END */

/* EOD FETCH LIST START */
export const eodFetchListStart = (startDetails) => ({
	type: endOfDayNewTypes.EOD_FETCH_LIST_START,
	payload: startDetails,
});

export const eodFetchListSuccess = (successResp) => ({
	type: endOfDayNewTypes.EOD_FETCH_LIST_SUCCESS,
	payload: successResp,
});

export const eodFetchListFailure = (error) => ({
	type: endOfDayNewTypes.EOD_FETCH_LIST_FAILURE,
	payload: error,
});

export const eodFetchListReset = () => ({
	type: endOfDayNewTypes.EOD_FETCH_LIST_RESET,
});

export const eodFetchListChanged = () => ({
	type: endOfDayNewTypes.EOD_FETCH_LIST_CHANGED,
});
/* EOD FETCH LIST END */

/* ADD EOD NEW START */
export const addEodNewStart = (startDetails) => ({
	type: endOfDayNewTypes.ADD_EOD_NEW_START,
	payload: startDetails,
});

export const addEodNewSuccess = (successResp) => ({
	type: endOfDayNewTypes.ADD_EOD_NEW_SUCCESS,
	payload: successResp,
});

export const addEodNewFailure = (error) => ({
	type: endOfDayNewTypes.ADD_EOD_NEW_FAILURE,
	payload: error,
});

export const addEodNewReset = () => ({
	type: endOfDayNewTypes.ADD_EOD_NEW_RESET,
});

export const addEodNewChanged = () => ({
	type: endOfDayNewTypes.ADD_EOD_NEW_CHANGED,
});
/* ADD EOD NEW END */

/* GET EOD STAFF LIST START NEW */
export const getEodStaffListNewStart = (startDetails) => ({
	type: endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_START,
	payload: startDetails,
});

export const getEodStaffListNewSuccess = (success) => ({
	type: endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_SUCCESS,
	payload: success,
});

export const getEodStaffListNewFailure = (error) => ({
	type: endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_FAILURE,
	payload: error,
});

export const getEodStaffListNewChanged = () => ({
	type: endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_CHANGED,
});

export const getEodStaffListNewReset = () => ({
	type: endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_RESET,
});
/* GET EOD STAFF LIST START NEW */

export const itemOrderAddNewStart = (item) => ({
	type: endOfDayNewTypes.ADD_ORDER_ITEM_NEW,
	payload: item,
});

/* STAFF ASSIGNED START NEW */
export const staffAssignedNewStart = (startDetails) => ({
	type: endOfDayNewTypes.STAFF_ASSIGNED_NEW_START,
	payload: startDetails,
});

export const staffAssignedNewSuccess = (success) => ({
	type: endOfDayNewTypes.STAFF_ASSIGNED_NEW_SUCCESS,
	payload: success,
});

export const staffAssignedNewFailure = (error) => ({
	type: endOfDayNewTypes.STAFF_ASSIGNED_NEW_FAILURE,
	payload: error,
});

export const staffAssignedNewChanged = () => ({
	type: endOfDayNewTypes.STAFF_ASSIGNED_NEW_CHANGED,
});

export const staffAssignedNewReset = () => ({
	type: endOfDayNewTypes.STAFF_ASSIGNED_NEW_RESET,
});
/* STAFF ASSIGNED END NEW */

/* REVERT ASSIGNED ORDER START NEW */
export const revertAssignedOrderNewStart = (startDetails) => ({
	type: endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_START,
	payload: startDetails,
});

export const revertAssignedOrderNewSuccess = (success) => ({
	type: endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_SUCCESS,
	payload: success,
});

export const revertAssignedOrderNewFailure = (error) => ({
	type: endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_FAILURE,
	payload: error,
});

export const revertAssignedOrderNewChanged = () => ({
	type: endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_CHANGED,
});

export const revertAssignedOrderNewReset = () => ({
	type: endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_RESET,
});
/* REVERT ASSIGNED ORDER END NEW */

/* ORDER DATE CHANGE START */
export const orderDateChangeStart = (startDetails) => ({
	type: endOfDayNewTypes.ORDER_DATE_CHANGE_START,
	payload: startDetails,
});

export const orderDateChangeSuccess = (success) => ({
	type: endOfDayNewTypes.ORDER_DATE_CHANGE_SUCCESS,
	payload: success,
});

export const orderDateChangeFailure = (error) => ({
	type: endOfDayNewTypes.ORDER_DATE_CHANGE_FAILURE,
	payload: error,
});

export const orderDateChangeChanged = () => ({
	type: endOfDayNewTypes.ORDER_DATE_CHANGE_CHANGED,
});

export const orderDateChangeReset = () => ({
	type: endOfDayNewTypes.ORDER_DATE_CHANGE_RESET,
});
/* ORDER DATE CHANGE END */
