const listOfCustomerTypes = {
	GET_LIST_OF_CUSTOMER_START: 'GET_LIST_OF_CUSTOMER_START',
	GET_LIST_OF_CUSTOMER_SUCCESS: 'GET_LIST_OF_CUSTOMER_SUCCESS',
	GET_LIST_OF_CUSTOMER_FAILURE: 'GET_LIST_OF_CUSTOMER_FAILURE',
	GET_LIST_OF_CUSTOMER_RESET: 'GET_LIST_OF_CUSTOMER_RESET',
	GET_LIST_OF_CUSTOMER_CHANGED: 'GET_LIST_OF_CUSTOMER_CHANGED',

	ON_COD_START: 'ON_COD_START',
	ON_COD_SUCCESS: 'ON_COD_SUCCESS',
	ON_COD_FAILURE: 'ON_COD_FAILURE',
	ON_COD_RESET: 'ON_COD_RESET',
	ON_COD_CHANGED: 'ON_COD_CHANGED',

	OFF_COD_START: 'OFF_COD_START',
	OFF_COD_SUCCESS: 'OFF_COD_SUCCESS',
	OFF_COD_FAILURE: 'OFF_COD_FAILURE',
	OFF_COD_RESET: 'OFF_COD_RESET',
	OFF_COD_CHANGED: 'OFF_COD_CHANGED',

	VIEW_CUSTOMER_ORDER_HISTORY_START: 'VIEW_CUSTOMER_ORDER_HISTORY_START',
	VIEW_CUSTOMER_ORDER_HISTORY_SUCCESS: 'VIEW_CUSTOMER_ORDER_HISTORY_SUCCESS',
	VIEW_CUSTOMER_ORDER_HISTORY_FAILURE: 'VIEW_CUSTOMER_ORDER_HISTORY_FAILURE',
	VIEW_CUSTOMER_ORDER_HISTORY_RESET: 'VIEW_CUSTOMER_ORDER_HISTORY_RESET',
	VIEW_CUSTOMER_ORDER_HISTORY_CHANGED: 'VIEW_CUSTOMER_ORDER_HISTORY_CHANGED',

	REMOVE_CUSTOMER_START: 'REMOVE_CUSTOMER_START',
	REMOVE_CUSTOMER_SUCCESS: 'REMOVE_CUSTOMER_SUCCESS',
	REMOVE_CUSTOMER_FAILURE: 'REMOVE_CUSTOMER_FAILURE',
	REMOVE_CUSTOMER_RESET: 'REMOVE_CUSTOMER_RESET',
	REMOVE_CUSTOMER_CHANGED: 'REMOVE_CUSTOMER_CHANGED',

	VIEW_CUSTOMER_ORDER_LIST_START: 'VIEW_CUSTOMER_ORDER_LIST_START',
	VIEW_CUSTOMER_ORDER_LIST_SUCCESS: 'VIEW_CUSTOMER_ORDER_LIST_SUCCESS',
	VIEW_CUSTOMER_ORDER_LIST_FAILURE: 'VIEW_CUSTOMER_ORDER_LIST_FAILURE',
	VIEW_CUSTOMER_ORDER_LIST_RESET: 'VIEW_CUSTOMER_ORDER_LIST_RESET',
	VIEW_CUSTOMER_ORDER_LIST_CHANGED: 'VIEW_CUSTOMER_ORDER_LIST_CHANGED',

	ADD_CUSTOMER_START: 'ADD_CUSTOMER_START',
	ADD_CUSTOMER_SUCCESS: 'ADD_CUSTOMER_SUCCESS',
	ADD_CUSTOMER_FAILURE: 'ADD_CUSTOMER_FAILURE',
	ADD_CUSTOMER_RESET: 'ADD_CUSTOMER_RESET',
	ADD_CUSTOMER_CHANGED: 'ADD_CUSTOMER_CHANGED',

};
export default listOfCustomerTypes;
