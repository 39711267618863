const flowersListTypes = {
	GET_FLOWERS_LIST_START: 'GET_FLOWERS_LIST_START',
	GET_FLOWERS_LIST_SUCCESS: 'GET_FLOWERS_LIST_SUCCESS',
	GET_FLOWERS_LIST_FAILURE: 'GET_FLOWERS_LIST_FAILURE',
	GET_FLOWERS_LIST_RESET: 'GET_FLOWERS_LIST_RESET',
	GET_FLOWERS_LIST_CHANGED: 'GET_FLOWERS_LIST_CHANGED',

	ADD_FLOWERS_START: 'ADD_FLOWERS_START',
	ADD_FLOWERS_SUCCESS: 'ADD_FLOWERS_SUCCESS',
	ADD_FLOWERS_FAILURE: 'ADD_FLOWERS_FAILURE',
	ADD_FLOWERS_RESET: 'ADD_FLOWERS_RESET',
	ADD_FLOWERS_CHANGED: 'ADD_FLOWERS_CHANGED',

	EDIT_FLOWERS_START: 'EDIT_FLOWERS_START',
	EDIT_FLOWERS_SUCCESS: 'EDIT_FLOWERS_SUCCESS',
	EDIT_FLOWERS_FAILURE: 'EDIT_FLOWERS_FAILURE',
	EDIT_FLOWERS_RESET: 'EDIT_FLOWERS_RESET',
	EDIT_FLOWERS_CHANGED: 'EDIT_FLOWERS_CHANGED',

	REMOVE_FLOWERS_START: 'REMOVE_FLOWERS_START',
	REMOVE_FLOWERS_SUCCESS: 'REMOVE_FLOWERS_SUCCESS',
	REMOVE_FLOWERS_FAILURE: 'REMOVE_FLOWERS_FAILURE',
	REMOVE_FLOWERS_RESET: 'REMOVE_FLOWERS_RESET',
	REMOVE_FLOWERS_CHANGED: 'REMOVE_FLOWERS_CHANGED',
};
export default flowersListTypes;
