/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import purchaseReportTypes from './purchaseReport.types';

/* PURCHASE REPORT LIST START */
export const purchaseReportListStart = (purchaseDetails) => ({
	type: purchaseReportTypes.PURCHASE_REPORT_LIST_START,
	payload: purchaseDetails,
});

export const purchaseReportListSuccess = (purchaseSuccess) => ({
	type: purchaseReportTypes.PURCHASE_REPORT_LIST_SUCCESS,
	payload: purchaseSuccess,
});

export const purchaseReportListFailure = (error) => ({
	type: purchaseReportTypes.PURCHASE_REPORT_LIST_FAILURE,
	payload: error,
});

export const purchaseReportListChanged = () => ({
	type: purchaseReportTypes.PURCHASE_REPORT_LIST_CHANGED,
});

export const purchaseReportListReset = () => ({
	type: purchaseReportTypes.PURCHASE_REPORT_LIST_RESET,
});
/* PURCHASE REPORT LIST END */
