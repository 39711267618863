/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';
import generalTypesAction from '../genera.types';
import collectionsTypes from './collections.types';
import { getCollectionsList } from './collections.service';
import {
	collectionsListSuccess,
	collectionsListFailure,
	collectionsListChanged,
} from './collections.action';
import { encodeJson } from '../enode-decode';

/* GET COLLECTIONS  LIST START */
export function* collectionsListDetailsStart({ payload: { limit, searchValueSet } }) {
	try {
		const jsonData = {
			Limit: limit,
			SearchValueSet: searchValueSet,
		};
		const CollectionsData = encodeJson(jsonData, generalTypesAction.COLLECTIONSLIST);
		const responseData = yield call(getCollectionsList, CollectionsData);
		if (responseData.data.statusCode === '100') {
			yield put(collectionsListFailure(responseData.data));
		} else {
			yield put(collectionsListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(collectionsListFailure(error));
	}
}

export function* collectionsListDetailsChanged() {
	yield put(collectionsListChanged());
}

export function* collectionsListDetails() {
	yield takeLatest(collectionsTypes.GET_COLLECTIONS_LIST_START, collectionsListDetailsStart);
}

export function* collectionsListResponseReset() {
	yield takeLatest(collectionsTypes.GET_COLLECTIONS_LIST_RESET, collectionsListDetailsChanged);
}
/* GET COLLECTIONS  LIST END */

export function* collectionsSaga() {
	yield all([call(collectionsListDetails), call(collectionsListResponseReset)]);
}
