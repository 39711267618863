/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_URL } from '../const';

export const UserLogin = (phoneNumber, password) => {
	const data = {
		PhoneNumber: phoneNumber,
		Password: password,
	};
	const url = `${API_URL}StaffLogin`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const staffLogoutAndLogionLog = (fetchData) => {
	const data = {
		StaffLogoutAndLogionLogData: fetchData,
	};
	const url = `${API_URL}StaffLogoutAndLogionLog`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
