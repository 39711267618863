/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import otpTypes from './otpList.types';

/* OTP LIST START */
export const otpListStart = (otpDetails) => ({
	type: otpTypes.GET_OTP_LIST_START,
	payload: otpDetails,
});

export const otpListSuccess = (otpSuccess) => ({
	type: otpTypes.GET_OTP_LIST_SUCCESS,
	payload: otpSuccess,
});

export const otpListFailure = (error) => ({
	type: otpTypes.GET_OTP_LIST_FAILURE,
	payload: error,
});

export const otpListChanged = () => ({
	type: otpTypes.GET_OTP_LIST_CHANGED,
});

export const otpListReset = () => ({
	type: otpTypes.GET_OTP_LIST_RESET,
});
/* OTP LIST END */
