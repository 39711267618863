/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import AssignedStaffTypes from './assigned-staff.types';
import {
	getAssignedStaffDetails,
	assginedStaffToStaff,
	assignedRevertStaff,
} from './assigned-staff.service';
import {
	fetchAssignedStaffDetailSuccess,
	fetchAssignedStaffDetailFailure,
	fetchAssignedStaffDetailResponseChanged,
	staffToStaffAssignedSuccess,
	staffToStaffAssignedFailure,
	staffToStaffAssignedChanged,
	revertAssignedStaffSuccess,
	revertAssignedStaffFailure,
	revertAssignedStaffChanged,
} from './assigned-staff.action';
import { encodeJson } from '../enode-decode';

/** *********************  START FETCH ASSIGNED STAFF DETAILS  ******************** */
export function* fetchAssignedStaffDetails({ payload }) {
	try {
		const key = 'FetchAssignedStaffDetailsKey';
		const jsonData = {
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Limit: payload.limit,
			SearchValueSet: payload.searchValueSet,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getAssignedStaffDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchAssignedStaffDetailFailure(responseData.data));
		} else {
			yield put(fetchAssignedStaffDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchAssignedStaffDetailFailure(error));
	}
}

export function* resetFetchAssignedStaffResponse() {
	yield put(fetchAssignedStaffDetailResponseChanged());
}

export function* onFetchAssignedStaffDetails() {
	yield takeLatest(AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_START, fetchAssignedStaffDetails);
}

export function* onFetchAssignedStaffResponseReset() {
	yield takeLatest(
		AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_RESPONSE_RESET_START,
		resetFetchAssignedStaffResponse,
	);
}
/** *********************  END FETCH ASSIGNED STAFF DETAILS ******************** */

/* STAFF TO STAFF ASSIGNED START */
export function* staffToStaffAssignedStatus({ payload }) {
	try {
		const key = 'AssignedStaffToStaffKey';
		const AssignStaffToStaffDetails = JSON.stringify(payload.AssignedArr).replace(/"/g, "'");
		const jsonData = {
			DeliveryDate: payload.deliveryDate,
			NoOfStaff: payload.staffListDetails.NoOfStaff,
			assignStaffToStaffDetails: AssignStaffToStaffDetails,
			StaffLeadID: payload.staffLeadID,
			CreatedBy: payload.createdBy,
			Secratekey: key,
		};
		const assignJson = encodeJson(jsonData, key);
		const responseData = yield call(assginedStaffToStaff, assignJson);
		if (responseData.data.statusCode === '100') {
			yield put(staffToStaffAssignedFailure(responseData.data));
		} else {
			yield put(staffToStaffAssignedSuccess(responseData.data));
		}
	} catch (error) {
		yield put(staffToStaffAssignedFailure(error));
	}
}

export function* restStaffToStaffAssignedResponse() {
	yield put(staffToStaffAssignedChanged());
}

export function* onStaffAssignedStart() {
	yield takeLatest(AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_START, staffToStaffAssignedStatus);
}

export function* onStaffAssignedResponse() {
	yield takeLatest(
		AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_RESET,
		restStaffToStaffAssignedResponse,
	);
}
/* STAFF TO STAFF ASSIGNED END */

/* REVERT ASSIGNED STAFF START */
export function* revertAssignedStaffStatus({ payload }) {
	try {
		const key = 'RevertAssignedStaffKey';
		const jsonData = {
			StaffID: payload.staffID,
			CreatedByID: payload.createdByID,
			DeliveryDate: payload.deliveryDate,
			Secratekey: key,
		};
		const revertJson = encodeJson(jsonData, key);
		const responseData = yield call(assignedRevertStaff, revertJson);
		if (responseData.data.statusCode === '100') {
			yield put(revertAssignedStaffFailure(responseData.data));
		} else {
			yield put(revertAssignedStaffSuccess(responseData.data));
		}
	} catch (error) {
		yield put(revertAssignedStaffFailure(error));
	}
}

export function* restRevertAssignedStaffResponse() {
	yield put(revertAssignedStaffChanged());
}

export function* onRevertAssignedStaffStart() {
	yield takeLatest(AssignedStaffTypes.REVERT_ASSIGNED_STAFF_START, revertAssignedStaffStatus);
}

export function* onRevertAssignedStaffResponse() {
	yield takeLatest(
		AssignedStaffTypes.REVERT_ASSIGNED_STAFF_RESET,
		restRevertAssignedStaffResponse,
	);
}
/* REVERT ASSIGNED STAFF END */

export function* assignedStaffSaga() {
	yield all([
		call(onFetchAssignedStaffDetails),
		call(onFetchAssignedStaffResponseReset),
		call(onStaffAssignedStart),
		call(onStaffAssignedResponse),
		call(onRevertAssignedStaffStart),
		call(onRevertAssignedStaffResponse),
	]);
}
