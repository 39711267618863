/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import AssignedStaffTypes from './assigned-staff.types';

export const itemStaffAddStart = (item) => ({
	type: AssignedStaffTypes.ADD_STAFF_ITEM,
	payload: item,
});

/** ***************** FETCH ASSIGNED STAFF DETAILS START *********************** */
export const fetchAssignedStaffDetailStart = (fetchdata) => ({
	type: AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_START,
	payload: fetchdata,
});

export const fetchAssignedStaffDetailSuccess = (successData) => ({
	type: AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchAssignedStaffDetailFailure = (failureData) => ({
	type: AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchAssignedStaffDetailResponseResetStart = () => ({
	type: AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_RESPONSE_RESET_START,
});

export const fetchAssignedStaffDetailResponseChanged = () => ({
	type: AssignedStaffTypes.ASSIGNED_STAFF_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH ASSIGNED STAFF DETAILS END *********************** */

/* STAFF TO STAFF ASSIGNED START */
export const staffToStaffAssignedStart = (staffToStaffAssigned) => ({
	type: AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_START,
	payload: staffToStaffAssigned,
});

export const staffToStaffAssignedSuccess = (fetchEndOfDaySuccess) => ({
	type: AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_SUCCESS,
	payload: fetchEndOfDaySuccess,
});

export const staffToStaffAssignedFailure = (error) => ({
	type: AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_FAILURE,
	payload: error,
});

export const staffToStaffAssignedChanged = () => ({
	type: AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_CHANGED,
});

export const staffToStaffAssignedReset = () => ({
	type: AssignedStaffTypes.STAFF_TO_STAFF_ASSIGNED_RESET,
});
/* STAFF TO STAFF ASSIGNED END */

/* REVERT ASSIGNED STAFF START */
export const revertAssignedStaffStart = (revertAssignedStaff) => ({
	type: AssignedStaffTypes.REVERT_ASSIGNED_STAFF_START,
	payload: revertAssignedStaff,
});

export const revertAssignedStaffSuccess = (viewStaffSuccess) => ({
	type: AssignedStaffTypes.REVERT_ASSIGNED_STAFF_SUCCESS,
	payload: viewStaffSuccess,
});

export const revertAssignedStaffFailure = (error) => ({
	type: AssignedStaffTypes.REVERT_ASSIGNED_STAFF_FAILURE,
	payload: error,
});

export const revertAssignedStaffChanged = () => ({
	type: AssignedStaffTypes.REVERT_ASSIGNED_STAFF_CHANGED,
});

export const revertAssignedStaffReset = () => ({
	type: AssignedStaffTypes.REVERT_ASSIGNED_STAFF_RESET,
});
/* REVERT ASSIGNED STAFF END */
