/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import productListTypes from './addProduct.types';

/* PRODUCT LIST START */
export const productListStart = (productListDetails) => ({
	type: productListTypes.GET_PRODUCT_LIST_START,
	payload: productListDetails,
});

export const productListSuccess = (productSuccess) => ({
	type: productListTypes.GET_PRODUCT_LIST_SUCCESS,
	payload: productSuccess,
});

export const productListFailure = (error) => ({
	type: productListTypes.GET_PRODUCT_LIST_FAILURE,
	payload: error,
});

export const productListChanged = () => ({
	type: productListTypes.GET_PRODUCT_LIST_CHANGED,
});

export const productListReset = () => ({
	type: productListTypes.GET_PRODUCT_LIST_RESET,
});
/* PRODUCT LIST END */

/* ADD PRODUCT START */
export const addProductStart = (productListDetails) => ({
	type: productListTypes.ADD_PRODUCT_START,
	payload: productListDetails,
});

export const addProductSuccess = (productSuccess) => ({
	type: productListTypes.ADD_PRODUCT_SUCCESS,
	payload: productSuccess,
});

export const addProductFailure = (error) => ({
	type: productListTypes.ADD_PRODUCT_FAILURE,
	payload: error,
});

export const addProductChanged = () => ({
	type: productListTypes.ADD_PRODUCT_CHANGED,
});

export const addProductReset = () => ({
	type: productListTypes.ADD_PRODUCT_RESET,
});

/* ADD PRODUCT END */

/* EDIT PRODUCT START */
export const editProductStart = (productListDetails) => ({
	type: productListTypes.EDIT_PRODUCT_START,
	payload: productListDetails,
});

export const editProductSuccess = (productSuccess) => ({
	type: productListTypes.EDIT_PRODUCT_SUCCESS,
	payload: productSuccess,
});

export const editProductFailure = (error) => ({
	type: productListTypes.EDIT_PRODUCT_FAILURE,
	payload: error,
});

export const editProductChanged = () => ({
	type: productListTypes.EDIT_PRODUCT_CHANGED,
});

export const editProductReset = () => ({
	type: productListTypes.EDIT_PRODUCT_RESET,
});

/* EDIT PRODUCT END */

/* GET PRODUCT OPTIONS LIST START */
export const getProductOptionsStart = (productListDetails) => ({
	type: productListTypes.GET_PRODUCT_OPTIONS_LIST_START,
	payload: productListDetails,
});

export const getProductOptionsSuccess = (productSuccess) => ({
	type: productListTypes.GET_PRODUCT_OPTIONS_LIST_SUCCESS,
	payload: productSuccess,
});

export const getProductOptionsFailure = (error) => ({
	type: productListTypes.GET_PRODUCT_OPTIONS_LIST_FAILURE,
	payload: error,
});

export const getProductOptionsChanged = () => ({
	type: productListTypes.GET_PRODUCT_OPTIONS_LIST_CHANGED,
});

export const getProductOptionsReset = () => ({
	type: productListTypes.GET_PRODUCT_OPTIONS_LIST_RESET,
});

/* GET PRODUCT OPTIONS LIST END */

/* ADD PRODUCT OPTIONS START */
export const addProductOptionsStart = (productListDetails) => ({
	type: productListTypes.ADD_PRODUCT_OPTIONS_START,
	payload: productListDetails,
});

export const addProductOptionsSuccess = (productSuccess) => ({
	type: productListTypes.ADD_PRODUCT_OPTIONS_SUCCESS,
	payload: productSuccess,
});

export const addProductOptionsFailure = (error) => ({
	type: productListTypes.ADD_PRODUCT_OPTIONS_FAILURE,
	payload: error,
});

export const addProductOptionsChanged = () => ({
	type: productListTypes.ADD_PRODUCT_OPTIONS_CHANGED,
});

export const addProductOptionsReset = () => ({
	type: productListTypes.ADD_PRODUCT_OPTIONS_RESET,
});

/* ADD PRODUCT OPTIONS END */

/* REMOVE PRODUCT OPTIONS LIST START */
export const removeProductOptionsStart = (productListDetails) => ({
	type: productListTypes.REMOVE_PRODUCT_OPTIONS_START,
	payload: productListDetails,
});

export const removeProductOptionsSuccess = (productSuccess) => ({
	type: productListTypes.REMOVE_PRODUCT_OPTIONS_SUCCESS,
	payload: productSuccess,
});

export const removeProductOptionsFailure = (error) => ({
	type: productListTypes.REMOVE_PRODUCT_OPTIONS_FAILURE,
	payload: error,
});

export const removeProductOptionsChanged = () => ({
	type: productListTypes.REMOVE_PRODUCT_OPTIONS_CHANGED,
});

export const removeProductOptionsReset = () => ({
	type: productListTypes.REMOVE_PRODUCT_OPTIONS_RESET,
});

/* REMOVE PRODUCT OPTIONS LIST END */

/* UPDATE PRODUCT OPTIONS START */
export const updateProductOptionsStart = (productListDetails) => ({
	type: productListTypes.UPDATE_PRODUCT_OPTIONS_START,
	payload: productListDetails,
});

export const updateProductOptionsSuccess = (productSuccess) => ({
	type: productListTypes.UPDATE_PRODUCT_OPTIONS_SUCCESS,
	payload: productSuccess,
});

export const updateProductOptionsFailure = (error) => ({
	type: productListTypes.UPDATE_PRODUCT_OPTIONS_FAILURE,
	payload: error,
});

export const updateProductOptionsChanged = () => ({
	type: productListTypes.UPDATE_PRODUCT_OPTIONS_CHANGED,
});

export const updateProductOptionsReset = () => ({
	type: productListTypes.UPDATE_PRODUCT_OPTIONS_RESET,
});

/* UPDATE PRODUCT OPTIONS END */
