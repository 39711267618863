/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getPincodeNewList = (fetchData) => {
	const data = {
		PincodeNewListToken: fetchData,
	};
	const url = `${API_URL}GetPincodeNewList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const pincodeWiseOrderList = (fetchData) => {
	const data = {
		OrderListPincodeWiseToken: fetchData,
	};
	const url = `${API_URL}PincodeWiseOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
