import PackingReportActionType from './packingReport.types';

// WHOLE HISTORY LIST
export const wholePackingHistoryStart = (startCredentials) => ({
	type: PackingReportActionType.GET_WHOLE_PACKING_HISTORY_START,
	payload: startCredentials,
});

export const wholePackingHistorySuccess = (success) => ({
	type: PackingReportActionType.GET_WHOLE_PACKING_HISTORY_SUCCESS,
	payload: success,
});

export const wholePackingHistoryFailure = (error) => ({
	type: PackingReportActionType.GET_WHOLE_PACKING_HISTORY_FAILURE,
	payload: error,
});

export const wholePackingHistoryResponseResetStart = () => ({
	type: PackingReportActionType.GET_WHOLE_PACKING_HISTORY_RESPONSE_REST_START,
});

export const wholePackingHistoryResponseChanged = () => ({
	type: PackingReportActionType.GET_WHOLE_PACKING_HISTORY_RESPONSE_CHANGED,
});

export const addDeliveryPackingPerisitData = (data) => ({
	type: PackingReportActionType.ADD_DELIVERY_HISTORY_PERISIT_DATA,
	payload: data,
});

// DELIVERY HISTORY LIST
export const deliveryPackingHistoryStart = (startCredentials) => ({
	type: PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_START,
	payload: startCredentials,
});

export const deliveryPackingHistorySuccess = (success) => ({
	type: PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_SUCCESS,
	payload: success,
});

export const deliveryPackingHistoryFailure = (error) => ({
	type: PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_FAILURE,
	payload: error,
});

export const deliveryPackingHistoryResponseResetStart = () => ({
	type: PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_RESPONSE_REST_START,
});

export const deliveryPackingHistoryResponseChanged = () => ({
	type: PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_RESPONSE_CHANGED,
});
