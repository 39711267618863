/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import orderAssignTypes from './OrderAssign.types';

/* PINCODE LIST NEW START */
export const getPincodeNewListStart = (startData) => ({
	type: orderAssignTypes.GET_PINCODE_NEW_LIST_START,
	payload: startData,
});

export const getPincodeNewListSuccess = (success) => ({
	type: orderAssignTypes.GET_PINCODE_NEW_LIST_SUCCESS,
	payload: success,
});

export const getPincodeNewListFailure = (error) => ({
	type: orderAssignTypes.GET_PINCODE_NEW_LIST_FAILURE,
	payload: error,
});

export const getPincodeNewListReset = () => ({
	type: orderAssignTypes.GET_PINCODE_NEW_LIST_RESPONSE_RESET_START,
});

export const getPincodeNewListChanged = () => ({
	type: orderAssignTypes.GET_PINCODE_NEW_LIST_RESPONSE_CHANGED,
});
/* PINCODE LIST NEW END */

/* PINCODE WISE ORDER LIST START */
export const pincodeWiseOrderListStart = (startData) => ({
	type: orderAssignTypes.PINCODE_WISE_ORDER_LIST_START,
	payload: startData,
});

export const pincodeWiseOrderListSuccess = (success) => ({
	type: orderAssignTypes.PINCODE_WISE_ORDER_LIST_SUCCESS,
	payload: success,
});

export const pincodeWiseOrderListFailure = (error) => ({
	type: orderAssignTypes.PINCODE_WISE_ORDER_LIST_FAILURE,
	payload: error,
});

export const pincodeWiseOrderListReset = () => ({
	type: orderAssignTypes.PINCODE_WISE_ORDER_LIST_RESPONSE_RESET_START,
});

export const pincodeWiseOrderListChanged = () => ({
	type: orderAssignTypes.PINCODE_WISE_ORDER_LIST_RESPONSE_CHANGED,
});
/* PINCODE WISE ORDER LIST END */

export const itemOrderAddOrderAssignStart = (item) => ({
	type: orderAssignTypes.ADD_ORDER_ITEM_ORDER_ASSIGN,
	payload: item,
});
