/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getPincodeList = (fetchData) => {
	const data = {
		GetAllPincodeListToken: fetchData,
	};
	const url = `${API_URL}GetAllPincodeList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createPincode = (fetchData) => {
	const data = {
		CreatePincodeToken: fetchData,
	};
	const url = `${API_URL}CreatePincodeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updatePincode = (fetchData) => {
	const data = {
		UpdatePincodeToken: fetchData,
	};
	const url = `${API_URL}UpdatePincodeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deletePincode = (fetchData) => {
	const data = {
		RemovePincodeToken: fetchData,
	};
	const url = `${API_URL}DeletePincodeDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
