/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import walletCustomersTypes from './walletCustomers.types';

/* WALLET CUSTOMERS LIST START */
export const walletCustomersStart = (walletDetails) => ({
	type: walletCustomersTypes.WALLET_CUSTOMERS_LIST_START,
	payload: walletDetails,
});

export const walletCustomersSuccess = (walletSuccess) => ({
	type: walletCustomersTypes.WALLET_CUSTOMERS_LIST_SUCCESS,
	payload: walletSuccess,
});

export const walletCustomersFailure = (error) => ({
	type: walletCustomersTypes.WALLET_CUSTOMERS_LIST_FAILURE,
	payload: error,
});

export const walletCustomersChanged = () => ({
	type: walletCustomersTypes.WALLET_CUSTOMERS_LIST_CHANGED,
});

export const walletCustomersReset = () => ({
	type: walletCustomersTypes.WALLET_CUSTOMERS_LIST_RESET,
});
/* WALLET CUSTOMERS LIST END */

/* UPDATE WALLET CUSTOMERS START */
export const updateWalletCustomersStart = (walletDetails) => ({
	type: walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_START,
	payload: walletDetails,
});

export const updateWalletCustomersSuccess = (walletSuccess) => ({
	type: walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_SUCCESS,
	payload: walletSuccess,
});

export const updateWalletCustomersFailure = (error) => ({
	type: walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_FAILURE,
	payload: error,
});

export const updateWalletCustomersChanged = () => ({
	type: walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_CHANGED,
});

export const updateWalletCustomersReset = () => ({
	type: walletCustomersTypes.UPDATE_WALLET_CUSTOMERS_RESET,
});
/* UPDATE WALLET CUSTOMERS END */
