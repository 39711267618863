/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import CustomerDashboardTypes from './customerDashboard.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	totalCustomerDetailsResponse: null,
	totalCustomerDetailsArr: [],
	totalCustomerDateWiseDetailsResponse: null,
	totalCustomerDateWiseDetailsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_SUCCESS:
			return {
				...state,
				totalCustomerDetailsResponse: action.payload,
				totalCustomerDetailsArr: decodeApiResponse(
					action.payload,
					'GetTotalCustomerDashboardListKey',
				),
			};

		case CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_FAILURE:
			return { ...state, totalCustomerDetailsResponse: action.payload };

		case CustomerDashboardTypes.TOTAL_CUSTOMER_DETAILS_RESPONSE_CHANGED:
			return { ...state, totalCustomerDetailsResponse: null };

		case CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_SUCCESS:
			return {
				...state,
				totalCustomerDateWiseDetailsResponse: action.payload,
				totalCustomerDateWiseDetailsArr: decodeApiResponse(
					action.payload,
					'GetDateWiseCustomerDetailsListKey',
				),
			};

		case CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_FAILURE:
			return { ...state, totalCustomerDateWiseDetailsResponse: action.payload };

		case CustomerDashboardTypes.TOTAL_CUSTOMER_DATE_WISE_DETAILS_RESPONSE_CHANGED:
			return { ...state, totalCustomerDateWiseDetailsResponse: null };

		default:
			return state;
	}
};
