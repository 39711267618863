const priceUpdateAllProductsTypes = {
	GET_ALL_PRODUCTS_DETAILS_START: 'get_all_products_details_start',
	GET_ALL_PRODUCTS_DETAILS_SUCCESS: 'get_all_products_details_success',
	GET_ALL_PRODUCTS_DETAILS_FAILURE: 'get_all_products_details_failure',
	GET_ALL_PRODUCTS_DETAILS_RESPONSE_RESET_START: 'get_all_products_details_response_reset_start',
	GET_ALL_PRODUCTS_DETAILS_RESPONSE_CHANGED: 'get_all_products_details_response_reset_changed',

	PRICE_UPDATE_ALL_PRODUCTS_START: 'price_update_all_products_start',
	PRICE_UPDATE_ALL_PRODUCTS_SUCCESS: 'price_update_all_products_success',
	PRICE_UPDATE_ALL_PRODUCTS_FAILURE: 'price_update_all_products_failure',
	PRICE_UPDATE_ALL_PRODUCTS_RESPONSE_RESET_START:
		'price_update_all_products_response_reset_start',
	PRICE_UPDATE_ALL_PRODUCTS_RESPONSE_CHANGED: 'price_update_all_products_response_reset_changed',
};
export default priceUpdateAllProductsTypes;
