/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import loginActionTypes from './login.types';

export const signInSuccess = (user) => ({
	type: loginActionTypes.SIGN_IN_SUCCESS,
	payload: user,
});

export const signInFailure = (error) => ({
	type: loginActionTypes.SIGN_IN_FAILURE,
	payload: error,
});

export const signInStart = (userCredentials) => ({
	type: loginActionTypes.SIGN_IN_START,
	payload: userCredentials,
});

export const logOut = () => ({
	type: loginActionTypes.SIGN_IN_LOGOUT,
});

export const signInReset = () => ({
	type: loginActionTypes.SIGN_IN_RESET,
});

/* STAFF LOGOUT AND LOGIN LOG START */
export const loginAndLogoutStart = (startData) => ({
	type: loginActionTypes.LOGIN_AND_LOGOUT_LOG_START,
	payload: startData,
});

export const loginAndLogoutSuccess = (success) => ({
	type: loginActionTypes.LOGIN_AND_LOGOUT_LOG_SUCCESS,
	payload: success,
});

export const loginAndLogoutFailure = (error) => ({
	type: loginActionTypes.LOGIN_AND_LOGOUT_LOG_FAILURE,
	payload: error,
});

export const loginAndLogoutReset = () => ({
	type: loginActionTypes.LOGIN_AND_LOGOUT_LOG_RESPONSE_RESET_START,
});

export const loginAndLogoutChanged = () => ({
	type: loginActionTypes.LOGIN_AND_LOGOUT_LOG_RESPONSE_CHANGED,
});
/* STAFF LOGOUT AND LOGIN LOG END */
