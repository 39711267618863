/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import productListTypes from './addProduct.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	productListResponse: null,
	productListArr: [],
	addProductResponse: null,
	editProductResponse: null,
	getProductOptionsResponse: null,
	getProductOptionsListArr: [],
	addProductOptionsResponse: null,
	removeProductOptionsResponse: null,
	updateProductOptionsResponse: null,
};

const productListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case productListTypes.GET_PRODUCT_LIST_SUCCESS:
			return {
				...state,
				productListResponse: action.payload,
				productListArr: docodeResponse(action.payload, 'ProductListKey'),
			};

		case productListTypes.GET_PRODUCT_LIST_FAILURE:
			return {
				...state,
				productListResponse: action.payload,
				productListArr: [],
			};

		case productListTypes.GET_PRODUCT_LIST_CHANGED:
			return { ...state, productListResponse: null };

		case productListTypes.ADD_PRODUCT_SUCCESS:
			return {
				...state,
				addProductResponse: action.payload,
			};

		case productListTypes.ADD_PRODUCT_FAILURE:
			return {
				...state,
				addProductResponse: action.payload,
			};

		case productListTypes.ADD_PRODUCT_CHANGED:
			return { ...state, addProductResponse: null };

		case productListTypes.EDIT_PRODUCT_SUCCESS:
			return {
				...state,
				editProductResponse: action.payload,
			};

		case productListTypes.EDIT_PRODUCT_FAILURE:
			return {
				...state,
				editProductResponse: action.payload,
			};

		case productListTypes.EDIT_PRODUCT_CHANGED:
			return { ...state, editProductResponse: null };

		case productListTypes.GET_PRODUCT_OPTIONS_LIST_SUCCESS:
			return {
				...state,
				getProductOptionsResponse: action.payload,
				getProductOptionsListArr: docodeResponse(action.payload, 'GetProductOptionsKey'),
			};

		case productListTypes.GET_PRODUCT_OPTIONS_LIST_FAILURE:
			return {
				...state,
				getProductOptionsResponse: action.payload,
				getProductOptionsListArr: [],
			};

		case productListTypes.GET_PRODUCT_OPTIONS_LIST_CHANGED:
			return { ...state, getProductOptionsResponse: null };

		case productListTypes.ADD_PRODUCT_OPTIONS_SUCCESS:
			return {
				...state,
				addProductOptionsResponse: action.payload,
			};

		case productListTypes.ADD_PRODUCT_OPTIONS_FAILURE:
			return {
				...state,
				addProductOptionsResponse: action.payload,
			};

		case productListTypes.ADD_PRODUCT_OPTIONS_CHANGED:
			return { ...state, addProductOptionsResponse: null };

		case productListTypes.REMOVE_PRODUCT_OPTIONS_SUCCESS:
			return {
				...state,
				removeProductOptionsResponse: action.payload,
			};

		case productListTypes.REMOVE_PRODUCT_OPTIONS_FAILURE:
			return {
				...state,
				removeProductOptionsResponse: action.payload,
			};

		case productListTypes.REMOVE_PRODUCT_OPTIONS_CHANGED:
			return {
				...state,
				removeProductOptionsResponse: null,
			};

		case productListTypes.UPDATE_PRODUCT_OPTIONS_SUCCESS:
			return {
				...state,
				updateProductOptionsResponse: action.payload,
			};

		case productListTypes.UPDATE_PRODUCT_OPTIONS_FAILURE:
			return {
				...state,
				updateProductOptionsResponse: action.payload,
			};

		case productListTypes.UPDATE_PRODUCT_OPTIONS_CHANGED:
			return {
				...state,
				updateProductOptionsResponse: null,
			};

		default:
			return state;
	}
};

export default productListReducer;
