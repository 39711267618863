/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import staffListTypes from './staffList.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	staffListResponse: null,
	staffListArr: [],
	staffCreateResponse: null,
	staffUpdateResponse: null,
	staffDeleteResponse: null,
};

const staffListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case staffListTypes.STAFF_LIST_SUCCESS:
			return {
				...state,
				staffListResponse: action.payload,
				staffListArr: docodeResponse(action.payload, 'StaffListKey'),
			};

		case staffListTypes.STAFF_LIST_FAILURE:
			return {
				...state,
				staffListResponse: action.payload,
				staffListArr: [],
			};

		case staffListTypes.STAFF_LIST_CHANGED:
			return { ...state, staffListResponse: null };

		case staffListTypes.STAFF_CREATE_REPSONSE_SUCCESS:
			return { ...state, staffCreateResponse: action.payload };
		case staffListTypes.STAFF_CREATE_REPSONSE_FAILURE:
			return { ...state, staffCreateResponse: action.payload };
		case staffListTypes.STAFF_CREATE_REPSONSE_CHANGED:
			return { ...state, staffCreateResponse: null };

		case staffListTypes.STAFF_UPDATE_REPSONSE_SUCCESS:
			return { ...state, staffUpdateResponse: action.payload };
		case staffListTypes.STAFF_UPDATE_REPSONSE_FAILURE:
			return { ...state, staffUpdateResponse: action.payload };
		case staffListTypes.STAFF_UPDATE_REPSONSE_CHANGED:
			return { ...state, staffUpdateResponse: null };

		case staffListTypes.STAFF_DELETE_REPSONSE_SUCCESS:
			return { ...state, staffDeleteResponse: action.payload };
		case staffListTypes.STAFF_DELETE_REPSONSE_FAILURE:
			return { ...state, staffDeleteResponse: action.payload };
		case staffListTypes.STAFF_DELETE_REPSONSE_CHANGED:
			return { ...state, staffDeleteResponse: null };

		default:
			return state;
	}
};

export default staffListReducer;
