/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getTodayDelivered = (fetchData) => {
	const data = {
		ProductOrderListViewToken: fetchData,
	};
	const url = `${API_URL}OrderListFetch`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const viewTodayOrderList = (fetchData) => {
	const data = {
		ViewOrderDetailsToken: fetchData,
	};
	const url = `${API_URL}OrderDetailsView`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
