/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */

import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useHoverDirty } from 'react-use';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Spinner from '../../../components/bootstrap/Spinner';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import {
	signInStart,
	signInReset,
	loginAndLogoutStart,
	loginAndLogoutReset,
} from '../../../redux/login/login.actions';
import {
	selectCurrentUser,
	selectLoginAndLogoutLogResp,
} from '../../../redux/login/login.selector';
import { dashboardMenu } from '../../../menu';
import Icon from '../../../components/icon/Icon';
import showNotification from '../../../components/extras/showNotification';

const LoginHeader = ({ isNewUser }) => {
	if (isNewUser) {
		return (
			<>
				<div className='text-center h1 fw-bold mt-5'>Create Account,</div>
				<div className='text-center h4 text-muted mb-5'>Sign up to get started!</div>
			</>
		);
	}
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
};

const Login = ({ isSignUp }) => {
	const { darkModeStatus } = useDarkMode();
	const dispatch = useDispatch();
	const [usernameInput, setUsernameInput] = useState(false);
	const [isNewUser, setIsNewUser] = useState(isSignUp);
	const getCurrentUserLoginDetails = useSelector(selectCurrentUser);
	const loginAndLogoutLogResp = useSelector(selectLoginAndLogoutLogResp);
	const [loginstatus, setLoginstatus] = useState(false);
	const ref = useRef(null);
	const isHovering = useHoverDirty(ref);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	// const handleOnClick = useCallback(() => navigate('/dashboard'), [navigate]);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (getCurrentUserLoginDetails != null) {
			if (getCurrentUserLoginDetails.statusCode === '01' && !loginstatus) {
				const stData = {
					StaffID: getCurrentUserLoginDetails.response[0].StaffID,
					LogType: 'LOGIN',
				};
				dispatch(loginAndLogoutStart(stData));
				navigate(`${dashboardMenu.dashboard.path}`);
				setLoginstatus(true);
			} else if (getCurrentUserLoginDetails.statusCode === '02') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Cancel' size='lg' className='me-1' />
						<span>Mobile Number Not Exist</span>
					</span>,
					'Wrong MobileNumber',
				);
				dispatch(signInReset());
			} else if (getCurrentUserLoginDetails.statusCode === '03') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Cancel' size='lg' className='me-1' />
						<span>User Password Mismatch</span>
					</span>,
					'Wrong Password',
				);
				dispatch(signInReset());
			}
		}

		if (loginAndLogoutLogResp !== null) {
			dispatch(loginAndLogoutReset());
		}
	});
	const formik = useFormik({
		initialValues: {
			phoneNumber: '',
			password: '',
		},
		// handleChange: (e, from) => {
		// 	if (from === 'ph') {
		// 		formik({ phoneNumber: e.target.value });
		// 	} else {
		// 		formik({ password: e.target.value });
		// 	}
		// },
		handleBlur: (from) => {
			if (from === 'ph') {
				if (phoneNumber.length < 10) {
					showNotification(
						<span className='d-flex align-items-center'>
							<Icon icon='Info' size='lg' className='me-1' />
							<span>Please Enter Correct MobileNumber</span>
						</span>,
						'Valid MobileNumber',
					);
				}
			}
		},
		onSubmit: (values) => {
			// if (values.phoneNumber !== '' && values.password !== '') {
			// dispatch(signInStart({ values }));
			// dispatch(
			// 	signInStart({
			// 		phoneNumber: values.phoneNumber,
			// 		password: values.password,
			// 	}),
			// );
			if (values.phoneNumber === '') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='HourglassEmpty' size='lg' className='me-1' />
						<span>Please Enter the MobileNumber</span>
					</span>,
					'Empty MobileNumber',
				);
			} else if (values.phoneNumber.length < 10) {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='Info' size='lg' className='me-1' />
						<span>Please Enter Correct MobileNumber</span>
					</span>,
					'Valid MobileNumber',
				);
			} else if (values.password === '') {
				showNotification(
					<span className='d-flex align-items-center'>
						<Icon icon='HourglassEmpty' size='lg' className='me-1' />
						<span>Please Enter Password</span>
					</span>,
					'Empty Password',
				);
			} else {
				dispatch(signInStart({ values }));
			}
			// }
		},
	});

	return (
		<PageWrapper
			title={isNewUser ? 'Sign Up' : 'Login'}
			className={classNames({ 'bg-warning': !isNewUser, 'bg-info': !!isNewUser })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<Logo width={200} />
									</Link>
								</div>
								{/* <div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-lo10-dark': darkModeStatus,
									})}>
									<div className='row row-cols-2 g-3 pb-3 px-3 mt-0'>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setUsernameInput(false);
													setIsNewUser(!isNewUser);
												}}>
												Login
											</Button>
										</div>
										<div className='col'>
											<Button
												color={darkModeStatus ? 'light' : 'dark'}
												isLight={!isNewUser}
												className='rounded-1 w-100'
												size='lg'
												onClick={() => {
													setUsernameInput(false);
													setIsNewUser(!isNewUser);
												}}>
												Sign Up
											</Button>
										</div>
									</div>
								</div> */}

								<LoginHeader isNewUser={isNewUser} />

								<form className='row g-4' onSubmit={formik.handleSubmit}>
									<div className='col-12'>
										{/* {!usernameInput ? (
											<FormGroup
												id='login-username'
												isFloating
												label='Your email or username'>
												<Input
													name='phoneNumber'
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													value={formik.values.phoneNumber}
													isTouched={formik.touched.Department}
													autoComplete='username'
												/>
											</FormGroup>
										) : (
											<FormGroup
												id='login-password'
												isFloating
												label='Password'>
												<Input
													type='password'
													value={formik.values.password}
													onChange={formik.handleChange}
													onBlur={formik.handleBlur}
													isTouched={formik.touched.Department}
													name='password'
													autoComplete='password'
												/>
											</FormGroup>
										)} */}
										<FormGroup
											id='login-username'
											isFloating
											label='Mobilenumber'>
											<Input
												name='phoneNumber'
												type='tel'
												autoComplete='tel'
												mask='9999999999'
												onChange={formik.handleChange}
												onBlur={formik.handleBlur('ph')}
												value={formik.values.phoneNumber}
												isTouched={formik.touched.Department}
												// autoComplete='username'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='login-password' isFloating label='Password'>
											<Input
												type='password'
												value={formik.values.password}
												onChange={formik.handleChange}
												isTouched={formik.touched.Department}
												name='password'
												autoComplete='password'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										{/* {!usernameInput ? (
											<Button
												color='warning'
												type='button'
												className='w-100 py-3'
												onClick={() => setUsernameInput(true)}>
												Continue
											</Button>
										) : (
											<Button
												color='warning'
												type='submit'
												className='w-100 py-3'>
												Login
											</Button>
										)} */}
										<Button
											color='warning'
											type='submit'
											className='w-100 py-3'
											ref={ref}
											// icon={isLoading ? null : 'PublishedWithChanges'}
											onClick={() => {
												ref.current.blur();
												setIsLoading(true);
											}}>
											{getCurrentUserLoginDetails !== null &&
											getCurrentUserLoginDetails.statusCode === '01' &&
											isLoading ? (
												<Spinner
													// color={isHovering ? 'light' : 'info'}
													color='dark'
													inButton
													isSmall>
													Loading...
												</Spinner>
											) : null}
											Login
										</Button>
									</div>
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': isNewUser,
									'link-dark': !isNewUser,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};
Login.propTypes = {
	isSignUp: PropTypes.bool,
};
Login.defaultProps = {
	isSignUp: false,
};

export default Login;
