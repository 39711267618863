const priceUpdateTypes = {
	PRICE_UPDATE_LIST_START: 'PRICE_UPDATE_LIST_START',
	PRICE_UPDATE_LIST_SUCCESS: 'PRICE_UPDATE_LIST_SUCCESS',
	PRICE_UPDATE_LIST_FAILURE: 'PRICE_UPDATE_LIST_FAILURE',
	PRICE_UPDATE_LIST_RESET: 'PRICE_UPDATE_LIST_RESET',
	PRICE_UPDATE_LIST_CHANGED: 'PRICE_UPDATE_LIST_CHANGED',

	PRICE_UPDATE_START: 'PRICE_UPDATE_START',
	PRICE_UPDATE_SUCCESS: 'PRICE_UPDATE_SUCCESS',
	PRICE_UPDATE_FAILURE: 'PRICE_UPDATE_FAILURE',
	PRICE_UPDATE_RESET: 'PRICE_UPDATE_RESET',
	PRICE_UPDATE_CHANGED: 'PRICE_UPDATE_CHANGED',
};
export default priceUpdateTypes;
