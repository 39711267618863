/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import dashBoardTypes from './DashBoard.types';

/* DASHBOARD LIST START */
export const dashBoardListStart = (listDetails) => ({
	type: dashBoardTypes.GET_DASHBOARD_LIST_START,
	payload: listDetails,
});

export const dashBoardListSuccess = (listSuccess) => ({
	type: dashBoardTypes.GET_DASHBOARD_LIST_SUCCESS,
	payload: listSuccess,
});

export const dashBoardListFailure = (error) => ({
	type: dashBoardTypes.GET_DASHBOARD_LIST_FAILURE,
	payload: error,
});

export const dashBoardListChanged = () => ({
	type: dashBoardTypes.GET_DASHBOARD_LIST_CHANGED,
});

export const dashBoardListReset = () => ({
	type: dashBoardTypes.GET_DASHBOARD_LIST_RESET,
});
/* DASHBOARD LIST END */

/* DASHBOARD VIEW LIST START */
export const dashBoardViewListStart = (listDetails) => ({
	type: dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_START,
	payload: listDetails,
});

export const dashBoardViewListSuccess = (listSuccess) => ({
	type: dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_SUCCESS,
	payload: listSuccess,
});

export const dashBoardViewListFailure = (error) => ({
	type: dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_FAILURE,
	payload: error,
});

export const dashBoardViewListChanged = () => ({
	type: dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_CHANGED,
});

export const dashBoardViewListReset = () => ({
	type: dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_RESET,
});
/* DASHBOARD VIEW LIST END */

/* DASHBOARD CUSTOMER LIST START */
export const dashBoardCustomerListStart = (listDetails) => ({
	type: dashBoardTypes.DASHBOARD_CUSTOMER_LIST_START,
	payload: listDetails,
});

export const dashBoardCustomerListSuccess = (listSuccess) => ({
	type: dashBoardTypes.DASHBOARD_CUSTOMER_LIST_SUCCESS,
	payload: listSuccess,
});

export const dashBoardCustomerListFailure = (error) => ({
	type: dashBoardTypes.DASHBOARD_CUSTOMER_LIST_FAILURE,
	payload: error,
});

export const dashBoardCustomerListChanged = () => ({
	type: dashBoardTypes.DASHBOARD_CUSTOMER_LIST_CHANGED,
});

export const dashBoardCustomerListReset = () => ({
	type: dashBoardTypes.DASHBOARD_CUSTOMER_LIST_RESET,
});
/* DASHBOARD CUSTOMER LIST END */

/** ***************** FETCH ORDER SALES DETAILS START *********************** */
export const fetchOrderSalesDetailStart = (fetchdata) => ({
	type: dashBoardTypes.FETCH_ORDER_SALES_DETAILS_START,
	payload: fetchdata,
});

export const fetchOrderSalesDetailSuccess = (successData) => ({
	type: dashBoardTypes.FETCH_ORDER_SALES_DETAILS_SUCCESS,
	payload: successData,
});

export const fetchOrderSalesDetailFailure = (failureData) => ({
	type: dashBoardTypes.FETCH_ORDER_SALES_DETAILS_FAILURE,
	payload: failureData,
});

export const fetchOrderSalesDetailResponseResetStart = () => ({
	type: dashBoardTypes.FETCH_ORDER_SALES_DETAILS_RESPONSE_RESET_START,
});

export const fetchOrderSalesDetailResponseChanged = () => ({
	type: dashBoardTypes.FETCH_ORDER_SALES_DETAILS_RESPONSE_CHANGED,
});

/** ***************** FETCH ORDER SALES DETAILS END *********************** */

/** ***************** FETCH ONLINE PAYMENTS CREATED DETAILS START *********************** */
export const totalOnlineCreatedListStart = (fetchdata) => ({
	type: dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_START,
	payload: fetchdata,
});

export const totalOnlineCreatedListSuccess = (successData) => ({
	type: dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_SUCCESS,
	payload: successData,
});

export const totalOnlineCreatedListFailure = (failureData) => ({
	type: dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_FAILURE,
	payload: failureData,
});

export const totalOnlineCreatedListResponseResetStart = () => ({
	type: dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_RESPONSE_RESET_START,
});

export const totalOnlineCreatedListResponseChanged = () => ({
	type: dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_RESPONSE_CHANGED,
});

/** ***************** FETCH ONLINE PAYMENTS CREATED DETAILS END *********************** */

/** ***************** DASHBOARD TRANSACTION CHECK START *********************** */
export const updateTransactionStatusDashboardStart = (fetchdata) => ({
	type: dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_START,
	payload: fetchdata,
});

export const updateTransactionStatusDashboardSuccess = (successData) => ({
	type: dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_SUCCESS,
	payload: successData,
});

export const updateTransactionStatusDashboardFailure = (failureData) => ({
	type: dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_FAILURE,
	payload: failureData,
});

export const updateTransactionStatusDashboardReset = () => ({
	type: dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_RESPONSE_RESET_START,
});

export const updateTransactionStatusDashboardChanged = () => ({
	type: dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_RESPONSE_CHANGED,
});

/** ***************** DASHBOARD TRANSACTION CHECK END *********************** */
