/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import TodayEodsTypes from './today-eods.types';
import {
	getTodayEodsStaffList,
	getTodayEodsOrderList,
	orderStatusUpdate,
} from './today-eods.service';
import {
	fetchTodayEodsStaffListSuccess,
	fetchTodayEodsStaffListFailure,
	fetchTodayEodsStaffListResponseChanged,
	fetchTodayEodsOrderListSuccess,
	fetchTodayEodsOrderListFailure,
	fetchTodayEodsOrderListResponseChanged,
	updateOrderStatusSuccess,
	updateOrderStatusFailure,
	updateOrderStatusResponseChanged,
} from './today-eods.action';
import { encodeJson } from '../enode-decode';

/** *********************  START TODAY EODS STAFF LIST  ******************** */
export function* fetchTodayEodsStaffList({ payload }) {
	try {
		const key = 'GetTodayEodsStaffListKey';
		const jsonData = {
			Limit: payload.limit,
			SearchValueSet: payload.searchValueSet,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getTodayEodsStaffList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchTodayEodsStaffListFailure(responseData.data));
		} else {
			yield put(fetchTodayEodsStaffListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchTodayEodsStaffListFailure(error));
	}
}

export function* resetFetchTodayEodsStaffListResponse() {
	yield put(fetchTodayEodsStaffListResponseChanged());
}

export function* onFetchTodayEodsStaffList() {
	yield takeLatest(TodayEodsTypes.TODAY_EODS_STAFF_LIST_START, fetchTodayEodsStaffList);
}

export function* onTodayEodsStaffListResponseReset() {
	yield takeLatest(
		TodayEodsTypes.TODAY_EODS_STAFF_LIST_RESPONSE_RESET_START,
		resetFetchTodayEodsStaffListResponse,
	);
}
/** *********************  END TODAY EODS STAFF LIST ******************** */

/** *********************  START TODAY EODS ORDER LIST  ******************** */
export function* fetchTodayEodsOrderList({ payload }) {
	try {
		const key = 'GetTodayEodsOrderListKey';
		const jsonData = {
			Limit: payload.limit,
			SearchValueSet: payload.searchValueSet,
			OrderFilter: payload.orderFilter,
			StaffID: payload.staffID,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getTodayEodsOrderList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchTodayEodsOrderListFailure(responseData.data));
		} else {
			yield put(fetchTodayEodsOrderListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchTodayEodsOrderListFailure(error));
	}
}

export function* resetFetchTodayEodsOrderListResponse() {
	yield put(fetchTodayEodsOrderListResponseChanged());
}

export function* onFetchTodayEodsOrderList() {
	yield takeLatest(TodayEodsTypes.TODAY_EODS_ORDER_LIST_START, fetchTodayEodsOrderList);
}

export function* onTodayEodsOrderListResponseReset() {
	yield takeLatest(
		TodayEodsTypes.TODAY_EODS_ORDER_LIST_RESPONSE_RESET_START,
		resetFetchTodayEodsOrderListResponse,
	);
}
/** *********************  END TODAY EODS ORDER LIST ******************** */

/** *********************  START UPDATE ORDER STATUS LIST  ******************** */
export function* updateOrderStatus({ payload }) {
	try {
		const key = 'UpdateOrderStatusKey';
		const jsonData = {
			UserID: payload.userID,
			OrderFromCustomerID: payload.orderFromCustomerID,
			OrderID: payload.orderID,
			StaffID: payload.staffID,
			TotalPaidAmount: payload.totalPaidAmount,
			DeliveryNotes: payload.deliveryNotes,
			CancelledNotes: payload.cancelledNotes,
			OrderCategory: payload.orderCategory,
			OrderStatus: payload.orderStatus,
			OrderType: payload.orderType,
			IsUsedWallet: payload.isUsedWallet,
			RemaingWalletAmount: payload.remaingWalletAmount,
			UsedWalletAmount: payload.usedWalletAmount,
			CouponID: payload.couponID,
			ReferralCode: payload.referralCode,
		};
		const ServiceName =
			payload.orderStatus === 'Delivered'
				? 'updateOrderDeliveredNew'
				: 'updateOrderCancelledNew';
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(orderStatusUpdate, encodeData, ServiceName);
		if (responseData.data.statusCode === '100') {
			yield put(updateOrderStatusFailure(responseData.data));
		} else {
			yield put(updateOrderStatusSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateOrderStatusFailure(error));
	}
}

export function* resetUpdateOrderStatusResponse() {
	yield put(updateOrderStatusResponseChanged());
}

export function* onUpdateOrderStatus() {
	yield takeLatest(TodayEodsTypes.UPDATE_ORDER_STATUS_START, updateOrderStatus);
}

export function* onUpdateOrderStatusResponseReset() {
	yield takeLatest(
		TodayEodsTypes.UPDATE_ORDER_STATUS_RESPONSE_RESET_START,
		resetUpdateOrderStatusResponse,
	);
}
/** *********************  END UPDATE ORDER STATUS LIST ******************** */

export function* todayEodsSaga() {
	yield all([
		call(onFetchTodayEodsStaffList),
		call(onTodayEodsStaffListResponseReset),
		call(onFetchTodayEodsOrderList),
		call(onTodayEodsOrderListResponseReset),
		call(onUpdateOrderStatus),
		call(onUpdateOrderStatusResponseReset),
	]);
}
