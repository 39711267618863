// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable array-callback-return */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
// import JWT from 'expo-jwt';
export const addStaffItem = (staffItems, staffItemToAdd) => {
	const orders = staffItems.map((item) => {
		return item.StaffID === staffItemToAdd.StaffID ? { ...item, ...staffItemToAdd } : item;
	});
	return orders;
};

export const staffDetails = (orderItems) => {
	let NoOfStaff = 0;
	orderItems.map(() => {
		NoOfStaff += 1;
	});
	const data = {
		NoOfStaff,
	};
	return data;
};
