/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import ruleAddListTypes from './ruleAddList.types';
import generalTypesAction from '../genera.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	ruleListResponse: null,
	ruleListResponseStatus: null,
	ruleListResponseDecode: null,
	ruleCreateResponse: null,
	ruleCreateStatus: null,
	ruleUpdateResponse: null,
	ruleUpdateStatus: null,
};

const ruleListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ruleAddListTypes.RULE_LIST_SUCCESS:
			return {
				...state,
				ruleListResponse: action.payload,
				ruleListResponseDecode: decodeApiResponse(action.payload, generalTypesAction.RULE),
				ruleListResponseStatus: 'Success',
			};

		case ruleAddListTypes.RULE_LIST_FAILURE:
			return {
				...state,
				ruleListResponse: action.payload,
				ruleListResponseDecode: action.payload,
				ruleListResponseStatus: 'Failure',
			};

		case ruleAddListTypes.RULE_LIST_CHANGED:
			return {
				...state,
				ruleListResponse: null,
				ruleListResponseDecode: null,
				ruleListResponseStatus: null,
			};

		case ruleAddListTypes.RULE_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				ruleCreateResponse: action.payload,
				ruleCreateStatus: 'Success',
			};
		case ruleAddListTypes.RULE_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				ruleCreateResponse: action.payload,
				ruleCreateStatus: 'Failure',
			};
		case ruleAddListTypes.RULE_CREATE_REPSONSE_CHANGED:
			return { ...state, ruleCreateResponse: null, ruleCreateStatus: null };

		case ruleAddListTypes.RULE_UPDATE_REPSONSE_SUCCESS:
			return {
				...state,
				ruleUpdateResponse: action.payload,
				ruleUpdateStatus: 'Success',
			};
		case ruleAddListTypes.RULE_UPDATE_REPSONSE_FAILURE:
			return {
				...state,
				ruleUpdateResponse: action.payload,
				ruleUpdateStatus: 'Failure',
			};
		case ruleAddListTypes.RULE_UPDATE_REPSONSE_CHANGED:
			return { ...state, ruleUpdateResponse: null, ruleUpdateStatus: null };

		default:
			return state;
	}
};

export default ruleListReducer;
