/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import purchaseListTypes from './purchaseList.types';

/* PURCHASE LIST START */
export const purchaseListStart = (purchaseDetails) => ({
	type: purchaseListTypes.PURCHASE_LIST_START,
	payload: purchaseDetails,
});

export const purchaseListSuccess = (purchaseSuccess) => ({
	type: purchaseListTypes.PURCHASE_LIST_SUCCESS,
	payload: purchaseSuccess,
});

export const purchaseListFailure = (error) => ({
	type: purchaseListTypes.PURCHASE_LIST_FAILURE,
	payload: error,
});

export const purchaseListChanged = () => ({
	type: purchaseListTypes.PURCHASE_LIST_CHANGED,
});

export const purchaseListReset = () => ({
	type: purchaseListTypes.PURCHASE_LIST_RESET,
});
/* PURCHASE LIST END */
