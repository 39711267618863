/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import vendorProductsTypes from './productsDealsByVendor.types';

const INITIAL_STATE = {
	vendorProductsListResponse: null,
	vendorProductsListStatus: null,
	vendorViewProductsResponse: null,
	vendorViewProductsStatus: null,
};

const vendorProductsListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case vendorProductsTypes.VENDOR_PRODUCTS_LIST_SUCCESS:
			return {
				...state,
				vendorProductsListResponse: action.payload,
				vendorProductsListStatus: 'Success',
			};

		case vendorProductsTypes.VENDOR_PRODUCTS_LIST_FAILURE:
			return {
				...state,
				vendorProductsListResponse: action.payload,
				vendorProductsListStatus: 'Failure',
			};

		case vendorProductsTypes.VENDOR_PRODUCTS_LIST_CHANGED:
			return { ...state, vendorProductsListResponse: null, vendorProductsListStatus: null };

		case vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_SUCCESS:
			return {
				...state,
				vendorViewProductsResponse: action.payload,
				vendorViewProductsStatus: 'Success',
			};

		case vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_FAILURE:
			return {
				...state,
				vendorViewProductsResponse: action.payload,
				vendorViewProductsStatus: 'Failure',
			};

		case vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_CHANGED:
			return { ...state, vendorViewProductsResponse: null, vendorViewProductsStatus: null };

		default:
			return state;
	}
};

export default vendorProductsListReducer;
