const productOrderTypes = {
	PRODUCT_ORDER_LIST_START: 'PRODUCT_ORDER_LIST_START',
	PRODUCT_ORDER_LIST_SUCCESS: 'PRODUCT_ORDER_LIST_SUCCESS',
	PRODUCT_ORDER_LIST_FAILURE: 'PRODUCT_ORDER_LIST_FAILURE',
	PRODUCT_ORDER_LIST_RESET: 'PRODUCT_ORDER_LIST_RESET',
	PRODUCT_ORDER_LIST_CHANGED: 'PRODUCT_ORDER_LIST_CHANGED',

	VIEW_PRODUCT_ORDER_LIST_START: 'VIEW_PRODUCT_ORDER_LIST_START',
	VIEW_PRODUCT_ORDER_LIST_SUCCESS: 'VIEW_PRODUCT_ORDER_LIST_SUCCESS',
	VIEW_PRODUCT_ORDER_LIST_FAILURE: 'VIEW_PRODUCT_ORDER_LIST_FAILURE',
	VIEW_PRODUCT_ORDER_LIST_RESET: 'VIEW_PRODUCT_ORDER_LIST_RESET',
	VIEW_PRODUCT_ORDER_LIST_CHANGED: 'VIEW_PRODUCT_ORDER_LIST_CHANGED',

	UPDATE_ORDER_STATUS_START: 'UPDATE_ORDER_STATUS_START',
	UPDATE_ORDER_STATUS_SUCCESS: 'UPDATE_ORDER_STATUS_SUCCESS',
	UPDATE_ORDER_STATUS_FAILURE: 'UPDATE_ORDER_STATUS_FAILURE',
	UPDATE_ORDER_STATUS_RESET: 'UPDATE_ORDER_STATUS_RESET',
	UPDATE_ORDER_STATUS_CHANGED: 'UPDATE_ORDER_STATUS_CHANGED',

	VIEW_USER_TRANSCATION_LIST_START: 'VIEW_USER_TRANSCATION_LIST_START',
	VIEW_USER_TRANSCATION_LIST_SUCCESS: 'VIEW_USER_TRANSCATION_LIST_SUCCESS',
	VIEW_USER_TRANSCATION_LIST_FAILURE: 'VIEW_USER_TRANSCATION_LIST_FAILURE',
	VIEW_USER_TRANSCATION_LIST_RESET: 'VIEW_USER_TRANSCATION_LIST_RESET',
	VIEW_USER_TRANSCATION_LIST_CHANGED: 'VIEW_USER_TRANSCATION_LIST_CHANGED',
};
export default productOrderTypes;
