/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getListOfCustomer = (postListValue) => {
	const data = {
		GetCustomerDetailsToken: postListValue,
	};
	const url = `${API_URL}GetListOfCustomer`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const listOfOnCod = (postListValue) => {
	const data = {
		OnUserID: postListValue.OnUserID,
	};
	const url = `${API_URL}UpdateOnCod`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const listOfOffCod = (postListValue) => {
	const data = {
		OffUserID: postListValue.OffUserID,
	};
	const url = `${API_URL}UpdateOffCod`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getListViewCutomerHistory = (postListValue) => {
	const data = {
		ViewCutomerHistoryToken: postListValue,
	};
	const url = `${API_URL}GetListViewCutomerHistory`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteCustomer = (postListValue) => {
	const data = {
		RemoveCustomerData: postListValue,
	};
	const url = `${API_URL}RemoveCustomer`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getListViewCutomerOrder = (postListValue) => {
	const data = {
		ViewCustomerOrderListToken: postListValue,
	};
	const url = `${API_URL}GetViewCustomerOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addNewCustomer = (postListValue) => {
	const data = {
		UserName: postListValue.UserName,
		PhoneNumber: postListValue.PhoneNumber,
		EmailID: postListValue.EmailID,
		LocationName: postListValue.LocationName,
		Address: postListValue.Address,
		LandMark: postListValue.LandMark,
		PinCode: postListValue.PinCode,
		CityID: postListValue.CityID,
		StateID: postListValue.StateID,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${API_URL}AddNewCustomer`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
