/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
const CustomerDashboardTypes = {
	TOTAL_CUSTOMER_DETAILS_START: 'total_customer_details_start',
	TOTAL_CUSTOMER_DETAILS_SUCCESS: 'total_customer_details_success',
	TOTAL_CUSTOMER_DETAILS_FAILURE: 'total_customer_details_failure',
	TOTAL_CUSTOMER_DETAILS_RESPONSE_RESET_START: 'total_customer_details_response_reset_start',
	TOTAL_CUSTOMER_DETAILS_RESPONSE_CHANGED: 'total_customer_details_response_reset_changed',

	TOTAL_CUSTOMER_DATE_WISE_DETAILS_START: 'total_customer_date_wise_details_start',
	TOTAL_CUSTOMER_DATE_WISE_DETAILS_SUCCESS: 'total_customer_date_wise_details_success',
	TOTAL_CUSTOMER_DATE_WISE_DETAILS_FAILURE: 'total_customer_date_wise_details_failure',
	TOTAL_CUSTOMER_DATE_WISE_DETAILS_RESPONSE_RESET_START: 'total_customer_date_wise_details_response_reset_start',
	TOTAL_CUSTOMER_DATE_WISE_DETAILS_RESPONSE_CHANGED: 'total_customer_date_wise_details_response_reset_changed',
};
export default CustomerDashboardTypes;
