const repackedTypes = {
	REPACKED_LIST_START: 'REPACKED_LIST_START',
	REPACKED_LIST_SUCCESS: 'REPACKED_LIST_SUCCESS',
	REPACKED_LIST_FAILURE: 'REPACKED_LIST_FAILURE',
	REPACKED_LIST_RESET: 'REPACKED_LIST_RESET',
	REPACKED_LIST_CHANGED: 'REPACKED_LIST_CHANGED',

	REPACKED_CREATE_START: 'REPACKED_CREATE_START',
	REPACKED_CREATE_SUCCESS: 'REPACKED_CREATE_SUCCESS',
	REPACKED_CREATE_FAILURE: 'REPACKED_CREATE_FAILURE',
	REPACKED_CREATE_CHANGED: 'REPACKED_CREATE_CHANGED',
	REPACKED_CREATE_RESET: 'REPACKED_CREATE_RESET',

	REPACKED_UPDATE_START: 'REPACKED_UPDATE_START',
	REPACKED_UPDATE_SUCCESS: 'REPACKED_UPDATE_SUCCESS',
	REPACKED_UPDATE_FAILURE: 'REPACKED_UPDATE_FAILURE',
	REPACKED_UPDATE_CHANGED: 'REPACKED_UPDATE_CHANGED',
	REPACKED_UPDATE_RESET: 'REPACKED_UPDATE_RESET',
};
export default repackedTypes;
