/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import repackedTypes from './rePacked.types';
import { getRepackedList, createRepacked, updateRepacked } from './rePacked.service';
import {
	repackedListSuccess,
	repackedListFailure,
	repackedListChanged,
	createRepackedSuccess,
	createRepackedFailure,
	createRepackedChanged,
	updateRepackedSuccess,
	updateRepackedFailure,
	updateRepackedChanged,
} from './rePacked.action';

/* REPACKED  LIST START */
export function* repackedListDetailsStart({ payload: { listOfRepacked } }) {
	try {
		const responseData = yield call(getRepackedList, listOfRepacked);
		if (responseData.data.statusCode === '100') {
			yield put(repackedListFailure(responseData.data));
		} else {
			yield put(repackedListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(repackedListFailure(error));
	}
}

export function* repackedListDetailsChanged() {
	yield put(repackedListChanged());
}

export function* repackedListDetails() {
	yield takeLatest(repackedTypes.REPACKED_LIST_START, repackedListDetailsStart);
}

export function* repackedListResponseReset() {
	yield takeLatest(repackedTypes.REPACKED_LIST_RESET, repackedListDetailsChanged);
}
/* REPACKED  LIST END */

/* REPACKED CREATE START */
export function* createNewRepacked({ payload: { values } }) {
	try {
		const jsonData = {
			RepackedDate: values.repackedDate,
			ExpiredOn: values.expiredOn,
		};
		const responseData = yield call(createRepacked, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(createRepackedFailure(responseData.data));
		} else {
			yield put(createRepackedSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createRepackedFailure(error));
	}
}

export function* createRepackedResponseReset() {
	yield put(createRepackedChanged());
}

export function* repackedCreateStart() {
	yield takeLatest(repackedTypes.REPACKED_CREATE_START, createNewRepacked);
}

export function* repackedCreateResponseReset() {
	yield takeLatest(repackedTypes.REPACKED_CREATE_RESET, createRepackedResponseReset);
}
/* REPACKED CREATE END */

/* REPACKED UPDATE START */
export function* updateRepackedDetails({ payload: { values } }) {
	try {
		const jsonData = {
			RepackedID: values.repackedID,
			RepackedDate: values.repackedDate,
			ExpiredOn: values.expiredOn,
		};
		const responseData = yield call(updateRepacked, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(updateRepackedFailure(responseData.data));
		} else {
			yield put(updateRepackedSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateRepackedFailure(error));
	}
}

export function* updateRepackedResponseReset() {
	yield put(updateRepackedChanged());
}

export function* repackedUpdateStart() {
	yield takeLatest(repackedTypes.REPACKED_UPDATE_START, updateRepackedDetails);
}

export function* repackedUpdateResponseReset() {
	yield takeLatest(repackedTypes.REPACKED_UPDATE_RESET, updateRepackedResponseReset);
}
/* REPACKED UPDATE END */

export function* repackedListSaga() {
	yield all([
		call(repackedListDetails),
		call(repackedListResponseReset),
		call(repackedCreateStart),
		call(repackedCreateResponseReset),
		call(repackedUpdateStart),
		call(repackedUpdateResponseReset),
	]);
}
