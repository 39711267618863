const dashBoardTypes = {
	GET_DASHBOARD_LIST_START: 'GET_DASHBOARD_LIST_START',
	GET_DASHBOARD_LIST_SUCCESS: 'GET_DASHBOARD_LIST_SUCCESS',
	GET_DASHBOARD_LIST_FAILURE: 'GET_DASHBOARD_LIST_FAILURE',
	GET_DASHBOARD_LIST_CHANGED: 'GET_DASHBOARD_LIST_CHANGED',
	GET_DASHBOARD_LIST_RESET: 'GET_DASHBOARD_LIST_RESET',

	VIEW_DASHBOARD_ORDER_LIST_START: 'VIEW_DASHBOARD_ORDER_LIST_START',
	VIEW_DASHBOARD_ORDER_LIST_SUCCESS: 'VIEW_DASHBOARD_ORDER_LIST_SUCCESS',
	VIEW_DASHBOARD_ORDER_LIST_FAILURE: 'VIEW_DASHBOARD_ORDER_LIST_FAILURE',
	VIEW_DASHBOARD_ORDER_LIST_RESET: 'VIEW_DASHBOARD_ORDER_LIST_RESET',
	VIEW_DASHBOARD_ORDER_LIST_CHANGED: 'VIEW_DASHBOARD_ORDER_LIST_CHANGED',

	DASHBOARD_CUSTOMER_LIST_START: 'DASHBOARD_CUSTOMER_LIST_START',
	DASHBOARD_CUSTOMER_LIST_SUCCESS: 'DASHBOARD_CUSTOMER_LIST_SUCCESS',
	DASHBOARD_CUSTOMER_LIST_FAILURE: 'DASHBOARD_CUSTOMER_LIST_FAILURE',
	DASHBOARD_CUSTOMER_LIST_RESET: 'DASHBOARD_CUSTOMER_LIST_RESET',
	DASHBOARD_CUSTOMER_LIST_CHANGED: 'DASHBOARD_CUSTOMER_LIST_CHANGED',

	FETCH_ORDER_SALES_DETAILS_START: 'fetch_order_sales_report_start',
	FETCH_ORDER_SALES_DETAILS_SUCCESS: 'fetch_order_sales_report_success',
	FETCH_ORDER_SALES_DETAILS_FAILURE: 'fetch_order_sales_report_failure',
	FETCH_ORDER_SALES_DETAILS_RESPONSE_RESET_START: 'fetch_order_sales_report_response_reset_start',
	FETCH_ORDER_SALES_DETAILS_RESPONSE_CHANGED: 'fetch_order_sales_report_response_reset_changed',

	TOTAL_ONLINE_CREATED_LIST_START: 'total_online_created_list_start',
	TOTAL_ONLINE_CREATED_LIST_SUCCESS: 'total_online_created_list_success',
	TOTAL_ONLINE_CREATED_LIST_FAILURE: 'total_online_created_list_failure',
	TOTAL_ONLINE_CREATED_LIST_RESPONSE_RESET_START:
		'total_online_created_list_response_reset_start',
	TOTAL_ONLINE_CREATED_LIST_RESPONSE_CHANGED: 'total_online_created_list_response_reset_changed',

	UPDATE_TRANSACTION_STATUS_DASBOARD_START: 'update_transaction_status_dashboard_start',
	UPDATE_TRANSACTION_STATUS_DASBOARD_SUCCESS: 'update_transaction_status_dashboard_success',
	UPDATE_TRANSACTION_STATUS_DASBOARD_FAILURE: 'update_transaction_status_dashboard_failure',
	UPDATE_TRANSACTION_STATUS_DASBOARD_RESPONSE_RESET_START:
		'update_transaction_status_dashboard_response_reset_start',
	UPDATE_TRANSACTION_STATUS_DASBOARD_RESPONSE_CHANGED:
		'update_transaction_status_dashboard_response_reset_changed',
};
export default dashBoardTypes;
