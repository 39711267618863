/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/namespace */
/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import orderAssignTypes from './OrderAssign.types';
import { addOrderItemOrderAssign } from './OrderAssign.utils';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	getPincodeNewListArr: [],
	getPincodeNewListResp: null,

	pincodeWiseOrderListArr: [],
	pincodeWiseOrderListResp: null,
};

const orderAssignReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case orderAssignTypes.GET_PINCODE_NEW_LIST_SUCCESS:
			return {
				...state,
				getPincodeNewListResp: action.payload,
				getPincodeNewListArr: docodeResponse(action.payload, 'GetPincodeNewListKey'),
			};

		case orderAssignTypes.GET_PINCODE_NEW_LIST_FAILURE:
			return {
				...state,
				getPincodeNewListResp: action.payload,
				getPincodeNewListArr: [],
			};

		case orderAssignTypes.GET_PINCODE_NEW_LIST_RESPONSE_CHANGED:
			return { ...state, getPincodeNewListResp: null };

		case orderAssignTypes.PINCODE_WISE_ORDER_LIST_SUCCESS:
			return {
				...state,
				pincodeWiseOrderListResp: action.payload,
				pincodeWiseOrderListArr: docodeResponse(action.payload, 'PincodeWiseOrderListKey'),
			};

		case orderAssignTypes.PINCODE_WISE_ORDER_LIST_FAILURE:
			return {
				...state,
				pincodeWiseOrderListResp: action.payload,
				pincodeWiseOrderListArr: [],
			};

		case orderAssignTypes.PINCODE_WISE_ORDER_LIST_RESPONSE_CHANGED:
			return { ...state, pincodeWiseOrderListResp: null };

		case orderAssignTypes.ADD_ORDER_ITEM_ORDER_ASSIGN:
			return {
				...state,
				pincodeWiseOrderListArr: addOrderItemOrderAssign(
					state.pincodeWiseOrderListArr,
					action.payload,
				),
			};

		default:
			return state;
	}
};

export default orderAssignReducer;
