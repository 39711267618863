/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import PackingReportActionType from './packingReport.types';
import { getWholePackingHistory, getDeliveryPackingHistory } from './packingReport.service';
import {
	wholePackingHistorySuccess,
	wholePackingHistoryFailure,
	wholePackingHistoryResponseChanged,
	deliveryPackingHistorySuccess,
	deliveryPackingHistoryFailure,
	deliveryPackingHistoryResponseChanged,
} from './packingReport.action';
import { encodeJson } from '../enode-decode';

// WHOLE PACKING LIST START
export function* historyWholePackingStart({ payload }) {
	try {
		const key = 'GetWholePackingHistoryKey';
		const jsonData = {
			BranchID: payload.branchID,
			DateFilter: payload.dateFilter,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getWholePackingHistory, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(wholePackingHistoryFailure(responseData.data));
		} else {
			yield put(wholePackingHistorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(wholePackingHistoryFailure(error));
	}
}

export function* historyWholePackingResponse() {
	yield put(wholePackingHistoryResponseChanged());
}

export function* onWholePackingHistoryStart() {
	yield takeLatest(
		PackingReportActionType.GET_WHOLE_PACKING_HISTORY_START,
		historyWholePackingStart,
	);
}

export function* onWholePackingHistoryResponseRest() {
	yield takeLatest(
		PackingReportActionType.GET_WHOLE_PACKING_HISTORY_RESPONSE_REST_START,
		historyWholePackingResponse,
	);
}
// WHOLE PACKING LIST END

// DELIVERY PACKING LIST START
export function* historyDeliveryPackingStart({ payload }) {
	try {
		const key = 'GetDeliveryPackingHistoryKey';
		const jsonData = {
			BranchID: payload.branchID,
			DateFilter: payload.dateFilter,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getDeliveryPackingHistory, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(deliveryPackingHistoryFailure(responseData.data));
		} else {
			yield put(deliveryPackingHistorySuccess(responseData.data));
		}
	} catch (error) {
		yield put(deliveryPackingHistoryFailure(error));
	}
}

export function* historyDeliveryPackingResponse() {
	yield put(deliveryPackingHistoryResponseChanged());
}

export function* onDeliveryPackingHistoryStart() {
	yield takeLatest(
		PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_START,
		historyDeliveryPackingStart,
	);
}

export function* onDeliveryPackingHistoryResponseRest() {
	yield takeLatest(
		PackingReportActionType.GET_DELIVERY_PACKING_HISTORY_RESPONSE_REST_START,
		historyDeliveryPackingResponse,
	);
}
// DELIVERY LIST END

export function* packingReportSaga() {
	yield all([
		call(onWholePackingHistoryStart),
		call(onWholePackingHistoryResponseRest),
		call(onDeliveryPackingHistoryStart),
		call(onDeliveryPackingHistoryResponseRest),
	]);
}
