/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getProductComboList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${API_URL}GetProductComboList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addProductCombo = (postListValue) => {
	const data = {
		ComboName: postListValue.ComboName,
		ProductComboDesc: postListValue.ProductComboDesc,
		ComboPrice: postListValue.ComboPrice,
		ComboMrpPrice: postListValue.ComboMrpPrice,
		// ComboDiscount: postListValue.ComboDiscount,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${API_URL}CreateProductCombo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateProductCombo = (postListValue) => {
	const data = {
		ProductComboID: postListValue.ProductComboID,
		ComboName: postListValue.ComboName,
		ProductComboDesc: postListValue.ProductComboDesc,
		ComboPrice: postListValue.ComboPrice,
		ComboMrpPrice: postListValue.ComboMrpPrice,
		// ComboDiscount: postListValue.ComboDiscount,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${API_URL}UpdateProductCombo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateOffProductCombo = (postListValue) => {
	const data = {
		ProductComboID: postListValue.ProductComboID,
	};
	const url = `${API_URL}UpdateOffProductCombo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateOnProductCombo = (postListValue) => {
	const data = {
		ProductComboID: postListValue.ProductComboID,
	};
	const url = `${API_URL}UpdateOnProductCombo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAddProductList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		DropdownSearchValueSet: postListValue.dropdownSearchValueSet,
	};
	const url = `${API_URL}GetAddProductList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const ethicProductCreate = (postListValue) => {
	const data = {
		ProductUpdate: postListValue,
	};
	const url = `${API_URL}EthicProductCreate`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getEthicProductList = (id) => {
	const data = {
		ProductComboID: id,
	};
	const url = `${API_URL}GetEthicProductList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteEthicProduct = (postListValue) => {
	const data = {
		ProductComboID: postListValue.ProductComboID,
		ProductID: postListValue.ProductID,
	};
	const url = `${API_URL}DeleteEthicProduct`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
