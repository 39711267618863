/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import purchaseOrderTypes from './purchaseOrder.types';
import generalTypesAction from '../genera.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	purchaseOrderListResponse: null,
	purchaseOrderListStatus: null,
	purchaseOrderListDecode: null,
	purchaseOrderStatusUpdateResponse: null,
	purchaseOrderStatusUpdateStatus: null,
	viewPoProductsListResponse: null,
	viewPoProductsListStatus: null,
	viewPoProductsListDecode: null,
};

const purchaseOrderListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case purchaseOrderTypes.PURCHASE_ORDER_LIST_SUCCESS:
			return {
				...state,
				purchaseOrderListResponse: action.payload,
				purchaseOrderListDecode: decodeApiResponse(
					action.payload,
					generalTypesAction.PURCHASEORDER,
				),
				purchaseOrderListStatus: 'Success',
			};

		case purchaseOrderTypes.PURCHASE_ORDER_LIST_FAILURE:
			return {
				...state,
				purchaseOrderListResponse: action.payload,
				purchaseOrderListDecode: null,
				purchaseOrderListStatus: 'Failure',
			};

		case purchaseOrderTypes.PURCHASE_ORDER_LIST_CHANGED:
			return {
				...state,
				purchaseOrderListResponse: null,
				purchaseOrderListDecode: null,
				purchaseOrderListStatus: null,
			};

		case purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_SUCCESS:
			return {
				...state,
				purchaseOrderStatusUpdateResponse: action.payload,
				purchaseOrderStatusUpdateStatus: 'Success',
			};
		case purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_FAILURE:
			return {
				...state,
				purchaseOrderStatusUpdateResponse: action.payload,
				purchaseOrderStatusUpdateStatus: 'Failure',
			};
		case purchaseOrderTypes.UPDATE_PURCHASE_ORDER_STATUS_CHANGED:
			return {
				...state,
				purchaseOrderStatusUpdateResponse: null,
				purchaseOrderStatusUpdateStatus: null,
			};

		case purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_SUCCESS:
			return {
				...state,
				viewPoProductsListResponse: action.payload,
				viewPoProductsListDecode: decodeApiResponse(
					action.payload,
					generalTypesAction.PURCHASEORDER,
				),
				viewPoProductsListStatus: 'Success',
			};

		case purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_FAILURE:
			return {
				...state,
				viewPoProductsListResponse: action.payload,
				viewPoProductsListDecode: null,
				viewPoProductsListStatus: 'Failure',
			};

		case purchaseOrderTypes.VIEW_PO_PRODUCTS_LIST_CHANGED:
			return {
				...state,
				viewPoProductsListResponse: null,
				viewPoProductsListDecode: null,
				viewPoProductsListStatus: null,
			};

		default:
			return state;
	}
};

export default purchaseOrderListReducer;
