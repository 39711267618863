// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable react-hooks/rules-of-hooks */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/named */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-return-assign */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-debugger */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/prefer-default-export */
// import JWT from 'expo-jwt';
import { useSelector } from 'react-redux';
import { decodeTokenJson } from '../enode-decode';
import { selectFilterProductCart } from '../PlaceOrder/placeOrder.selector';

export const decodeApiResponse = (jsonData, key) => {
	const decodedData = decodeTokenJson(jsonData.response, key);
	return decodedData;
};

export const docodeResponse = (response, key) => {
	const docodeVal = decodeTokenJson(response.response, key);
	return docodeVal.data;
};

export const updateSeletedOption = (selectedProduct, listOfOption, optionSelected) => {
	const updatedProduct = { ...selectedProduct };
	const updatedOption = listOfOption.map((optionItem) =>
		optionItem.ProductPriceChartID === optionSelected.ProductPriceChartID
			? { ...optionItem, IsSelectedProduct: 1 }
			: { ...optionItem, IsSelectedProduct: 0 },
	);
	updatedProduct.ProductPriceChartID = optionSelected.ProductPriceChartID;
	updatedProduct.SelectedOption = optionSelected.ProductOptions;
	updatedProduct.MrpPrice = optionSelected.MrpPrice;
	updatedProduct.SellingPrice = optionSelected.SellingPrice;
	updatedProduct.Quantity = 0;
	updatedProduct.OptionsList = updatedOption;
	return updatedProduct;
};

export const updateOption = (OptionsList, ProductPriceChartID) => {
	const updatedOption = OptionsList.find(
		(item) => item.ProductPriceChartID === ProductPriceChartID,
	);
	return updatedOption;
};

export const updateCategoryProductOnCartList = (
	categoryProductList,
	updateProduct,
	// ProductID,
	// ProductClassifyID,
) => {
	return categoryProductList.map((productList) =>
		productList.ProductClassifyID === updateProduct.ProductClassifyID
			? updateProduct
			: productList,
	);
};

export const addProductCategoryItemToCart = (
	productCategoryCartItems,
	addToCartItem,
	// ProductClassifyID,
	// ProductPriceChartID,
) => {
	const existingCartItem = productCategoryCartItems.find(
		(cartItem) => cartItem.ProductClassifyID === addToCartItem.ProductClassifyID,
	);

	if (existingCartItem) {
		return productCategoryCartItems.map((cartItem) =>
			cartItem.ProductClassifyID === addToCartItem.ProductClassifyID &&
			cartItem.ProductPriceChartID === addToCartItem.ProductPriceChartID
				? { ...cartItem, Quantity: cartItem.Quantity + 1 }
				: cartItem,
		);
	}
	return [...productCategoryCartItems, { ...addToCartItem, Quantity: 1 }];
};

/** *************** START ADD ITEM TO CART UPDATE PRODUCT LIST ARRAY ******************** */
export const updateAddItemCatetoryProductList = (addProductListArr, addItem, ProductClassifyID) => {
	const existingCartItem = addProductListArr.find(
		(cartItem) => cartItem.ProductClassifyID === addItem.ProductClassifyID,
	);

	if (existingCartItem) {
		return addProductListArr.map((cartItem) =>
			cartItem.ProductClassifyID === addItem.ProductClassifyID
				? { ...cartItem, Quantity: cartItem.Quantity + 1 }
				: cartItem,
		);
	}

	return addProductListArr;
};

/** *************** END ADD ITEM TO CART UPDATE PRODUCT LIST ARRAY ******************** */

export const removeProductCategoryItemFromCart = (
	productCategoryCartItems,
	romoveCartItem,
	ProductClassifyID,
) => {
	const existingCartItem = productCategoryCartItems.find(
		(cartItem) => cartItem.ProductClassifyID === romoveCartItem.ProductClassifyID,
	);

	if (existingCartItem.Quantity === 1) {
		return productCategoryCartItems.filter(
			(cartItem) => cartItem.ProductClassifyID !== romoveCartItem.ProductClassifyID,
		);
	}

	return productCategoryCartItems.map((cartItem) =>
		cartItem.ProductClassifyID === romoveCartItem.ProductClassifyID
			? { ...cartItem, Quantity: cartItem.Quantity - 1 }
			: cartItem,
	);
};

/** *************** START REMOVE ITEM TO CART UPDATE PRODUCT LIST ARRAY ******************** */
export const updateRemoveCatetoryProductList = (
	removeProductListArr,
	removeItem,
	ProductClassifyID,
) => {
	const existingCartItem = removeProductListArr.find(
		(cartItem) => cartItem.ProductClassifyID === removeItem.ProductClassifyID,
	);

	if (existingCartItem) {
		return removeProductListArr.map((cartItem) =>
			cartItem.ProductClassifyID === removeItem.ProductClassifyID
				? { ...cartItem, Quantity: cartItem.Quantity - 1 }
				: cartItem,
		);
	}

	return [...removeProductListArr, { ...removeItem, Quantity: 1 }];
};

/** *************** END REMOVE ITEM TO CART UPDATE PRODUCT LIST ARRAY ******************** */

export const addedCartListItems = () => {
	const categoryProductCartList = useSelector(selectFilterProductCart);

	return categoryProductCartList;
};

export const updateCartItemProductList = (selectedProductList, cartItemsArr) => {
	const products = selectedProductList.map((item) => {
		const cart = cartItemsArr.find(
			(cartItem) => cartItem.ProductClassifyID === item.ProductClassifyID,
		);
		return cart ? { ...item, ...cart } : item;
	});
	return products;
};
