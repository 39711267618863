/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getUserAllOrderList = (postListValue) => {
	const data = {
		GetCustomerOnlineAndWalletListData: postListValue,
	};
	const url = `${API_URL}GetCustomerOnlineAndWalletList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getRefundUpdate = (fetchData) => {
	const data = {
		RefundUpdateToken: fetchData,
	};
	const url = `${API_URL}UpdateRefundDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getCustomerRefundedList = (postListValue) => {
	const data = {
		GetCustomerRefundedListData: postListValue,
	};
	const url = `${API_URL}GetCustomerRefundedList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
