const endOfDayNewTypes = {
	GET_EOD_ORDER_LIST_NEW_START: 'GET_EOD_ORDER_LIST_NEW_START',
	GET_EOD_ORDER_LIST_NEW_SUCCESS: 'GET_EOD_ORDER_LIST_NEW_SUCCESS',
	GET_EOD_ORDER_LIST_NEW_FAILURE: 'GET_EOD_ORDER_LIST_NEW_FAILURE',
	GET_EOD_ORDER_LIST_NEW_RESET: 'GET_EOD_ORDER_LIST_NEW_RESET',
	GET_EOD_ORDER_LIST_NEW_CHANGED: 'GET_EOD_ORDER_LIST_NEW_CHANGED',

	VIEW_EOD_ORDER_LIST_NEW_START: 'VIEW_EOD_ORDER_LIST_NEW_START',
	VIEW_EOD_ORDER_LIST_NEW_SUCCESS: 'VIEW_EOD_ORDER_LIST_NEW_SUCCESS',
	VIEW_EOD_ORDER_LIST_NEW_FAILURE: 'VIEW_EOD_ORDER_LIST_NEW_FAILURE',
	VIEW_EOD_ORDER_LIST_NEW_RESET: 'VIEW_EOD_ORDER_LIST_NEW_RESET',
	VIEW_EOD_ORDER_LIST_NEW_CHANGED: 'VIEW_EOD_ORDER_LIST_NEW_CHANGED',

	EOD_FETCH_LIST_START: 'EOD_FETCH_LIST_START',
	EOD_FETCH_LIST_SUCCESS: 'EOD_FETCH_LIST_SUCCESS',
	EOD_FETCH_LIST_FAILURE: 'EOD_FETCH_LIST_FAILURE',
	EOD_FETCH_LIST_RESET: 'EOD_FETCH_LIST_RESET',
	EOD_FETCH_LIST_CHANGED: 'EOD_FETCH_LIST_CHANGED',

	ADD_EOD_NEW_START: 'ADD_EOD_NEW_START',
	ADD_EOD_NEW_SUCCESS: 'ADD_EOD_NEW_SUCCESS',
	ADD_EOD_NEW_FAILURE: 'ADD_EOD_NEW_FAILURE',
	ADD_EOD_NEW_RESET: 'ADD_EOD_NEW_RESET',
	ADD_EOD_NEW_CHANGED: 'ADD_EOD_NEW_CHANGED',

	GET_EOD_STAFF_LIST_NEW_START: 'GET_EOD_STAFF_LIST_NEW_START',
	GET_EOD_STAFF_LIST_NEW_SUCCESS: 'GET_EOD_STAFF_LIST_NEW_SUCCESS',
	GET_EOD_STAFF_LIST_NEW_FAILURE: 'GET_EOD_STAFF_LIST_NEW_FAILURE',
	GET_EOD_STAFF_LIST_NEW_RESET: 'GET_EOD_STAFF_LIST_NEW_RESET',
	GET_EOD_STAFF_LIST_NEW_CHANGED: 'GET_EOD_STAFF_LIST_NEW_CHANGED',

	ADD_ORDER_ITEM_NEW: 'ADD_ORDER_ITEM_NEW',

	STAFF_ASSIGNED_NEW_START: 'STAFF_ASSIGNED_NEW_START',
	STAFF_ASSIGNED_NEW_SUCCESS: 'STAFF_ASSIGNED_NEW_SUCCESS',
	STAFF_ASSIGNED_NEW_FAILURE: 'STAFF_ASSIGNED_NEW_FAILURE',
	STAFF_ASSIGNED_NEW_RESET: 'STAFF_ASSIGNED_NEW_RESET',
	STAFF_ASSIGNED_NEW_CHANGED: 'STAFF_ASSIGNED_NEW_CHANGED',

	REVERT_ASSIGNED_ORDER_NEW_START: 'REVERT_ASSIGNED_ORDER_NEW_START',
	REVERT_ASSIGNED_ORDER_NEW_SUCCESS: 'REVERT_ASSIGNED_ORDER_NEW_SUCCESS',
	REVERT_ASSIGNED_ORDER_NEW_FAILURE: 'REVERT_ASSIGNED_ORDER_NEW_FAILURE',
	REVERT_ASSIGNED_ORDER_NEW_RESET: 'REVERT_ASSIGNED_ORDER_NEW_RESET',
	REVERT_ASSIGNED_ORDER_NEW_CHANGED: 'REVERT_ASSIGNED_ORDER_NEW_CHANGED',

	ORDER_DATE_CHANGE_START: 'ORDER_DATE_CHANGE_START',
	ORDER_DATE_CHANGE_SUCCESS: 'ORDER_DATE_CHANGE_SUCCESS',
	ORDER_DATE_CHANGE_FAILURE: 'ORDER_DATE_CHANGE_FAILURE',
	ORDER_DATE_CHANGE_RESET: 'ORDER_DATE_CHANGE_RESET',
	ORDER_DATE_CHANGE_CHANGED: 'ORDER_DATE_CHANGE_CHANGED',
};
export default endOfDayNewTypes;
