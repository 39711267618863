const staffListTypes = {
	STAFF_LIST_START: 'STAFF_LIST_START',
	STAFF_LIST_SUCCESS: 'STAFF_LIST_SUCCESS',
	STAFF_LIST_FAILURE: 'STAFF_LIST_FAILURE',
	STAFF_LIST_RESET: 'STAFF_LIST_RESET',
	STAFF_LIST_CHANGED: 'STAFF_LIST_CHANGED',

	STAFF_CREATE_REPSONSE_START: 'STAFF_CREATE_REPSONSE_START',
	STAFF_CREATE_REPSONSE_SUCCESS: 'STAFF_CREATE_REPSONSE_SUCCESS',
	STAFF_CREATE_REPSONSE_FAILURE: 'STAFF_CREATE_REPSONSE_FAILURE',
	STAFF_CREATE_REPSONSE_CHANGED: 'STAFF_CREATE_REPSONSE_CHANGED',
	STAFF_CREATE_REPSONSE_RESET: 'STAFF_CREATE_REPSONSE_RESET',

	STAFF_UPDATE_REPSONSE_START: 'STAFF_UPDATE_REPSONSE_START',
	STAFF_UPDATE_REPSONSE_SUCCESS: 'STAFF_UPDATE_REPSONSE_SUCCESS',
	STAFF_UPDATE_REPSONSE_FAILURE: 'STAFF_UPDATE_REPSONSE_FAILURE',
	STAFF_UPDATE_REPSONSE_CHANGED: 'STAFF_UPDATE_REPSONSE_CHANGED',
	STAFF_UPDATE_REPSONSE_RESET: 'STAFF_UPDATE_REPSONSE_RESET',

	STAFF_DELETE_REPSONSE_START: 'STAFF_DELETE_REPSONSE_START',
	STAFF_DELETE_REPSONSE_SUCCESS: 'STAFF_DELETE_REPSONSE_SUCCESS',
	STAFF_DELETE_REPSONSE_FAILURE: 'STAFF_DELETE_REPSONSE_FAILURE',
	STAFF_DELETE_REPSONSE_CHANGED: 'STAFF_DELETE_REPSONSE_CHANGED',
	STAFF_DELETE_REPSONSE_RESET: 'STAFF_DELETE_REPSONSE_RESET',
};
export default staffListTypes;
