/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import StaffReportTypes from './staff-report.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	fetchStaffReportDetailsResponse: null,
	fetchStaffReportDetailsArr: [],
};

export default (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case StaffReportTypes.STAFF_REPORT_DETAILS_SUCCESS:
			return {
				...state,
				fetchStaffReportDetailsResponse: action.payload,
				fetchStaffReportDetailsArr: docodeResponse(
					action.payload,
					'FetchStaffReportDetailsKey',
				),
			};

		case StaffReportTypes.STAFF_REPORT_DETAILS_FAILURE:
			return {
				...state,
				fetchStaffReportDetailsResponse: action.payload,
				fetchStaffReportDetailsArr: [],
			};

		case StaffReportTypes.STAFF_REPORT_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchStaffReportDetailsResponse: null };

		default:
			return state;
	}
};
