/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import todayDeliveredTypes from './todayDelivered.types';

/* TODAY DELIVERED LIST START */
export const todayDeliveredListChanged = () => ({
	type: todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_CHANGED,
});

export const todayDeliveredListReset = () => ({
	type: todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_RESET,
});

export const todayDeliveredListStart = (todayDelivered) => ({
	type: todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_START,
	payload: todayDelivered,
});

export const todayDeliveredListSuccess = (todayDeliveredSuccess) => ({
	type: todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_SUCCESS,
	payload: todayDeliveredSuccess,
});

export const todayDeliveredListFailure = (error) => ({
	type: todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_FAILURE,
	payload: error,
});
/* TODAY DELIVERED LIST END */

/* VIEW ORDER DETAILS START */
export const viewTodayOrderDetailsChanged = () => ({
	type: todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_CHANGED,
});

export const viewTodayOrderDetailsReset = () => ({
	type: todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_RESET,
});

export const viewTodayOrderDetailsStart = (viewTodayOrderDetails) => ({
	type: todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_START,
	payload: viewTodayOrderDetails,
});

export const viewTodayOrderDetailsSuccess = (viewOrderSuccess) => ({
	type: todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_SUCCESS,
	payload: viewOrderSuccess,
});

export const viewTodayOrderDetailsFailure = (error) => ({
	type: todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_FAILURE,
	payload: error,
});
/* VIEW ORDER DETAILS END */
