/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import comboListTypes from './comboList.types';

/* COMBO LIST START */
export const comboListStart = (comboDetails) => ({
	type: comboListTypes.GET_COMBO_LIST_START,
	payload: comboDetails,
});

export const comboListSuccess = (comboSuccess) => ({
	type: comboListTypes.GET_COMBO_LIST_SUCCESS,
	payload: comboSuccess,
});

export const comboListFailure = (error) => ({
	type: comboListTypes.GET_COMBO_LIST_FAILURE,
	payload: error,
});

export const comboListChanged = () => ({
	type: comboListTypes.GET_COMBO_LIST_CHANGED,
});

export const comboListReset = () => ({
	type: comboListTypes.GET_COMBO_LIST_RESET,
});
/* COMBO LIST END */

/* INSERT COMBO START */
export const insertComboStart = (comboInsertDetails) => ({
	type: comboListTypes.INSERT_COMBO_RESPONSE_START,
	payload: comboInsertDetails,
});

export const insertComboSuccess = (comboInsertSuccess) => ({
	type: comboListTypes.INSERT_COMBO_RESPONSE_SUCCESS,
	payload: comboInsertSuccess,
});

export const insertComboFailure = (error) => ({
	type: comboListTypes.INSERT_COMBO_RESPONSE_FAILURE,
	payload: error,
});

export const insertComboChanged = () => ({
	type: comboListTypes.INSERT_COMBO_RESPONSE_CHANGED,
});

export const insertComboReset = () => ({
	type: comboListTypes.INSERT_COMBO_RESPONSE_RESET,
});
/* INSERT COMBO END */

/* UPDATE COMBO START */
export const editComboStart = (comboDetails) => ({
	type: comboListTypes.UPDATE_COMBO_RESPONSE_START,
	payload: comboDetails,
});

export const editComboSuccess = (comboSuccess) => ({
	type: comboListTypes.UPDATE_COMBO_RESPONSE_SUCCESS,
	payload: comboSuccess,
});

export const editComboFailure = (error) => ({
	type: comboListTypes.UPDATE_COMBO_RESPONSE_FAILURE,
	payload: error,
});

export const editComboChanged = () => ({
	type: comboListTypes.UPDATE_COMBO_RESPONSE_CHANGED,
});

export const editComboReset = () => ({
	type: comboListTypes.UPDATE_COMBO_RESPONSE_RESET,
});
/* UPDATE COMBO END */

/* DELETE COMBO START */
export const removeComboStart = (comboDetails) => ({
	type: comboListTypes.DELETE_COMBO_RESPONSE_START,
	payload: comboDetails,
});

export const removeComboSuccess = (comboSuccess) => ({
	type: comboListTypes.DELETE_COMBO_RESPONSE_SUCCESS,
	payload: comboSuccess,
});

export const removeComboFailure = (error) => ({
	type: comboListTypes.DELETE_COMBO_RESPONSE_FAILURE,
	payload: error,
});

export const removeComboChanged = () => ({
	type: comboListTypes.DELETE_COMBO_RESPONSE_CHANGED,
});

export const removeComboReset = () => ({
	type: comboListTypes.DELETE_COMBO_RESPONSE_RESET,
});
/* DELETE COMBO END */

/* ADD COMBO PRODUCT START */
export const addComboProductStart = (comboDetails) => ({
	type: comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_START,
	payload: comboDetails,
});

export const addComboProductSuccess = (comboSuccess) => ({
	type: comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_SUCCESS,
	payload: comboSuccess,
});

export const addComboProductFailure = (error) => ({
	type: comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_FAILURE,
	payload: error,
});

export const addComboProductChanged = () => ({
	type: comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_CHANGED,
});

export const addComboProductReset = () => ({
	type: comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_RESET,
});
/* ADD COMBO PRODUCT END */

/* CREATE PRODUCT START */
export const createProductStart = (comboDetails) => ({
	type: comboListTypes.CREATE_PRODUCT_RESPONSE_START,
	payload: comboDetails,
});

export const createProductSuccess = (comboSuccess) => ({
	type: comboListTypes.CREATE_PRODUCT_RESPONSE_SUCCESS,
	payload: comboSuccess,
});

export const createProductFailure = (error) => ({
	type: comboListTypes.CREATE_PRODUCT_RESPONSE_FAILURE,
	payload: error,
});

export const createProductChanged = () => ({
	type: comboListTypes.CREATE_PRODUCT_RESPONSE_CHANGED,
});

export const createProductReset = () => ({
	type: comboListTypes.CREATE_PRODUCT_RESPONSE_RESET,
});
/* CREATE PRODUCT END */

/* GET PRODUCTS LIST START */
export const productsListStart = (comboDetails) => ({
	type: comboListTypes.GET_PRODUCT_LIST_RESPONSE_START,
	payload: comboDetails,
});

export const productsListSuccess = (comboSuccess) => ({
	type: comboListTypes.GET_PRODUCT_LIST_RESPONSE_SUCCESS,
	payload: comboSuccess,
});

export const productsListFailure = (error) => ({
	type: comboListTypes.GET_PRODUCT_LIST_RESPONSE_FAILURE,
	payload: error,
});

export const productsListChanged = () => ({
	type: comboListTypes.GET_PRODUCT_LIST_RESPONSE_CHANGED,
});

export const productsListReset = () => ({
	type: comboListTypes.GET_PRODUCT_LIST_RESPONSE_RESET,
});
/* GET PRODUCTS LIST END */

/* DELETE PRODUCTS START */
export const deleteProductStart = (comboDetails) => ({
	type: comboListTypes.DELETE_PRODUCT_START,
	payload: comboDetails,
});

export const deleteProductSuccess = (comboSuccess) => ({
	type: comboListTypes.DELETE_PRODUCT_SUCCESS,
	payload: comboSuccess,
});

export const deleteProductFailure = (error) => ({
	type: comboListTypes.DELETE_PRODUCT_FAILURE,
	payload: error,
});

export const deleteProductChanged = () => ({
	type: comboListTypes.DELETE_PRODUCT_CHANGED,
});

export const deleteProductReset = () => ({
	type: comboListTypes.DELETE_PRODUCT_RESET,
});
/* DELETE PRODUCTS END */

/* IS FESTIVAL START */
export const isFestivalStart = (comboDetails) => ({
	type: comboListTypes.IS_FESTIVAL_SWITCH_START,
	payload: comboDetails,
});

export const isFestivalSuccess = (comboSuccess) => ({
	type: comboListTypes.IS_FESTIVAL_SWITCH_SUCCESS,
	payload: comboSuccess,
});

export const isFestivalFailure = (error) => ({
	type: comboListTypes.IS_FESTIVAL_SWITCH_FAILURE,
	payload: error,
});

export const isFestivalChanged = () => ({
	type: comboListTypes.IS_FESTIVAL_SWITCH_CHANGED,
});

export const isFestivalReset = () => ({
	type: comboListTypes.IS_FESTIVAL_SWITCH_RESET,
});
/* IS FESTIVAL END */
