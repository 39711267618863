/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getAllOrderList = (OrderListData) => {
	const data = {
		OrderListToken: OrderListData,
	};
	const url = `${API_URL}GetAllOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAllViewOrderList = (OrderViewListData) => {
	const data = {
		OrderViewListToken: OrderViewListData,
	};
	const url = `${API_URL}GetViewAllOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
