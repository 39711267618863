/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import loginActionTypes from './login.types';

const INITIAL_STATE = {
	loginStatus: '',
	loginResponse: null,
	loginAndLogoutLogResponse: null,
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case loginActionTypes.SIGN_IN_SUCCESS:
			return { ...state, loginResponse: action.payload, loginStatus: 'Success' };

		case loginActionTypes.SIGN_IN_FAILURE:
			return { ...state, loginResponse: null, loginStatus: action.payload };

		case loginActionTypes.LOGIN_USER_RESPONSE_CHANGED:
			return { ...state, loginResponse: null, loginStatus: null };

		case loginActionTypes.SIGN_IN_LOGOUT:
			return { ...state, loginResponse: null, loginStatus: null };

		case loginActionTypes.LOGIN_AND_LOGOUT_LOG_SUCCESS:
			return {
				...state,
				loginAndLogoutLogResponse: action.payload,
			};

		case loginActionTypes.LOGIN_AND_LOGOUT_LOG_FAILURE:
			return {
				...state,
				loginAndLogoutLogResponse: action.payload,
			};

		case loginActionTypes.LOGIN_AND_LOGOUT_LOG_RESPONSE_CHANGED:
			return { ...state, loginAndLogoutLogResponse: null };

		default:
			return state;
	}
};

export default userReducer;
