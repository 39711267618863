/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getMinicherList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${API_URL}GetMinicherList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addMinicher = (postListValue) => {
	const data = {
		MinicherName: postListValue.MinicherName,
		MinicherQuantity: postListValue.MinicherQuantity,
		MinicherImage: postListValue.MinicherImage,
		MinicherPrice: postListValue.MinicherPrice,
		MinicherMrpPrice: postListValue.MinicherMrpPrice,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${API_URL}CreateMinicher`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateMinicher = (postListValue) => {
	const data = {
		MinicherID: postListValue.MinicherID,
		MinicherName: postListValue.MinicherName,
		MinicherQuantity: postListValue.MinicherQuantity,
		MinicherImage: postListValue.MinicherImage,
		MinicherPrice: postListValue.MinicherPrice,
		MinicherMrpPrice: postListValue.MinicherMrpPrice,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${API_URL}UpdateMinicher`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateOffMinicher = (postListValue) => {
	const data = {
		MinicherID: postListValue.MinicherID,
	};
	const url = `${API_URL}UpdateOffMinicher`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateOnMinicher = (postListValue) => {
	const data = {
		MinicherID: postListValue.MinicherID,
	};
	const url = `${API_URL}UpdateOnMinicher`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getAddMinicherProductList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		DropdownSearchValueSet: postListValue.dropdownSearchValueSet,
	};
	const url = `${API_URL}GetAddMinicherProductList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const minicherProductCreate = (postListValue) => {
	const data = {
		ProductUpdate: postListValue,
	};
	const url = `${API_URL}MinicherProductCreate`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getMinicherProductList = (id) => {
	const data = {
		MinicherID: id,
	};
	const url = `${API_URL}GetMinicherProductList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteMinicherProduct = (postListValue) => {
	const data = {
		MinicherID: postListValue.MinicherID,
		ProductID: postListValue.ProductID,
	};
	const url = `${API_URL}DeleteMinicherProduct`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
