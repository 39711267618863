const vendorProductsTypes = {
	VENDOR_PRODUCTS_LIST_START: 'VENDOR_PRODUCTS_LIST_START',
	VENDOR_PRODUCTS_LIST_SUCCESS: 'VENDOR_PRODUCTS_LIST_SUCCESS',
	VENDOR_PRODUCTS_LIST_FAILURE: 'VENDOR_PRODUCTS_LIST_FAILURE',
	VENDOR_PRODUCTS_LIST_RESET: 'VENDOR_PRODUCTS_LIST_RESET',
	VENDOR_PRODUCTS_LIST_CHANGED: 'VENDOR_PRODUCTS_LIST_CHANGED',

	VENDOR_VIEW_PRODUCTS_LIST_START: 'VENDOR_VIEW_PRODUCTS_LIST_START',
	VENDOR_VIEW_PRODUCTS_LIST_SUCCESS: 'VENDOR_VIEW_PRODUCTS_LIST_SUCCESS',
	VENDOR_VIEW_PRODUCTS_LIST_FAILURE: 'VENDOR_VIEW_PRODUCTS_LIST_FAILURE',
	VENDOR_VIEW_PRODUCTS_LIST_RESET: 'VENDOR_VIEW_PRODUCTS_LIST_RESET',
	VENDOR_VIEW_PRODUCTS_LIST_CHANGED: 'VENDOR_VIEW_PRODUCTS_LIST_CHANGED',
};
export default vendorProductsTypes;
