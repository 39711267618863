// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-dupe-keys */
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import userReducer from './login/login.reducer';
import endOfDayReducer from './EndOfDay/EndOfDay.reducer';
import deliveryProcessReducer from './deliveryProcess/deliveryProcess.reducer';
import dashboardReducer from './dashboard/DashBoard.reducer';
import priceUpdateReducer from './priceUpdate/priceUpdate.reducer';
import purchaseListReducer from './PurchaseList/purchaseList.reducer';
import listOfCustomerReducer from './ListOfCustomer/listOfCustomer.reducer';
import otpReducer from './OtpList/otpList.reducer';
import todayDeliveredReducer from './TodayDelivered/todayDelivered.reducer';
import staffListReducer from './staff/staffList.reducer';
import walletCustomersReducer from './walletCustomers/walletCustomers.reducer';
import comboListReducer from './Combo/comboList.reducer';
import festivalListReducer from './Festival/festival.reducer';
import productListReducer from './AddProduct/addProduct.reducer';
import productOrderReducer from './ProductOrder/productOrder.reducer';
import stockListReducer from './StockList/stockList.reducer';
import productComboReducer from './ProductCombo/productCombo.reducer';
import couponListReducer from './Coupon/couponAddList.reducer';
import transcationReducer from './Transcation/transcationList.reducer';
import vendorListReducer from './vendor/vendor.reducer';
import inventoryListReducer from './CreateInventory/createInventory.reducer';
import bannerListReducer from './Banner/banner.reducer';
import vendorProductsListReducer from './ProductsDealsByVendor/productsDealsByVendor.reducer';
import flowerOrderReducer from './FlowerOrder/flowerOrder.reducer';
import flowersListReducer from './AddFlowers/addFlowers.reducer';
import ruleListReducer from './Rule/ruleAddList.reducer';
import repackedListReducer from './RePacked/rePacked.reducer';
import hubListReducer from './AddHub/addHub.reducer';
import purchaseOrderListReducer from './PurchaseOrder/purchaseOrder.reducer';
import collectionsReducer from './Collections/collections.reducer';
import orderListReducer from './OrderList/orderList.reducer';
import minicherReducer from './AddMinicher/addMinicher.reducer';
import reconciliationReducer from './Reconciliation/reconciliation.reducer';
import refundReducer from './Refund/refund.reducer';
import placeOrderReducer from './PlaceOrder/placeOrder.reducer';
import salesReportReducer from './sales-report/sales-report.reducer';
import pushNotificationReducer from './PushNotification/pushNotification.reducer';
import customerDashboardReducer from './CustomerDashboard/customerDashboard.reducer';
import staffReportReducer from './staff-report/staff-report.reducer';
import orderReportReducer from './OrderReport/orderReport.reducer';
import assignedStaffReducer from './assigned-staff/assigned-staff.reducer';
import todayEodsReducer from './today-eods/today-eods.reducer';
import branchDetailsReducer from './Branch/branch.reducer';
import endOfDayNewReducer from './EndOfDayNew/EndOfDayNew.reducer';
import orderAssignReducer from './OrderAssign/OrderAssign.reducer';
import priceUpdateAllProductsReducer from './PriceUpdateAllProducts/priceUpdateAllProducts.reducer';
import purchaseReportReducer from './PurchaseReport/purchaseReport.reducer';
import packingReportReducer from './PackingReport/packingReport.reducer';
import pincodeReducer from './pincode/pincode.reducer';
import dashboardCategoryReducer from './dashboardCategory/dashboardCategory.reducer';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['login'],
};
const rootReducer = combineReducers({
	login: userReducer,
	deliveryList: deliveryProcessReducer,
	listAllDelivered: endOfDayReducer,
	dashboardList: dashboardReducer,
	priceUpdate: priceUpdateReducer,
	purchaseList: purchaseListReducer,
	customerList: listOfCustomerReducer,
	otpList: otpReducer,
	todayDeliveredList: todayDeliveredReducer,
	staffList: staffListReducer,
	walletCustomersList: walletCustomersReducer,
	comboList: comboListReducer,
	festivalList: festivalListReducer,
	productList: productListReducer,
	productOrderList: productOrderReducer,
	stockList: stockListReducer,
	listProductCombo: productComboReducer,
	couponList: couponListReducer,
	transcationList: transcationReducer,
	vendorList: vendorListReducer,
	inventoryList: inventoryListReducer,
	bannerList: bannerListReducer,
	vendorProductsList: vendorProductsListReducer,
	flowerOrderList: flowerOrderReducer,
	flowersList: flowersListReducer,
	ruleList: ruleListReducer,
	repackedList: repackedListReducer,
	hubList: hubListReducer,
	purchaseOrderList: purchaseOrderListReducer,
	collectionsDetails: collectionsReducer,
	orderList: orderListReducer,
	listMinicher: minicherReducer,
	reconciliation: reconciliationReducer,
	refund: refundReducer,
	placeOrder: placeOrderReducer,
	sales_report: salesReportReducer,
	pushNotification: pushNotificationReducer,
	total_customer: customerDashboardReducer,
	staff_report: staffReportReducer,
	orderReport: orderReportReducer,
	assigned_staff: assignedStaffReducer,
	today_eods: todayEodsReducer,
	branch_Details: branchDetailsReducer,
	eodData: endOfDayNewReducer,
	orderAssignData: orderAssignReducer,
	priceUpdateAll: priceUpdateAllProductsReducer,
	purchaseReport: purchaseReportReducer,
	packingReport: packingReportReducer,
	pincode: pincodeReducer,
	dashboardCategory: dashboardCategoryReducer,
});

export default persistReducer(persistConfig, rootReducer);
