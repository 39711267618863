const walletCustomersTypes = {
	WALLET_CUSTOMERS_LIST_START: 'WALLET_CUSTOMERS_LIST_START',
	WALLET_CUSTOMERS_LIST_SUCCESS: 'WALLET_CUSTOMERS_LIST_SUCCESS',
	WALLET_CUSTOMERS_LIST_FAILURE: 'WALLET_CUSTOMERS_LIST_FAILURE',
	WALLET_CUSTOMERS_LIST_RESET: 'WALLET_CUSTOMERS_LIST_RESET',
	WALLET_CUSTOMERS_LIST_CHANGED: 'WALLET_CUSTOMERS_LIST_CHANGED',

	UPDATE_WALLET_CUSTOMERS_START: 'UPDATE_WALLET_CUSTOMERS_START',
	UPDATE_WALLET_CUSTOMERS_SUCCESS: 'UPDATE_WALLET_CUSTOMERS_SUCCESS',
	UPDATE_WALLET_CUSTOMERS_FAILURE: 'UPDATE_WALLET_CUSTOMERS_FAILURE',
	UPDATE_WALLET_CUSTOMERS_RESET: 'UPDATE_WALLET_CUSTOMERS_RESET',
	UPDATE_WALLET_CUSTOMERS_CHANGED: 'UPDATE_WALLET_CUSTOMERS_CHANGED',
};
export default walletCustomersTypes;
