/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import bannerTypes from './banner.types';
import { decodeApiResponse } from '../common/common.utils';

const INITIAL_STATE = {
	bannerListResponse: null,
	bannerListArr: [],
	bannerCreateResponse: null,
	bannerDeleteResponse: null,
	bannerUpdateResponse: null,
};

const bannerListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case bannerTypes.BANNER_LIST_SUCCESS:
			return {
				...state,
				bannerListResponse: action.payload,
				bannerListArr: decodeApiResponse(action.payload, 'GetBannerListKey'),
			};

		case bannerTypes.BANNER_LIST_FAILURE:
			return {
				...state,
				bannerListResponse: action.payload,
			};

		case bannerTypes.BANNER_LIST_CHANGED:
			return { ...state, bannerListResponse: null };

		case bannerTypes.BANNER_CREATE_SUCCESS:
			return {
				...state,
				bannerCreateResponse: action.payload,
			};

		case bannerTypes.BANNER_CREATE_FAILURE:
			return {
				...state,
				bannerCreateResponse: action.payload,
			};

		case bannerTypes.BANNER_CREATE_CHANGED:
			return { ...state, bannerCreateResponse: null };

		case bannerTypes.BANNER_UPDATE_SUCCESS:
			return {
				...state,
				bannerUpdateResponse: action.payload,
			};

		case bannerTypes.BANNER_UPDATE_FAILURE:
			return {
				...state,
				bannerUpdateResponse: action.payload,
			};

		case bannerTypes.BANNER_UPDATE_CHANGED:
			return { ...state, bannerUpdateResponse: null };

		case bannerTypes.BANNER_DELETE_SUCCESS:
			return {
				...state,
				bannerDeleteResponse: action.payload,
			};

		case bannerTypes.BANNER_DELETE_FAILURE:
			return {
				...state,
				bannerDeleteResponse: action.payload,
			};

		case bannerTypes.BANNER_DELETE_CHANGED:
			return { ...state, bannerDeleteResponse: null };

		default:
			return state;
	}
};

export default bannerListReducer;
