/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getRuleList = (RuleData) => {
	const data = {
		RuleListToken: RuleData,
	};
	const url = `${API_URL}GetAllRuleList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createRule = (RuleData) => {
	const data = {
		CreateRuleToken: RuleData,
	};
	const url = `${API_URL}CreateRuleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateRule = (RuleData) => {
	const data = {
		UpdateRuleToken: RuleData,
	};
	const url = `${API_URL}UpdateRuleDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
