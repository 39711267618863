/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import loginActionTypes from './login.types';
import { UserLogin, staffLogoutAndLogionLog } from './login.services';
import {
	signInSuccess,
	signInFailure,
	logOut,
	loginAndLogoutSuccess,
	loginAndLogoutFailure,
	loginAndLogoutChanged,
} from './login.actions';
import { encodeJson } from '../enode-decode';

export function* singInWithPhoneNumber({ payload: { values } }) {
	try {
		const responseData = yield call(UserLogin, values.phoneNumber, values.password);
		if (responseData.data.statusCode === '100') {
			yield put(signInFailure(responseData.data.message_text));
		} else {
			yield put(signInSuccess(responseData.data));
		}
	} catch (error) {
		yield put(signInFailure(error));
	}
}
export function* makeUserLogout() {
	yield put(logOut());
}

export function* userLogout() {
	yield takeLatest(loginActionTypes.SIGN_IN_RESET, makeUserLogout);
}

export function* onEmailSignInStart() {
	yield takeLatest(loginActionTypes.SIGN_IN_START, singInWithPhoneNumber);
}

/* STAFF LOGOUT AND LOGIN LOG START */
export function* loginAndLogoutData({ payload }) {
	try {
		const key = 'StaffLogoutAndLogionLogKey';
		const jsonData = {
			StaffID: payload.StaffID,
			LogType: payload.LogType,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(staffLogoutAndLogionLog, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(loginAndLogoutFailure(responseData.data));
		} else {
			yield put(loginAndLogoutSuccess(responseData.data));
		}
	} catch (error) {
		yield put(loginAndLogoutFailure(error));
	}
}

export function* loginAndLogoutDataReset() {
	yield put(loginAndLogoutChanged());
}

export function* onLoginAndLogoutLogReset() {
	yield takeLatest(
		loginActionTypes.LOGIN_AND_LOGOUT_LOG_RESPONSE_RESET_START,
		loginAndLogoutDataReset,
	);
}

export function* onLoginAndLogoutLogStart() {
	yield takeLatest(loginActionTypes.LOGIN_AND_LOGOUT_LOG_START, loginAndLogoutData);
}
/* STAFF LOGOUT AND LOGIN LOG END */

export function* loginSaga() {
	yield all([
		call(onEmailSignInStart),
		call(userLogout),
		call(onLoginAndLogoutLogStart),
		call(onLoginAndLogoutLogReset),
	]);
}
