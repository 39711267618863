// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable import/no-unresolved */
import React, { lazy } from 'react';
import { componentsMenu, dashboardMenu, flowersMenu, ethicMenu, demoPages } from '../menu';
import Login from '../pages/presentation/auth/Login';

const LANDING = {
	DASHBOARD: lazy(() => import('../pages/dashboard/DashboardPage')),
	DASHBOARD_BOOKING: lazy(() => import('../pages/dashboard/DashboardBookingPage')),
	SUMMARY: lazy(() => import('../pages/SummaryPage')),
};

const MASTERS = {
	STAFF: lazy(() => import('../pages/masters/Staff/StaffPage')),
	PRODUCTCOMBO: lazy(() => import('../pages/masters/ProductCombo/ProductComboPage')),
	COMBO: lazy(() => import('../pages/masters/Combo/ComboListPage')),
	FESTIVAL: lazy(() => import('../pages/masters/Festival/FestivalPage')),
	ADDMINICHER: lazy(() => import('../pages/masters/AddMinicher/AddMinicherPage')),
	ALLPRODUCTPRICEUPDATE: lazy(() =>
		import('../pages/masters/PriceUpdateAllProducts/PriceUpdateAllProductsPage'),
	),
	PINCODE: lazy(() => import('../pages/masters/Pincode/PincodePage')),
	DASHBOARDCATEGORY: lazy(() =>
		import('../pages/masters/DashboardCategory/DashboardCategoryPage'),
	),
};

// const PRODUCTS = {
// 	PRICEUPDATE: lazy(() => import('../pages/products/PriceUpdate/PriceUpdatePage')),
// 	WALLETCUSTOMERS: lazy(() => import('../pages/products/WalletCustomers/WalletCustomersPage')),
// };

const ADDPRODUCT = {
	FOOD: lazy(() => import('../pages/AddProduct/Food/FoodPage')),
	SNACK: lazy(() => import('../pages/AddProduct/Snack/SnackPage')),
	VEGTABLES: lazy(() => import('../pages/AddProduct/Vegtables/VegtablesPage')),
	GROCERY: lazy(() => import('../pages/AddProduct/Grocery/GroceryPage')),
	FRUITS: lazy(() => import('../pages/AddProduct/Fruits/FruitsPage')),
	BOUQUET: lazy(() => import('../pages/AddProduct/Bouquet/BouquetPage')),
	MAALAI: lazy(() => import('../pages/AddProduct/Maalai/MaalaiPage')),
	POOJAIITEM: lazy(() => import('../pages/AddProduct/PoojaiItem/PoojaiItemPage')),
	ORNAMENTS: lazy(() => import('../pages/AddProduct/Ornaments/OrnamentsPage')),
	MINICHER: lazy(() => import('../pages/AddProduct/Minicher/MinicherPage')),
	FLOWERS: lazy(() => import('../pages/AddProduct/Flowers/FlowersPage')),
	SWEET: lazy(() => import('../pages/AddProduct/Sweet/SweetPage')),
	PERSONALCARE: lazy(() => import('../pages/AddProduct/PersonalCare/PersonalCarePage')),
	ADDFLOWERS: lazy(() => import('../pages/products/AddFlowers/AddFlowersPage')),
	FRESHFLOWERS: lazy(() => import('../pages/AddProduct/FreshFlowers/FreshFlowersPage')),
	SAILOGABALA: lazy(() => import('../pages/AddProduct/SaiLogabala/SaiLogabalaPage')),
	PAPAD: lazy(() => import('../pages/AddProduct/Papad/PapadPage')),
	VASAKALMALAI: lazy(() => import('../pages/AddProduct/VasakalMalai/VasakalMalaiPage')),
};

const CUSTOMERS = {
	LISTOFCUSTOMER: lazy(() => import('../pages/customers/ListOfcustomers/ListOfcustomersPage')),
	VIEWCUSTOMER: lazy(() => import('../pages/customers/OrderHistory/CustomerOrderHistoryPage')),
	OTPLIST: lazy(() => import('../pages/customers/OtpList/OtpListPage')),
	UPDATEWALLET: lazy(() => import('../pages/customers/UpdateWallet/UpdateWalletPage')),
	REFUNDPAGE: lazy(() => import('../pages/customers/Refund/RefundPage')),
};

const REPORTS = {
	SALESDASHBOARD: lazy(() => import('../pages/reports/SalesDashboard/SalesDashboard')),
	CUSTOMERDASHBOARD: lazy(() => import('../pages/reports/CustomerDashboard/CustomerDashboard')),
	RECONCILIATION: lazy(() => import('../pages/reports/Reconciliation/Reconciliation')),
	STAFFREPORT: lazy(() => import('../pages/reports/StaffReport/StaffReport')),
	ORDERREPORT: lazy(() => import('../pages/reports/OrderReport/OrderReport')),
	PURCHASEREPORT: lazy(() => import('../pages/reports/PurchaseReport/PurchaseReportPage')),
	PACKINGREPORT: lazy(() => import('../pages/reports/PackingReport/PackingReportPage')),
};

const ORDERS = {
	DELIVERY_LIST: lazy(() => import('../pages/orders/DeliveryProccess/DeliveryProcessPage')),
	FLOWERORDER: lazy(() => import('../pages/orders/FlowerOrder/FlowerOrderPage')),
	PRODUCTORDER: lazy(() => import('../pages/orders/ProductOrder/ProductOrderPage')),
	ENDOFDAY: lazy(() => import('../pages/orders/EndOfDay/EndOfDayPage')),
	ENDOFDAYNEW: lazy(() => import('../pages/orders/EndOfDayNew/EndOfDayNewPage')),
	ORDERASSIGN: lazy(() => import('../pages/orders/OrderAssign/OrderAssignPage')),
	ASSIGNEDSTAFF: lazy(() => import('../pages/orders/AssignedStaff/AssignedStaff')),
	TODAYDELIVERED: lazy(() => import('../pages/orders/TodayDelivered/TodayDeliveredPage')),
	PLACEORDER: lazy(() => import('../pages/orders/PlaceOrder/place-order.component')),
	ORDERPLACE: lazy(() => import('../pages/orders/PlaceOrder/order-place/order-place.component')),
	LISTPRODUCT: lazy(() =>
		import('../pages/orders/PlaceOrder/list-products/list-products.component'),
	),
	TRANSCATION: lazy(() => import('../pages/orders/Transcation/TranscationPage')),
	ORDERLIST: lazy(() => import('../pages/orders/OrderList/OrderListPage')),
	COLLECTIONS: lazy(() => import('../pages/orders/Collections/CollectionsPage')),
	VIEWCANCELLEDORDERS: lazy(() =>
		import('../pages/orders/EndOfDay/ViewEodCancelOrderList/ViewEodCancelOrderListPage'),
	),
	TODAYEOD: lazy(() => import('../pages/orders/TodayEod/TodayEodPage')),
};

const INVENTORY = {
	PURCHASE_LIST: lazy(() => import('../pages/inventory/PurchaseList/PurchaseListPage')),
	STOCK_LIST: lazy(() => import('../pages/inventory/StockList/StockListPage')),
};

// const INVENTORYMASTER = {
// 	ADDHUB: lazy(() => import('../pages/InventoryMaster/AddHub/AddHubPage')),
// 	VENDOR: lazy(() => import('../pages/InventoryMaster/Vendor/VendorPage')),
// 	CREATE_INVENTORY: lazy(() =>
// 		import('../pages/InventoryMaster/CreateInventory/CreateInventoryPage'),
// 	),
// 	PURCHASEORDER: lazy(() => import('../pages/InventoryMaster/PurchaseOrder/PurchaseOrderPage')),
// 	PRODUCTS_DEALS_BY_VENDOR: lazy(() =>
// 		import('../pages/InventoryMaster/ProductsDealsByVendor/ProductsDealsByVendorPage'),
// 	),
// 	RE_PACKED: lazy(() => import('../pages/InventoryMaster/RePacked/RePackedPage')),
// };

const PROMOTIONS = {
	COUPON: lazy(() => import('../pages/Promotions/Coupon/CouponPage')),
	BANNER: lazy(() => import('../pages/Promotions/Banner/BannerPage')),
	RULE: lazy(() => import('../pages/Promotions/Rule/RulePage')),
	PUSHNOTIFICATION: lazy(() =>
		import('../pages/Promotions/PushNotification/PushNotificationPage'),
	),
};

// const SINGLE = {
// 	BOXED: lazy(() => import('../pages/presentation/single-pages/SingleBoxedPage')),
// 	FLUID: lazy(() => import('../pages/presentation/single-pages/SingleFluidPage')),
// };
// const LIST = {
// 	BOXED: lazy(() => import('../pages/presentation/demo-pages/ListBoxedPage')),
// 	FLUID: lazy(() => import('../pages/presentation/demo-pages/ListFluidPage')),
// };
// const GRID = {
// 	BOXED: lazy(() => import('../pages/presentation/demo-pages/GridBoxedPage')),
// 	FLUID: lazy(() => import('../pages/presentation/demo-pages/GridFluidPage')),
// };
// const EDIT = {
// 	BOXED: lazy(() => import('../pages/presentation/demo-pages/EditBoxedPage')),
// 	FLUID: lazy(() => import('../pages/presentation/demo-pages/EditFluidPage')),
// 	WIZARD: lazy(() => import('../pages/presentation/demo-pages/EditWizardPage')),
// 	IN_CANVAS: lazy(() => import('../pages/presentation/demo-pages/EditInCanvasPage')),
// 	IN_MODAL: lazy(() => import('../pages/presentation/demo-pages/EditInModalPage')),
// };
const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};
// const APP = {
// 	PROJECT_MANAGEMENT: {
// 		PROJECTS_LIST: lazy(() =>
// 			import('../pages/presentation/project-management/ProjectManagementsList'),
// 		),
// 		PROJECT: lazy(() =>
// 			import('../pages/presentation/project-management/ProjectManagementsProject'),
// 		),
// 	},
// 	KNOWLEDGE: {
// 		GRID: lazy(() => import('../pages/presentation/knowledge/KnowledgeGridPage')),
// 		VIEW: lazy(() => import('../pages/presentation/knowledge/KnowledgeViewPage')),
// 	},
// 	SALES: {
// 		TRANSACTIONS: lazy(() => import('../pages/presentation/sales/TransActionsPage')),
// 		PRODUCTS: lazy(() => import('../pages/presentation/sales/SalesListPage')),
// 		PRODUCTS_GRID: lazy(() => import('../pages/presentation/sales/ProductsGridPage')),
// 		PRODUCTS_VIEW: lazy(() => import('../pages/presentation/sales/ProductViewPage')),
// 	},
// 	APPOINTMENT: {
// 		CALENDAR: lazy(() => import('../pages/presentation/appointment/CalendarPage')),
// 		EMPLOYEE_LIST: lazy(() => import('../pages/presentation/appointment/EmployeeList')),
// 		EMPLOYEE_VIEW: lazy(() => import('../pages/presentation/appointment/EmployeePage')),
// 		APPOINTMENT_LIST: lazy(() => import('../pages/presentation/appointment/AppointmentList')),
// 	},
// 	CRM: {
// 		CRM_DASHBOARD: lazy(() => import('../pages/presentation/crm/CrmDashboard')),
// 		CUSTOMERS: lazy(() => import('../pages/presentation/crm/CustomersList')),
// 		CUSTOMER: lazy(() => import('../pages/presentation/crm/Customer')),
// 		SALES: lazy(() => import('../pages/presentation/crm/Sales')),
// 		INVOICE: lazy(() => import('../pages/presentation/crm/Invoice')),
// 	},
// 	CHAT: {
// 		WITH_LIST: lazy(() => import('../pages/presentation/chat/WithListChatPage')),
// 		ONLY_LIST: lazy(() => import('../pages/presentation/chat/OnlyListChatPage')),
// 	},
// };
// const PAGE_LAYOUTS = {
// 	HEADER_SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/HeaderAndSubheader')),
// 	HEADER: lazy(() => import('../pages/presentation/page-layouts/OnlyHeader')),
// 	SUBHEADER: lazy(() => import('../pages/presentation/page-layouts/OnlySubheader')),
// 	CONTENT: lazy(() => import('../pages/presentation/page-layouts/OnlyContent')),
// 	BLANK: lazy(() => import('../pages/presentation/page-layouts/Blank')),
// 	ASIDE: lazy(() => import('../pages/presentation/aside-types/DefaultAsidePage')),
// 	MINIMIZE_ASIDE: lazy(() => import('../pages/presentation/aside-types/MinimizeAsidePage')),
// };

// const CONTENT = {
// 	CONTENTS: lazy(() => import('../pages/documentation/content/ContentListPage')),
// 	TYPOGRAPHY: lazy(() => import('../pages/documentation/content/TypographyPage')),
// 	IMAGES: lazy(() => import('../pages/documentation/content/ImagesPage')),
// 	TABLES: lazy(() => import('../pages/documentation/content/TablesPage')),
// 	FIGURES: lazy(() => import('../pages/documentation/content/FiguresPage')),
// };
// const FORMS_PAGE = {
// 	FORMS: lazy(() => import('../pages/documentation/forms/FormsListPage')),
// 	FORM_GROUP: lazy(() => import('../pages/documentation/forms/FormGroupPage')),
// 	FORM_CONTROLS: lazy(() => import('../pages/documentation/forms/FormControlsPage')),
// 	SELECT: lazy(() => import('../pages/documentation/forms/SelectPage')),
// 	CHECKS_AND_RADIO: lazy(() => import('../pages/documentation/forms/ChecksAndRadioPage')),
// 	RANGE: lazy(() => import('../pages/documentation/forms/RangePage')),
// 	INPUT_GROUP: lazy(() => import('../pages/documentation/forms/InputGroupPage')),
// 	VALIDATION: lazy(() => import('../pages/documentation/forms/ValidationPage')),
// 	WIZARD: lazy(() => import('../pages/documentation/forms/WizardPage')),
// };
// const COMPONENTS_PAGE = {
// 	COMPONENTS: lazy(() => import('../pages/documentation/components/ComponentsListPage')),
// 	ACCORDION: lazy(() => import('../pages/documentation/components/AccordionPage')),
// 	ALERT: lazy(() => import('../pages/documentation/components/AlertPage')),
// 	BADGE: lazy(() => import('../pages/documentation/components/BadgePage')),
// 	BREADCRUMB: lazy(() => import('../pages/documentation/components/BreadcrumbPage')),
// 	BUTTON: lazy(() => import('../pages/documentation/components/ButtonPage')),
// 	BUTTON_GROUP: lazy(() => import('../pages/documentation/components/ButtonGroupPage')),
// 	CARD: lazy(() => import('../pages/documentation/components/CardPage')),
// 	CAROUSEL: lazy(() => import('../pages/documentation/components/CarouselPage')),
// 	COLLAPSE: lazy(() => import('../pages/documentation/components/CollapsePage')),
// 	DROPDOWN: lazy(() => import('../pages/documentation/components/DropdownsPage')),
// 	LIST_GROUP: lazy(() => import('../pages/documentation/components/ListGroupPage')),
// 	MODAL: lazy(() => import('../pages/documentation/components/ModalPage')),
// 	NAVS_TABS: lazy(() => import('../pages/documentation/components/NavsTabsPage')),
// 	OFF_CANVAS: lazy(() => import('../pages/documentation/components/OffCanvasPage')),
// 	PAGINATION: lazy(() => import('../pages/documentation/components/PaginationPage')),
// 	POPOVERS: lazy(() => import('../pages/documentation/components/PopoversPage')),
// 	PROGRESS: lazy(() => import('../pages/documentation/components/ProgressPage')),
// 	SCROLLSPY: lazy(() => import('../pages/documentation/components/ScrollspyPage')),
// 	SPINNER: lazy(() => import('../pages/documentation/components/SpinnersPage')),
// 	TABLE: lazy(() => import('../pages/documentation/components/TablePage')),
// 	TOASTS: lazy(() => import('../pages/documentation/components/ToastsPage')),
// 	TOOLTIP: lazy(() => import('../pages/documentation/components/TooltipPage')),
// };
// const UTILITIES = {
// 	UTILITIES: lazy(() => import('../pages/documentation/utilities/UtilitiesListPage')),
// 	API: lazy(() => import('../pages/documentation/utilities/ApiPage')),
// 	BACKGROUND: lazy(() => import('../pages/documentation/utilities/BackgroundPage')),
// 	BORDERS: lazy(() => import('../pages/documentation/utilities/BordersPage')),
// 	COLORS: lazy(() => import('../pages/documentation/utilities/ColorsPage')),
// 	DISPLAY: lazy(() => import('../pages/documentation/utilities/DisplayPage')),
// 	FLEX: lazy(() => import('../pages/documentation/utilities/FlexPage')),
// 	FLOAT: lazy(() => import('../pages/documentation/utilities/FloatPage')),
// 	INTERACTIONS: lazy(() => import('../pages/documentation/utilities/InteractionsPage')),
// 	OVERFLOW: lazy(() => import('../pages/documentation/utilities/OverflowPage')),
// 	POSITION: lazy(() => import('../pages/documentation/utilities/PositionPage')),
// 	SHADOWS: lazy(() => import('../pages/documentation/utilities/ShadowsPage')),
// 	SIZING: lazy(() => import('../pages/documentation/utilities/SizingPage')),
// 	SPACING: lazy(() => import('../pages/documentation/utilities/SpacingPage')),
// 	TEXT: lazy(() => import('../pages/documentation/utilities/TextPage')),
// 	VERTICAL_ALIGN: lazy(() => import('../pages/documentation/utilities/VerticalAlignPage')),
// 	VISIBILITY: lazy(() => import('../pages/documentation/utilities/VisibilityPage')),
// };
// const ICONS = {
// 	ICONS_LIST: lazy(() => import('../pages/documentation/icons/IconsListPage')),
// 	ICON: lazy(() => import('../pages/documentation/icons/IconPage')),
// 	BOOTSTRAP: lazy(() => import('../pages/documentation/icons/BootstrapIconPage')),
// 	MATERIAL: lazy(() => import('../pages/documentation/icons/MaterialPage')),
// };
// const CHARTS_PAGE = {
// 	CHART_LIST: lazy(() => import('../pages/documentation/charts/ChartsListPage')),
// 	GENERAL_USAGE: lazy(() => import('../pages/documentation/charts/ChartGeneralUsagePage')),
// 	SPARKLINE: lazy(() => import('../pages/documentation/charts/ChartSparklinePage')),
// 	LINE: lazy(() => import('../pages/documentation/charts/ChartLinePage')),
// 	AREA: lazy(() => import('../pages/documentation/charts/ChartAreaPage')),
// 	COLUMN: lazy(() => import('../pages/documentation/charts/ChartColumnPage')),
// 	BAR: lazy(() => import('../pages/documentation/charts/ChartBarPage')),
// 	MIXED: lazy(() => import('../pages/documentation/charts/ChartMixedPage')),
// 	TIMELINE: lazy(() => import('../pages/documentation/charts/ChartTimelinePage')),
// 	CANDLESTICK: lazy(() => import('../pages/documentation/charts/ChartCandlestickPage')),
// 	BOX_WHISKER: lazy(() => import('../pages/documentation/charts/ChartBoxWhiskerPage')),
// 	PIE_DONUT: lazy(() => import('../pages/documentation/charts/ChartPieDonutPage')),
// 	RADAR: lazy(() => import('../pages/documentation/charts/ChartRadarPage')),
// 	POLAR: lazy(() => import('../pages/documentation/charts/ChartPolarPage')),
// 	RADIAL_BAR: lazy(() => import('../pages/documentation/charts/ChartRadialBarPage')),
// 	BUBBLE: lazy(() => import('../pages/documentation/charts/ChartBubblePage')),
// 	SCATTER: lazy(() => import('../pages/documentation/charts/ChartScatterPage')),
// 	HEAT_MAP: lazy(() => import('../pages/documentation/charts/ChartHeatMapPage')),
// 	TREE_MAP: lazy(() => import('../pages/documentation/charts/ChartTreeMapPage')),
// };
const EXTRA = {
	NOTIFICATION: lazy(() => import('../pages/documentation/extras/NotificationPage')),
	HOOKS: lazy(() => import('../pages/documentation/extras/HooksPage')),
};

const presentation = [
	/**
	 * Landing
	 */
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.staff.path,
		element: <MASTERS.STAFF />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.pincode.path,
		element: <MASTERS.PINCODE />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.dashboardCategory.path,
		element: <MASTERS.DASHBOARDCATEGORY />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.combo.path,
		element: <MASTERS.COMBO />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.productCombo.path,
		element: <MASTERS.PRODUCTCOMBO />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.festival.path,
		element: <MASTERS.FESTIVAL />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.minicher.path,
		element: <MASTERS.ADDMINICHER />,
		exact: true,
	},
	{
		path: dashboardMenu.masters.subMenu.priceUpdateAllProducts.path,
		element: <MASTERS.ALLPRODUCTPRICEUPDATE />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.products.subMenu.priceUpdate.path,
	// 	element: <PRODUCTS.PRICEUPDATE />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.products.subMenu.walletCustomers.path,
	// 	element: <PRODUCTS.WALLETCUSTOMERS />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.addProduct.subMenu.food.path,
		element: <ADDPRODUCT.FOOD />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.snack.path,
		element: <ADDPRODUCT.SNACK />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.vegtables.path,
		element: <ADDPRODUCT.VEGTABLES />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.grocery.path,
		element: <ADDPRODUCT.GROCERY />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.fruits.path,
		element: <ADDPRODUCT.FRUITS />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.bouquet.path,
		element: <ADDPRODUCT.BOUQUET />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.maalai.path,
		element: <ADDPRODUCT.MAALAI />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.poojaItem.path,
		element: <ADDPRODUCT.POOJAIITEM />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.ornaments.path,
		element: <ADDPRODUCT.ORNAMENTS />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.addMinicher.path,
		element: <ADDPRODUCT.MINICHER />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.flowers.path,
		element: <ADDPRODUCT.FLOWERS />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.sweet.path,
		element: <ADDPRODUCT.SWEET />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.personalCare.path,
		element: <ADDPRODUCT.PERSONALCARE />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.addFlowers.path,
		element: <ADDPRODUCT.ADDFLOWERS />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.freshFlowers.path,
		element: <ADDPRODUCT.FRESHFLOWERS />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.saiLogabala.path,
		element: <ADDPRODUCT.SAILOGABALA />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.papad.path,
		element: <ADDPRODUCT.PAPAD />,
		exact: true,
	},
	{
		path: dashboardMenu.addProduct.subMenu.vasakalMalai.path,
		element: <ADDPRODUCT.VASAKALMALAI />,
		exact: true,
	},
	{
		path: dashboardMenu.customers.subMenu.listOfcustomers.path,
		element: <CUSTOMERS.LISTOFCUSTOMER />,
		exact: true,
	},
	{
		path: dashboardMenu.customers.subMenu.viewCustomersHistory.path,
		element: <CUSTOMERS.VIEWCUSTOMER />,
		exact: true,
	},
	{
		path: dashboardMenu.customers.subMenu.otpList.path,
		element: <CUSTOMERS.OTPLIST />,
		exact: true,
	},
	{
		path: dashboardMenu.customers.subMenu.updateWallet.path,
		element: <CUSTOMERS.UPDATEWALLET />,
		exact: true,
	},
	{
		path: dashboardMenu.customers.subMenu.refund.path,
		element: <CUSTOMERS.REFUNDPAGE />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.deliveyProccessList.path,
		element: <ORDERS.DELIVERY_LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.flowerOrder.path,
		element: <ORDERS.FLOWERORDER />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.prdocutOrder.path,
		element: <ORDERS.PRODUCTORDER />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.transcation.path,
		element: <ORDERS.TRANSCATION />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.orderList.path,
		element: <ORDERS.ORDERLIST />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.collections.path,
		element: <ORDERS.COLLECTIONS />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.viewCancelledOrders.path,
		element: <ORDERS.VIEWCANCELLEDORDERS />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.endOfDay.path,
		element: <ORDERS.ENDOFDAY />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.endOfDayNew.path,
		element: <ORDERS.ENDOFDAYNEW />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.orderAssign.path,
		element: <ORDERS.ORDERASSIGN />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.todayEods.path,
		element: <ORDERS.TODAYEOD />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.assignedStaff.path,
		element: <ORDERS.ASSIGNEDSTAFF />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.todayDelivered.path,
		element: <ORDERS.TODAYDELIVERED />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.placeOrder.path,
		element: <ORDERS.PLACEORDER />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.orderPlace.path,
		element: <ORDERS.ORDERPLACE />,
		exact: true,
	},
	{
		path: dashboardMenu.orders.subMenu.listProduct.path,
		element: <ORDERS.LISTPRODUCT />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.subMenu.salesDashboard.path,
		element: <REPORTS.SALESDASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.subMenu.customerDashboard.path,
		element: <REPORTS.CUSTOMERDASHBOARD />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.subMenu.reconciliation.path,
		element: <REPORTS.RECONCILIATION />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.subMenu.staffReport.path,
		element: <REPORTS.STAFFREPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.subMenu.orderReport.path,
		element: <REPORTS.ORDERREPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.subMenu.purchaseReport.path,
		element: <REPORTS.PURCHASEREPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.reports.subMenu.packingReport.path,
		element: <REPORTS.PACKINGREPORT />,
		exact: true,
	},
	{
		path: dashboardMenu.inventory.subMenu.purchaseList.path,
		element: <INVENTORY.PURCHASE_LIST />,
		exact: true,
	},
	{
		path: dashboardMenu.inventory.subMenu.stockList.path,
		element: <INVENTORY.STOCK_LIST />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.addHub.path,
	// 	element: <INVENTORYMASTER.ADDHUB />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.vendor.path,
	// 	element: <INVENTORYMASTER.VENDOR />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.createInventory.path,
	// 	element: <INVENTORYMASTER.CREATE_INVENTORY />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.purchaseOrder.path,
	// 	element: <INVENTORYMASTER.PURCHASEORDER />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.productsDealsByVendor.path,
	// 	element: <INVENTORYMASTER.PRODUCTS_DEALS_BY_VENDOR />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.rePacked.path,
	// 	element: <INVENTORYMASTER.RE_PACKED />,
	// 	exact: true,
	// },
	{
		path: dashboardMenu.promotions.subMenu.coupon.path,
		element: <PROMOTIONS.COUPON />,
		exact: true,
	},
	{
		path: dashboardMenu.promotions.subMenu.banner.path,
		element: <PROMOTIONS.BANNER />,
		exact: true,
	},
	{
		path: dashboardMenu.promotions.subMenu.rule.path,
		element: <PROMOTIONS.RULE />,
		exact: true,
	},
	{
		path: dashboardMenu.promotions.subMenu.pushNotification.path,
		element: <PROMOTIONS.PUSHNOTIFICATION />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];

const flower = [
	/**
	 * Landing
	 */
	// {
	// 	path: flowersMenu.dashboard.path,
	// 	element: <LANDING.DASHBOARD />,
	// 	exact: true,
	// },
	{
		path: flowersMenu.masters.subMenu.staff.path,
		element: <MASTERS.STAFF />,
		exact: true,
	},
	{
		path: flowersMenu.orders.subMenu.endOfDay.path,
		element: <ORDERS.ENDOFDAY />,
		exact: true,
	},
	{
		path: flowersMenu.orders.subMenu.assignedStaff.path,
		element: <ORDERS.ASSIGNEDSTAFF />,
		exact: true,
	},
	{
		path: flowersMenu.reports.subMenu.staffReport.path,
		element: <REPORTS.STAFFREPORT />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];

const ethic = [
	/**
	 * Landing
	 */
	// {
	// 	path: ethicMenu.dashboard.path,
	// 	element: <LANDING.DASHBOARD />,
	// 	exact: true,
	// },
	{
		path: ethicMenu.masters.subMenu.staff.path,
		element: <MASTERS.STAFF />,
		exact: true,
	},
	{
		path: ethicMenu.masters.subMenu.productCombo.path,
		element: <MASTERS.PRODUCTCOMBO />,
		exact: true,
	},
	{
		path: ethicMenu.masters.subMenu.combo.path,
		element: <MASTERS.COMBO />,
		exact: true,
	},
	{
		path: ethicMenu.masters.subMenu.festival.path,
		element: <MASTERS.FESTIVAL />,
		exact: true,
	},
	{
		path: ethicMenu.addProduct.subMenu.food.path,
		element: <ADDPRODUCT.FOOD />,
		exact: true,
	},
	{
		path: ethicMenu.addProduct.subMenu.snack.path,
		element: <ADDPRODUCT.SNACK />,
		exact: true,
	},
	{
		path: ethicMenu.addProduct.subMenu.vegtables.path,
		element: <ADDPRODUCT.VEGTABLES />,
		exact: true,
	},
	{
		path: ethicMenu.addProduct.subMenu.grocery.path,
		element: <ADDPRODUCT.GROCERY />,
		exact: true,
	},
	{
		path: ethicMenu.addProduct.subMenu.fruits.path,
		element: <ADDPRODUCT.FRUITS />,
		exact: true,
	},
	{
		path: ethicMenu.addProduct.subMenu.flowers.path,
		element: <ADDPRODUCT.FLOWERS />,
		exact: true,
	},
	{
		path: ethicMenu.addProduct.subMenu.sweet.path,
		element: <ADDPRODUCT.SWEET />,
		exact: true,
	},
	{
		path: ethicMenu.addProduct.subMenu.personalCare.path,
		element: <ADDPRODUCT.PERSONALCARE />,
		exact: true,
	},
	{
		path: ethicMenu.customers.subMenu.listOfcustomers.path,
		element: <CUSTOMERS.LISTOFCUSTOMER />,
		exact: true,
	},
	{
		path: ethicMenu.customers.subMenu.viewCustomersHistory.path,
		element: <CUSTOMERS.VIEWCUSTOMER />,
		exact: true,
	},
	{
		path: ethicMenu.customers.subMenu.otpList.path,
		element: <CUSTOMERS.OTPLIST />,
		exact: true,
	},
	{
		path: ethicMenu.orders.subMenu.prdocutOrder.path,
		element: <ORDERS.PRODUCTORDER />,
		exact: true,
	},
	{
		path: ethicMenu.orders.subMenu.transcation.path,
		element: <ORDERS.TRANSCATION />,
		exact: true,
	},
	{
		path: ethicMenu.orders.subMenu.endOfDay.path,
		element: <ORDERS.ENDOFDAY />,
		exact: true,
	},
	{
		path: ethicMenu.orders.subMenu.todayDelivered.path,
		element: <ORDERS.TODAYDELIVERED />,
		exact: true,
	},
	{
		path: ethicMenu.inventory.subMenu.purchaseList.path,
		element: <INVENTORY.PURCHASE_LIST />,
		exact: true,
	},
	{
		path: ethicMenu.inventory.subMenu.stockList.path,
		element: <INVENTORY.STOCK_LIST />,
		exact: true,
	},
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.addHub.path,
	// 	element: <INVENTORYMASTER.ADDHUB />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.vendor.path,
	// 	element: <INVENTORYMASTER.VENDOR />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.createInventory.path,
	// 	element: <INVENTORYMASTER.CREATE_INVENTORY />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.purchaseOrder.path,
	// 	element: <INVENTORYMASTER.PURCHASEORDER />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.productsDealsByVendor.path,
	// 	element: <INVENTORYMASTER.PRODUCTS_DEALS_BY_VENDOR />,
	// 	exact: true,
	// },
	// {
	// 	path: dashboardMenu.inventoryMaster.subMenu.rePacked.path,
	// 	element: <INVENTORYMASTER.RE_PACKED />,
	// 	exact: true,
	// },
	{
		path: ethicMenu.promotions.subMenu.coupon.path,
		element: <PROMOTIONS.COUPON />,
		exact: true,
	},
	{
		path: ethicMenu.promotions.subMenu.banner.path,
		element: <PROMOTIONS.BANNER />,
		exact: true,
	},
	{
		path: ethicMenu.promotions.subMenu.rule.path,
		element: <PROMOTIONS.RULE />,
		exact: true,
	},
	{
		path: demoPages.page404.path,
		element: <AUTH.PAGE_404 />,
		exact: true,
	},
	{
		path: demoPages.login.path,
		element: <Login />,
		exact: true,
	},
	{
		path: demoPages.signUp.path,
		element: <Login isSignUp />,
		exact: true,
	},
];
const documentation = [
	/**
	 * Bootstrap
	 */

	/**
	 * Content
	 */
	// {
	// 	path: componentsMenu.content.path,
	// 	element: <CONTENT.CONTENTS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.content.subMenu.typography.path,
	// 	element: <CONTENT.TYPOGRAPHY />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.content.subMenu.images.path,
	// 	element: <CONTENT.IMAGES />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.content.subMenu.tables.path,
	// 	element: <CONTENT.TABLES />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.content.subMenu.figures.path,
	// 	element: <CONTENT.FIGURES />,
	// 	exact: true,
	// },

	/**
	 * Forms
	 */
	// {
	// 	path: componentsMenu.forms.path,
	// 	element: <FORMS_PAGE.FORMS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.formGroup.path,
	// 	element: <FORMS_PAGE.FORM_GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.formControl.path,
	// 	element: <FORMS_PAGE.FORM_CONTROLS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.select.path,
	// 	element: <FORMS_PAGE.SELECT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.checksAndRadio.path,
	// 	element: <FORMS_PAGE.CHECKS_AND_RADIO />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.range.path,
	// 	element: <FORMS_PAGE.RANGE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.inputGroup.path,
	// 	element: <FORMS_PAGE.INPUT_GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.validation.path,
	// 	element: <FORMS_PAGE.VALIDATION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.forms.subMenu.wizard.path,
	// 	element: <FORMS_PAGE.WIZARD />,
	// 	exact: true,
	// },

	/**
	 * Components
	 */
	// {
	// 	path: componentsMenu.components.path,
	// 	element: <COMPONENTS_PAGE.COMPONENTS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.tooltip.path,
	// 	element: <COMPONENTS_PAGE.TOOLTIP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.toasts.path,
	// 	element: <COMPONENTS_PAGE.TOASTS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.scrollspy.path,
	// 	element: <COMPONENTS_PAGE.SCROLLSPY />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.carousel.path,
	// 	element: <COMPONENTS_PAGE.CAROUSEL />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.spinners.path,
	// 	element: <COMPONENTS_PAGE.SPINNER />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.listGroup.path,
	// 	element: <COMPONENTS_PAGE.LIST_GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.breadcrumb.path,
	// 	element: <COMPONENTS_PAGE.BREADCRUMB />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.collapse.path,
	// 	element: <COMPONENTS_PAGE.COLLAPSE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.pagination.path,
	// 	element: <COMPONENTS_PAGE.PAGINATION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.progress.path,
	// 	element: <COMPONENTS_PAGE.PROGRESS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.card.path,
	// 	element: <COMPONENTS_PAGE.CARD />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.button.path,
	// 	element: <COMPONENTS_PAGE.BUTTON />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.buttonGroup.path,
	// 	element: <COMPONENTS_PAGE.BUTTON_GROUP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.alert.path,
	// 	element: <COMPONENTS_PAGE.ALERT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.badge.path,
	// 	element: <COMPONENTS_PAGE.BADGE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.popovers.path,
	// 	element: <COMPONENTS_PAGE.POPOVERS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.dropdowns.path,
	// 	element: <COMPONENTS_PAGE.DROPDOWN />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.accordion.path,
	// 	element: <COMPONENTS_PAGE.ACCORDION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.modal.path,
	// 	element: <COMPONENTS_PAGE.MODAL />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.navsTabs.path,
	// 	element: <COMPONENTS_PAGE.NAVS_TABS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.offcanvas.path,
	// 	element: <COMPONENTS_PAGE.OFF_CANVAS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.components.subMenu.table.path,
	// 	element: <COMPONENTS_PAGE.TABLE />,
	// 	exact: true,
	// },

	/**
	 * Utilities
	 */
	// {
	// 	path: componentsMenu.utilities.path,
	// 	element: <UTILITIES.UTILITIES />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.api.path,
	// 	element: <UTILITIES.API />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.background.path,
	// 	element: <UTILITIES.BACKGROUND />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.borders.path,
	// 	element: <UTILITIES.BORDERS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.colors.path,
	// 	element: <UTILITIES.COLORS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.display.path,
	// 	element: <UTILITIES.DISPLAY />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.flex.path,
	// 	element: <UTILITIES.FLEX />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.float.path,
	// 	element: <UTILITIES.FLOAT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.interactions.path,
	// 	element: <UTILITIES.INTERACTIONS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.overflow.path,
	// 	element: <UTILITIES.OVERFLOW />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.position.path,
	// 	element: <UTILITIES.POSITION />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.shadows.path,
	// 	element: <UTILITIES.SHADOWS />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.sizing.path,
	// 	element: <UTILITIES.SIZING />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.spacing.path,
	// 	element: <UTILITIES.SPACING />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.text.path,
	// 	element: <UTILITIES.TEXT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.verticalAlign.path,
	// 	element: <UTILITIES.VERTICAL_ALIGN />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.utilities.subMenu.visibility.path,
	// 	element: <UTILITIES.VISIBILITY />,
	// 	exact: true,
	// },

	/**
	 * Extra
	 */

	/**
	 * Icons
	 */
	// {
	// 	path: componentsMenu.icons.path,
	// 	element: <ICONS.ICONS_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.icons.subMenu.icon.path,
	// 	element: <ICONS.ICON />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.icons.subMenu.material.path,
	// 	element: <ICONS.MATERIAL />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.icons.subMenu.bootstrapIcon.path,
	// 	element: <ICONS.BOOTSTRAP />,
	// 	exact: true,
	// },

	/**
	 * Charts
	 */
	// {
	// 	path: componentsMenu.charts.path,
	// 	element: <CHARTS_PAGE.CHART_LIST />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsUsage.path,
	// 	element: <CHARTS_PAGE.GENERAL_USAGE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsSparkline.path,
	// 	element: <CHARTS_PAGE.SPARKLINE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsLine.path,
	// 	element: <CHARTS_PAGE.LINE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsArea.path,
	// 	element: <CHARTS_PAGE.AREA />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsColumn.path,
	// 	element: <CHARTS_PAGE.COLUMN />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsBar.path,
	// 	element: <CHARTS_PAGE.BAR />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsMixed.path,
	// 	element: <CHARTS_PAGE.MIXED />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsTimeline.path,
	// 	element: <CHARTS_PAGE.TIMELINE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsCandleStick.path,
	// 	element: <CHARTS_PAGE.CANDLESTICK />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsBoxWhisker.path,
	// 	element: <CHARTS_PAGE.BOX_WHISKER />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsPieDonut.path,
	// 	element: <CHARTS_PAGE.PIE_DONUT />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsRadar.path,
	// 	element: <CHARTS_PAGE.RADAR />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsPolar.path,
	// 	element: <CHARTS_PAGE.POLAR />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsRadialBar.path,
	// 	element: <CHARTS_PAGE.RADIAL_BAR />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsBubble.path,
	// 	element: <CHARTS_PAGE.BUBBLE />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsScatter.path,
	// 	element: <CHARTS_PAGE.SCATTER />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsHeatMap.path,
	// 	element: <CHARTS_PAGE.HEAT_MAP />,
	// 	exact: true,
	// },
	// {
	// 	path: componentsMenu.charts.subMenu.chartsTreeMap.path,
	// 	element: <CHARTS_PAGE.TREE_MAP />,
	// 	exact: true,
	// },

	{
		path: componentsMenu.notification.path,
		element: <EXTRA.NOTIFICATION />,
		exact: true,
	},
	{
		path: componentsMenu.hooks.path,
		element: <EXTRA.HOOKS />,
		exact: true,
	},
];
const contents = [...presentation, ...documentation, ...flower, ...ethic];

export default contents;
