/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import stockListTypes from './stockList.types';
import {
	getStocksList,
	reduceStockQuantity,
	stockAddQuantity,
	onAndOffTopSellingSwitch,
	getViewStockList,
} from './stockList.service';
import {
	stockListSuccess,
	stockListFailure,
	stockListChanged,
	reduceStockSuccess,
	reduceStockFailure,
	reduceStockChanged,
	stockAddSuccess,
	stockAddFailure,
	stockAddChanged,
	topSellingSwitchSuccess,
	topSellingSwitchFailure,
	topSellingSwitchChanged,
	viewStockExpirySuccess,
	viewStockExpiryFailure,
	viewStockExpiryChanged,
} from './stockList.action';
import { encodeJson } from '../enode-decode';

/* STOCK LIST START */
export function* stockListDetailsStart({ payload }) {
	try {
		const key = 'StockListKey';
		const jsonData = {
			Limit: payload.limit,
			SearchValueSet: payload.searchValueSet,
			ProductCategory: payload.activeTab,
			BranchID: payload.branchID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getStocksList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(stockListFailure(responseData.data));
		} else {
			yield put(stockListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(stockListFailure(error));
	}
}

export function* stockListDetailsChanged() {
	yield put(stockListChanged());
}

export function* stockListDetails() {
	yield takeLatest(stockListTypes.STOCK_LIST_START, stockListDetailsStart);
}

export function* stockListResponseReset() {
	yield takeLatest(stockListTypes.STOCK_LIST_RESET, stockListDetailsChanged);
}
/* STOCK LIST END */

/* REDUCE STOCK START */
export function* reduceStockDetailsStart({ payload }) {
	try {
		const key = 'ReduceProductStocksKey';
		const jsonData = {
			ProductPriceChartID: payload.PriceChartID,
			ProductQuanitity: payload.ProductStockQunatity,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(reduceStockQuantity, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(reduceStockFailure(responseData.data));
		} else {
			yield put(reduceStockSuccess(responseData.data));
		}
	} catch (error) {
		yield put(reduceStockFailure(error));
	}
}

export function* reduceStockDetailsChanged() {
	yield put(reduceStockChanged());
}

export function* reduceStockDetails() {
	yield takeLatest(stockListTypes.REDUCE_STOCK_START, reduceStockDetailsStart);
}

export function* reduceStockResponseReset() {
	yield takeLatest(stockListTypes.REDUCE_STOCK_RESET, reduceStockDetailsChanged);
}
/* REDUCE STOCK END */

/* ADD STOCK START */
export function* stockAddDetailsStart({ payload }) {
	try {
		const key = 'StockAddKey';
		const jsonData = {
			ProductPriceChartID: payload.PriceChartID,
			ProductQuanitity: payload.ProductStockQunatity,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(stockAddQuantity, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(stockAddFailure(responseData.data));
		} else {
			yield put(stockAddSuccess(responseData.data));
		}
	} catch (error) {
		yield put(stockAddFailure(error));
	}
}

export function* stockAddDetailsChanged() {
	yield put(stockAddChanged());
}

export function* stockAddDetails() {
	yield takeLatest(stockListTypes.ADD_STOCK_START, stockAddDetailsStart);
}

export function* stockAddResponseReset() {
	yield takeLatest(stockListTypes.ADD_STOCK_RESET, stockAddDetailsChanged);
}
/* ADD STOCK END */

/* ADD STOCK START */
export function* switchTopSelling({ payload }) {
	try {
		const key = 'UpdateTopSellingKey';
		const jsonData = {
			ProductPriceChartID: payload.setProductPriceChartID,
			TopSellingSet: payload.topSellingSet,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(onAndOffTopSellingSwitch, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(topSellingSwitchFailure(responseData.data));
		} else {
			yield put(topSellingSwitchSuccess(responseData.data));
		}
	} catch (error) {
		yield put(topSellingSwitchFailure(error));
	}
}

export function* switchTopSellingChanged() {
	yield put(topSellingSwitchChanged());
}

export function* switchTopSellingStart() {
	yield takeLatest(stockListTypes.TOP_SELLING_SWITCH_START, switchTopSelling);
}

export function* switchTopSellingReset() {
	yield takeLatest(stockListTypes.TOP_SELLING_SWITCH_RESET, switchTopSellingChanged);
}
/* ADD STOCK END */

/* VIEW STOCK EXPIRY START */
export function* stockExpiryView({ payload }) {
	try {
		const key = 'GetViewStockListKey';
		const jsonData = {
			ProductID: payload.ProductID,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getViewStockList, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(viewStockExpiryFailure(responseData.data));
		} else {
			yield put(viewStockExpirySuccess(responseData.data));
		}
	} catch (error) {
		yield put(viewStockExpiryFailure(error));
	}
}

export function* stockExpiryViewChanged() {
	yield put(viewStockExpiryChanged());
}

export function* stockExpiryViewStart() {
	yield takeLatest(stockListTypes.VIEW_STOCK_EXPIRY_START, stockExpiryView);
}

export function* stockExpiryViewReset() {
	yield takeLatest(stockListTypes.VIEW_STOCK_EXPIRY_RESET, stockExpiryViewChanged);
}
/* VIEW STOCK EXPIRY END */

export function* stockListSaga() {
	yield all([
		call(stockListDetails),
		call(stockListResponseReset),
		call(reduceStockDetails),
		call(reduceStockResponseReset),
		call(stockAddDetails),
		call(stockAddResponseReset),
		call(switchTopSellingStart),
		call(switchTopSellingReset),
		call(stockExpiryViewStart),
		call(stockExpiryViewReset),
	]);
}
