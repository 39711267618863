/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import endOfDayTypes from './EndOfDay.types';

/* LIST END OF DAY START */
export const listEndOfDayChanged = () => ({
	type: endOfDayTypes.GET_LIST_END_OF_DAY_CHANGED,
});

export const listEndOfDayReset = () => ({
	type: endOfDayTypes.GET_LIST_END_OF_DAY_RESET,
});

export const listEndOfDayStart = (listAllDeliveredDetails) => ({
	type: endOfDayTypes.GET_LIST_END_OF_DAY_START,
	payload: listAllDeliveredDetails,
});

export const listEndOfDaySuccess = (listAllDeliveredSuccess) => ({
	type: endOfDayTypes.GET_LIST_END_OF_DAY_SUCCESS,
	payload: listAllDeliveredSuccess,
});

export const listEndOfDayFailure = (error) => ({
	type: endOfDayTypes.GET_LIST_END_OF_DAY_FAILURE,
	payload: error,
});
/* LIST END OF DAY END */

/* END OF DAY START */
export const addEndOfDayChanged = () => ({
	type: endOfDayTypes.ADD_END_OF_DAY_CHANGED,
});

export const addEndOfDayReset = () => ({
	type: endOfDayTypes.ADD_END_OF_DAY_RESET,
});

export const addEndOfDayStart = (updateEndOfDayDetails) => ({
	type: endOfDayTypes.ADD_END_OF_DAY_START,
	payload: updateEndOfDayDetails,
});

export const addEndOfDaySuccess = (updateEndOfDaySuccess) => ({
	type: endOfDayTypes.ADD_END_OF_DAY_SUCCESS,
	payload: updateEndOfDaySuccess,
});

export const addEndOfDayFailure = (error) => ({
	type: endOfDayTypes.ADD_END_OF_DAY_FAILURE,
	payload: error,
});
/* END OF DAY END */

/* FETCH END OF DAY DETAILS START */
export const fetchEndOfDayDetailsChanged = () => ({
	type: endOfDayTypes.FETCH_END_OF_DAY_CHANGED,
});

export const fetchEndOfDayDetailsReset = () => ({
	type: endOfDayTypes.FETCH_END_OF_DAY_RESET,
});

export const fetchEndOfDayDetailsStart = (fetchEndOfDayDetails) => ({
	type: endOfDayTypes.FETCH_END_OF_DAY_START,
	payload: fetchEndOfDayDetails,
});

export const fetchEndOfDayDetailsSuccess = (fetchEndOfDaySuccess) => ({
	type: endOfDayTypes.FETCH_END_OF_DAY_SUCCESS,
	payload: fetchEndOfDaySuccess,
});

export const fetchEndOfDayDetailsFailure = (error) => ({
	type: endOfDayTypes.FETCH_END_OF_DAY_FAILURE,
	payload: error,
});
/* FETCH END OF DAY DETAILS END */

/* VIEW ORDER DETAILS START */
export const viewOrderDetailsDetailsChanged = () => ({
	type: endOfDayTypes.VIEW_ORDERS_LIST_CHANGED,
});

export const viewOrderDetailsDetailsReset = () => ({
	type: endOfDayTypes.VIEW_ORDERS_LIST_RESET,
});

export const viewOrderDetailsDetailsStart = (viewOrderDetails) => ({
	type: endOfDayTypes.VIEW_ORDERS_LIST_START,
	payload: viewOrderDetails,
});

export const viewOrderDetailsDetailsSuccess = (viewOrderSuccess) => ({
	type: endOfDayTypes.VIEW_ORDERS_LIST_SUCCESS,
	payload: viewOrderSuccess,
});

export const viewOrderDetailsDetailsFailure = (error) => ({
	type: endOfDayTypes.VIEW_ORDERS_LIST_FAILURE,
	payload: error,
});
/* VIEW ORDER DETAILS END */

export const itemOrderAddStart = (item) => ({
	type: endOfDayTypes.ADD_ORDER_ITEM,
	payload: item,
});

/* ORDERS CANCELLED START */
export const orderCancelledStart = (orderCancelled) => ({
	type: endOfDayTypes.ORDERS_CANCELLED_START,
	payload: orderCancelled,
});

export const orderCancelledSuccess = (viewOrderSuccess) => ({
	type: endOfDayTypes.ORDERS_CANCELLED_SUCCESS,
	payload: viewOrderSuccess,
});

export const orderCancelledFailure = (error) => ({
	type: endOfDayTypes.ORDERS_CANCELLED_FAILURE,
	payload: error,
});

export const orderCancelledChanged = () => ({
	type: endOfDayTypes.ORDERS_CANCELLED_CHANGED,
});

export const orderCancelledReset = () => ({
	type: endOfDayTypes.ORDERS_CANCELLED_RESET,
});
/* ORDERS CANCELLED END */

/* STAFF ASSIGNED START */
export const staffAssignedStart = (staffAssigned) => ({
	type: endOfDayTypes.STAFF_ASSIGNED_START,
	payload: staffAssigned,
});

export const staffAssignedSuccess = (fetchEndOfDaySuccess) => ({
	type: endOfDayTypes.STAFF_ASSIGNED_SUCCESS,
	payload: fetchEndOfDaySuccess,
});

export const staffAssignedFailure = (error) => ({
	type: endOfDayTypes.STAFF_ASSIGNED_FAILURE,
	payload: error,
});

export const staffAssignedChanged = () => ({
	type: endOfDayTypes.STAFF_ASSIGNED_CHANGED,
});

export const staffAssignedReset = () => ({
	type: endOfDayTypes.STAFF_ASSIGNED_RESET,
});
/* STAFF ASSIGNED END */

/* REVERT ASSIGNED ORDER START */
export const revertAssignedOrderStart = (revertAssignedOrder) => ({
	type: endOfDayTypes.REVERT_ASSIGNED_ORDER_START,
	payload: revertAssignedOrder,
});

export const revertAssignedOrderSuccess = (viewOrderSuccess) => ({
	type: endOfDayTypes.REVERT_ASSIGNED_ORDER_SUCCESS,
	payload: viewOrderSuccess,
});

export const revertAssignedOrderFailure = (error) => ({
	type: endOfDayTypes.REVERT_ASSIGNED_ORDER_FAILURE,
	payload: error,
});

export const revertAssignedOrderChanged = () => ({
	type: endOfDayTypes.REVERT_ASSIGNED_ORDER_CHANGED,
});

export const revertAssignedOrderReset = () => ({
	type: endOfDayTypes.REVERT_ASSIGNED_ORDER_RESET,
});
/* REVERT ASSIGNED ORDER END */

/* GET CANCELLED ORDER LIST START */
export const viewCancelledOrderListStart = (viewCancelledOrder) => ({
	type: endOfDayTypes.GET_CANCELLED_ORDER_LIST_START,
	payload: viewCancelledOrder,
});

export const viewCancelledOrderListSuccess = (viewOrderSuccess) => ({
	type: endOfDayTypes.GET_CANCELLED_ORDER_LIST_SUCCESS,
	payload: viewOrderSuccess,
});

export const viewCancelledOrderListFailure = (error) => ({
	type: endOfDayTypes.GET_CANCELLED_ORDER_LIST_FAILURE,
	payload: error,
});

export const viewCancelledOrderListChanged = () => ({
	type: endOfDayTypes.GET_CANCELLED_ORDER_LIST_CHANGED,
});

export const viewCancelledOrderListReset = () => ({
	type: endOfDayTypes.GET_CANCELLED_ORDER_LIST_RESET,
});
/* GET CANCELLED ORDER LIST START */

/* GET ORDER PINCODE LIST START */
export const getOrderPinCodeListStart = (getOrderPinCode) => ({
	type: endOfDayTypes.GET_ORDER_PINCODE_LIST_START,
	payload: getOrderPinCode,
});

export const getOrderPinCodeListSuccess = (viewOrderSuccess) => ({
	type: endOfDayTypes.GET_ORDER_PINCODE_LIST_SUCCESS,
	payload: viewOrderSuccess,
});

export const getOrderPinCodeListFailure = (error) => ({
	type: endOfDayTypes.GET_ORDER_PINCODE_LIST_FAILURE,
	payload: error,
});

export const getOrderPinCodeListChanged = () => ({
	type: endOfDayTypes.GET_ORDER_PINCODE_LIST_CHANGED,
});

export const getOrderPinCodeListReset = () => ({
	type: endOfDayTypes.GET_ORDER_PINCODE_LIST_RESET,
});
/* GET ORDER PINCODE LIST START */

/* GET EOD STAFF LIST START */
export const getEodStaffListStart = (getEodStaff) => ({
	type: endOfDayTypes.GET_EOD_STAFF_LIST_START,
	payload: getEodStaff,
});

export const getEodStaffListSuccess = (viewOrderSuccess) => ({
	type: endOfDayTypes.GET_EOD_STAFF_LIST_SUCCESS,
	payload: viewOrderSuccess,
});

export const getEodStaffListFailure = (error) => ({
	type: endOfDayTypes.GET_EOD_STAFF_LIST_FAILURE,
	payload: error,
});

export const getEodStaffListChanged = () => ({
	type: endOfDayTypes.GET_EOD_STAFF_LIST_CHANGED,
});

export const getEodStaffListReset = () => ({
	type: endOfDayTypes.GET_EOD_STAFF_LIST_RESET,
});
/* GET EOD STAFF LIST START */
