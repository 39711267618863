/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getFlowerOrderList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		FromDate: postListValue.FromDate,
		ToDate: postListValue.ToDate,
		OrderStatus: postListValue.activeTab,
		SearchValueSet: postListValue.searchValueSet,
		SelectedEmptyDate: postListValue.selectedEmptyDate,
	};
	const url = `${API_URL}GetFlowerOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const viewFlowerOrderList = (postListValue) => {
	const data = {
		UserID: postListValue.UserID,
		OrderMode: postListValue.OrderMode,
		OrderFromCustomerID: postListValue.OrderFromCustomerID,
		FromDate: postListValue.FromDate,
		ToDate: postListValue.ToDate,
		OrderStatus: postListValue.activeTab,
		SearchValueSet: postListValue.searchValueSet,
		SelectedEmptyDate: postListValue.selectedEmptyDate,
	};
	const url = `${API_URL}GetViewFlowerOrderList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const viewFlowerTransactionsList = (ViewFlowerTransactionsData) => {
	const data = {
		ViewFlowerTransactionsToken: ViewFlowerTransactionsData,
	};
	const url = `${API_URL}GetViewFlowerTransactionsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
