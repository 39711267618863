/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import vendorTypes from './vendor.types';

const INITIAL_STATE = {
	vendorListResponse: null,
	vendorListStatus: null,
	vendorCreateResponse: null,
	vendorCreateStatus: null,
	vendorUpdateResponse: null,
	vendorUpdateStatus: null,
	vendorDeleteResponse: null,
	vendorDeleteStatus: null,
	vendorCityListResponse: null,
	vendorCityListStatus: null,
	vendorStateListResponse: null,
	vendorStateListStatus: null,
};

const vendorListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case vendorTypes.VENDOR_LIST_SUCCESS:
			return {
				...state,
				vendorListResponse: action.payload,
				vendorListStatus: 'Success',
			};

		case vendorTypes.VENDOR_LIST_FAILURE:
			return {
				...state,
				vendorListResponse: action.payload,
				vendorListStatus: 'Failure',
			};

		case vendorTypes.VENDOR_LIST_CHANGED:
			return { ...state, vendorListResponse: null, vendorListStatus: null };

		case vendorTypes.VENDOR_CREATE_SUCCESS:
			return {
				...state,
				vendorCreateResponse: action.payload,
				vendorCreateStatus: 'Success',
			};
		case vendorTypes.VENDOR_CREATE_FAILURE:
			return {
				...state,
				vendorCreateResponse: action.payload,
				vendorCreateStatus: 'Failure',
			};
		case vendorTypes.VENDOR_CREATE_CHANGED:
			return { ...state, vendorCreateResponse: null, vendorCreateStatus: null };

		case vendorTypes.VENDOR_UPDATE_SUCCESS:
			return {
				...state,
				vendorUpdateResponse: action.payload,
				vendorUpdateStatus: 'Success',
			};
		case vendorTypes.VENDOR_UPDATE_FAILURE:
			return {
				...state,
				vendorUpdateResponse: action.payload,
				vendorUpdateStatus: 'Failure',
			};
		case vendorTypes.VENDOR_UPDATE_CHANGED:
			return { ...state, vendorUpdateResponse: null, vendorUpdateStatus: null };

		case vendorTypes.VENDOR_DELETE_SUCCESS:
			return {
				...state,
				vendorDeleteResponse: action.payload,
				vendorDeleteStatus: 'Success',
			};
		case vendorTypes.VENDOR_DELETE_FAILURE:
			return {
				...state,
				vendorDeleteResponse: action.payload,
				vendorDeleteStatus: 'Failure',
			};
		case vendorTypes.VENDOR_DELETE_CHANGED:
			return { ...state, vendorDeleteResponse: null, vendorDeleteStatus: null };

		case vendorTypes.VENDOR_STATE_LIST_SUCCESS:
			return {
				...state,
				vendorStateListResponse: action.payload,
				vendorStateListStatus: 'Success',
			};

		case vendorTypes.VENDOR_STATE_LIST_FAILURE:
			return {
				...state,
				vendorStateListResponse: action.payload,
				vendorStateListStatus: 'Failure',
			};

		case vendorTypes.VENDOR_STATE_LIST_CHANGED:
			return { ...state, vendorStateListResponse: null, vendorStateListStatus: null };

		case vendorTypes.VENDOR_CITY_LIST_SUCCESS:
			return {
				...state,
				vendorCityListResponse: action.payload,
				vendorCityListStatus: 'Success',
			};

		case vendorTypes.VENDOR_CITY_LIST_FAILURE:
			return {
				...state,
				vendorCityListResponse: action.payload,
				vendorCityListStatus: 'Failure',
			};

		case vendorTypes.VENDOR_CITY_LIST_CHANGED:
			return { ...state, vendorCityListResponse: null, vendorCityListStatus: null };

		default:
			return state;
	}
};

export default vendorListReducer;
