/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import dashBoardTypes from './DashBoard.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	dashBoardListArr: [],
	dashBoardListResponse: null,
	dashBoardViewListArr: [],
	dashBoardViewListResponse: null,
	dashBoardCustomerListArr: [],
	dashBoardCustomerListResponse: null,
	fetchOrderSalesDetailsResponse: null,
	fetchOrderSalesDetailsArr: [],
	totalOnlineCreatedListResponse: null,
	totalOnlineCreatedListArr: [],
	updateTransactionCheckDahboardResponse: null,
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case dashBoardTypes.GET_DASHBOARD_LIST_SUCCESS:
			return {
				...state,
				dashBoardListArr: docodeResponse(action.payload, 'DashboardListKey'),
				dashBoardListResponse: action.payload,
			};

		case dashBoardTypes.GET_DASHBOARD_LIST_FAILURE:
			return { ...state, dashBoardListArr: [], dashBoardListResponse: action.payload };

		case dashBoardTypes.GET_DASHBOARD_LIST_CHANGED:
			return { ...state, dashBoardListResponse: null };

		case dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_SUCCESS:
			return {
				...state,
				dashBoardViewListArr: docodeResponse(action.payload, 'DashboardViewListKey'),
				dashBoardViewListResponse: action.payload,
			};

		case dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_FAILURE:
			return {
				...state,
				dashBoardViewListArr: [],
				dashBoardViewListResponse: action.payload,
			};

		case dashBoardTypes.VIEW_DASHBOARD_ORDER_LIST_CHANGED:
			return { ...state, dashBoardViewListResponse: null };

		case dashBoardTypes.DASHBOARD_CUSTOMER_LIST_SUCCESS:
			return {
				...state,
				dashBoardCustomerListArr: docodeResponse(
					action.payload,
					'DashboardCustomerListKey',
				),
				dashBoardCustomerListResponse: action.payload,
			};

		case dashBoardTypes.DASHBOARD_CUSTOMER_LIST_FAILURE:
			return {
				...state,
				dashBoardCustomerListArr: [],
				dashBoardCustomerListResponse: action.payload,
			};

		case dashBoardTypes.DASHBOARD_CUSTOMER_LIST_CHANGED:
			return { ...state, dashBoardCustomerListResponse: null };

		case dashBoardTypes.FETCH_ORDER_SALES_DETAILS_SUCCESS:
			return {
				...state,
				fetchOrderSalesDetailsResponse: action.payload,
				fetchOrderSalesDetailsArr: docodeResponse(
					action.payload,
					'FetchOrderSalesDetailsKey',
				),
			};

		case dashBoardTypes.FETCH_ORDER_SALES_DETAILS_FAILURE:
			return { ...state, fetchOrderSalesDetailsResponse: action.payload };

		case dashBoardTypes.FETCH_ORDER_SALES_DETAILS_RESPONSE_CHANGED:
			return { ...state, fetchOrderSalesDetailsResponse: null };

		case dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_SUCCESS:
			return {
				...state,
				totalOnlineCreatedListResponse: action.payload,
				totalOnlineCreatedListArr: docodeResponse(
					action.payload,
					'GetTotalOnlineCreatedListKey',
				),
			};

		case dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_FAILURE:
			return { ...state, totalOnlineCreatedListResponse: action.payload };

		case dashBoardTypes.TOTAL_ONLINE_CREATED_LIST_RESPONSE_CHANGED:
			return { ...state, totalOnlineCreatedListResponse: null };

		case dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_SUCCESS:
			return {
				...state,
				updateTransactionCheckDahboardResponse: action.payload,
			};

		case dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_FAILURE:
			return { ...state, updateTransactionCheckDahboardResponse: action.payload };

		case dashBoardTypes.UPDATE_TRANSACTION_STATUS_DASBOARD_RESPONSE_CHANGED:
			return { ...state, updateTransactionCheckDahboardResponse: null };

		default:
			return state;
	}
};

export default dashboardReducer;
