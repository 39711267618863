/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import festivalTypes from './festival.types';
import {
	getListOfFestival,
	addFestivalComboList,
	editFestivalComboList,
	removeFestivalComboList,
	getProductsAddList,
	addComboProduct,
	listOfComboProductGet,
	deleteComboProduct,
} from './festival.service';
import {
	festivalListSuccess,
	festivalListFailure,
	festivalListChanged,
	insertFestivalComboSuccess,
	insertFestivalComboFailure,
	insertFestivalComboChanged,
	editFestivalComboSuccess,
	editFestivalComboFailure,
	editFestivalComboChanged,
	removeFestivalComboSuccess,
	removeFestivalComboFailure,
	removeFestivalComboChanged,
	productListSuccess,
	productListFailure,
	productListChanged,
	createComboProductSuccess,
	createComboProductFailure,
	createComboProductChanged,
	listComboProductSuccess,
	listComboProductFailure,
	listComboProductChanged,
	deleteComboSuccess,
	deleteComboFailure,
	deleteComboChanged,
} from './festival.action';

/* FESTIVAL LIST START */
export function* listOfFestival({ payload: { festivalListDetails } }) {
	try {
		const responseData = yield call(getListOfFestival, festivalListDetails);
		if (responseData.data.statusCode === '100') {
			yield put(festivalListFailure(responseData.data));
		} else {
			yield put(festivalListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(festivalListFailure(error));
	}
}

export function* listOfFestivalReset() {
	yield put(festivalListChanged());
}

export function* getFestivalListReset() {
	yield takeLatest(festivalTypes.FESTIVAL_LIST_RESET, listOfFestivalReset);
}

export function* getFestivalList() {
	yield takeLatest(festivalTypes.FESTIVAL_LIST_START, listOfFestival);
}
/* FESTIVAL LIST END */

/* INSERT FESTIVAL COMBO START */
export function* insertComboList({ payload: { values } }) {
	try {
		const jsonData = {
			FestivalName: values.FestivalName,
			FestivalOn: values.FestivalOn,
			FromDate: values.FromDate,
			ToDate: values.ToDate,
		};
		const responseData = yield call(addFestivalComboList, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(insertFestivalComboFailure(responseData.data));
		} else {
			yield put(insertFestivalComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(insertFestivalComboFailure(error));
	}
}

export function* insertComboListReset() {
	yield put(insertFestivalComboChanged());
}

export function* insertFestivalCombo() {
	yield takeLatest(festivalTypes.INSERT_FESTIVAL_LIST_START, insertComboList);
}

export function* insertFestivalComboReset() {
	yield takeLatest(festivalTypes.INSERT_FESTIVAL_LIST_RESET, insertComboListReset);
}
/* INSERT FESTIVAL COMBO END */

/* UPDATE FESTIVAL COMBO START */
export function* updateComboReset({ payload: { values } }) {
	try {
		const jsonData = {
			FestivalName: values.FestivalName,
			FestivalOn: values.FestivalOn,
			FromDate: values.FromDate,
			ToDate: values.ToDate,
		};
		const responseData = yield call(editFestivalComboList, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(editFestivalComboFailure(responseData.data));
		} else {
			yield put(editFestivalComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(editFestivalComboFailure(error));
	}
}

export function* updateComboList() {
	yield put(editFestivalComboChanged());
}

export function* updateFestivalResponseReset() {
	yield takeLatest(festivalTypes.UPDATE_FESTIVAL_LIST_RESET, updateComboList);
}

export function* updateFestivalResponseStart() {
	yield takeLatest(festivalTypes.UPDATE_FESTIVAL_LIST_START, updateComboReset);
}
/* UPDATE FESTIVAL COMBO END */

/* DELETE FESTIVAL COMBO START */
export function* comboRemove({ payload: { removeComboID } }) {
	try {
		const jsonData = {
			ComboID: removeComboID,
		};
		const responseData = yield call(removeFestivalComboList, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(removeFestivalComboSuccess(responseData.data));
		} else {
			yield put(removeFestivalComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(removeFestivalComboFailure(error));
	}
}

export function* comboRemoveResponse() {
	yield put(removeFestivalComboChanged());
}

export function* festivalComboRemoveReset() {
	yield takeLatest(festivalTypes.DELETE_FESTIVAL_LIST_RESET, comboRemoveResponse);
}

export function* festivalComboRemoveStart() {
	yield takeLatest(festivalTypes.DELETE_FESTIVAL_LIST_START, comboRemove);
}
/* DELETE FESTIVAL COMBO END */

/* PRODUCT LIST START */
export function* productListAdd({ payload: { festivalComboProductList } }) {
	try {
		const responseData = yield call(getProductsAddList, festivalComboProductList);
		if (responseData.data.statusCode === '100') {
			yield put(productListFailure(responseData.data));
		} else {
			yield put(productListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(productListFailure(error));
	}
}

export function* productListReset() {
	yield put(productListChanged());
}

export function* productListAddResponseReset() {
	yield takeLatest(festivalTypes.PRODUCT_LIST_RESET, productListReset);
}

export function* productListAddStart() {
	yield takeLatest(festivalTypes.PRODUCT_LIST_START, productListAdd);
}
/* PRODUCT LIST END */

/* CREATE PRODUCT START */
export function* comboCreateProduct({ payload: { comboProductCredentials } }) {
	try {
		const jsonData = JSON.stringify(comboProductCredentials).replace(/"/g, "'");
		const responseData = yield call(addComboProduct, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(createComboProductFailure(responseData.data));
		} else {
			yield put(createComboProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createComboProductFailure(error));
	}
}

export function* comboCreateProductReset() {
	yield put(createComboProductChanged());
}

export function* productComboResponseReset() {
	yield takeLatest(festivalTypes.CREATE_COMBO_PRODUCT_RESET, comboCreateProductReset);
}

export function* productComboCreateStart() {
	yield takeLatest(festivalTypes.CREATE_COMBO_PRODUCT_START, comboCreateProduct);
}
/* CREATE PRODUCT END */

/* PRODUCTS LIST START */
export function* comboProductGetList({ payload: { id } }) {
	try {
		const responseData = yield call(listOfComboProductGet, id);
		if (responseData.data.statusCode === '100') {
			yield put(listComboProductFailure(responseData.data));
		} else {
			yield put(listComboProductSuccess(responseData.data));
		}
	} catch (error) {
		yield put(listComboProductFailure(error));
	}
}

export function* comboProductGetListReset() {
	yield put(listComboProductChanged());
}

export function* productListComboReset() {
	yield takeLatest(festivalTypes.COMBO_PRODUCT_LIST_RESET, comboProductGetListReset);
}

export function* productListComboStart() {
	yield takeLatest(festivalTypes.COMBO_PRODUCT_LIST_START, comboProductGetList);
}
/* PRODUCTS LIST END */

/* DELETE COMBO PRODUCT START */
export function* comboProductRemove({ payload: { ProductsValue } }) {
	try {
		const responseData = yield call(deleteComboProduct, ProductsValue);
		if (responseData.data.statusCode === '100') {
			yield put(deleteComboFailure(responseData.data));
		} else {
			yield put(deleteComboSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteComboFailure(error));
	}
}

export function* comboProductRemoveResponse() {
	yield put(deleteComboChanged());
}

export function* removeComboProductResponseReset() {
	yield takeLatest(festivalTypes.DELETE_COMBO_RESET, comboProductRemoveResponse);
}

export function* removeComboProductStart() {
	yield takeLatest(festivalTypes.DELETE_COMBO_START, comboProductRemove);
}
/* DELETE COMBO PRODUCT END */

export function* festivalListSaga() {
	yield all([
		call(getFestivalList),
		call(getFestivalListReset),
		call(insertFestivalCombo),
		call(insertFestivalComboReset),
		call(updateFestivalResponseStart),
		call(updateFestivalResponseReset),
		call(festivalComboRemoveStart),
		call(festivalComboRemoveReset),
		call(productListAddStart),
		call(productListAddResponseReset),
		call(productComboCreateStart),
		call(productComboResponseReset),
		call(productListComboStart),
		call(productListComboReset),
		call(removeComboProductStart),
		call(removeComboProductResponseReset),
	]);
}
