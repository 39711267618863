// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable default-param-last */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
import orderReportType from './orderReport.types';

import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	orederReportListRes: null,
	orederReportListArr: [],
	orederReportViewRes: null,
	orederReportViewArr: [],
};

const orderReportReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case orderReportType.ORDER_REPORT_LIST_SUCCESS:
			return {
				...state,
				orederReportListRes: action.payload,
				orederReportListArr: docodeResponse(action.payload, 'Get_OrderReport_List'),
			};

		case orderReportType.ORDER_REPORT_LIST_FAILURE:
			return {
				...state,
				orederReportListRes: action.payload,
				orederReportListArr: [],
			};

		case orderReportType.ORDER_REPORT_LIST_CHANGED:
			return { ...state, orederReportListRes: null };

		/** *** ORDER REPORT LIST ***** */

		case orderReportType.ORDER_REPORT_VIEW_SUCCESS:
			return {
				...state,
				orederReportViewRes: action.payload,
				orederReportViewArr: docodeResponse(action.payload, 'OrderDetailsViewKey'),
			};

		case orderReportType.ORDER_REPORT_VIEW_FAILURE:
			return {
				...state,
				orederReportViewRes: action.payload,
				orederReportViewArr: [],
			};

		case orderReportType.ORDER_REPORT_VIEW_CHANGED:
			return { ...state, orederReportViewRes: null };

		/** *** ORDER REPORT VIEW ***** */

		default:
			return state;
	}
};

export default orderReportReducer;
