/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getListOfFestival = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${API_URL}GetFestivalList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addFestivalComboList = (postListValue) => {
	const data = {
		FestivalName: postListValue.FestivalName,
		FestivalOn: postListValue.FestivalOn,
		FromDate: postListValue.FromDate,
		ToDate: postListValue.ToDate,
	};
	const url = `${API_URL}CreateFestivalComboList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const editFestivalComboList = (postListValue) => {
	const data = {
		FestivalName: postListValue.FestivalName,
		FestivalOn: postListValue.FestivalOn,
		FromDate: postListValue.FromDate,
		ToDate: postListValue.ToDate,
	};
	const url = `${API_URL}UpdateFestivalComboList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const removeFestivalComboList = (postListValue) => {
	const data = {
		ComboID: postListValue.ComboID,
	};
	const url = `${API_URL}DeleteFestivalComboList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getProductsAddList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
	};
	const url = `${API_URL}GetProductsAddList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addComboProduct = (postListValue) => {
	const data = {
		ProductUpdate: postListValue,
	};
	const url = `${API_URL}InsertFestivalCombo`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const listOfComboProductGet = (id) => {
	const data = {
		FestivalID: id,
	};
	const url = `${API_URL}GetComboList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteComboProduct = (postListValue) => {
	const data = {
		ComboID: postListValue.ComboID,
	};
	const url = `${API_URL}DeleteComboProduct`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
