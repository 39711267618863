/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getStaffList = (fetchData) => {
	const data = {
		GetAllStaffListToken: fetchData,
	};
	const url = `${API_URL}GetAllStaffList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createStaff = (fetchData) => {
	const data = {
		CreateStaffToken: fetchData,
	};
	const url = `${API_URL}CreateStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateStaff = (fetchData) => {
	const data = {
		UpdateStaffToken: fetchData,
	};
	const url = `${API_URL}UpdateStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteStaff = (fetchData) => {
	const data = {
		RemoveStaffToken: fetchData,
	};
	const url = `${API_URL}DeleteStaffDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
