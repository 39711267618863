const vendorTypes = {
	VENDOR_LIST_START: 'VENDOR_LIST_START',
	VENDOR_LIST_SUCCESS: 'VENDOR_LIST_SUCCESS',
	VENDOR_LIST_FAILURE: 'VENDOR_LIST_FAILURE',
	VENDOR_LIST_RESET: 'VENDOR_LIST_RESET',
	VENDOR_LIST_CHANGED: 'VENDOR_LIST_CHANGED',

	VENDOR_CREATE_START: 'VENDOR_CREATE_START',
	VENDOR_CREATE_SUCCESS: 'VENDOR_CREATE_SUCCESS',
	VENDOR_CREATE_FAILURE: 'VENDOR_CREATE_FAILURE',
	VENDOR_CREATE_CHANGED: 'VENDOR_CREATE_CHANGED',
	VENDOR_CREATE_RESET: 'VENDOR_CREATE_RESET',

	VENDOR_UPDATE_START: 'VENDOR_UPDATE_START',
	VENDOR_UPDATE_SUCCESS: 'VENDOR_UPDATE_SUCCESS',
	VENDOR_UPDATE_FAILURE: 'VENDOR_UPDATE_FAILURE',
	VENDOR_UPDATE_CHANGED: 'VENDOR_UPDATE_CHANGED',
	VENDOR_UPDATE_RESET: 'VENDOR_UPDATE_RESET',

	VENDOR_DELETE_START: 'VENDOR_DELETE_START',
	VENDOR_DELETE_SUCCESS: 'VENDOR_DELETE_SUCCESS',
	VENDOR_DELETE_FAILURE: 'VENDOR_DELETE_FAILURE',
	VENDOR_DELETE_CHANGED: 'VENDOR_DELETE_CHANGED',
	VENDOR_DELETE_RESET: 'VENDOR_DELETE_RESET',

	VENDOR_STATE_LIST_START: 'VENDOR_STATE_LIST_START',
	VENDOR_STATE_LIST_SUCCESS: 'VENDOR_STATE_LIST_SUCCESS',
	VENDOR_STATE_LIST_FAILURE: 'VENDOR_STATE_LIST_FAILURE',
	VENDOR_STATE_LIST_RESET: 'VENDOR_STATE_LIST_RESET',
	VENDOR_STATE_LIST_CHANGED: 'VENDOR_STATE_LIST_CHANGED',

	VENDOR_CITY_LIST_START: 'VENDOR_CITY_LIST_START',
	VENDOR_CITY_LIST_SUCCESS: 'VENDOR_CITY_LIST_SUCCESS',
	VENDOR_CITY_LIST_FAILURE: 'VENDOR_CITY_LIST_FAILURE',
	VENDOR_CITY_LIST_RESET: 'VENDOR_CITY_LIST_RESET',
	VENDOR_CITY_LIST_CHANGED: 'VENDOR_CITY_LIST_CHANGED',
};
export default vendorTypes;
