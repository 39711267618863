// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-shadow */
import placeOrderType from './placeOrder.types';

/* PRODUCT LIST START */
export const listProductStart = (productDetails) => ({
	type: placeOrderType.LIST_PRODUCT_START,
	payload: productDetails,
});

export const listProductSuccess = (productSuccess) => ({
	type: placeOrderType.LIST_PRODUCT_SUCCESS,
	payload: productSuccess,
});

export const listProductFailure = (error) => ({
	type: placeOrderType.LIST_PRODUCT_FAILURE,
	payload: error,
});

export const listProductReset = () => ({
	type: placeOrderType.LIST_PRODUCT_RESET,
});

export const listProductChanged = () => ({
	type: placeOrderType.LIST_PRODUCT_CHANGED,
});

/*  PRODUCT LIST END */

/* ALL PRODUCT LIST START */
export const allProductListStart = (productList) => ({
	type: placeOrderType.ALL_PRODUCT_LIST_START,
	payload: productList,
});

export const allProductListSuccess = (allListProductSuccess) => ({
	type: placeOrderType.ALL_PRODUCT_LIST_SUCCESS,
	payload: allListProductSuccess,
});

export const allProductListFailure = (error) => ({
	type: placeOrderType.ALL_PRODUCT_LIST_FAILURE,
	payload: error,
});

export const allProductListReset = () => ({
	type: placeOrderType.ALL_PRODUCT_LIST_RESET,
});

export const allProductListChanged = () => ({
	type: placeOrderType.ALL_PRODUCT_LIST_CHANGED,
});

/* ALL PRODUCT LIST END */

/* USER LOGIN CHECK START */
export const userLoginCheckStart = (productDetails) => ({
	type: placeOrderType.USER_LOGIN_CHECK_START,
	payload: productDetails,
});

export const userLoginCheckSuccess = (userLoginSuccess) => ({
	type: placeOrderType.USER_LOGIN_CHECK_SUCCESS,
	payload: userLoginSuccess,
});

export const userLoginCheckFailure = (error) => ({
	type: placeOrderType.USER_LOGIN_CHECK_FAILURE,
	payload: error,
});

export const userLoginCheckReset = () => ({
	type: placeOrderType.USER_LOGIN_CHECK_RESET,
});

export const userLoginCheckChanged = () => ({
	type: placeOrderType.USER_LOGIN_CHECK_CHANGED,
});

/* USER LOGIN CHECK END */

/* OTP VERIFIED START */
export const otpverifyStart = (productDetails) => ({
	type: placeOrderType.OTP_VERIFIY_START,
	payload: productDetails,
});

export const otpverifySuccess = (otpVerifySuccess) => ({
	type: placeOrderType.OTP_VERIFIY_SUCCESS,
	payload: otpVerifySuccess,
});

export const otpverifyFailure = (error) => ({
	type: placeOrderType.OTP_VERIFIY_FAILURE,
	payload: error,
});

export const otpverifyReset = () => ({
	type: placeOrderType.OTP_VERIFIY_RESET,
});

export const otpverifyChanged = () => ({
	type: placeOrderType.OTP_VERIFIY_CHANGED,
});

/* OTP VERIFIED END */

/* ADD ADDRESS START */
export const addAddressStart = (addressDetails) => ({
	type: placeOrderType.ADD_ADDRESS_START,
	payload: addressDetails,
});

export const addAddressSuccess = (addressSuccess) => ({
	type: placeOrderType.ADD_ADDRESS_SUCCESS,
	payload: addressSuccess,
});

export const addAddressFailure = (error) => ({
	type: placeOrderType.ADD_ADDRESS_FAILURE,
	payload: error,
});

export const addAddressReset = () => ({
	type: placeOrderType.ADD_ADDRESS_RESET,
});

export const addAddressChanged = () => ({
	type: placeOrderType.ADD_ADDRESS_CHANGED,
});

/* ADD ADDRESS END */

/* PLACE ORDER START */
export const placeOrderStart = (placeOrder) => ({
	type: placeOrderType.PLACE_ORDER_START,
	payload: placeOrder,
});

export const placeOrderSuccess = (placeOrderSuccess) => ({
	type: placeOrderType.PLACE_ORDER_SUCCESS,
	payload: placeOrderSuccess,
});

export const placeOrderFailure = (error) => ({
	type: placeOrderType.PLACE_ORDER_FAILURE,
	payload: error,
});

export const placeOrderReset = () => ({
	type: placeOrderType.PLACE_ORDER_RESET,
});

export const placeOrderChanged = () => ({
	type: placeOrderType.PLACE_ORDER_CHANGED,
});

/* PLACE ORDER END */

/* QUANTITY UPDATE START */

export const categoryProductListUpdate = (categoryCartItems) => ({
	type: placeOrderType.UPDATE_CATEGORY_PRODUCT_LIST,
	payload: categoryCartItems,
});

/* QUANTITY CART UPDATE START */

export const productCategoryItemAddStart = (item) => ({
	type: placeOrderType.ADD_PRODUSCT_CATEGORY_CART_ITEM,
	payload: item,
});

/* QUANTITY UPDATE START */

export const productCategoryItemRemoveStart = (item) => ({
	type: placeOrderType.REMOVE_PRODUSCT_CATEGORY_CART_ITEM,
	payload: item,
});

export const updateProductListCategory = (item) => ({
	type: placeOrderType.UPDATE_PRODUCT_LIST_CATEGORY,
	payload: item,
});

export const updateorderLocationAddress = (locationAddress) => ({
	type: placeOrderType.ORDER_LOCATION_ADDRESS_UPDATE,
	payload: locationAddress,
});

// ALL BRANCH PINCODE LIST
export const allBranchPincodeStart = (pincode) => ({
	type: placeOrderType.GET_ALL_PINCODE_LIST_START,
	payload: pincode,
});

export const allBranchPincodeSuccess = (pincodeSuccess) => ({
	type: placeOrderType.GET_ALL_PINCODE_LIST_SUCCESS,
	payload: pincodeSuccess,
});

export const allBranchPincodeFailure = (error) => ({
	type: placeOrderType.GET_ALL_PINCODE_LIST_FAILURE,
	payload: error,
});

export const allBranchPincodeRestStart = () => ({
	type: placeOrderType.GET_ALL_PINCODE_LIST_RESET_START,
});

export const allBranchPincodeChanged = () => ({
	type: placeOrderType.GET_ALL_PINCODE_LIST_CHANGED,
});
