/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/namespace */
/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import endOfDayNewTypes from './EndOfDayNew.types';
import { addOrderItemNew } from './EndOfDayNew.utils';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	eodOrderListArr: [],
	eodOrderListResp: null,

	viewEodOrderListArr: [],
	viewEodOrderListResp: null,

	eodFetchListArr: [],
	eodFetchListResp: null,

	addEodNewResp: null,

	getEodStaffListNewArr: [],
	getEodStaffListNewResponse: null,

	staffAssginedNewResponse: null,
	revertAssignedOrderNewResponse: null,

	orderDateChangeResponse: null,
};

const endOfDayNewReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_SUCCESS:
			return {
				...state,
				eodOrderListResp: action.payload,
				eodOrderListArr: docodeResponse(action.payload, 'GetEodNewOrderListKey'),
			};

		case endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_FAILURE:
			return {
				...state,
				eodOrderListResp: action.payload,
				eodOrderListArr: [],
			};

		case endOfDayNewTypes.GET_EOD_ORDER_LIST_NEW_CHANGED:
			return { ...state, eodOrderListResp: null };

		case endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_SUCCESS:
			return {
				...state,
				viewEodOrderListResp: action.payload,
				viewEodOrderListArr: docodeResponse(action.payload, 'OrderDetailsViewKey'),
			};

		case endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_FAILURE:
			return {
				...state,
				viewEodOrderListResp: action.payload,
				viewEodOrderListArr: [],
			};

		case endOfDayNewTypes.VIEW_EOD_ORDER_LIST_NEW_CHANGED:
			return { ...state, viewEodOrderListResp: null };

		case endOfDayNewTypes.EOD_FETCH_LIST_SUCCESS:
			return {
				...state,
				eodFetchListResp: action.payload,
				eodFetchListArr: docodeResponse(action.payload, 'EodNewFetchListKey'),
			};

		case endOfDayNewTypes.EOD_FETCH_LIST_FAILURE:
			return {
				...state,
				eodFetchListResp: action.payload,
				eodFetchListArr: [],
			};

		case endOfDayNewTypes.EOD_FETCH_LIST_CHANGED:
			return { ...state, eodFetchListResp: null };

		case endOfDayNewTypes.ADD_EOD_NEW_SUCCESS:
			return {
				...state,
				addEodNewResp: action.payload,
			};

		case endOfDayNewTypes.ADD_EOD_NEW_FAILURE:
			return {
				...state,
				addEodNewResp: action.payload,
			};

		case endOfDayNewTypes.ADD_EOD_NEW_CHANGED:
			return { ...state, addEodNewResp: null };

		case endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_SUCCESS:
			return {
				...state,
				getEodStaffListNewResponse: action.payload,
				getEodStaffListNewArr: docodeResponse(action.payload, 'EodStaffListNewGetKey'),
			};

		case endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_FAILURE:
			return { ...state, getEodStaffListNewResponse: action.payload };

		case endOfDayNewTypes.GET_EOD_STAFF_LIST_NEW_CHANGED:
			return { ...state, getEodStaffListNewResponse: null };

		case endOfDayNewTypes.ADD_ORDER_ITEM_NEW:
			return {
				...state,
				eodOrderListArr: addOrderItemNew(state.eodOrderListArr, action.payload),
			};

		case endOfDayNewTypes.STAFF_ASSIGNED_NEW_SUCCESS:
			return { ...state, staffAssginedNewResponse: action.payload };

		case endOfDayNewTypes.STAFF_ASSIGNED_NEW_FAILURE:
			return { ...state, staffAssginedNewResponse: null };

		case endOfDayNewTypes.STAFF_ASSIGNED_NEW_CHANGED:
			return { ...state, staffAssginedNewResponse: null };

		case endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_SUCCESS:
			return { ...state, revertAssignedOrderNewResponse: action.payload };

		case endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_FAILURE:
			return { ...state, revertAssignedOrderNewResponse: null };

		case endOfDayNewTypes.REVERT_ASSIGNED_ORDER_NEW_CHANGED:
			return { ...state, revertAssignedOrderNewResponse: null };

		case endOfDayNewTypes.ORDER_DATE_CHANGE_SUCCESS:
			return { ...state, orderDateChangeResponse: action.payload };

		case endOfDayNewTypes.ORDER_DATE_CHANGE_FAILURE:
			return { ...state, orderDateChangeResponse: null };

		case endOfDayNewTypes.ORDER_DATE_CHANGE_CHANGED:
			return { ...state, orderDateChangeResponse: null };

		default:
			return state;
	}
};

export default endOfDayNewReducer;
