/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { INV_API_URL } from '../const';

export const getVendorList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${INV_API_URL}GetAllVendorList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createVendor = (postListValue) => {
	const data = {
		VendorName: postListValue.VendorName,
		VendorAddress: postListValue.VendorAddress,
		VendorPhoneNumber: postListValue.VendorPhoneNumber,
		VendorEmailID: postListValue.VendorEmailID,
		ContactPersonOneName: postListValue.ContactPersonOneName,
		ContactPersonOnePhoneNumber: postListValue.ContactPersonOnePhoneNumber,
		ContactPersonTwoName: postListValue.ContactPersonTwoName,
		ContactPersonTwoPhoneNumber: postListValue.ContactPersonTwoPhoneNumber,
		CityID: postListValue.CityID,
		StateID: postListValue.StateID,
	};
	const url = `${INV_API_URL}CreateVendorDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateVendor = (postListValue) => {
	const data = {
		VendorID: postListValue.VendorID,
		VendorName: postListValue.VendorName,
		VendorAddress: postListValue.VendorAddress,
		VendorPhoneNumber: postListValue.VendorPhoneNumber,
		VendorEmailID: postListValue.VendorEmailID,
		ContactPersonOneName: postListValue.ContactPersonOneName,
		ContactPersonOnePhoneNumber: postListValue.ContactPersonOnePhoneNumber,
		ContactPersonTwoName: postListValue.ContactPersonTwoName,
		ContactPersonTwoPhoneNumber: postListValue.ContactPersonTwoPhoneNumber,
		CityID: postListValue.CityID,
		StateID: postListValue.StateID,
	};
	const url = `${INV_API_URL}UpdateVendorDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const deleteVendor = (postListValue) => {
	const data = {
		VendorID: postListValue.VendorID,
	};
	const url = `${INV_API_URL}DeleteVendorDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getStateList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		DropdownSearchValueSet: postListValue.dropdownSearchValueSet,
	};
	const url = `${INV_API_URL}GetStateList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getCityList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		DropdownSearchValueSet: postListValue.dropdownCitySearch,
	};
	const url = `${INV_API_URL}GetCityList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
