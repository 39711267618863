/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import SalesReportTypes from './sales-report.types';
import { getSalesDetails, getSalesProductDetails } from './sales-report.service';
import {
	fetchSalesDetailSuccess,
	fetchSalesDetailFailure,
	fetchSalesDetailResponseChanged,
	fetchProductSalesDetailSuccess,
	fetchProductSalesDetailFailure,
	fetchProductSalesDetailResponseChanged,
} from './sales-report.action';
import { encodeJson } from '../enode-decode';

/** *********************  START FETCH SALES DETAILS  ******************** */
export function* fetchSalesDetails({ payload }) {
	try {
		const key = 'FetchSalesDetailsKey';
		const jsonData = {
			FromDate: payload.fDate,
			ToDate: payload.tDate,
			FilterData: payload.filterData,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getSalesDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchSalesDetailFailure(responseData.data));
		} else {
			yield put(fetchSalesDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchSalesDetailFailure(error));
	}
}

export function* resetFetchSalesResponse() {
	yield put(fetchSalesDetailResponseChanged());
}

export function* onFetchSalesDetails() {
	yield takeLatest(SalesReportTypes.FETCH_SALES_DETAILS_START, fetchSalesDetails);
}

export function* onFetchSalesResponseReset() {
	yield takeLatest(
		SalesReportTypes.FETCH_SALES_DETAILS_RESPONSE_RESET_START,
		resetFetchSalesResponse,
	);
}
/** *********************  END FETCH SALES DETAILS ******************** */

/** *********************  START FETCH PRODUCT SALES DETAILS  ******************** */
export function* fetchProductSalesDetails({ payload }) {
	try {
		const key = 'FetchProductSalesDetailsKey';
		const jsonData = {
			FromDate: payload.fProdDate,
			ToDate: payload.tProdDate,
			FilterData: payload.filterProdData,
			Secratekey: key,
		};
		const encodeData = encodeJson(jsonData, key);
		const responseData = yield call(getSalesProductDetails, encodeData);
		if (responseData.data.statusCode === '100') {
			yield put(fetchProductSalesDetailFailure(responseData.data));
		} else {
			yield put(fetchProductSalesDetailSuccess(responseData.data));
		}
	} catch (error) {
		yield put(fetchProductSalesDetailFailure(error));
	}
}

export function* resetFetchProductSalesResponse() {
	yield put(fetchProductSalesDetailResponseChanged());
}

export function* onFetchProductSalesDetails() {
	yield takeLatest(SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_START, fetchProductSalesDetails);
}

export function* onFetchProductSalesResponseReset() {
	yield takeLatest(
		SalesReportTypes.FETCH_PRODUCT_SALES_DETAILS_RESPONSE_RESET_START,
		resetFetchProductSalesResponse,
	);
}
/** *********************  END FETCH PRODUCT SALES DETAILS ******************** */

export function* salesSaga() {
	yield all([
		call(onFetchSalesDetails),
		call(onFetchSalesResponseReset),
		call(onFetchProductSalesDetails),
		call(onFetchProductSalesResponseReset),
	]);
}
