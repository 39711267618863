/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import vendorProductsTypes from './productsDealsByVendor.types';

/* VENDOR PRODUCTS LIST START */
export const vendorProductsListStart = (vendorDetails) => ({
	type: vendorProductsTypes.VENDOR_PRODUCTS_LIST_START,
	payload: vendorDetails,
});

export const vendorProductsListSuccess = (vendorSuccess) => ({
	type: vendorProductsTypes.VENDOR_PRODUCTS_LIST_SUCCESS,
	payload: vendorSuccess,
});

export const vendorProductsListFailure = (error) => ({
	type: vendorProductsTypes.VENDOR_PRODUCTS_LIST_FAILURE,
	payload: error,
});

export const vendorProductsListChanged = () => ({
	type: vendorProductsTypes.VENDOR_PRODUCTS_LIST_CHANGED,
});

export const vendorProductsListReset = () => ({
	type: vendorProductsTypes.VENDOR_PRODUCTS_LIST_RESET,
});
/* VENDOR PRODUCTS LIST END */

/* VENDOR VIEW PRODUCTS LIST START */
export const vendorViewProductsStart = (vendorDetails) => ({
	type: vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_START,
	payload: vendorDetails,
});

export const vendorViewProductsSuccess = (vendorSuccess) => ({
	type: vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_SUCCESS,
	payload: vendorSuccess,
});

export const vendorViewProductsFailure = (error) => ({
	type: vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_FAILURE,
	payload: error,
});

export const vendorViewProductsChanged = () => ({
	type: vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_CHANGED,
});

export const vendorViewProductsReset = () => ({
	type: vendorProductsTypes.VENDOR_VIEW_PRODUCTS_LIST_RESET,
});
/* VENDOR VIEW PRODUCTS LIST END */
