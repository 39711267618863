// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable array-callback-return */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable no-debugger */
/* eslint-disable import/prefer-default-export */
// import JWT from 'expo-jwt';
export const addOrderItemOrderAssign = (placeOrderItems, orderItemToAdd) => {
	const orders = placeOrderItems.map((item) => {
		return item.OrderPlaceID === orderItemToAdd.OrderPlaceID
			? { ...item, ...orderItemToAdd }
			: item;
	});
	return orders;
};

export const orderDetailsOrderAssign = (orderItems) => {
	let NoOfDelivery = 0;
	let TotalAmount = 0;
	let NoOfOnlineOrders = 0;
	let OnlineAmount = 0;
	let NoOfWalletOrders = 0;
	let WalletAmount = 0;
	let NoOfCashOrders = 0;
	let CashAmount = 0;
	orderItems.map((orderItem) => {
		NoOfDelivery += 1;
		TotalAmount += orderItem.TotalPaidAmount;
		if (orderItem.OrderType === 'Online') {
			NoOfOnlineOrders += 1;
			OnlineAmount += orderItem.TotalPaidAmount;
		} else if (orderItem.OrderType === 'Wallet') {
			NoOfWalletOrders += 1;
			WalletAmount += orderItem.TotalPaidAmount;
		} else if (orderItem.OrderType === 'Cash') {
			NoOfCashOrders += 1;
			CashAmount += orderItem.TotalPaidAmount;
		}
	});
	const data = {
		NoOfDelivery,
		TotalAmount,
		NoOfOnlineOrders,
		OnlineAmount,
		NoOfWalletOrders,
		WalletAmount,
		NoOfCashOrders,
		CashAmount,
	};
	return data;
};
