/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getStocksList = (postListValue) => {
	const data = {
		StockListData: postListValue,
	};
	const url = `${API_URL}GetStocksList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const reduceStockQuantity = (postListValue) => {
	const data = {
		ReduceProductStocksData: postListValue,
	};
	const url = `${API_URL}ReduceStockQuantity`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const stockAddQuantity = (postListValue) => {
	const data = {
		StockAddData: postListValue,
	};
	const url = `${API_URL}StockAddQuantity`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const onAndOffTopSellingSwitch = (postListValue) => {
	const data = {
		UpdateTopSellingData: postListValue,
	};
	const url = `${API_URL}UpdateTopSelling`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getViewStockList = (postListValue) => {
	const data = {
		GetViewStockListData: postListValue,
	};
	const url = `${API_URL}GetViewStockList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
