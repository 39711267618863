/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import flowersListTypes from './addFlowers.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	flowersListResponse: null,
	flowersListResponseArr: [],
	addFlowersResponse: null,
	editFlowersResponse: null,
	removeFlowersResponse: null,
};

const flowersListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case flowersListTypes.GET_FLOWERS_LIST_SUCCESS:
			return {
				...state,
				flowersListResponse: action.payload,
				flowersListResponseArr: docodeResponse(action.payload, 'ComboProductsListKey'),
			};

		case flowersListTypes.GET_FLOWERS_LIST_FAILURE:
			return {
				...state,
				flowersListResponse: action.payload,
				flowersListResponseArr: [],
			};

		case flowersListTypes.GET_FLOWERS_LIST_CHANGED:
			return { ...state, flowersListResponse: null };

		case flowersListTypes.ADD_FLOWERS_SUCCESS:
			return {
				...state,
				addFlowersResponse: action.payload,
			};

		case flowersListTypes.ADD_FLOWERS_FAILURE:
			return {
				...state,
				addFlowersResponse: action.payload,
			};

		case flowersListTypes.ADD_FLOWERS_CHANGED:
			return { ...state, addFlowersResponse: null };

		case flowersListTypes.EDIT_FLOWERS_SUCCESS:
			return {
				...state,
				editFlowersResponse: action.payload,
			};

		case flowersListTypes.EDIT_FLOWERS_FAILURE:
			return {
				...state,
				editFlowersResponse: action.payload,
			};

		case flowersListTypes.EDIT_FLOWERS_CHANGED:
			return { ...state, editFlowersResponse: null };

		case flowersListTypes.REMOVE_FLOWERS_SUCCESS:
			return {
				...state,
				removeFlowersResponse: action.payload,
			};

		case flowersListTypes.REMOVE_FLOWERS_FAILURE:
			return {
				...state,
				removeFlowersResponse: action.payload,
			};

		case flowersListTypes.REMOVE_FLOWERS_CHANGED:
			return { ...state, removeFlowersResponse: null };

		default:
			return state;
	}
};

export default flowersListReducer;
