/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import todayDeliveredTypes from './todayDelivered.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	todayDeliveredResponse: null,
	todayDeliveredListArr: [],
	todayDeliveredResponseStatus: null,
	viewTodayOrderResponse: null,
	viewTodayOrderResponseStatus: null,
	viewTodayOrderArr: [],
};

const todayDeliveredReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_SUCCESS:
			return {
				...state,
				todayDeliveredResponse: action.payload,
				todayDeliveredListArr: docodeResponse(action.payload, 'GetProductOrderListKey'),
				todayDeliveredResponseStatus: 'Success',
			};

		case todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_FAILURE:
			return {
				...state,
				todayDeliveredResponse: action.payload,
				todayDeliveredListArr: [],
				todayDeliveredResponseStatus: 'Failure',
			};

		case todayDeliveredTypes.GET_TODAY_DELIVERED_LIST_CHANGED:
			return { ...state, todayDeliveredResponse: null, todayDeliveredResponseStatus: null };

		case todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_SUCCESS:
			return {
				...state,
				viewTodayOrderResponse: action.payload,
				viewTodayOrderArr: docodeResponse(action.payload, 'OrderDetailsViewKey'),
				viewTodayOrderResponseStatus: 'Success',
			};

		case todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_FAILURE:
			return {
				...state,
				viewTodayOrderResponse: action.payload,
				viewTodayOrderArr: [],
				viewTodayOrderResponseStatus: 'Failure',
			};

		case todayDeliveredTypes.VIEW_TODAY_ORDERS_LIST_CHANGED:
			return {
				...state,
				viewTodayOrderResponse: null,
				viewTodayOrderResponseStatus: null,
			};

		default:
			return state;
	}
};

export default todayDeliveredReducer;
