// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-debugger */
// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-unused-vars */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import orderReportType from './orderReport.types';
import { getOrderReport, getOrderReportView } from './orderReport.service';
import {
	oderReportListSuccess,
	oderReportListFailure,
	oderReportListChanged,
	oderReportViewSuccess,
	oderReportViewFailure,
	oderReportViewChanged,
} from './orderReport.action';
import { encodeJson } from '../enode-decode';

/* ORDER REPORT LIST START */
export function* orderReportList({ payload }) {
	try {
		const key = 'Get_OrderReport_List';
		const jsonData = {
			FromDate: payload.fromDate,
			ToDate: payload.toDate,
			Limit: payload.limit,
			BranchID: payload.branchID,
			OrderFilter: payload.orderFilter,
			SearchValueSet: payload.searchValueSet,
			Secratekey: key,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(getOrderReport, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(oderReportListFailure(responseData.data));
		} else {
			yield put(oderReportListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(oderReportListFailure(error));
	}
}

export function* orderReportReset() {
	yield put(oderReportListChanged());
}

export function* getOrderReportListReset() {
	yield takeLatest(orderReportType.ORDER_REPORT_LIST_RESET, orderReportReset);
}

export function* getOrderReportList() {
	yield takeLatest(orderReportType.ORDER_REPORT_LIST_START, orderReportList);
}
/* ORDER REPORT LIST END */

/* ORDER REPORT VIEW START */
export function* orderReportView({ payload }) {
	try {
		const key = 'OrderDetailsViewKey';
		const jsonData = {
			OrderPlaceID: payload.orderPlaceID,
			Secratekey: key,
		};
		const refundData = encodeJson(jsonData, key);
		const responseData = yield call(getOrderReportView, refundData);
		if (responseData.data.statusCode === '100') {
			yield put(oderReportViewFailure(responseData.data));
		} else {
			yield put(oderReportViewSuccess(responseData.data));
		}
	} catch (error) {
		yield put(oderReportViewFailure(error));
	}
}

export function* orderReportViewReset() {
	yield put(oderReportViewChanged());
}

export function* getOrderReportViewReset() {
	yield takeLatest(orderReportType.ORDER_REPORT_VIEW_RESET, orderReportViewReset);
}

export function* getOrderReportListView() {
	yield takeLatest(orderReportType.ORDER_REPORT_VIEW_START, orderReportView);
}
/* ORDER REPORT VIEW END */

export function* orderReportSaga() {
	yield all([
		call(getOrderReportList),
		call(getOrderReportListReset),
		call(getOrderReportListView),
		call(getOrderReportViewReset),
	]);
}
