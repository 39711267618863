/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { HUB_API_URL } from '../const';

export const getHubList = (postListValue) => {
	const data = {
		Limit: postListValue.limit,
		SearchValueSet: postListValue.searchValueSet,
	};
	const url = `${HUB_API_URL}GetHubList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const createHub = (postListValue) => {
	const data = {
		HubName: postListValue.HubName,
		PropritorName: postListValue.PropritorName,
		PhoneNumber: postListValue.PhoneNumber,
		HubPassword: postListValue.HubPassword,
		Gender: postListValue.Gender,
		DOB: postListValue.DOB,
		HubAddress1: postListValue.HubAddress1,
		HubAddress2: postListValue.HubAddress2,
		HubPincode: postListValue.HubPincode,
		HubCityID: postListValue.HubCityID,
		HubStateID: postListValue.HubStateID,
		HubPhoto: postListValue.HubPhoto,
		HubLatitude: postListValue.HubLatitude,
		HubLogitude: postListValue.HubLogitude,
		IsDeliveryPatner: postListValue.IsDeliveryPatner,
		TokenPaid: postListValue.TokenPaid,
		TypeOfHouse: postListValue.TypeOfHouse,
		PropertyType: postListValue.Propertytype,
		PropertyPhoto1: postListValue.PropertyPhoto1,
		PropertyPhoto2: postListValue.PropertyPhoto2,
		AlternatePhoneNumber: postListValue.AlternatePhoneNumber,
		AadharCardNumber: postListValue.AadharCardNumber,
		PanCard: postListValue.PanCard,
		RationCard: postListValue.RationCard,
		IsMarried: postListValue.IsMarried,
		SpousesName: postListValue.SpousesName,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${HUB_API_URL}CreateHubDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const editHub = (postListValue) => {
	const data = {
		HubID: postListValue.HubID,
		HubName: postListValue.HubName,
		PropritorName: postListValue.PropritorName,
		PhoneNumber: postListValue.PhoneNumber,
		HubPassword: postListValue.HubPassword,
		Gender: postListValue.Gender,
		DOB: postListValue.DOB,
		Age: postListValue.Age,
		HubAddress1: postListValue.HubAddress1,
		HubAddress2: postListValue.HubAddress2,
		HubPincode: postListValue.HubPincode,
		HubCityID: postListValue.HubCityID,
		HubStateID: postListValue.HubStateID,
		HubPhoto: postListValue.HubPhoto,
		HubLatitude: postListValue.HubLatitude,
		HubLogitude: postListValue.HubLogitude,
		IsDeliveryPatner: postListValue.IsDeliveryPatner,
		TokenPaid: postListValue.TokenPaid,
		TypeOfHouse: postListValue.TypeOfHouse,
		PropertyType: postListValue.Propertytype,
		PropertyPhoto1: postListValue.PropertyPhoto1,
		PropertyPhoto2: postListValue.PropertyPhoto2,
		AlternatePhoneNumber: postListValue.AlternatePhoneNumber,
		AadharCardNumber: postListValue.AadharCardNumber,
		PanCard: postListValue.PanCard,
		RationCard: postListValue.RationCard,
		IsMarried: postListValue.IsMarried,
		SpousesName: postListValue.SpousesName,
		CreatedBy: postListValue.CreatedBy,
	};
	const url = `${HUB_API_URL}UpdateHubDetails`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
