/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const sendPushNotification = (notificationData) => {
	const data = {
		PushNotificationSendToken: notificationData,
	};
	const url = `${API_URL}SendPushNotification`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const getNotificationCustomerList = (notificationData) => {
	const data = {
		GetNotificationCustomerData: notificationData,
	};
	const url = `${API_URL}GetNotificationCustomerList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
