/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getWalletCustomersList = (fetchData) => {
	const data = {
		GetWalletCustomerListToken: fetchData,
	};
	const url = `${API_URL}GetWalletCustomersList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const walletCustomersUpdate = (fetchData) => {
	const data = {
		UpdateWalletAmtToken: fetchData,
	};
	const url = `${API_URL}UpdateWalletCustomer`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
