/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import pushNotificationTypes from './pushNotification.types';

/* SEND PUSH NOTIFICATION START */
export const sendPushNotificationStart = (notificationDetails) => ({
	type: pushNotificationTypes.SEND_PUSH_NOTIFICATION_START,
	payload: notificationDetails,
});

export const sendPushNotificationSuccess = (notificationDetails) => ({
	type: pushNotificationTypes.SEND_PUSH_NOTIFICATION_SUCCESS,
	payload: notificationDetails,
});

export const sendPushNotificationFailure = (error) => ({
	type: pushNotificationTypes.SEND_PUSH_NOTIFICATION_FAILURE,
	payload: error,
});

export const sendPushNotificationChanged = () => ({
	type: pushNotificationTypes.SEND_PUSH_NOTIFICATION_CHANGED,
});

export const resetSendPushNotificationResponse = () => ({
	type: pushNotificationTypes.SEND_PUSH_NOTIFICATION_RESET,
});
/* SEND PUSH NOTIFICATION END */

/* NOTIFICATION CUSTOMER LIST START */
export const notificationCustomerListStart = (notificationDetails) => ({
	type: pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_START,
	payload: notificationDetails,
});

export const notificationCustomerListSuccess = (notificationDetails) => ({
	type: pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_SUCCESS,
	payload: notificationDetails,
});

export const notificationCustomerListFailure = (error) => ({
	type: pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_FAILURE,
	payload: error,
});

export const notificationCustomerListChanged = () => ({
	type: pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_CHANGED,
});

export const resetNotificationCustomerListResponse = () => ({
	type: pushNotificationTypes.NOTIFICATION_CUSTOMER_LIST_RESET,
});
/* NOTIFICATION CUSTOMER LIST END */
