/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable prettier/prettier */
const StaffReportTypes = {
	STAFF_REPORT_DETAILS_START: 'staff_report_details_start',
	STAFF_REPORT_DETAILS_SUCCESS: 'staff_report_details_success',
	STAFF_REPORT_DETAILS_FAILURE: 'staff_report_details_failure',
	STAFF_REPORT_DETAILS_RESPONSE_RESET_START: 'staff_report_details_response_reset_start',
	STAFF_REPORT_DETAILS_RESPONSE_CHANGED: 'staff_report_details_response_reset_changed',
};
export default StaffReportTypes;
