/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import comboListTypes from './comboList.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	comboListResponse: null,
	comboResponseListArr: [],
	insertComboResponse: null,
	editComboResponse: null,
	removeComboResponse: null,
	addComboProductResponse: null,
	addComboProductArr: [],
	createProductResponse: null,
	getProductsListResponse: null,
	getProductsListArr: [],
	deleteProductsResponse: null,
	isFestivalResponse: null,
};

const comboListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case comboListTypes.GET_COMBO_LIST_SUCCESS:
			return {
				...state,
				comboListResponse: action.payload,
				comboResponseListArr: docodeResponse(action.payload, 'GetComboListProductsKey'),
			};

		case comboListTypes.GET_COMBO_LIST_FAILURE:
			return {
				...state,
				comboListResponse: action.payload,
				comboResponseListArr: [],
			};

		case comboListTypes.GET_COMBO_LIST_CHANGED:
			return { ...state, comboListResponse: null };

		case comboListTypes.INSERT_COMBO_RESPONSE_SUCCESS:
			return { ...state, insertComboResponse: action.payload };
		case comboListTypes.INSERT_COMBO_RESPONSE_FAILURE:
			return { ...state, insertComboResponse: action.payload };
		case comboListTypes.INSERT_COMBO_RESPONSE_CHANGED:
			return { ...state, insertComboResponse: null };

		case comboListTypes.UPDATE_COMBO_RESPONSE_SUCCESS:
			return { ...state, editComboResponse: action.payload };
		case comboListTypes.UPDATE_COMBO_RESPONSE_FAILURE:
			return { ...state, editComboResponse: action.payload };
		case comboListTypes.UPDATE_COMBO_RESPONSE_CHANGED:
			return { ...state, editComboResponse: null };

		case comboListTypes.DELETE_COMBO_RESPONSE_SUCCESS:
			return { ...state, removeComboResponse: action.payload };
		case comboListTypes.DELETE_COMBO_RESPONSE_FAILURE:
			return { ...state, removeComboResponse: action.payload };
		case comboListTypes.DELETE_COMBO_RESPONSE_CHANGED:
			return { ...state, removeComboResponse: null };

		case comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_SUCCESS:
			return {
				...state,
				addComboProductResponse: action.payload,
				addComboProductArr: docodeResponse(action.payload, 'GetAddComboProductsKey'),
			};
		case comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_FAILURE:
			return {
				...state,
				addComboProductResponse: action.payload,
				addComboProductArr: [],
			};
		case comboListTypes.ADD_COMBO_PRODUCT_RESPONSE_CHANGED:
			return { ...state, addComboProductResponse: null };

		case comboListTypes.CREATE_PRODUCT_RESPONSE_SUCCESS:
			return {
				...state,
				createProductResponse: action.payload,
			};
		case comboListTypes.CREATE_PRODUCT_RESPONSE_FAILURE:
			return {
				...state,
				createProductResponse: action.payload,
			};
		case comboListTypes.CREATE_PRODUCT_RESPONSE_CHANGED:
			return { ...state, createProductResponse: null };

		case comboListTypes.GET_PRODUCT_LIST_RESPONSE_SUCCESS:
			return {
				...state,
				getProductsListResponse: action.payload,
				getProductsListArr: docodeResponse(action.payload, 'GetProductsListKey'),
			};
		case comboListTypes.GET_PRODUCT_LIST_RESPONSE_FAILURE:
			return {
				...state,
				getProductsListResponse: action.payload,
				getProductsListArr: [],
			};
		case comboListTypes.GET_PRODUCT_LIST_RESPONSE_CHANGED:
			return { ...state, getProductsListResponse: null };

		case comboListTypes.DELETE_PRODUCT_SUCCESS:
			return {
				...state,
				deleteProductsResponse: action.payload,
			};
		case comboListTypes.DELETE_PRODUCT_FAILURE:
			return {
				...state,
				deleteProductsResponse: action.payload,
			};
		case comboListTypes.DELETE_PRODUCT_CHANGED:
			return { ...state, deleteProductsResponse: null };

		case comboListTypes.IS_FESTIVAL_SWITCH_SUCCESS:
			return {
				...state,
				isFestivalResponse: action.payload,
			};
		case comboListTypes.IS_FESTIVAL_SWITCH_FAILURE:
			return {
				...state,
				isFestivalResponse: action.payload,
			};
		case comboListTypes.IS_FESTIVAL_SWITCH_CHANGED:
			return { ...state, isFestivalResponse: null };

		default:
			return state;
	}
};

export default comboListReducer;
