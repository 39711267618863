const productListTypes = {
	GET_PRODUCT_LIST_START: 'GET_PRODUCT_LIST_START',
	GET_PRODUCT_LIST_SUCCESS: 'GET_PRODUCT_LIST_SUCCESS',
	GET_PRODUCT_LIST_FAILURE: 'GET_PRODUCT_LIST_FAILURE',
	GET_PRODUCT_LIST_RESET: 'GET_PRODUCT_LIST_RESET',
	GET_PRODUCT_LIST_CHANGED: 'GET_PRODUCT_LIST_CHANGED',

	ADD_PRODUCT_START: 'ADD_PRODUCT_START',
	ADD_PRODUCT_SUCCESS: 'ADD_PRODUCT_SUCCESS',
	ADD_PRODUCT_FAILURE: 'ADD_PRODUCT_FAILURE',
	ADD_PRODUCT_RESET: 'ADD_PRODUCT_RESET',
	ADD_PRODUCT_CHANGED: 'ADD_PRODUCT_CHANGED',

	EDIT_PRODUCT_START: 'EDIT_PRODUCT_START',
	EDIT_PRODUCT_SUCCESS: 'EDIT_PRODUCT_SUCCESS',
	EDIT_PRODUCT_FAILURE: 'EDIT_PRODUCT_FAILURE',
	EDIT_PRODUCT_RESET: 'EDIT_PRODUCT_RESET',
	EDIT_PRODUCT_CHANGED: 'EDIT_PRODUCT_CHANGED',

	GET_PRODUCT_OPTIONS_LIST_START: 'GET_PRODUCT_OPTIONS_LIST_START',
	GET_PRODUCT_OPTIONS_LIST_SUCCESS: 'GET_PRODUCT_OPTIONS_LIST_SUCCESS',
	GET_PRODUCT_OPTIONS_LIST_FAILURE: 'GET_PRODUCT_OPTIONS_LIST_FAILURE',
	GET_PRODUCT_OPTIONS_LIST_RESET: 'GET_PRODUCT_OPTIONS_LIST_RESET',
	GET_PRODUCT_OPTIONS_LIST_CHANGED: 'GET_PRODUCT_OPTIONS_LIST_CHANGED',

	ADD_PRODUCT_OPTIONS_START: 'ADD_PRODUCT_OPTIONS_START',
	ADD_PRODUCT_OPTIONS_SUCCESS: 'ADD_PRODUCT_OPTIONS_SUCCESS',
	ADD_PRODUCT_OPTIONS_FAILURE: 'ADD_PRODUCT_OPTIONS_FAILURE',
	ADD_PRODUCT_OPTIONS_RESET: 'ADD_PRODUCT_OPTIONS_RESET',
	ADD_PRODUCT_OPTIONS_CHANGED: 'ADD_PRODUCT_OPTIONS_CHANGED',

	REMOVE_PRODUCT_OPTIONS_START: 'REMOVE_PRODUCT_OPTIONS_START',
	REMOVE_PRODUCT_OPTIONS_SUCCESS: 'REMOVE_PRODUCT_OPTIONS_SUCCESS',
	REMOVE_PRODUCT_OPTIONS_FAILURE: 'REMOVE_PRODUCT_OPTIONS_FAILURE',
	REMOVE_PRODUCT_OPTIONS_RESET: 'REMOVE_PRODUCT_OPTIONS_RESET',
	REMOVE_PRODUCT_OPTIONS_CHANGED: 'REMOVE_PRODUCT_OPTIONS_CHANGED',

	UPDATE_PRODUCT_OPTIONS_START: 'UPDATE_PRODUCT_OPTIONS_START',
	UPDATE_PRODUCT_OPTIONS_SUCCESS: 'UPDATE_PRODUCT_OPTIONS_SUCCESS',
	UPDATE_PRODUCT_OPTIONS_FAILURE: 'UPDATE_PRODUCT_OPTIONS_FAILURE',
	UPDATE_PRODUCT_OPTIONS_RESET: 'UPDATE_PRODUCT_OPTIONS_RESET',
	UPDATE_PRODUCT_OPTIONS_CHANGED: 'UPDATE_PRODUCT_OPTIONS_CHANGED',
};
export default productListTypes;
