/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getBannerList = (postListValue) => {
	const data = {
		BannerListToken: postListValue,
	};
	const url = `${API_URL}GetAllBanner`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const addBanner = (postListValue) => {
	const data = {
		BannerAddDetailsToken: postListValue,
	};
	const url = `${API_URL}CreateBanner`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const bannerUpdate = (postListValue) => {
	const data = {
		BannerUpdateToken: postListValue,
	};
	const url = `${API_URL}UpdateBanner`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const bannerDelete = (postListValue) => {
	const data = {
		BannerDeleteToken: postListValue,
	};
	const url = `${API_URL}RemoveBanner`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
