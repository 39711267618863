/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import couponAddListTypes from './couponAddList.types';

const INITIAL_STATE = {
	couponListResponse: null,
	couponListResponseStatus: null,
	couponCreateResponse: null,
	couponCreateStatus: null,
	couponUpdateResponse: null,
	couponUpdateStatus: null,
	couponOffResponse: null,
	couponOffStatus: null,
	couponOnResponse: null,
	couponOnStatus: null,
};

const couponListReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case couponAddListTypes.COUPON_LIST_SUCCESS:
			return {
				...state,
				couponListResponse: action.payload,
				couponListResponseStatus: 'Success',
			};

		case couponAddListTypes.COUPON_LIST_FAILURE:
			return {
				...state,
				couponListResponse: action.payload,
				couponListResponseStatus: 'Failure',
			};

		case couponAddListTypes.COUPON_LIST_CHANGED:
			return { ...state, couponListResponse: null, couponListResponseStatus: null };

		case couponAddListTypes.COUPON_CREATE_REPSONSE_SUCCESS:
			return {
				...state,
				couponCreateResponse: action.payload,
				couponCreateStatus: 'Success',
			};
		case couponAddListTypes.COUPON_CREATE_REPSONSE_FAILURE:
			return {
				...state,
				couponCreateResponse: action.payload,
				couponCreateStatus: 'Failure',
			};
		case couponAddListTypes.COUPON_CREATE_REPSONSE_CHANGED:
			return { ...state, couponCreateResponse: null, couponCreateStatus: null };

		case couponAddListTypes.COUPON_UPDATE_REPSONSE_SUCCESS:
			return {
				...state,
				couponUpdateResponse: action.payload,
				couponUpdateStatus: 'Success',
			};
		case couponAddListTypes.COUPON_UPDATE_REPSONSE_FAILURE:
			return {
				...state,
				couponUpdateResponse: action.payload,
				couponUpdateStatus: 'Failure',
			};
		case couponAddListTypes.COUPON_UPDATE_REPSONSE_CHANGED:
			return { ...state, couponUpdateResponse: null, couponUpdateStatus: null };

		case couponAddListTypes.COUPON_OFF_REPSONSE_SUCCESS:
			return {
				...state,
				couponOffResponse: action.payload,
				couponOffStatus: 'Success',
			};
		case couponAddListTypes.COUPON_OFF_REPSONSE_FAILURE:
			return {
				...state,
				couponOffResponse: action.payload,
				couponOffStatus: 'Failure',
			};
		case couponAddListTypes.COUPON_OFF_REPSONSE_CHANGED:
			return { ...state, couponOffResponse: null, couponOffStatus: null };

		case couponAddListTypes.COUPON_ON_REPSONSE_SUCCESS:
			return {
				...state,
				couponOnResponse: action.payload,
				couponOnStatus: 'Success',
			};
		case couponAddListTypes.COUPON_ON_REPSONSE_FAILURE:
			return {
				...state,
				couponOnResponse: action.payload,
				couponOnStatus: 'Failure',
			};
		case couponAddListTypes.COUPON_ON_REPSONSE_CHANGED:
			return { ...state, couponOnResponse: null, couponOnStatus: null };

		default:
			return state;
	}
};

export default couponListReducer;
