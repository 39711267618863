/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/namespace */
/* eslint-disable import/named */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import priceUpdateAllProductsTypes from './priceUpdateAllProducts.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	getAllProductsListArr: [],
	getAllProductsListResp: null,

	updatePriceAllProductsResp: null,
};

const priceUpdateAllProductsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_SUCCESS:
			return {
				...state,
				getAllProductsListResp: action.payload,
				getAllProductsListArr: docodeResponse(action.payload, 'GetAllProductsListKey'),
			};

		case priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_FAILURE:
			return {
				...state,
				getAllProductsListResp: action.payload,
				getAllProductsListArr: [],
			};

		case priceUpdateAllProductsTypes.GET_ALL_PRODUCTS_DETAILS_RESPONSE_CHANGED:
			return { ...state, getAllProductsListResp: null };

		case priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_SUCCESS:
			return {
				...state,
				updatePriceAllProductsResp: action.payload,
			};

		case priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_FAILURE:
			return {
				...state,
				updatePriceAllProductsResp: action.payload,
			};

		case priceUpdateAllProductsTypes.PRICE_UPDATE_ALL_PRODUCTS_RESPONSE_CHANGED:
			return { ...state, updatePriceAllProductsResp: null };

		default:
			return state;
	}
};

export default priceUpdateAllProductsReducer;
