// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-duplicate-case */
/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable default-param-last */

import refundTypes from './refund.types';
import { docodeResponse } from '../common/common.utils';

const INITIAL_STATE = {
	refundResponse: null,
	refundList: [],
	refundUpdateResponse: null,
	viewRefundedResponse: null,
	viewRefundedList: [],
};

const refundReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case refundTypes.REFUND_LIST_SUCCESS:
			return {
				...state,
				refundResponse: action.payload,
				refundList: docodeResponse(action.payload, 'GetCustomerOnlineAndWalletListKey'),
			};

		case refundTypes.REFUND_LIST_FAILURE:
			return {
				...state,
				refundResponse: action.payload,
				refundList: [],
			};

		case refundTypes.REFUND_LIST_CHANGED:
			return { ...state, refundResponse: null };

		/** *REFUND UPDATE ***** */

		case refundTypes.REFUND_UPDATE_SUCCESS:
			return {
				...state,
				refundUpdateResponse: action.payload,
			};

		case refundTypes.REFUND_UPDATE_FAILURE:
			return {
				...state,
				refundUpdateResponse: action.payload,
			};

		case refundTypes.REFUND_UPDATE_CHANGED:
			return { ...state, refundUpdateResponse: null };

		case refundTypes.VIEW_REFUNDED_LIST_SUCCESS:
			return {
				...state,
				viewRefundedResponse: action.payload,
				viewRefundedList: docodeResponse(action.payload, 'GetCustomerRefundedListKey'),
			};

		case refundTypes.VIEW_REFUNDED_LIST_FAILURE:
			return {
				...state,
				viewRefundedResponse: action.payload,
				viewRefundedList: [],
			};

		case refundTypes.VIEW_REFUNDED_LIST_CHANGED:
			return { ...state, viewRefundedResponse: null };

		default:
			return state;
	}
};

export default refundReducer;
