/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import collectionsTypes from './collections.types';

/* GET COLLECTIONS LIST START */
export const collectionsListStart = (ruleDetails) => ({
	type: collectionsTypes.GET_COLLECTIONS_LIST_START,
	payload: ruleDetails,
});

export const collectionsListSuccess = (ruleSuccess) => ({
	type: collectionsTypes.GET_COLLECTIONS_LIST_SUCCESS,
	payload: ruleSuccess,
});

export const collectionsListFailure = (error) => ({
	type: collectionsTypes.GET_COLLECTIONS_LIST_FAILURE,
	payload: error,
});

export const collectionsListChanged = () => ({
	type: collectionsTypes.GET_COLLECTIONS_LIST_CHANGED,
});

export const collectionsListReset = () => ({
	type: collectionsTypes.GET_COLLECTIONS_LIST_RESET,
});
/* GET COLLECTIONS LIST END */
