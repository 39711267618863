/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import axios from 'axios';
import { API_URL } from '../const';

export const getAllProductsList = (fetchData) => {
	const data = {
		GetAllProductsListData: fetchData,
	};
	const url = `${API_URL}getAllProductsList`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};

export const updateAllProductsPrice = (fetchData) => {
	const data = {
		UpdateAllProductsPriceData: fetchData,
	};
	const url = `${API_URL}updateAllProductsPrice`;
	return axios.post(url, data, { headers: { 'Content-type': 'application/json' } });
};
