/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
/* eslint-disable no-debugger */
import { takeLatest, put, all, call } from 'redux-saga/effects';

import vendorTypes from './vendor.types';
import {
	getVendorList,
	createVendor,
	updateVendor,
	deleteVendor,
	getStateList,
	getCityList,
} from './vendor.service';
import {
	vendorListSuccess,
	vendorListFailure,
	vendorListChanged,
	createVendorSuccess,
	createVendorFailure,
	createVendorChanged,
	updateVendorSuccess,
	updateVendorFailure,
	updateVendorChanged,
	deleteVendorSuccess,
	deleteVendorFailure,
	deleteVendorChanged,
	vendorStateListSuccess,
	vendorStateListFailure,
	vendorStateListChanged,
	vendorCityListSuccess,
	vendorCityListFailure,
	vendorCityListChanged,
} from './vendor.action';

/* VENDOR  LIST START */
export function* vendorListDetailsStart({ payload: { listOfVendor } }) {
	try {
		const responseData = yield call(getVendorList, listOfVendor);
		if (responseData.data.statusCode === '100') {
			yield put(vendorListFailure(responseData.data));
		} else {
			yield put(vendorListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(vendorListFailure(error));
	}
}

export function* vendorListDetailsChanged() {
	yield put(vendorListChanged());
}

export function* vendorListDetails() {
	yield takeLatest(vendorTypes.VENDOR_LIST_START, vendorListDetailsStart);
}

export function* vendorListResponseReset() {
	yield takeLatest(vendorTypes.VENDOR_LIST_RESET, vendorListDetailsChanged);
}
/* VENDOR  LIST END */

/* VENDOR CREATE START */
export function* createNewVendor({ payload: { values, cityID, stateID } }) {
	try {
		const jsonData = {
			VendorName: values.vendorName,
			CityID: cityID,
			StateID: stateID,
			VendorAddress: values.vendorAddress,
			VendorPhoneNumber: values.vendorPhoneNumber,
			VendorEmailID: values.vendorEmailID,
			ContactPersonOneName: values.contactPersonOneName,
			ContactPersonOnePhoneNumber: values.contactPersonOnePhoneNumber,
			ContactPersonTwoName: values.contactPersonTwoName,
			ContactPersonTwoPhoneNumber: values.contactPersonTwoPhoneNumber,
		};
		const responseData = yield call(createVendor, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(createVendorFailure(responseData.data));
		} else {
			yield put(createVendorSuccess(responseData.data));
		}
	} catch (error) {
		yield put(createVendorFailure(error));
	}
}

export function* createVendorResponseReset() {
	yield put(createVendorChanged());
}

export function* vendorCreateStart() {
	yield takeLatest(vendorTypes.VENDOR_CREATE_START, createNewVendor);
}

export function* vendorCreateResponseReset() {
	yield takeLatest(vendorTypes.VENDOR_CREATE_RESET, createVendorResponseReset);
}
/* VENDOR CREATE END */

/* VENDOR UPDATE START */
export function* updateVendorDetails({ payload: { values, cityID, stateID } }) {
	try {
		const jsonData = {
			VendorID: values.vendorID,
			VendorName: values.vendorName,
			CityID: cityID,
			StateID: stateID,
			VendorAddress: values.vendorAddress,
			VendorPhoneNumber: values.vendorPhoneNumber,
			VendorEmailID: values.vendorEmailID,
			ContactPersonOneName: values.contactPersonOneName,
			ContactPersonOnePhoneNumber: values.contactPersonOnePhoneNumber,
			ContactPersonTwoName: values.contactPersonTwoName,
			ContactPersonTwoPhoneNumber: values.contactPersonTwoPhoneNumber,
		};
		const responseData = yield call(updateVendor, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(updateVendorFailure(responseData.data));
		} else {
			yield put(updateVendorSuccess(responseData.data));
		}
	} catch (error) {
		yield put(updateVendorFailure(error));
	}
}

export function* updateVendorResponseReset() {
	yield put(updateVendorChanged());
}

export function* vendorUpdateStart() {
	yield takeLatest(vendorTypes.VENDOR_UPDATE_START, updateVendorDetails);
}

export function* vendorUpdateResponseReset() {
	yield takeLatest(vendorTypes.VENDOR_UPDATE_RESET, updateVendorResponseReset);
}
/* VENDOR UPDATE END */

/* VENDOR DELETE START */
export function* deleteVendorDetails({ payload: { vendorID } }) {
	try {
		const jsonData = {
			VendorID: vendorID,
		};
		const responseData = yield call(deleteVendor, jsonData);
		if (responseData.data.statusCode === '100') {
			yield put(deleteVendorFailure(responseData.data));
		} else {
			yield put(deleteVendorSuccess(responseData.data));
		}
	} catch (error) {
		yield put(deleteVendorFailure(error));
	}
}

export function* deleteVendorResponseReset() {
	yield put(deleteVendorChanged());
}

export function* vendorDeleteStart() {
	yield takeLatest(vendorTypes.VENDOR_DELETE_START, deleteVendorDetails);
}

export function* vendorDeleteResponseReset() {
	yield takeLatest(vendorTypes.VENDOR_DELETE_RESET, deleteVendorResponseReset);
}
/* VENDOR DELETE END */

/* VENDOR STATE LIST START */
export function* vendorStateListDetailsStart({ payload: { listOfState } }) {
	try {
		const responseData = yield call(getStateList, listOfState);
		if (responseData.data.statusCode === '100') {
			yield put(vendorStateListFailure(responseData.data));
		} else {
			yield put(vendorStateListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(vendorStateListFailure(error));
	}
}

export function* vendorStateListDetailsChanged() {
	yield put(vendorStateListChanged());
}

export function* vendorStateListDetails() {
	yield takeLatest(vendorTypes.VENDOR_STATE_LIST_START, vendorStateListDetailsStart);
}

export function* vendorStateListResponseReset() {
	yield takeLatest(vendorTypes.VENDOR_STATE_LIST_RESET, vendorStateListDetailsChanged);
}
/* VENDOR STATE LIST END */

/* VENDOR CITY LIST START */
export function* vendorCityListDetailsStart({ payload: { listOfCity } }) {
	try {
		const responseData = yield call(getCityList, listOfCity);
		if (responseData.data.statusCode === '100') {
			yield put(vendorCityListFailure(responseData.data));
		} else {
			yield put(vendorCityListSuccess(responseData.data));
		}
	} catch (error) {
		yield put(vendorCityListFailure(error));
	}
}

export function* vendorCityListDetailsChanged() {
	yield put(vendorCityListChanged());
}

export function* vendorCityListDetails() {
	yield takeLatest(vendorTypes.VENDOR_CITY_LIST_START, vendorCityListDetailsStart);
}

export function* vendorCityListResponseReset() {
	yield takeLatest(vendorTypes.VENDOR_CITY_LIST_RESET, vendorCityListDetailsChanged);
}
/* VENDOR CITY LIST END */

export function* vendorListSaga() {
	yield all([
		call(vendorListDetails),
		call(vendorListResponseReset),
		call(vendorCreateStart),
		call(vendorCreateResponseReset),
		call(vendorUpdateStart),
		call(vendorUpdateResponseReset),
		call(vendorDeleteStart),
		call(vendorDeleteResponseReset),
		call(vendorStateListDetails),
		call(vendorStateListResponseReset),
		call(vendorCityListDetails),
		call(vendorCityListResponseReset),
	]);
}
