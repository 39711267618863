/* eslint-disable eslint-comments/disable-enable-pair */
/* eslint-disable import/prefer-default-export */
import productOrderTypes from './productOrder.types';

/* PRODUCT ORDER LIST START */
export const productOrderStart = (productOrderDetails) => ({
	type: productOrderTypes.PRODUCT_ORDER_LIST_START,
	payload: productOrderDetails,
});

export const productOrderSuccess = (productOrderListSuccess) => ({
	type: productOrderTypes.PRODUCT_ORDER_LIST_SUCCESS,
	payload: productOrderListSuccess,
});

export const productOrderFailure = (error) => ({
	type: productOrderTypes.PRODUCT_ORDER_LIST_FAILURE,
	payload: error,
});

export const productOrderChanged = () => ({
	type: productOrderTypes.PRODUCT_ORDER_LIST_CHANGED,
});

export const productOrderReset = () => ({
	type: productOrderTypes.PRODUCT_ORDER_LIST_RESET,
});
/* PRODUCT ORDER LIST END */

/* VIEW PRODUCT ORDER LIST START */
export const viewProductOrderStart = (productOrderDetails) => ({
	type: productOrderTypes.VIEW_PRODUCT_ORDER_LIST_START,
	payload: productOrderDetails,
});

export const viewProductOrderSuccess = (productOrderListSuccess) => ({
	type: productOrderTypes.VIEW_PRODUCT_ORDER_LIST_SUCCESS,
	payload: productOrderListSuccess,
});

export const viewProductOrderFailure = (error) => ({
	type: productOrderTypes.VIEW_PRODUCT_ORDER_LIST_FAILURE,
	payload: error,
});

export const viewProductOrderChanged = () => ({
	type: productOrderTypes.VIEW_PRODUCT_ORDER_LIST_CHANGED,
});

export const viewProductOrderReset = () => ({
	type: productOrderTypes.VIEW_PRODUCT_ORDER_LIST_RESET,
});
/* VIEW PRODUCT ORDER LIST END */

/* UPDATE ORDER STATUS START */
export const updateOrderStatusStart = (productOrderDetails) => ({
	type: productOrderTypes.UPDATE_ORDER_STATUS_START,
	payload: productOrderDetails,
});

export const updateOrderStatusSuccess = (productOrderListSuccess) => ({
	type: productOrderTypes.UPDATE_ORDER_STATUS_SUCCESS,
	payload: productOrderListSuccess,
});

export const updateOrderStatusFailure = (error) => ({
	type: productOrderTypes.UPDATE_ORDER_STATUS_FAILURE,
	payload: error,
});

export const updateOrderStatusChanged = () => ({
	type: productOrderTypes.UPDATE_ORDER_STATUS_CHANGED,
});

export const updateOrderStatusReset = () => ({
	type: productOrderTypes.UPDATE_ORDER_STATUS_RESET,
});
/* UPDATE ORDER STATUS END */

/* VIEW USER TRANSCATION LIST START */
export const viewTranscationListStart = (productOrderDetails) => ({
	type: productOrderTypes.VIEW_USER_TRANSCATION_LIST_START,
	payload: productOrderDetails,
});

export const viewTranscationListSuccess = (productOrderListSuccess) => ({
	type: productOrderTypes.VIEW_USER_TRANSCATION_LIST_SUCCESS,
	payload: productOrderListSuccess,
});

export const viewTranscationListFailure = (error) => ({
	type: productOrderTypes.VIEW_USER_TRANSCATION_LIST_FAILURE,
	payload: error,
});

export const viewTranscationListChanged = () => ({
	type: productOrderTypes.VIEW_USER_TRANSCATION_LIST_CHANGED,
});

export const viewTranscationListReset = () => ({
	type: productOrderTypes.VIEW_USER_TRANSCATION_LIST_RESET,
});
/* VIEW USER TRANSCATION LIST END */
